import Vue from "vue";
import i18n from "@/plugins/i18n";

export const errorHandler = (error, commit, type) => {
  const message =
    error?.response?.data?.status?.message || i18n.t("message.deleteError");

  commit("setLoading", {
    type,
    payload: message,
  });

  Vue.prototype.$notify({
    group: "info",
    type: "error",
    text: message,
  });
};
