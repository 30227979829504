export default {
  NEW_VISIT: "newvisit",
  BEFORE_VISIT: "beforevisit",
  ALARM_VISIT: "alarmvisit",
  ERROR_VISIT: "errorvisit",
  EVENT_VISIT: "eventvisit",
  SYNC_DEVICE: "syncdevice",
  LOAD_FEATURES: "loadfeatures",
  PERSON_ERRORS: "personerrors",
  PERMIT_REQUEST_LOG: "permit_request_log",
  PERMIT_REQUEST_PROGRESS: "permit_request_progress",
};
