<template>
  <v-form ref="form">
    <v-card elevation="2" :loading="isPendingStore">
      <template slot="progress">
        <v-progress-linear color="primary" height="10" indeterminate />
      </template>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-btn
              v-if="!isChangeAuth && id"
              class="mt-2"
              @click="isChangeAuth = true"
            >
              {{ $t("operators.changeAuth") }}
            </v-btn>

            <v-row v-if="!id || isChangeAuth">
              <v-col v-if="!id">
                <v-text-field
                  v-model="username"
                  :label="`${$t('auth.username')} *`"
                  outlined
                  :readonly="id > 0"
                  :error-messages="usernameErrors"
                  @input="$v.username.$touch()"
                  @blur="$v.username.$touch()"
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="password"
                  :label="`${$t('auth.password')} *`"
                  type="password"
                  outlined
                  :error-messages="passwordErrors"
                  @input="$v.password.$touch()"
                  @blur="$v.password.$touch()"
                />
              </v-col>
              <v-col>
                <v-text-field
                  ref="passwordConfirmation"
                  v-model="passwordConfirm"
                  :label="`${$t('auth.passwordConfirm')} *`"
                  type="password"
                  outlined
                  :error-messages="passwordConfirmErrors"
                  @input="$v.passwordConfirm.$touch()"
                  @blur="$v.passwordConfirm.$touch()"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" md="6">
            <v-row no-gutters>
              <v-col>
                <v-autocomplete
                  v-model="personId"
                  :loading="isLoadingPersons"
                  :items="personsWithoutOperatorId"
                  :search-input.sync="searchPersons"
                  :label="$t('requests.user')"
                  item-text="full_name"
                  item-value="id"
                  outlined
                  clearable
                >
                  <template v-slot:append-item>
                    <div v-intersect="endIntersect" />
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="auto" class="pt-3 pl-3">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      icon
                      color="primary"
                      @click="goToAddPerson"
                    >
                      <v-icon>mdi-account-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("users.addNewUser") }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>

          <v-col v-if="!isEdit || (data && !data.is_admin)" cols="12">
            <Roles ref="roles" :data="roles" @change="onChangeRoles" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn :loading="isPendingStore" @click="cancel">
          <v-icon left>mdi-cancel</v-icon>
          {{ $t("button.cancel") }}
        </v-btn>
        <v-spacer />
        <v-btn color="green" dark :loading="isPendingStore" @click="save">
          <v-icon left>mdi-content-save-outline</v-icon>
          {{ $t("button.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapActions, mapState } from "vuex";
import debounce from "lodash.debounce";

import Roles from "../Roles";

import validator from "./validator";

export default {
  name: "OperatorForm",

  mixins: [validator],

  components: { Roles },

  props: {
    id: {
      type: [Number, String],
      default: null,
    },
    data: {
      type: Object,
      default: null,
    },
    isPendingStore: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const personId = this.data?.persons_id || null;
    const roles = this.data?.roles || [
      {
        type: this.$const.ROLES.OPERATOR,
        accesslevels: [],
        departments: [],
        allowed_zones: [],
        permissions: [],
      },
    ];
    const username = this.data?.username || "";

    return {
      valid: true,

      isChangeAuth: false,

      personId,
      roles,
      username,
      password: "",
      passwordConfirm: "",

      searchPersons: null,
    };
  },

  watch: {
    searchPersons(val) {
      this.onSearchPersons(val);
    },
  },

  computed: {
    ...mapState({
      persons: (state) => state.persons.data,
      personsPagination: (state) => state.persons.pagination,
      isLoadingPersons: (state) => state.persons.isPending,
    }),

    personsWithoutOperatorId() {
      return this.persons.filter(
        (item) => !item.operator_id || item.operator_id == this.id
      );
    },
  },

  methods: {
    ...mapActions({
      getPersons: "persons/get",
    }),

    onSearchPersons: debounce(function (name) {
      const params = {};

      if (name) {
        params.search = `name:${name}`;
      }

      this.getPersons(params);
    }, 300),

    cancel() {
      this.$emit("cancel");
    },

    save() {
      if (!this.validateForm()) return;

      const data = {
        persons_id: this.personId,
      };

      if (!this.data?.is_admin) {
        data.roles = this.roles;
      }

      if (this.isChangeAuth || !this.id) {
        data.username = this.username;
        data.password = this.password;
      }

      this.$emit("store", data);
    },

    goToAddPerson() {
      const router = this.$router.resolve({
        name: this.$const.ROUTES.ADD_PERSON,
      });
      window.open(router.href, "_blank");
    },

    onChangeRoles(roles) {
      this.roles = roles;
    },

    endIntersect() {
      if (this.isLoadingPersons) return;
      if (this.personsPagination.current_page === this.personsPagination.total)
        return;

      this.getPersons({
        lazy: true,
        search: this.searchPersons,
        page: this.personsPagination.current_page + 1,
      });
    },
  },

  created() {
    this.getPersons({ search: `id:${this.personId}` });
  },
};
</script>
