<template>
  <div>
    <v-text-field
      v-model="numberState"
      :label="`${$t('permits.number')} *`"
      dense
      outlined
      append-icon="mdi-cog"
      :error-messages="errorMessages"
      @click:append="openGenerateNumberDialog"
      @input="onInputNumber"
      @change="$emit('change', $event)"
    >
      <template v-slot:message="{ message }">
        <span v-html="message" />
      </template>
    </v-text-field>

    <v-dialog v-model="isOpenGenerateNumberDialog" max-width="400">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("permits.generateCardNumber") }}
        </v-card-title>

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  :label="$t('card.series')"
                  type="number"
                  v-model="genSeries"
                  :rules="intRules"
                  @input="calcSerialAndNumber"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :label="$t('card.number')"
                  type="number"
                  v-model="genNumber"
                  :rules="intRules"
                  @input="calcSerialAndNumber"
                />
              </v-col>
            </v-row>
            <v-text-field
              :label="$t('card.hex_code') + ' ORION'"
              type="text"
              v-model="genHexCodeBolid"
              :rules="hexRules"
              @input="inputHex($event, 'genHexCodeBolid')"
            />
            <v-text-field
              :label="$t('card.hex_code')"
              type="text"
              v-model="genHexCode"
              :rules="hexRules"
              autofocus
              @input="inputHex($event, 'genHexCode')"
            />
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red" text @click="isOpenGenerateNumberDialog = false">
            <v-icon left>mdi-close</v-icon>
            {{ $t("button.cancel") }}
          </v-btn>

          <v-btn color="success" text @click="accept">
            <v-icon left>mdi-check</v-icon>
            {{ $t("button.apply") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import crc8 from "@/plugins/crc8";
import { isHexadecimal, isInt } from "validator";

export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      valid: true,

      isOpenGenerateNumberDialog: false,

      genSeries: "",
      genNumber: "",
      genHexCode: "",
      genHexCodeBolid: "",
      genDecimalNumber: this.value,
      numberState: this.value,

      hexRules: [(v) => isHexadecimal(v) || this.$t("message.isHex")],
      intRules: [(v) => isInt(v) || this.$t("message.isInt")],
    };
  },

  methods: {
    accept() {
      if (!this.$refs.form.validate()) return;

      this.isOpenGenerateNumberDialog = false;
      this.numberState = this.genDecimalNumber;
      this.$emit("input", this.genDecimalNumber);
    },

    onInputNumber(number) {
      this.genDecimalNumber = number;
      this.calcDecimalNumber();
      this.$emit("input", number);
    },

    inputHex(hex, type) {
      let newHex = hex.toUpperCase();
      newHex = newHex
        .split("")
        .map((char) => {
          switch (char) {
            case "Ф":
              return "A";
            case "И":
              return "B";
            case "С":
              return "C";
            case "В":
              return "D";
            case "У":
              return "E";
            case "А":
              return "F";
            default:
              return char;
          }
        })
        .join("");

      this[type] = newHex;

      this.calcHex();
      this.calcHexBolid();
    },

    calcSerialAndNumber() {
      if (!this.genNumber && !this.genSeries) return;

      let hser = parseInt(this.genSeries, 10).toString(16);
      if (!this.genSeries) {
        hser = "00";
      }
      let hnum = parseInt(this.genNumber, 10).toString(16);
      if (hnum.length < 4) {
        hnum = "0000" + hnum;
        hnum = hnum.substr(hnum.length - 4, 4);
      }
      if (hser.length < 2) {
        hser = "00" + hser;
        hser = hser.substr(hnum.length - 2, 2);
      }
      let hex = hser + hnum;
      this.hexString(hex);
      this.genHexCode = hex.toUpperCase();
      this.genDecimalNumber = BigInt("0x" + hex).toString(10);
    },

    calcDecimalNumber() {
      if (!this.numberState) return;

      this.genNumber = "";
      this.genSeries = "";
      this.genDecimalNumber = this.numberState.replace(/[^\d]/g, "");
      let hex = BigInt(this.genDecimalNumber, 10).toString(16);
      hex = hex.padStart(16 - hex.length, "0");
      this.genHexCode = hex.toUpperCase();

      let hnum = hex.substr(hex.length - 4, 4);

      this.genNumber = "0";
      if (hnum != "") {
        this.genNumber = parseInt(hnum, 16).toString();
      }
      let hser = hex.substr(0, hex.length - 4);

      this.hexString(hser + hnum);

      this.genSeries = "0";
      if (hser != "") {
        this.genSeries = parseInt(hser, 16).toString();
      }
    },

    calcHex() {
      if (!this.genHexCode) return;
      this.genNumber = "";
      this.genSeries = "";
      let hx = this.genHexCode;
      this.genDecimalNumber = BigInt("0x" + hx).toString(10);

      let hex = parseInt(this.genDecimalNumber, 10).toString(16);
      this.hexString(hex);
      let hnum = hex.substr(hex.length - 4, 4);
      this.genNumber = "0";
      if (hnum != "") {
        this.genNumber = parseInt(hnum, 16).toString();
      }
      let hser = hex.substr(0, hex.length - 4);
      this.genSeries = "0";
      if (hser != "") {
        this.genSeries = parseInt(hser, 16).toString();
      }
    },

    calcHexBolid() {
      if (!this.genHexCodeBolid) return;
      this.genNumber = "";
      this.genSeries = "";
      this.genHexCode = "";

      let hx = this.genHexCodeBolid.substr(2, 12);

      this.genDecimalNumber = BigInt("0x" + hx).toString(10);

      let hex = BigInt(this.genDecimalNumber).toString(16);
      this.genHexCode = hex.toUpperCase();

      let hnum = hex.substr(hex.length - 4, 4);
      this.genNumber = "0";
      if (hnum != "") {
        this.genNumber = parseInt(hnum, 16).toString();
      }
      let hser = hex.substr(0, hex.length - 4);
      this.genSeries = "0";
      if (hser != "") {
        this.genSeries = parseInt(hser, 16).toString();
      }
    },

    hexString(wiegand_hex) {
      let hh = wiegand_hex.padStart(12, "0");
      let b = [];
      let j = 0;
      hh = hh + "01";
      for (var i = 6; i >= 0; i--) {
        b[j] = parseInt(hh.substr(i * 2, 2), 16);
        j++;
      }
      hh = crc8(b).toString(16).padStart(2, "0") + hh;
      this.genHexCodeBolid = hh.toUpperCase();
    },

    openGenerateNumberDialog() {
      this.isOpenGenerateNumberDialog = true;

      this.calcDecimalNumber();
    },
  },

  created() {
    this.calcDecimalNumber();
  },
};
</script>
