<template>
  <div>
    <v-row v-if="can($const.RULES.ACCESS_LEVELS_ADD)">
      <v-col cols="12" md="auto">
        <v-btn-toggle v-model="view" dense>
          <v-btn value="list">
            <v-icon>mdi-format-list-bulleted</v-icon>
          </v-btn>

          <v-btn value="tree">
            <v-icon>mdi-file-tree</v-icon>
          </v-btn>
        </v-btn-toggle>
      </v-col>
      <v-spacer />
      <v-col cols="12" md="auto">
        <v-btn color="primary" @click="goToAdd">
          <v-icon left> mdi-plus </v-icon>
          {{ $t("departments.addNew") }}
        </v-btn>
      </v-col>
    </v-row>

    <List v-if="view === 'list'" />
    <Tree v-if="view === 'tree'" />
  </div>
</template>

<script>
import List from "./components/List";
import Tree from "./components/Tree";

import permissions from "@/mixins/permissions";

export default {
  name: "AccessLevels",

  mixins: [permissions],

  components: {
    List,
    Tree,
  },

  data() {
    return {
      view: "list",
    };
  },

  methods: {
    goToAdd() {
      this.$router.push({
        name: this.$const.ROUTES.ADD_ACCESS_LEVEL,
        params: {
          query: this.$route.query,
        },
      });
    },
  },
};
</script>
