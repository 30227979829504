<template>
  <div v-if="minify">
    <v-menu offset-y :max-width="400" :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-badge
          color="primary"
          bordered
          overlap
          :content="value.length"
          :value="value.length ? true : false"
        >
          <v-btn v-bind="attrs" v-on="on" small depressed color="blue" dark>
            {{ label }}&nbsp;
            <v-icon right> mdi-menu-down </v-icon>
          </v-btn>
        </v-badge>
      </template>

      <v-card max-height="400" max-width="500" :loading="loading">
        <v-card-text v-if="errorMessage">
          <Alert type="error" class="mb-0">
            {{ errorMessage }}
          </Alert>
        </v-card-text>

        <template v-else>
          <v-text-field
            v-model="search"
            :label="$t('common.search')"
            flat
            solo
            dense
            hide-details
            clearable
            clear-icon="mdi-close-circle-outline"
            prepend-inner-icon="mdi-magnify"
          />
          <v-divider class="m-0" />
          <v-list>
            <v-treeview
              v-model="selected"
              selectable
              :items="items"
              :search="search"
              :itemKey="itemValue"
              :itemText="itemName"
              :itemChildren="itemChildren"
              @input="$emit('input', $event)"
              v-bind="$attrs"
            />
          </v-list>
        </template>
      </v-card>
    </v-menu>
  </div>

  <div v-else class="filter__item">
    <h5 v-if="label" class="mb-2">{{ label }}</h5>
    <v-divider v-else class="m-0 mb-3" />

    <Alert v-if="errorMessage" type="error">
      {{ errorMessage }}
    </Alert>

    <v-skeleton-loader v-if="loading" type="text@3" class="p-2" />
    <template v-else>
      <v-text-field
        v-if="searchable"
        v-model="search"
        :placeholder="$t('common.search')"
        flat
        dense
        solo
        hide-details
        clearable
        clear-icon="mdi-close-circle-outline"
        prepend-inner-icon="mdi-magnify"
      />
      <v-treeview
        v-model="selected"
        selectable
        open-on-click
        :items="items"
        :search="search"
        :itemKey="itemValue"
        :itemText="itemName"
        :itemChildren="itemChildren"
        @input="$emit('input', $event)"
        v-bind="$attrs"
      />
    </template>
  </div>
</template>

<script>
import Alert from "@/components/Alert";

export default {
  name: "FilterMultiTree",

  components: { Alert },

  props: {
    value: {
      type: [Array, String, Number],
      default: () => [],
    },
    label: {
      type: String,
      default: "",
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    minify: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemName: {
      type: String,
      default: "name",
    },
    itemValue: {
      type: String,
      default: "value",
    },
    itemChildren: {
      type: String,
      default: "children",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
  },

  data() {
    const selected = this.getSelected();

    return {
      search: "",
      selected,
    };
  },

  methods: {
    getSelected() {
      let selected = [];

      if (Array.isArray(this.value)) {
        selected = this.value;
      } else {
        selected = [this.value];
      }

      return selected;
    },
  },
};
</script>
