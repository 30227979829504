var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{attrs:{"loading":_vm.isPending,"headers":_vm.headers,"items":_vm.accessLevels,"items-per-page":_vm.pagination.per_page,"hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"mb-2 mt-5"},[(_vm.errorMessage)?_c('v-col',{attrs:{"cols":"12"}},[_c('Alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('AccessLevelsFilter',{on:{"input":_vm.onChangeFilter},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)],1)]},proxy:true},{key:`item.name`,fn:function({ item }){return [(item.is_guest)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({attrs:{"left":"","small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-account-clock")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("access-levels.isGuest")))])]):_vm._e(),(_vm.can(_vm.$const.RULES.ACCESS_LEVELS_EDIT))?_c('router-link',{attrs:{"to":{
        name: _vm.$const.ROUTES.EDIT_ACCESS_LEVEL,
        params: {
          id: item.id,
          query: _vm.$route.query,
        },
      }}},[_vm._v(" "+_vm._s(item.name)+" ")]):[_vm._v(" "+_vm._s(item.name)+" ")]]}},{key:`item.parent`,fn:function({ item }){return [(item.parent)?[(_vm.can(_vm.$const.RULES.ACCESS_LEVELS_EDIT))?_c('router-link',{attrs:{"to":{
          name: _vm.$const.ROUTES.EDIT_ACCESS_LEVEL,
          params: { id: item.parent.id },
        }}},[_vm._v(" "+_vm._s(item.parent.name)+" ")]):[_vm._v(" "+_vm._s(item.name)+" ")]]:_c('span',[_vm._v(" "+_vm._s(_vm.$t("access-levels.withoutParent"))+" ")])]}},{key:`item.actions`,fn:function({ item }){return [(_vm.can(_vm.$const.RULES.ACCESS_LEVELS_EDIT))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.goToEdit(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("access-levels.edit")))])]):_vm._e(),(_vm.can(_vm.$const.RULES.ACCESS_LEVELS_DELETE))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":"red"},on:{"click":function($event){return _vm.onRemove(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("access-levels.delete")))])]):_vm._e()]}},{key:"footer",fn:function(){return [_c('DataTableFooter',{ref:"tableFooter",attrs:{"pagination":_vm.pagination,"totalText":_vm.$t('access-levels.total'),"perPage":_vm.limit},on:{"changePagination":_vm.onChangePagination,"changePerPage":_vm.onChangePerPage}})]},proxy:true}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }