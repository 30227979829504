<template>
  <v-card
    :class="['filter', 'mx-auto', { 'filter--minify': attrs.minify }]"
    outlined
    :color="attrs.minify ? '#F3F2FF' : null"
    :loading="loading"
  >
    <v-card-title class="py-2">
      {{ $t("common.filter") }}
      <v-spacer />
      <v-menu
        v-if="canMinify"
        v-model="menu"
        offset-y
        left
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-action>
                <v-switch v-model="isMinify" />
              </v-list-item-action>
              <v-list-item-title>{{ $t("filter.minify") }}</v-list-item-title>
            </v-list-item>
          </v-list>

          <v-card-actions>
            <v-spacer />

            <v-btn color="red" icon @click="onCancel"
              ><v-icon>mdi-close</v-icon></v-btn
            >
            <v-btn color="green" icon @click="onSave"
              ><v-icon>mdi-check</v-icon></v-btn
            >
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-card-title>

    <v-divider class="m-0" />

    <v-card-text>
      <slot :attrs="attrs" />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "FilterBlock",

  props: {
    minify: {
      type: Boolean,
      default: false,
    },

    canMinify: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      menu: false,
      isMinify: this.minify,

      attrs: {
        minify: this.minify,
      },
    };
  },

  methods: {
    onCancel() {
      this.isMinify = this.attrs.minify;
      this.menu = false;
    },

    onSave() {
      this.attrs = {
        minify: this.isMinify,
      };

      this.$emit("saveSettings", this.attrs);
      this.menu = false;
    },
  },
};
</script>
