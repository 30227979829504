<template>
  <v-row>
    <v-col cols="4">
      <v-text-field
        v-model="surnameCapital"
        :label="`${$t('user.surname')}*`"
        :rules="surnameRules"
        :disabled="disabled"
        outlined
        @input="change"
        @keydown.space.prevent="$refs.firstname.focus()"
      />
    </v-col>

    <v-col cols="4">
      <v-text-field
        ref="firstname"
        v-model="nameCapital"
        :label="`${$t('user.firstname')}`"
        :rules="firstnameRules"
        :disabled="disabled"
        outlined
        @input="change"
        @keydown.space.prevent="$refs.lastname.focus()"
      />
    </v-col>

    <v-col cols="4">
      <v-text-field
        ref="lastname"
        v-model="patronymicCapital"
        :label="`${$t('user.lastname')}`"
        :rules="patronymicRules"
        :disabled="disabled"
        outlined
        @input="change"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "FullName",

  props: {
    surname: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    patronymic: {
      type: String,
      default: "",
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    surname(surname) {
      this.surnameState = surname;
    },

    name(name) {
      this.nameState = name;
    },

    patronymic(patronymic) {
      this.patronymicState = patronymic;
    },
  },

  data() {
    return {
      surnameState: this.surname,
      nameState: this.name,
      patronymicState: this.patronymic,

      surnameRules: [
        (v) => !!v || this.$t("message.NameIsRequired"),
        (v) => v.length <= 40 || this.$t("user.nameSizeValidate"),
        // (v) => {
        //   if (this.surnameState) {
        //     return this.validateName(v) || this.$t("user.nameValidate");
        //   }

        //   return true;
        // },
      ],

      firstnameRules: [
        (v) => v.length <= 40 || this.$t("user.nameSizeValidate"),
        // (v) => {
        //   if (this.nameState) {
        //     return this.validateName(v) || this.$t("user.nameValidate");
        //   }

        //   return true;
        // },
      ],

      patronymicRules: [
        (v) => v.length <= 40 || this.$t("user.nameSizeValidate"),
        // (v) => {
        //   if (this.patronymicState) {
        //     return this.validateName(v) || this.$t("user.nameValidate");
        //   }

        //   return true;
        // },
      ],
    };
  },

  methods: {
    change() {
      this.$emit("change", {
        surname: this.surnameState,
        name: this.nameState,
        patronymic: this.patronymicState,
      });
    },

    validateName(name) {
      const regex = /^[a-zA-Zа-яА-ЯёЁ]{1,}'?-?[a-zA-Zа-яА-ЯёЁ]{1,}$/;

      return regex.test(name);
    },
  },

  computed: {
    surnameCapital: {
      get: function () {
        return this.surnameState;
      },
      set: function (newSurname) {
        if (newSurname.length < 1) {
          this.surnameState = "";
          return;
        }
        this.surnameState = newSurname.replace(
          /^./,
          newSurname[0].toUpperCase()
        );
      },
    },

    nameCapital: {
      get: function () {
        return this.nameState;
      },
      set: function (newName) {
        if (newName.length < 1) {
          this.nameState = "";
          return;
        }
        this.nameState = newName.replace(/^./, newName[0].toUpperCase());
      },
    },

    patronymicCapital: {
      get: function () {
        return this.patronymicState;
      },
      set: function (newPatronymic) {
        if (newPatronymic.length < 1) {
          this.patronymicState = "";
          return;
        }
        this.patronymicState = newPatronymic.replace(
          /^./,
          newPatronymic[0].toUpperCase()
        );
      },
    },
  },
};
</script>
