import Vue from "vue";
import store from "./store";
import App from "./App.vue";

// Plugins
import vuetify from "@/plugins/vuetify";
import i18n from "./plugins/i18n";
import "@/plugins/vuetify-tel-input";
import "@/plugins/vue-notification";
import "@/plugins/vue-moment";
import "@/plugins/vue-clipboard";
import "@/plugins/portal-vue";
import "@/plugins/vmask";
import "@/plugins/datetimepicker";
import "@/plugins/auth";
import "@/plugins/constants";

// Router
import router from "./router";

// PWA
import "@/registerServiceWorker";

Vue.config.productionTip = false;
Vue.config.devtools = true;

const app = new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
});

store.$app = app;
app.$mount("#app");
