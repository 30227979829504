<template>
  <v-card class="mx-auto" outlined color="#F3F2FF">
    <v-card-text>
      <v-row>
        <v-col v-if="isShowMode" cols="auto">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" small depressed color="blue" dark>
                {{ $t("users.userType") }}
                <template v-if="value.mode"
                  >[{{ getUserTypeLabel(value.mode) }}]</template
                >
                &nbsp;
                <v-icon right> mdi-menu-down </v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-list>
                <v-list-item-group
                  :value="value.mode"
                  @change="submit('mode', $event)"
                >
                  <template v-if="value.mode">
                    <v-list-item :value="null" dense>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("button.clear")
                        }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon>mdi-close-circle-outline</v-icon>
                      </v-list-item-icon>
                    </v-list-item>

                    <v-divider class="m-0" />
                  </template>

                  <v-list-item value="errors">
                    <v-list-item-content>
                      <v-list-item-title>{{
                        getUserTypeLabel("errors")
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item value="noerrors">
                    <v-list-item-content>
                      <v-list-item-title>{{
                        getUserTypeLabel("noerrors")
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item value="blocks">
                    <v-list-item-content>
                      <v-list-item-title>{{
                        getUserTypeLabel("blocks")
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item value="blacks">
                    <v-list-item-content>
                      <v-list-item-title>{{
                        getUserTypeLabel("blacks")
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item value="deleted">
                    <v-list-item-content>
                      <v-list-item-title>{{
                        getUserTypeLabel("deleted")
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-menu>
        </v-col>

        <v-col cols="auto">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" small depressed color="blue" dark>
                {{ $t("permits.permits") }}
                <template v-if="value.permit"
                  >[{{ getPermitsLabel(value.permit) }}]</template
                >
                &nbsp;
                <v-icon right> mdi-menu-down </v-icon>
              </v-btn>
            </template>

            <v-card :loading="isPendingTemplates">
              <v-list>
                <v-list-item-group
                  :value="value.permit"
                  @change="submit('permit', $event)"
                >
                  <template v-if="value.permit">
                    <v-list-item :value="null" dense>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("button.clear")
                        }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon>mdi-close-circle-outline</v-icon>
                      </v-list-item-icon>
                    </v-list-item>

                    <v-divider class="m-0" />
                  </template>

                  <v-list-item
                    v-for="(template, index) in templates"
                    :key="index"
                    :value="template.type"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ template.name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-menu>
        </v-col>

        <v-col cols="auto">
          <v-menu offset-y :max-width="400" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-badge
                color="primary"
                bordered
                overlap
                :content="departments.length"
                :value="departments.length ? true : false"
              >
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  depressed
                  color="blue"
                  dark
                >
                  {{ $t("users.department") }}&nbsp;
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </v-badge>
            </template>

            <v-card
              max-height="400"
              max-width="500"
              :loading="isPendingDepartments"
            >
              <v-text-field
                v-model="searchDepartments"
                :label="$t('datatable.search')"
                flat
                solo
                hide-details
                clearable
                clear-icon="mdi-close-circle-outline"
              />
              <v-divider class="m-0" />
              <v-list>
                <v-treeview
                  :value="departments"
                  selectable
                  :items="departmentsList"
                  item-text="name"
                  selection-type="independent"
                  :search="searchDepartments"
                  :open-all="searchDepartments ? true : false"
                  @input="submit('departments', $event)"
                />
              </v-list>
            </v-card>
          </v-menu>
        </v-col>

        <v-col cols="auto">
          <v-menu offset-y :max-width="400" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-badge
                color="primary"
                bordered
                overlap
                :content="accessLevels.length"
                :value="accessLevels.length ? true : false"
              >
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  depressed
                  color="blue"
                  dark
                >
                  {{ $t("users.subdivision") }}&nbsp;
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </v-badge>
            </template>

            <v-card
              max-height="400"
              max-width="500"
              :loading="isPendingAccessLevels"
            >
              <v-text-field
                v-model="searchAccessLevels"
                :label="$t('datatable.search')"
                flat
                solo
                hide-details
                clearable
                clear-icon="mdi-close-circle-outline"
              />
              <v-divider class="m-0" />
              <v-list>
                <v-treeview
                  :value="accessLevels"
                  selectable
                  :items="accessLevelsList"
                  item-text="name"
                  selection-type="independent"
                  :search="searchAccessLevels"
                  :open-all="searchAccessLevels ? true : false"
                  @input="submit('accessLevels', $event)"
                />
              </v-list>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <Search
            :value="value.search"
            :searchParams="searchParams"
            :saveSearchHistory="$const.SEARCH_HISTORY.PERSONS"
            @search="submit('search', $event)"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";

import Search from "@/components/Search";

export default {
  name: "PersonsFilter",

  components: { Search },

  props: {
    isShowMode: {
      type: Boolean,
      default: true,
    },

    value: {
      type: Object,
      default: () => ({
        departments: [],
        accessLevels: [],
        mode: "",
        permit: "",
        search: "",
      }),
    },
  },

  data() {
    return {
      searchDepartments: "",
      searchAccessLevels: "",
      searchParams: [
        {
          value: "name",
          text: this.$t("user.name"),
        },
        {
          value: "id",
          text: "ID",
        },
        {
          value: "tel",
          text: this.$t("user.phone"),
        },
        {
          value: "card",
          text: this.$t("card.numberOrHex"),
        },
      ],
    };
  },

  methods: {
    ...mapActions({
      getDepartments: "departments/getTree",
      getAccessLevels: "accessLevels/getTree",
      getTemplates: "permitTemplates/get",
    }),

    submit(key, value) {
      this.$emit("input", { ...this.value, [key]: value });
    },

    getUserTypeLabel(type) {
      switch (type) {
        case "blocks":
          return this.$t("users.blocked");
        case "blacks":
          return this.$t("users.blacklist");
        case "errors":
          return this.$t("users.errors");
        case "noerrors":
          return this.$t("users.noErrors");
        case "deleted":
          return this.$t("users.deleted");
        default:
          return "";
      }
    },

    getPermitsLabel(type) {
      const typeObject = this.templates.find((item) => {
        return item.type === type;
      });

      return typeObject ? typeObject.name : "";
    },
  },

  computed: {
    ...mapState({
      isPendingDepartments: (state) => state.departments.isPending,
      departmentsTree: (state) => state.departments.tree,

      isPendingAccessLevels: (state) => state.accessLevels.isPending,
      accessLevelsTree: (state) => state.accessLevels.tree,

      isPendingTemplates: (state) => state.permitTemplates.isPending,
      templates: (state) => state.permitTemplates.data,
    }),

    departmentsList() {
      return [
        {
          id: -1,
          name: this.$t("departments.without"),
          parent_id: null,
        },
        ...this.departmentsTree,
      ];
    },

    departments() {
      let departments = [];

      if (Array.isArray(this.value.departments)) {
        departments = this.value.departments.map((item) => parseInt(item));
      } else {
        departments = [parseInt(this.value.departments)];
      }

      return departments;
    },

    accessLevelsList() {
      return [
        {
          id: -1,
          name: this.$t("access-levels.without"),
          parent_id: null,
        },
        ...this.accessLevelsTree,
      ];
    },

    accessLevels() {
      let accessLevels = [];

      if (Array.isArray(this.value.accessLevels)) {
        accessLevels = this.value.accessLevels.map((item) => parseInt(item));
      } else {
        accessLevels = [parseInt(this.value.accessLevels)];
      }

      return accessLevels;
    },
  },

  created() {
    this.getDepartments();
    this.getAccessLevels();
    this.getTemplates();
  },
};
</script>
