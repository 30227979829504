<template>
  <v-card>
    <WebCam
      ref="webcam"
      :device-id="deviceId"
      @cameras="onCameras"
      @video-live="onCameraReady"
    />

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn color="primary" @click="takePhoto">{{
        $t("imageBox.createPhoto")
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { WebCam } from "vue-web-cam";

export default {
  components: {
    WebCam,
  },

  computed: {
    device() {
      return this.devices.find((n) => n.value === this.deviceId);
    },
  },

  watch: {
    devices() {
      const [first] = this.devices;
      if (first) {
        this.deviceId = first.value;
      }
    },
  },

  data() {
    return {
      deviceId: "0",
      devices: [],
      isCameraReady: false,
    };
  },

  methods: {
    onCameraReady() {
      this.isCameraReady = true;
    },

    onCameras(cameras) {
      const devices = [];
      cameras.forEach((device) => {
        devices.push({
          label: device.label,
          value: device.deviceId,
        });
      });

      this.devices = devices;
    },

    takePhoto() {
      this.$emit("capture", this.$refs.webcam.capture());
    },
  },
};
</script>
