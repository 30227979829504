<template>
  <v-row class="mt-0">
    <v-col>
      <v-select
        v-model="type"
        :items="types"
        :label="$t('permits.passType')"
        dense
        outlined
        @change="onChange"
      />
    </v-col>

    <v-col v-if="type === 'qr'">
      <QrNumberInput
        ref="qrNumber"
        v-model="number"
        :error-messages="numberErrors"
        @openQrCodeDialog="$emit('openQrCodeDialog', $event)"
        @generateQRCode="generateQRCode"
      />
    </v-col>

    <v-col v-if="type === 'card'">
      <CardNumberInput
        ref="cardNumber"
        v-model="number"
        :error-messages="numberErrors"
      />
    </v-col>

    <v-col cols="auto">
      <v-btn icon color="red" @click="remove">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { v4 as uuid } from "uuid";
import { mapActions } from "vuex";

import QrNumberInput from "./QrNumberInput";
import CardNumberInput from "./CardNumberInput";

import validator from "./validator";

export default {
  name: "PermitCardToken",

  mixins: [validator],

  components: {
    QrNumberInput,
    CardNumberInput,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
    personId: {
      type: [Number, String],
      default: null,
    },
  },

  data() {
    return {
      loopId: this.data.loopId,
      type: this.data.type,
      number: this.data.number,

      isOpenGenerateNumberDialog: false,

      types: [
        { text: this.$t("permits.card"), value: "card" },
        { text: this.$t("permits.qr"), value: "qr" },
      ],
    };
  },

  watch: {
    number: {
      handler() {
        this.$v.number.$touch();
        this.onChange();
      },
      immediate: true,
    },
  },

  methods: {
    ...mapActions({
      checkToken: "persons/checkToken",
    }),

    onChange() {
      this.$emit("change", {
        loopId: this.loopId,
        type: this.type,
        number: this.number,
      });
    },

    remove() {
      this.$emit("remove", this.loopId);
    },

    generateQRCode() {
      this.number = uuid();
    },
  },
};
</script>
