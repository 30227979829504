import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const state = {
  isPending: false,
  isSuccess: false,
  isFailure: false,
  errorMessage: "",

  list: [],
  tree: [],
};

export default {
  state,
  mutations,
  actions,
  getters,
};
