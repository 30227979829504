<template>
  <v-dialog :value="isOpen" max-width="600" @click:outside="$emit('close')">
    <v-card :loading="isLoading" :disabled="isLoading">
      <template slot="progress">
        <v-progress-linear color="primary" height="10" indeterminate />
      </template>

      <v-card-title class="text-h5">
        {{ $t("users.setAccessLevels") }}
      </v-card-title>

      <v-card-text>
        <v-treeview
          v-model="accessLevel"
          :items="accessLevels"
          selectable
          transition
          item-key="id"
          @update:active="accessLevel = $event"
        />
      </v-card-text>

      <v-card-actions>
        <v-btn text @click="$emit('close')">
          <v-icon left>mdi-close</v-icon>
          {{ $t("button.close") }}
        </v-btn>

        <v-spacer />

        <v-btn
          color="red"
          text
          @click="onSet('acc_del')"
          :loading="isPendingMultiSetAccessLevel"
        >
          <v-icon left>mdi-delete</v-icon>
          {{ $t("button.delete") }}
        </v-btn>

        <v-btn
          color="warning"
          text
          @click="onSet('acc_upd')"
          :loading="isPendingMultiSetAccessLevel"
        >
          <v-icon left>mdi-pencil</v-icon>
          {{ $t("button.replace") }}
        </v-btn>

        <v-btn
          color="success"
          text
          @click="onSet('acc_add')"
          :loading="isPendingMultiSetAccessLevel"
        >
          <v-icon left>mdi-plus</v-icon>
          {{ $t("button.add") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "SetAccessLevelDialog",

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    selectedIds: {
      type: Array,
      default: () => [],
    },
    filter: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      accessLevel: [],
    };
  },

  methods: {
    ...mapActions({
      get: "accessLevels/getTree",
      multiSetAccessLevels: "persons/multiSetAccessLevels",
    }),

    async onSet(action) {
      let params = {
        accesslevels_ids: this.accessLevel,
        action,
      };

      if (this.selectedIds.length) {
        params.ids = this.selectedIds;
      } else {
        params = {
          ...params,
          ...this.filter,
        };
      }

      await this.multiSetAccessLevels(params);

      this.accessLevel = [];
      this.$emit("save");
    },
  },

  computed: {
    ...mapState({
      isLoading: (state) => state.accessLevels.isLoading,
      accessLevels: (state) => state.accessLevels.tree,
      isPendingMultiSetAccessLevel: (state) =>
        state.persons.isPendingMultiSetAccessLevel,
    }),
  },

  created() {
    this.get();
  },
};
</script>
