<template>
  <v-card class="mx-auto" outlined :color="cardColor" :disabled="readonly">
    <!-- Edit mode -->
    <v-form v-if="isEdit" ref="form">
      <v-card-text>
        <v-select
          v-model="type"
          :items="typeOptions"
          :label="`${$t('permits.type')} *`"
          outlined
          :error-messages="typeErrors"
          @input="$v.type.$touch()"
          @blur="$v.type.$touch()"
        />

        <Field
          v-for="field in typeCustomFields"
          :key="field.name"
          :data="field"
          :value="getCustomFieldValue(field.name)"
          @change="onChangeField"
        />

        <v-radio-group v-model="onepass" row class="mt-0">
          <template v-slot:label>
            {{ $t("permits.passage") }}
          </template>
          <v-radio :label="$t('permits.onePass')" :value="true" />
          <v-radio :label="$t('permits.multiPass')" :value="false" />
        </v-radio-group>

        <v-row v-if="onepass">
          <v-col v-if="isNew">
            <v-text-field
              type="number"
              v-model.number="pass"
              :label="$t('permits.passNumber')"
              outlined
              :error-messages="passErrors"
              @input="$v.pass.$touch()"
              @blur="$v.pass.$touch()"
            />
          </v-col>

          <v-col v-else>
            <v-row align="center">
              <v-col cols="12">
                {{ $t("permits.passNumber") }}: {{ pass }}
              </v-col>

              <v-col>
                <v-select
                  v-model="typePassChange"
                  :items="typePassChangeOptions"
                  outlined
                  dense
                />
              </v-col>

              <v-col>
                <v-text-field
                  type="number"
                  v-model.number="passChange"
                  outlined
                  dense
                  :error-messages="passChangeErrors"
                  @input="$v.passChange.$touch()"
                  @blur="$v.passChange.$touch()"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <date-picker
              v-model="startDate"
              type="datetime"
              format="DD.MM.YYYY HH:mm"
              value-type="format"
              :placeholder="$t('permits.startDate')"
              :disabled-date="disabledStartDate"
              confirm
              style="width: 100%"
              @input="onChangeStartDate"
            />
          </v-col>
          <v-col>
            <date-picker
              v-model="expireDate"
              type="datetime"
              format="DD.MM.YYYY HH:mm"
              value-type="format"
              :placeholder="$t('permits.expireDate')"
              :disabled-date="disabledExpireDate"
              :disabled-time="disabledExpireTime"
              confirm
              style="width: 100%"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-label>{{ $t("permits.accessLevel") }} *</v-label>
            <TreeSelect
              v-model="permitAccess"
              class="mt-2"
              :class="{ 'vue-treeselect--error': !!permitAccessErrors.length }"
              :multiple="true"
              :options="accessLevels"
              placeholder=""
              :searchable="true"
              :show-count="true"
              :noOptionsText="$t('datatable.noData')"
              flat
              @input="$v.permitAccess.$touch()"
            />
            <v-messages
              :value="permitAccessErrors"
              color="error"
              class="my-2 px-2"
            />
          </v-col>
        </v-row>

        <v-switch v-model="isNotUse2FA" :label="$t('permits.isNotUse2FA')" />

        <h3 class="mt-5">{{ $t("permits.pass") }}</h3>

        <v-switch v-model="isFaceActive" :label="$t('permits.isFaceActive')" />

        <template v-if="isShowTokens">
          <Token
            v-for="token in tokens"
            :key="`token-${token.loopId}`"
            :ref="`token-${token.loopId}`"
            :data="token"
            :personId="personId"
            @remove="onRemoveToken"
            @change="onChangeToken"
            @openQrCodeDialog="openQrCodeDialog"
          />

          <v-row>
            <v-col>
              <v-btn color="primary" dark @click="addPass">
                <v-icon left> mdi-plus </v-icon>
                {{ $t("permits.addPass") }}
              </v-btn>
            </v-col>
          </v-row>
        </template>

        <v-divider class="mt-5" />
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn color="red" text @click="cancel">
          <v-icon left>mdi-close</v-icon>
          {{ $t("button.cancel") }}
        </v-btn>

        <v-btn color="green" text @click="onSave">
          <v-icon left>mdi-content-save-outline</v-icon>
          {{ $t("button.save") }}
        </v-btn>
      </v-card-actions>
    </v-form>

    <!-- Show mode -->
    <template v-else>
      <v-card-text>
        <v-row dense>
          <v-col cols="6">
            <strong>{{ $t("permits.type") }}</strong>
          </v-col>
          <v-col cols="4">{{ getTypeName(type) }}</v-col>

          <v-spacer />

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-col cols="auto" v-bind="attrs" v-on="on">
                <v-switch
                  v-model="isActive"
                  hide-details
                  class="mt-0 pt-0"
                  @change="save"
                />
              </v-col>
            </template>
            <span v-if="!isActive">{{ $t("permits.permitOn") }}</span>
            <span v-else>{{ $t("permits.permitOff") }}</span>
          </v-tooltip>
        </v-row>

        <v-row
          dense
          v-for="value in getValuesByTypeCustomField(values)"
          :key="value.name"
        >
          <v-col>
            <strong>{{ getValueLabel(value.key) }}</strong>
          </v-col>
          <v-col>{{ getValueValue(value.key, value.value) }}</v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <strong>{{ $t("permits.passage") }}</strong>
          </v-col>
          <v-col v-if="onepass">
            {{ $t("permits.onePass") }} ({{ $t("permits.passNumber") }}:
            {{ pass }})
          </v-col>
          <v-col v-else>
            {{ $t("permits.multiPass") }}
          </v-col>
        </v-row>

        <v-row v-if="startDate" dense>
          <v-col>
            <strong>{{ $t("permits.startDate") }}</strong>
          </v-col>
          <v-col>{{ startDate }}</v-col>
        </v-row>

        <v-row v-if="expireDate" dense>
          <v-col>
            <strong>{{ $t("permits.expireDate") }}</strong>
          </v-col>
          <v-col>{{ expireDate }}</v-col>
        </v-row>

        <v-row v-if="permitAccess.length" dense>
          <v-col>
            <strong>{{ $t("permits.accessLevel") }}</strong>
          </v-col>
          <v-col>{{ accessLevelNames }}</v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <strong>{{ $t("permits.isNotUse2FA") }}</strong>
          </v-col>
          <v-col>
            {{ isNotUse2FA ? $t("common.yes") : $t("common.no") }}
          </v-col>
        </v-row>

        <template v-if="tokens.length">
          <h3 class="mt-5 mb-3">{{ $t("permits.pass") }}</h3>

          <v-card
            v-if="isFaceActive"
            class="mx-auto mb-3"
            outlined
            color="#fff"
          >
            <v-card-text>
              <v-row>
                <v-col>
                  <v-row dense>
                    <v-col>
                      <strong>{{ $t("permits.type") }}</strong>
                    </v-col>
                    <v-col>
                      {{ $t(`permits.isFaceActive`) }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card
            v-for="(token, index) in tokens"
            :key="`token-${index}`"
            class="mx-auto mb-3"
            outlined
            color="#fff"
          >
            <v-card-text>
              <v-row>
                <v-col>
                  <v-row dense>
                    <v-col>
                      <strong>{{ $t("permits.type") }}</strong>
                    </v-col>
                    <v-col>
                      {{ $t(`permits.${token.type}`) }}
                    </v-col>
                  </v-row>
                  <v-row v-if="token.number" dense>
                    <v-col>
                      <strong>{{ $t("permits.number") }}</strong>
                    </v-col>
                    <v-col>
                      {{ token.number }}
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>

            <v-card-actions v-if="token.type === 'qr'">
              <v-spacer />

              <v-btn text small @click="openQrCodeDialog(token.number)">
                <v-icon left small>mdi-qrcode</v-icon>
                {{ $t("permits.view-qr") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-card-text>

      <v-card-actions v-if="isActive">
        <v-spacer />
        <v-btn text @click="onEdit">{{ $t("permits.editPermit") }}</v-btn>
      </v-card-actions>
    </template>

    <v-dialog v-model="isOpenQrCodeDialog" max-width="400">
      <v-card>
        <v-card-title class="text-h5">
          {{ $t("permits.qr") }}
        </v-card-title>

        <v-card-text id="printQrBlock">
          <div>
            <v-row>
              <v-col>
                <VueQRCodeComponent :text="dialogQrCode" :size="352" />
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="6">
                <strong>{{ $t("permits.type") }}</strong>
              </v-col>
              <v-col cols="4">{{ getTypeName(type) }}</v-col>
            </v-row>

            <v-row
              dense
              v-for="value in getValuesByTypeCustomField(values)"
              :key="value.name"
            >
              <v-col>
                <strong>{{ getValueLabel(value.key) }}</strong>
              </v-col>
              <v-col>{{ getValueValue(value.key, value.value) }}</v-col>
            </v-row>

            <v-row dense>
              <v-col>
                <strong>{{ $t("permits.passage") }}</strong>
              </v-col>
              <v-col v-if="onepass">
                {{ $t("permits.onePass") }} ({{ $t("permits.passNumber") }}:
                {{ pass }})
              </v-col>
              <v-col v-else>
                {{ $t("permits.multiPass") }}
              </v-col>
            </v-row>

            <v-row v-if="startDate" dense>
              <v-col>
                <strong>{{ $t("permits.startDate") }}</strong>
              </v-col>
              <v-col>{{ startDate }}</v-col>
            </v-row>

            <v-row v-if="expireDate" dense>
              <v-col>
                <strong>{{ $t("permits.expireDate") }}</strong>
              </v-col>
              <v-col>{{ expireDate }}</v-col>
            </v-row>

            <v-row v-if="permitAccess.length" dense>
              <v-col>
                <strong>{{ $t("permits.accessLevel") }}</strong>
              </v-col>
              <v-col>{{ accessLevelNames }}</v-col>
            </v-row>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn color="red" text @click="isOpenQrCodeDialog = false">
            <v-icon left>mdi-close</v-icon>
            {{ $t("button.close") }}
          </v-btn>

          <v-btn color="success" text v-print="'#printQrBlock'">
            <v-icon left>mdi-printer</v-icon>
            {{ $t("button.print") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
const DATE_FORMAT = "DD.MM.YYYY HH:mm";

import { mapState } from "vuex";
import TreeSelect from "@riophae/vue-treeselect";
import print from "vue-print-nb";
import VueQRCodeComponent from "vue-qrcode-component";

import Token from "./Token";
import Field from "./Field";

import validator from "./validator";

export default {
  name: "PermitCard",

  mixins: [validator],

  directives: {
    print,
  },

  components: { TreeSelect, Token, Field, VueQRCodeComponent },

  props: {
    data: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    personId: {
      type: [Number, String],
      default: null,
    },
    isShowTokens: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    const data = this.getData(this.data);

    return {
      ...data,
      previewsData: { ...data },

      isOpenQrCodeDialog: false,
      dialogQrCode: undefined,

      typePassChange: "increment",
      passChange: 0,
      typePassChangeOptions: [
        { value: "increment", text: this.$t("permits.increment") },
        { value: "decrement", text: this.$t("permits.decrement") },
      ],
    };
  },
  watch: {
    data(data) {
      const newData = this.getData(data);

      for (const property in newData) {
        this[property] = newData[property];
      }
    },
  },

  methods: {
    getData(data) {
      let permitAccess = [];
      let tokens = [];
      let values = [];

      if (data.permit_access) {
        permitAccess = data.permit_access.map(
          (accessLevel) => accessLevel.accesslevels_id
        );
      }

      if (this.data.tokens && this.data.tokens.length) {
        tokens = this.data.tokens.map((item) => ({
          ...item,
          loopId: item.id,
        }));
      }

      if (this.data.values && this.data.values.length) {
        values = [...this.data.values];
      }

      return {
        id: data.id || 0,
        loopId: data.loopId,
        isHide: data.isHide,
        isEdit: data.isEdit,
        isNew: data.isNew || false,
        isActive: data.is_active,
        type: data.type || null,
        onepass: data.onepass !== undefined ? data.onepass : false,
        isNotUse2FA:
          data.use_personal_mode !== undefined ? data.use_personal_mode : false,
        pass: Number.isInteger(parseInt(data.pass)) ? data.pass : 1,
        startDate: data.start_time
          ? this.$moment(data.start_time).format(DATE_FORMAT)
          : null,
        expireDate: data.expire_time
          ? this.$moment(data.expire_time).format(DATE_FORMAT)
          : null,
        isFaceActive:
          data.is_face_active !== undefined ? data.is_face_active : true,
        permitAccess: [...permitAccess],
        tokens: [...tokens],
        values: [...values],
      };
    },

    onChangeStartDate(date) {
      const startDate = this.$moment(date, DATE_FORMAT);
      const expireDate = this.$moment(this.expireDate, DATE_FORMAT);
      const isFromAfterTo = startDate.isAfter(expireDate);

      if (isFromAfterTo) {
        this.expireDate = startDate.add(1, "day").format(DATE_FORMAT);
      }
    },

    disabledStartDate(date) {
      let isDisabled = false;

      const currentDay = this.$moment(date);
      const today = this.$moment().subtract(1, "days");

      if (currentDay.isBefore(today)) {
        isDisabled = true;
      }

      return isDisabled;
    },

    disabledExpireDate(date) {
      let isDisabled = false;

      const currentDay = this.$moment(date);
      const today = this.$moment().subtract(1, "days");

      if (currentDay.isBefore(today)) {
        isDisabled = true;
      }

      return isDisabled;
    },

    disabledExpireTime(date) {
      let isDisabled = false;

      const currentDay = this.$moment(date);
      const startDate = this.startDate
        ? this.$moment(this.startDate, DATE_FORMAT).add(1, "minutes")
        : this.$moment().subtract(1, "days");

      if (currentDay.isBefore(startDate)) {
        isDisabled = true;
      }

      return isDisabled;
    },

    addPass() {
      let loopId = 1;
      if (this.tokens.length > 0) {
        const lastID =
          this.tokens[this.tokens.length - 1].id ||
          this.tokens[this.tokens.length - 1].loopId;

        loopId = lastID + 1;
      }

      this.tokens.push({
        loopId,
        type: "card",
        number: "",
      });
    },

    onRemoveToken(id) {
      this.tokens = this.tokens.filter((token) => token.loopId !== id);
    },

    onChangeToken({ loopId, type, number }) {
      this.tokens = this.tokens.map((token) => {
        const newToken = { ...token };

        if (token.loopId === loopId) {
          newToken.type = type;
          newToken.number = number;
        }

        return newToken;
      });
    },

    onChangeField(value) {
      const index = this.values.findIndex((p) => p.key === value.key);

      if (index !== -1) {
        this.values.splice(index, 1, value);
      } else {
        this.values.push(value);
      }
    },

    cancel() {
      if (this.id) {
        this.save(this.previewsData);
      } else {
        this.$emit("cancel", this.loopId);
      }
    },

    onSave() {
      if (!this.validateForm()) return;

      this.save(this);
    },

    save(permit) {
      if (typeof permit == "boolean") permit = this;
      const data = {
        loopId: permit.loopId,
        isEdit: false,
        isHide: permit.isHide,
        is_active: permit.isActive,
        type: permit.type,
        onepass: permit.onepass,
        use_personal_mode: permit.isNotUse2FA,
        start_time: this.$moment(permit.startDate, DATE_FORMAT).toISOString(),
        expire_time: this.$moment(permit.expireDate, DATE_FORMAT).toISOString(),
        permit_access: permit.permitAccess.map((accesslevels_id) => ({
          accesslevels_id,
        })),
        is_face_active: permit.isFaceActive,
        tokens: permit.tokens,
        values: this.getValuesByTypeCustomField(permit.values),
      };

      if (permit.id) {
        data.id = permit.id;
      }

      if (permit.onepass && permit.isNew) {
        data.pass = permit.pass;
      }

      if (permit.passChange) {
        data.pass_change = {
          value: permit.passChange,
          is_increment: permit.typePassChange === "increment",
        };

        switch (permit.typePassChange) {
          case "increment":
            data.pass = permit.pass + permit.passChange;
            break;
          case "decrement":
            data.pass = permit.pass - permit.passChange;
            break;
          default:
            break;
        }

        if (data.pass < 0) {
          data.pass = 0;
        }
      }

      this.$emit("save", data);
    },

    getTypeName(type) {
      const typeObject = this.typeOptions.find((item) => {
        return item.value === type;
      });

      return typeObject ? typeObject.text : "";
    },

    getValuesByTypeCustomField(values) {
      const newValues = values.filter((value) => {
        const typeObject = this.typeCustomFields.find((customField) => {
          return customField.name === value.key;
        });

        if (typeObject) {
          return true;
        }

        return false;
      });

      return newValues;
    },

    getValueLabel(key) {
      const valueObject = this.typeCustomFields.find((item) => {
        return item.name === key;
      });

      return valueObject ? valueObject.label : "";
    },

    getValueValue(key, value) {
      const valueObject = this.typeCustomFields.find((item) => {
        return item.name === key;
      });

      let itemValue = value;

      if (valueObject && valueObject.items) {
        const item = valueObject.items.find((item) => {
          return item.value === value;
        });

        itemValue = item ? item.label : "";
      }

      return itemValue;
    },

    getCustomFieldValue(key) {
      const valueObject = this.values.find((item) => {
        return item.key === key;
      });

      return valueObject ? valueObject.value : "";
    },

    onEdit() {
      this.isEdit = true;
      this.$emit("changeEdit", { id: this.id, isEdit: true });
    },

    openQrCodeDialog(qr) {
      this.dialogQrCode = qr;
      this.isOpenQrCodeDialog = true;
    },

    closeQrCodeDialog() {
      this.dialogQrCode = undefined;
      this.isOpenQrCodeDialog = false;
    },
  },

  computed: {
    ...mapState({
      accessLevels: (state) => state.accessLevels.tree,
      templates: (state) => state.permitTemplates.data,
    }),

    accessLevelNames() {
      const accessLevels = this.accessLevels.filter((item) =>
        this.permitAccess.includes(item.id)
      );

      return accessLevels.map((item) => item.name).join(", ");
    },

    typeOptions() {
      return this.templates.map((item) => ({
        value: item.type,
        text: item.name,
      }));
    },

    typeCustomFields() {
      const template = this.templates.find((item) => item.type === this.type);

      return template ? template.custom_fields : [];
    },

    cardColor() {
      let color = "#F3F2FF";

      if (!this.isActive) {
        color = "#EEEEEE";
      }

      return color;
    },
  },
};
</script>
