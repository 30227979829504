<template>
  <div style="background-color: transparent">
    <ZoomImg v-show="isOpenZoomImg" :url="zoomImgUrl" />

    <v-row class="visitors-container">
      <v-col :cols="12" :md="7">
        <EventCard
          :entrance="entranceVisit"
          :exit="exitVisit"
          :temperatureRange="settings.temperature_range.split(',')"
          :temperatureRise="settings.temperature_rise"
          @unblock="onUnblockUser"
          @guestReg="onGuestReg"
        />
      </v-col>

      <v-col :cols="12" :md="5">
        <v-card elevation="2" class="settings-page">
          <v-tabs v-model="tabActive" show-arrows>
            <v-tab>{{ $t("users.passages") }}</v-tab>
            <v-tab>{{ $t("users.indoors") }}</v-tab>
            <v-tab>{{ $t("users.alarm") }}</v-tab>
          </v-tabs>

          <MonitoringFilter
            class="pt-5 pl-3 pr-3"
            :typeUser="typeUser"
            :zoneId="zoneId"
            @change="onChangeFilter"
          />

          <v-card-text>
            <v-tabs-items
              v-model="tabActive"
              style="overflow: initial"
              touchless
            >
              <v-tab-item :transition="false" :reverse-transition="false">
                <VisitorTable
                  :data="visitors.data"
                  :rowstyle="styleRow"
                  @dblclick="showUser"
                  @hoverImg="openZoomImg"
                  @leaveHoverImg="closeZoomImg"
                />
              </v-tab-item>

              <v-tab-item :transition="false" :reverse-transition="false">
                <IndoorTable
                  :data="indoors.data"
                  :rowstyle="styleRow"
                  @dblclick="showUser"
                  @closeVisits="initData"
                  @hoverImg="openZoomImg"
                  @leaveHoverImg="closeZoomImg"
                  @report="reportIndoors"
                >
                  <template v-slot:footer>
                    <v-pagination
                      v-show="indoors.pagination.total > 1"
                      v-model="indoors.pagination.current_page"
                      :length="indoors.pagination.total"
                      :total-visible="7"
                      @input="initData"
                    />
                  </template>
                </IndoorTable>
              </v-tab-item>

              <v-tab-item :transition="false" :reverse-transition="false">
                <EventLogTable
                  :data="eventLogs.data"
                  :rowstyle="styleRow"
                  @dblclick="showUser"
                  @guestReg="onGuestReg"
                  @hoverImg="openZoomImg"
                  @leaveHoverImg="closeZoomImg"
                >
                  <template v-slot:footer>
                    <v-pagination
                      v-show="eventLogs.pagination.total > 1"
                      v-model="eventLogs.pagination.current_page"
                      :length="eventLogs.pagination.total"
                      :total-visible="7"
                      @input="initData"
                    />
                  </template>
                </EventLogTable>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="auto" class="visitors-container-footer">
        <v-switch
          v-if="settings.temperature_enabled"
          v-model="temperatureCheck"
          :label="$t('users.displayWithTemperature')"
          @change="onChangeTemperatureMode"
        />
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-dialog
        v-if="isShowAlarmDialog"
        :value="true"
        max-width="700px"
        persistent
      >
        <ModalAlarmDialog :data="deviceAlarm" @close="closeAlarmDialog" />
      </v-dialog>
    </v-row>

    <DeviceMonitoring v-if="can(this.$const.RULES.DEVICE_VIEW)" />
  </div>
</template>

<script>
import EventCard from "./EventCard";
import MonitoringFilter from "./Filter";
import VisitorTable from "./VisitorTable";
import IndoorTable from "./IndoorTable";
import EventLogTable from "./EventLogTable";
import ZoomImg from "@/components/ZoomImg";
import ModalAlarmDialog from "./ModalAlarmDialog";
import DeviceMonitoring from "./devices";
import { mapState, mapGetters } from "vuex";

import permissions from "@/mixins/permissions";

const NEW_VISIT_TAB = 0;
const INDOOR_TAB = 1;
const EVENT_LOG_TAB = 2;

export default {
  mixins: [permissions],

  components: {
    EventCard,
    MonitoringFilter,
    VisitorTable,
    IndoorTable,
    EventLogTable,
    ZoomImg,
    ModalAlarmDialog,
    DeviceMonitoring,
  },

  data() {
    return {
      isShowAlarmDialog: false,
      deviceAlarm: null,

      isOpenZoomImg: false,
      zoomImgUrl: "",
      entranceVisit: null,
      exitVisit: null,
      tabActive: EVENT_LOG_TAB,
      temperatureCheck: false,
      typeUser: null,
      zoneId: null,
      indoorsInterval: undefined,
      visitorsLimit: 50,
      visitors: {
        data: [],
        pagination: {
          total: 0,
          per_page: 2,
          to: 0,
          current_page: 1,
        },
      },
      indoors: {
        data: [],
        pagination: {
          total: 0,
          per_page: 2,
          to: 0,
          current_page: 1,
        },
      },
      eventLogs: {
        data: [],
        pagination: {
          total: 0,
          per_page: 2,
          to: 0,
          current_page: 1,
        },
      },
    };
  },

  watch: {
    tabActive(tab) {
      localStorage.setItem("terminal.dascboard.tabs", tab);
      this.resetPage();
      this.initData();
    },
  },

  methods: {
    openZoomImg(img) {
      this.isOpenZoomImg = true;
      this.zoomImgUrl = img;
    },

    closeZoomImg() {
      this.isOpenZoomImg = false;
      this.zoomImgUrl = "";
    },

    onNewVisit(visit) {
      const correctVisit = this.correctVisits(visit, false);

      if (
        !this.role.allowed_zones.length ||
        this.role.allowed_zones.includes(parseInt(correctVisit.zone_id))
      ) {
        this.setEntranceAndExitVisits(correctVisit);
        this.setItemToVisitorsList(correctVisit);
        this.setItemToLogList(correctVisit);
      }
    },

    onBeforeVisit(visit) {
      const correctVisit = this.correctVisits(visit, false);

      if (
        !this.role.allowed_zones.length ||
        this.role.allowed_zones.includes(parseInt(correctVisit.zone_id))
      ) {
        this.setEntranceAndExitVisits(correctVisit);
        this.temperatureAlarm(correctVisit);
        this.setItemToLogList(correctVisit);
      }
    },

    onErrorVisit(visit) {
      const correctVisit = this.correctVisits(visit, false);

      if (
        !this.role.allowed_zones.length ||
        this.role.allowed_zones.includes(parseInt(correctVisit.zone_id))
      ) {
        this.setEntranceAndExitVisits(correctVisit);
        this.setItemToLogList(correctVisit);
      }
    },

    onAlarmVisit(visit) {
      const correctVisit = this.correctVisits(visit, false);

      if (
        !this.role.allowed_zones.length ||
        this.role.allowed_zones.includes(parseInt(correctVisit.zone_id))
      ) {
        this.setEntranceAndExitVisits(correctVisit);
        this.temperatureAlarm(correctVisit);
        this.setItemToLogList(correctVisit);

        if (correctVisit.eventType === "deviceAlarm") {
          this.showAlarmDialog(correctVisit);
        }
      }
    },

    onEventVisit(visit) {
      const correctVisit = this.correctVisits(visit);

      if (
        !this.role.allowed_zones.length ||
        this.role.allowed_zones.includes(parseInt(correctVisit.zone_id))
      ) {
        this.setEntranceAndExitVisits(correctVisit);
        this.setItemToLogList(correctVisit);
      }
    },

    connectWebSockets() {
      this.$root.$on(this.$const.WS_CHANNELS.NEW_VISIT, (visit) => {
        this.onNewVisit(visit);
      });

      this.$root.$on(this.$const.WS_CHANNELS.BEFORE_VISIT, (visit) => {
        this.onBeforeVisit(visit);
      });

      this.$root.$on(this.$const.WS_CHANNELS.ERROR_VISIT, (visit) => {
        this.onErrorVisit(visit);
      });

      this.$root.$on(this.$const.WS_CHANNELS.ALARM_VISIT, (visit) => {
        this.onAlarmVisit(visit);
      });

      this.$root.$on(this.$const.WS_CHANNELS.EVENT_VISIT, (visit) => {
        this.onEventVisit(visit);
      });
    },

    disconnectedWebSockets() {
      this.$root.$off(this.$const.WS_CHANNELS.NEW_VISIT);
      this.$root.$off(this.$const.WS_CHANNELS.BEFORE_VISIT);
      this.$root.$off(this.$const.WS_CHANNELS.ERROR_VISIT);
      this.$root.$off(this.$const.WS_CHANNELS.ALARM_VISIT);
      this.$root.$off(this.$const.WS_CHANNELS.EVENT_VISIT);
    },

    correctVisits(visit, is_short) {
      const item = { ...visit };

      let format = is_short ? "HH:mm:ss" : "DD.MM.YY HH:mm:ss";
      item.visits = this.$moment(String(item.created_at)).format(format);

      let zone = "";
      let zone_id = 0;
      let data = {};
      let fullname = "";
      let udata = {};
      let device = "";
      let identity_types = [];
      let identity_value = "";
      let message = "";
      let code = "";
      let pass_type = "";
      if (item.event) {
        try {
          data = item.event;
          zone = data.zone || "";
          fullname = data.name || this.$t("users.unrecognized");
          device = data.device_name || "";
          identity_value = data.identity_value || "";
          message = data.message || "";
          code = data.code || "";
          zone_id = data.zone_id || "";
          identity_types = this.getIdentityTypesArr(data.identity_types);
          if (data.type) {
            pass_type = data.type;
          } else {
            pass_type = item.user_id ? "before" : "alarm";
          }
        } catch (err) {
          return;
        }
      }
      if (!item.full_name || !item.full_name.trim()) {
        item.full_name = fullname;
      }

      if (item.udata) {
        try {
          udata = JSON.parse(item.udata);
        } catch (err) {
          return;
        }
      }
      if (data.temperature > 0) {
        item.temperatureValue = data.temperature;
        if (!this.settings.temperature_rise)
          item.temperature =
            "/ " +
            this.calculateCalcFeel(data.temperature) +
            "°" +
            this.settings.temp_meas.toUpperCase();
      }

      item.zone_id = zone_id;
      item.identity_value = identity_value;
      item.message = message;
      item.code = code;
      item.identity_types = identity_types;
      item.pass_type = pass_type;
      item.company = "";
      let cmpname = [];
      item.organization = item.organization || [];
      item.organization.forEach((el) => {
        cmpname.push(el.name);
      });

      item.company = cmpname.join(", ");

      if (parseInt(data.code) >= 301) {
        item.eventType = "deviceAlarm";
      } else {
        item.eventType = "userAlarm";
      }

      let avatarStatic = "";
      let avatar = "";

      if (parseInt(item.event.code) === 350) {
        avatarStatic = require("@/assets/img/press-btn.svg");
      } else if (parseInt(item.event.code) >= 301) {
        item.eventType = "deviceAlarm";
      } else {
        item.eventType = "userAlarm";
      }

      if (item.eventType === "deviceAlarm") {
        if (item.photo) {
          if (item.event.image_encode) {
            avatar = `data:image/png;base64,${item.event.image_encode}`;
          } else {
            avatar = item.photo;
          }
        } else {
          avatarStatic = require("@/assets/img/error.svg");
        }
      } else {
        if (item.event.image_encode) {
          avatar = `data:image/png;base64,${item.event.image_encode}`;
        } else {
          avatar = item.photo ? item.photo : null;
        }
      }

      item.avatarStatic = avatarStatic;
      item.avatar = avatar;

      item.isHasPhoto = item.avatar ? true : false;

      // Вывод информационного поля
      if (item.type_user == "guest") {
        item.info = udata["visit_purpose"];
      } else {
        item.info = udata["post"];
      }
      item.udata = "";

      if (item.type_user) {
        item.utype = this.$t("users." + item.type_user);
      }

      if (!item.purpose) item.purpose = "exit";

      item.purposeType = item.purpose;

      if (zone) item.purpose = `${zone} / ${device}`;
      item.data = "";

      return item;
    },

    // correctEventVisits(visit) {
    //   const code = visit.event.code;

    //   const data = {
    //     full_name: this.$t("monitoring.otherEvent"),
    //     zone: visit.event.zone || "",
    //     device: visit.event.device_name || "",
    //     purposeType: "",
    //     purpose: "",
    //     photoStatic: "/avatar/profile/avatar.png",
    //     avatarStatic: "/avatar/small/avatar.png",
    //     eventType: "otherEvent",
    //     visits: this.$moment(String(visit.created_at)).format(
    //       "DD.MM.YY HH:mm:ss"
    //     ),
    //     created_at: visit.created_at,
    //   };

    //   if (!visit.purpose) {
    //     data.purposeType = "exit";
    //   } else {
    //     data.purposeType = visit.purpose;
    //   }

    //   if (data.zone) data.purpose = `${data.zone} / ${data.device}`;

    //   switch (code) {
    //     case "350":
    //       data.full_name = this.$t("monitoring.pressBtn");
    //       data.photoStatic = require("@/assets/img/press-btn.svg");
    //       data.avatarStatic = require("@/assets/img/press-btn.svg");
    //       break;
    //     default:
    //       break;
    //   }

    //   return data;
    // },

    calculateCalcFeel(t) {
      if (this.settings.temp_meas == "f") {
        return Math.round(((t * 9) / 5 + 32) * 10) / 10;
      } else {
        return t;
      }
    },

    getIdentityTypesArr(types) {
      let result = [];

      if (types) {
        result = types.split(",");
      }

      return result;
    },

    setEntranceAndExitVisits(visit) {
      if (visit) {
        switch (visit.purposeType) {
          case "entrance":
          case "duplex":
            if (
              !this.entranceVisit ||
              this.$moment(visit.created_at).isAfter(
                this.$moment(this.entranceVisit.created_at)
              )
            ) {
              this.entranceVisit = { ...visit };
            }
            break;
          case "exit":
            if (
              !this.exitVisit ||
              this.$moment(visit.created_at).isAfter(
                this.$moment(this.exitVisit.created_at)
              )
            ) {
              this.exitVisit = { ...visit };
            }
            break;
          default:
            this.entranceVisit = null;
            this.exitVisit = null;
            break;
        }
      }
    },

    clearEntranceAndExitVisits() {
      this.entranceVisit = null;
      this.exitVisit = null;
    },

    getSettings() {
      const tab = parseInt(localStorage.getItem("terminal.dascboard.tabs"));

      if (tab !== null) {
        this.tabActive = tab;
      }
    },

    onUnblockUser(id) {
      if (this.entranceVisit.person_id === id) {
        this.entranceVisit.Blocked = false;
      }

      if (this.exitVisit.person_id === id) {
        this.exitVisit.Blocked = false;
      }
    },

    onGuestReg(visitor) {
      const index = visitor.avatar.indexOf(",");
      const photo = visitor.avatar.slice(index + 1);

      const person = {
        last_name: `${this.$t("users.guest")}-${this.$moment().format(
          "DDMMYYHHmm"
        )}`,
        photo,
        permits: [
          {
            isEdit: true,
            is_active: true,
            type: "guest",
            onepass: true,
            expire_time: this.$moment().add(24, "hour").toISOString(),
            token: [
              {
                type: this.getIdentityValueType(visitor.identity_value) || null,
                number:
                  this.getIdentityValueType(visitor.identity_value) === "card"
                    ? visitor.identity_value
                    : null,
              },
            ],
          },
        ],
      };

      this.$router.push({
        name: this.$const.ROUTES.ADD_PERSON,
        params: { person },
      });
    },

    getIdentityValueType(identityValue) {
      const separator = identityValue.indexOf(":");
      const type = identityValue.substring(0, separator);
      return type;
    },

    initData() {
      this.clearSetIntervalIndoors();

      switch (this.tabActive) {
        case NEW_VISIT_TAB:
          this.getVisitors();
          break;
        case INDOOR_TAB:
          this.getIndoors();

          this.indoorsInterval = setInterval(this.getIndoors, 5000);
          break;
        case EVENT_LOG_TAB:
          this.getEventLog();
          break;
        default:
          this.getVisitors();
          break;
      }
    },

    clearSetIntervalIndoors() {
      if (this.indoorsInterval) {
        clearInterval(this.indoorsInterval);
      }
    },

    setItemToVisitorsList(visit) {
      const isAddToArray =
        this.visitors.data.length &&
        this.visitors.pagination.current_page === 1 &&
        this.$moment(visit.created_at).isAfter(
          this.$moment(
            this.visitors.data[this.visitors.data.length - 1].created_at
          )
        );

      if (isAddToArray) {
        if (this.visitors.data.length > this.visitors.pagination.per_page - 1)
          this.visitors.data.splice(-1);

        for (let i = 0; i < this.visitors.data.length; i++) {
          const arrVisit = this.visitors.data[i];

          const prevCount = this.eventCounter;
          this.eventCounter = this.eventCounter + 1;

          if (
            this.$moment(visit.created_at).isAfter(
              this.$moment(arrVisit.created_at)
            ) ||
            this.eventCounter > prevCount
          ) {
            this.visitors.data.splice(i, 0, visit);
            break;
          }
        }
      } else {
        if (this.visitors.data.length < this.visitors.pagination.per_page)
          this.visitors.data.push(visit);
      }
    },

    setItemToLogList(visit) {
      if (this.eventLogs.data.length) {
        const length = this.eventLogs.data.length;

        const isAddToArray =
          this.eventLogs.data.length &&
          this.eventLogs.pagination.current_page === 1 &&
          this.$moment(visit.created_at).isAfter(
            this.$moment(
              this.eventLogs.data[this.eventLogs.data.length - 1].created_at
            )
          );

        if (isAddToArray) {
          if (length >= this.eventLogs.pagination.per_page) {
            this.eventLogs.data.splice(-1);
          }

          for (let i = 0; i < this.eventLogs.data.length; i++) {
            const arrVisit = this.eventLogs.data[i];
            if (
              this.$moment(visit.created_at).isAfter(
                this.$moment(arrVisit.created_at)
              )
            ) {
              this.eventLogs.data.splice(i, 0, visit);
              break;
            }
          }
        }
      } else {
        this.eventLogs.data.push(visit);
      }
    },

    getVisitors() {
      let params = {
        temp: this.temperatureCheck,
        limit: this.visitorsLimit,
      };

      if (this.typeUser) {
        params = {
          ...params,
          type_user: this.typeUser,
        };
      }

      if (this.zoneId) {
        params = {
          ...params,
          zone_id: this.zoneId,
        };
      }

      this.axios("visitors", { params }).then((response) => {
        this.visitors.data = response.data.data
          ? response.data.data.map((item) => {
              return this.correctVisits(item, false);
            })
          : [];

        this.visitors.pagination = response.data.meta.pagination || {
          total: 0,
          per_page: 2,
          to: 0,
          current_page: 1,
        };
      });
    },

    getIndoors() {
      let params = {
        page: this.indoors.pagination?.current_page || 1,
        temp: this.temperatureCheck,
      };

      if (this.typeUser) {
        params = {
          ...params,
          type_user: this.typeUser,
        };
      }

      if (this.zoneId) {
        params = {
          ...params,
          zone_id: this.zoneId,
        };
      }

      this.axios("advanced/log", {
        params,
      }).then((response) => {
        this.indoors.data = response.data.data
          ? response.data.data.map((item) => {
              return this.correctVisits(item, false);
            })
          : [];
        this.indoors.pagination = response.data.meta.pagination || {
          total: 0,
          per_page: 2,
          to: 0,
          current_page: 1,
        };
      });
    },

    getEventLog(isFirstRequest) {
      let params = {
        page: this.eventLogs.pagination?.current_page || 1,
        temp: this.temperatureCheck,
      };

      if (this.typeUser) {
        params = {
          ...params,
          type_user: this.typeUser,
        };
      }

      if (this.zoneId) {
        params = {
          ...params,
          zone_id: this.zoneId,
        };
      }

      this.axios("alarmvisits", { params }).then((response) => {
        this.eventLogs.data = response.data.data
          ? response.data.data.map((item) => {
              return this.correctVisits(item, false);
            })
          : [];

        this.eventLogs.pagination = response.data.meta.pagination || {
          total: 0,
          per_page: 2,
          to: 0,
          current_page: 1,
        };

        if (isFirstRequest) {
          const visitorEntrance = this.eventLogs.data.find(
            (user) =>
              user.purposeType === "entrance" || user.purposeType === "duplex"
          );
          const visitorExit = this.eventLogs.data.find(
            (user) => user.purposeType === "exit"
          );

          this.setEntranceAndExitVisits(visitorEntrance);
          this.setEntranceAndExitVisits(visitorExit);
        }
      });
    },

    temperatureAlarm(visit) {
      if (
        this.isTempAlarm(visit.temperatureValue) &&
        this.settings.beep_person_alarm
      ) {
        const audio = new Audio(require("@/assets/sound/ambulance.mp3"));
        audio.play();
      }
    },

    isTempAlarm(temp) {
      if (
        temp &&
        (temp <= parseFloat(this.settings.temperature_range.split(",")[0]) ||
          temp >= parseFloat(this.settings.temperature_range.split(",")[1]))
      ) {
        return true;
      }

      return false;
    },

    onChangeFilter({ typeUser, zoneId }) {
      this.typeUser = typeUser;
      this.zoneId = zoneId;
      this.resetPage();

      this.initData();
    },

    resetPage(page = 1) {
      this.visitors.pagination.current_page = page;
      this.indoors.pagination.current_page = page;
      this.eventLogs.pagination.current_page = page;
    },

    showUser(item) {
      if (item?.person_id) {
        this.$router.push({
          name: this.$const.ROUTES.SHOW_PERSON,
          params: { id: item.person_id },
        });
      }
    },

    styleRow(item) {
      if (item.blacklisted) {
        return "background: #272D3D; color: #fff;";
      } else if (
        this.isTempAlarm(item.temperatureValue) &&
        this.settings.light_person_alarm
      ) {
        return "background: #ff9996; color: #000;";
      }
    },

    onChangeTemperatureMode() {
      this.resetPage();
      this.initData();
    },

    reportIndoors() {
      const params = {
        lang: this.settings.lang,
        temp: this.temperatureCheck,
      };

      if (this.typeUser) {
        params.type_user = this.typeUser;
      }

      if (this.zoneId) {
        params.zone_id = this.zoneId;
      }

      const requestParams = {
        method: "get",
        params,
        url: "report/occup",
        responseType: "blob",
      };

      this.axios
        .request(requestParams)
        .then((response) => {
          let filename =
            "occup-" + this.$moment().format("YYYYMMDDHHmmss") + ".xlsx";
          let url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: "application/xlsx",
            })
          );
          let link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch(() => {
          this.$root.$prompt({
            message: this.$t("advanced.nopass"),
            btnAgree: this.$t("button.close"),
            btnDisagree: false,
          });
        });
    },

    closeAlarmDialog() {
      this.isShowAlarmDialog = false;
      this.deviceAlarm = null;
    },

    showAlarmDialog(alarm) {
      this.deviceAlarm = alarm;
      this.isShowAlarmDialog = true;
    },
  },

  computed: {
    ...mapGetters({
      role: "user/getRole",
    }),

    ...mapState({
      settings: (state) => state.settings.data,
    }),
  },

  destroyed() {
    this.disconnectedWebSockets();
    this.clearSetIntervalIndoors();
  },

  created() {
    this.connectWebSockets();
    this.getSettings();
    this.initData();
    this.getEventLog(true);
  },
};
</script>
