<template>
  <DataTable
    :fields="fields"
    :rows="items"
    :dblclick="onDblClick"
    :rowstyle="rowstyle"
  >
    {{ $t("users.no_passes_registered") }}

    <template #[`item.avatar`]="{ item }">
      <v-avatar v-if="item.avatarStatic">
        <v-img :src="item.avatarStatic" />
      </v-avatar>

      <v-avatar
        v-else-if="isPhotoBase64(item.avatar)"
        @mouseenter="item.isHasPhoto ? onHoverImg(item.avatar) : null"
        @mouseleave="onLeaveHoverImg"
      >
        <v-img :src="item.avatar" />
      </v-avatar>

      <v-avatar
        v-else-if="item.avatar"
        @mouseenter="
          item.isHasPhoto
            ? onHoverImg(`/avatar/profile/${item.avatar}`, true)
            : null
        "
        @mouseleave="onLeaveHoverImg"
      >
        <ImgProtected :src="`/avatar/small/${item.avatar}`" :alt="item.name" />
      </v-avatar>

      <v-avatar v-else color="primary" size="50">
        <v-icon dark> mdi-account-circle </v-icon>
      </v-avatar>
    </template>

    <template v-slot:footer>
      <v-row>
        <v-col><slot name="footer" /></v-col>
        <v-spacer />
        <v-col class="d-flex">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="items.length"
                v-bind="attrs"
                v-on="on"
                class="mr-1"
                icon
                @click="reportIndoors"
              >
                <v-icon>mdi-file-excel</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("advanced.reportIndoors") }}</span>
          </v-tooltip>
          <v-btn
            v-if="items.length"
            color="success"
            @click="closeVisitAll(items)"
          >
            {{ $t("button.closeAllVisits") }}
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </DataTable>
</template>

<script>
import { isBase64 } from "validator";

import DataTable from "@/components/datatable";
import ImgProtected from "@/components/ImgProtected";

import imgProtectedMixin from "@/mixins/imgProtected";

export default {
  components: { DataTable, ImgProtected },

  mixins: [imgProtectedMixin],

  props: {
    data: {
      type: Array,
      default: () => [],
    },

    rowstyle: Function,
  },

  data() {
    return {
      fields: [
        {
          name: "avatar",
          style: "width: 50px;",
        },
        {
          group: [
            {
              name: "full_name",
              title: this.$t("users.fullname"),
              btag: "",
              atag: "",
            },
            {
              name: "purpose",
              title: this.$t("users.device"),
              style: "font-size: 13px; margin-right: 3px;",
            },
            {
              name: "utype",
              title: this.$t("users.status"),
              style: "font-size: 10px; margin-right: 3px; display: inline",
            },
            {
              name: "company",
              title: this.$t("users.subdivision"),
              btag: " : ",
              style: "font-size: 10px; display: inline",
            },
          ],
          header: { text: this.$t("users.visitor") },
        },
        {
          name: "visits",
          header: { text: this.$t("users.visit_time") },
          style: "width: 50px;",
        },
        {
          type: "button",
          title: this.$t("users.eventClose"),
          class: "btn btn-success mb-3 mdi mdi-open-in-new",
          click: this.closeVisit,
          style: "width: 40px; margin-left: 2px;",
        },
      ],

      closedItems: new Set(),
    };
  },

  methods: {
    onDblClick(item) {
      this.$emit("dblclick", item);
    },

    closeVisitAll(data) {
      if (data.length) {
        const ids = data.map((item) => item.user_id);
        this.closedItems = new Set([...this.closedItems, ...ids]);

        this.axios
          .put("visit/close", { ids })
          .then(() => {
            this.$emit("closeVisits");
          })
          .catch((error) => {
            let message = error.response.data.status.message;

            switch (message) {
              case "no permission for close visits":
                message = this.$t("users.noRightsToCloseVisitMessage");
                break;
              default:
                break;
            }

            this.$notify({
              group: "info",
              type: "error",
              text: message,
            });
          });
      }
    },

    closeVisit(item) {
      this.closeVisitAll([item]);
    },

    reportIndoors() {
      this.$emit("report");
    },

    async onHoverImg(img, isProtected) {
      let image = img;

      if (isProtected) {
        await this.getImage(img);
        image = this.imgProtected;
      }

      this.$emit("hoverImg", image);
    },

    onLeaveHoverImg(img) {
      this.$emit("leaveHoverImg", img);
    },

    isPhotoBase64(photo) {
      if (photo) {
        const index = photo.indexOf(",");
        const photoBase64 = photo.slice(index + 1);

        return isBase64(photoBase64);
      }

      return false;
    },
  },

  computed: {
    items() {
      return this.data.filter((item) => !this.closedItems.has(item.user_id));
    },
  },
};
</script>
