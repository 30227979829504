import Vue from "vue";
import i18n from "@/plugins/i18n";

export default {
  getList({ commit }, params) {
    commit("setLoading", { type: "IS_PENDING" });

    return Vue.axios("zones", { params })
      .then((response) => {
        const { data } = response.data;

        commit("setList", data);
        commit("setLoading", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.errorLoading"),
        });
      });
  },

  getTree({ commit }) {
    commit("setLoading", { type: "IS_PENDING" });

    return Vue.axios("zones/tree")
      .then((response) => {
        const { data } = response.data;

        commit("setTree", data);
        commit("setLoading", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.errorLoading"),
        });
      });
  },
};
