import Vue from "vue";
import i18n from "@/plugins/i18n";
import { resetAutoDestroyState } from "@vue/test-utils";

export default {
  get({ commit }) {
    commit("setLoading", { type: "IS_PENDING" });

    return Vue.axios("setting/default")
      .then((response) => {
        commit("set", response.data.data);
        commit("setLoading", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.saveError"),
        });
      });
  },
  getUrl({ commit }) {
    commit("setLoadingServerUrl", { type: "IS_PENDING" });

    return Vue.axios
      .get("/setting/getUrl")
      .then((response) => {
        const { data } = response.data;

        commit("setServerUrl", data);
        commit("setLoadingServerUrl", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoadingServerUrl", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.errorLoading"),
        });
      });
  },
  setStore({ commit, dispatch }, params) {
    commit("setLoadingStore", { type: "IS_PENDING" });

    return Vue.axios
      .post("settings", params)
      .then(() => {
        Vue.prototype.$notify({
          group: "info",
          type: "success",
          text: i18n.t("message.saveSuccess"),
        });
        commit("setLoadingStore", { type: "IS_SUCCESS" });
        dispatch("get");
      })
      .catch((error) => {
        commit("setLoadingStore", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });
        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.saveError"),
        });
      });
  },
  resetTerminal({ commit }) {
    commit("setLoadingResetTerminal", { type: "IS_PENDING" });

    Vue.axios
      .get("/restart")
      .then(() => {
        commit("setLoadingResetTerminal", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoadingResetTerminal", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.error"),
        });
      });
  },
  getSettings({ commit }) {
    commit("setLoadingSettingsData", { type: "IS_PENDING" });

    return Vue.axios
      .get("/settings")
      .then((response) => {
        let set = {};
        response.data.data.forEach((item) => {
          set[item.Key] = item.Value;
        });
        commit("setSettingsData", set);
        commit("setLoadingSettingsData", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoadingSettingsData", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.errorLoading"),
        });
      });
  },
  setSettings({ commit }, values) {
    commit("setLoadingSaveSettingsData", { type: "IS_PENDING" });
    Vue.axios
      .post("/settings", values)
      .then(() => {
        commit("setLoadingSaveSettingsData", { type: "IS_SUCCESS" });
        Vue.prototype.$notify({
          group: "info",
          type: "success",
          text: i18n.t("message.saveSuccess"),
        });
      })
      .catch((error) => {
        commit("setLoadingSaveSettingsData", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });
        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.saveError"),
        });
      });
  },
  backup({ commit }) {
    commit("setLoadingBackup", { type: "IS_PENDING" });
    Vue.axios
      .post("backup")
      .then(() => {
        commit("setLoadingBackup", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoadingBackup", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          type: "error",
          text: error.response.data.status.message,
        });
      });
  },
  restore({ commit }, formData, config) {
    commit("setLoadingRestore", { type: "IS_PENDING" });

    Vue.axios
      .post("/restore", formData, config)
      .then(() => {
        commit("setLoadingRestore", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoadingRestore", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          type: "error",
          text: error.response.data.status.message,
        });
      });
  },
  restoreBackup({ commit }, name) {
    commit("setLoadingRestoreBackup", { type: "IS_PENDING" });
    Vue.axios
      .post(`restore/` + name)
      .then(() => {
        commit("setLoadingRestoreBackup", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoadingRestoreBackup", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          type: "error",
          text: error.response.data.status.message,
        });
      });
  },
  backupStop({ commit }) {
    commit("setLoadingBackupStop", { type: "IS_PENDING" });
    Vue.axios
      .put("backup/stop")
      .then(() => {
        commit("setLoadingBackupStop", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoadingBackupStop", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });
        Vue.prototype.$notify({
          type: "error",
          text: error.response.data.status.message,
        });
      });
  },
  deleteBackup({ commit }, name) {
    commit("setLoadingDeleteBackup", { type: "IS_PENDING" });
    Vue.axios
      .delete(`backup/` + name)
      .then(() => {
        commit("setLoadingDeleteBackup", { type: "IS_SUCESS" });
      })
      .catch((error) => {
        commit("setLoadingDeleteBackup", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          type: "error",
          text: error.response.data.status.message,
        });
      });
  },
  downloadBackup({ commit }, name) {
    commit("setLoadingDownloadBackup", { type: "IS_PENDING" });

    Vue.axios({
      url: `backup/` + name,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        commit("setLoadingDownloadBackup", { type: "IS_SUCCESS" });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
      })
      .catch((error) => {
        commit("setLoadingDownloadBackup", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          type: "error",
          text: error.response.data.status.message,
        });
      });
  },
  getBackupList({ commit }) {
    commit("setLoadingBackupList", { type: "IS_PENDING" });

    Vue.axios("backup/list")
      .then((response) => {
        commit("setLoadingBackupList", { type: "IS_SUCCESS" });
        commit("setBackupList", response.data.data);
      })
      .catch((error) => {
        commit("setLoadingBackupList", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          type: "error",
          text: error.response.data.status.message,
        });
      });
  },
  getBackupStatus({ commit }) {
    commit("setLoadingBackupStatus", { type: "IS_PENDING" });

    Vue.axios("backup/status")
      .then(() => {
        commit("setLoadingBackupStatus", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoadingBackupStatus", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          type: "error",
          text: error.response.data.status.message,
        });
      });
  },
};
