export default {
  MONITORING: "monitoring",
  FULLSCREEN_MONITORING: "fullscreenMonitoring",

  ANALYTICS: "analytics",
  EVENTS_JOURNAL: "events-journal",
  PASSES_JOURNAL: "passes-journal",

  SETTINGS: "settings",
  GENERAL_SETTINGS: "general-settings",
  IMPORT: "import",
  NOTIFICATIONS: "notifications",
  LOG: "log",

  EDIT_PERSONAL: "editPersonal",

  ADMISSION: "admission",

  PERSONS: "persons",
  ADD_PERSON: "person-add",
  EDIT_PERSON: "person-edit",
  SHOW_PERSON: "person-show",

  DEPARTMENTS: "departments",
  ADD_DEPARTMENT: "department-add",
  EDIT_DEPARTMENT: "department-edit",

  ACCESS_LEVELS: "access-levels",
  ADD_ACCESS_LEVEL: "access-level-add",
  EDIT_ACCESS_LEVEL: "access-level-edit",

  OBJECT: "object",

  ZONES: "zones",
  ADD_ZONE: "zone-add",
  EDIT_ZONE: "zone-edit",

  DEVICES: "devices",
  ADD_DEVICE: "addDevice",
  EDIT_DEVICE: "editDevice",

  DOCUMENTS: "documents",

  PERMIT_REQUESTS: "permitRequests",
  ADD_PERMIT_REQUEST: "addPermitRequest",
  ADD_MULTI_PERMIT_REQUEST: "addMultiPermitRequest",
  EDIT_PERMIT_REQUEST: "editPermitRequest",

  OPERATORS: "operators",
  ADD_OPERATOR: "operator-add",
  EDIT_OPERATOR: "operator-edit",
};
