<template>
  <v-card class="mx-auto" outlined color="#F3F2FF">
    <v-card-text>
      <v-row>
        <v-col cols="auto">
          <date-picker
            :value="dateRange"
            type="datetime"
            range
            :format="DATE_FORMAT"
            value-type="format"
            :placeholder="$t('advanced.dateTime')"
            confirm
            @confirm="submit('dateRange', $event)"
            @clear="submit('dateRange', [])"
          />
        </v-col>

        <v-col cols="auto">
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-badge
                color="primary"
                bordered
                overlap
                :content="identityTypes.length"
                :value="identityTypes.length ? true : false"
              >
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  depressed
                  color="blue"
                  dark
                >
                  {{ $t("advanced.identityTypes") }}&nbsp;
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </v-badge>
            </template>

            <v-card>
              <v-list>
                <v-list-item-group
                  :value="identityTypes"
                  multiple
                  @change="submit('identityTypes', $event)"
                >
                  <v-list-item value="qr">
                    <template v-slot:default="{ active }">
                      <v-list-item-action>
                        <v-checkbox :input-value="active" color="primary" />
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("advanced.qr")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-item>

                  <v-list-item value="card">
                    <template v-slot:default="{ active }">
                      <v-list-item-action>
                        <v-checkbox :input-value="active" color="primary" />
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("advanced.card")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-item>

                  <v-list-item value="face">
                    <template v-slot:default="{ active }">
                      <v-list-item-action>
                        <v-checkbox :input-value="active" color="primary" />
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("advanced.face")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-menu>
        </v-col>

        <v-col cols="auto">
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-badge
                color="primary"
                bordered
                overlap
                :content="type.length"
                :value="type.length ? true : false"
              >
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  depressed
                  color="blue"
                  dark
                >
                  {{ $t("advanced.typeStatus") }}&nbsp;
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </v-badge>
            </template>

            <v-card>
              <v-list>
                <v-list-item-group
                  :value="type"
                  multiple
                  @change="submit('type', $event)"
                >
                  <v-list-item value="alarm">
                    <template v-slot:default="{ active }">
                      <v-list-item-action>
                        <v-checkbox :input-value="active" color="primary" />
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("users.notRecognized")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-item>

                  <v-list-item value="pass">
                    <template v-slot:default="{ active }">
                      <v-list-item-action>
                        <v-checkbox :input-value="active" color="primary" />
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("users.recognizedAndPass")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-item>

                  <v-list-item value="before">
                    <template v-slot:default="{ active }">
                      <v-list-item-action>
                        <v-checkbox :input-value="active" color="primary" />
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("users.recognized")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-item>

                  <v-list-item value="error">
                    <template v-slot:default="{ active }">
                      <v-list-item-action>
                        <v-checkbox :input-value="active" color="primary" />
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("users.error")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-menu>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <Search
            :value="value.search"
            :searchParams="searchParams"
            :saveSearchHistory="$const.SEARCH_HISTORY.ANALYTICS"
            @search="submit('search', $event)"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Search from "@/components/Search";

const DATE_FORMAT = "DD.MM.YYYY HH:mm";

export default {
  components: {
    Search,
  },

  props: {
    value: {
      type: Object,
      default: () => ({
        identityTypes: [],
        type: [],
        search: "",
        startDate: "",
        endDate: "",
      }),
    },
  },

  data() {
    return {
      DATE_FORMAT,

      searchParams: [
        {
          value: "name",
          text: this.$t("user.name"),
        },
        {
          value: "identety_value",
          text: this.$t("advanced.identityValue"),
        },
        {
          value: "zone",
          text: this.$t("advanced.zone"),
        },
      ],
    };
  },

  methods: {
    submit(key, value) {
      if (key === "dateRange") {
        const startDate = this.$moment(value[0], DATE_FORMAT).toISOString();

        const endDate = this.$moment(value[1], DATE_FORMAT).toISOString();

        this.$emit("input", { ...this.value, startDate, endDate });
      } else {
        this.$emit("input", { ...this.value, [key]: value });
      }
    },
  },

  computed: {
    dateRange() {
      return [
        this.$moment(this.value.startDate).format(DATE_FORMAT),
        this.$moment(this.value.endDate).format(DATE_FORMAT),
      ];
    },

    identityTypes() {
      let identityTypes = [];

      if (Array.isArray(this.value.identityTypes)) {
        identityTypes = this.value.identityTypes;
      } else {
        identityTypes = [this.value.identityTypes];
      }

      return identityTypes;
    },

    type() {
      let type = [];

      if (Array.isArray(this.value.type)) {
        type = this.value.type;
      } else {
        type = [this.value.type];
      }

      return type;
    },
  },
};
</script>
