import Vue from "vue";
import VueRouter from "vue-router";

// Constants rules
import RULES from "@/constants/rules";
import ROUTES from "@/constants/routes";

// PAGES
// Monitoring
import Monitoring from "./pages/monitoring/index.vue";
import FullScreenMonitoring from "./pages/monitoring/FullScreen.vue";

// Analytics
import Analytics from "./pages/analytics";

// Events journal
import EventsJournal from "./pages/events";

// Passes journal
import PassesJournal from "./pages/passes";

// Settings
import Settings from "./pages/settings";
import GeneralSettings from "./pages/general-settings";
import ImportPage from "./pages/import-export";
import Notifications from "./pages/notifications";
import Log from "./pages/log";

// Personal
import EditPersonal from "./pages/personal/Edit";

// Object
import ObjectPage from "./pages/object";

// Documents
import Documents from "./pages/documents";

// Admission
import Admission from "./pages/admission";

// Persons
import Persons from "./pages/persons";
import PersonAdd from "./pages/persons/add";
import PersonEdit from "./pages/persons/edit";
import PersonShow from "./pages/persons/show";

// Departments
import Departments from "./pages/departments";
import DepartmentAdd from "./pages/departments/add";
import DepartmentEdit from "./pages/departments/edit";

// Access levels
import AccessLevels from "./pages/access-levels";
import AccessLevelAdd from "./pages/access-levels/add";
import AccessLevelEdit from "./pages/access-levels/edit";

// Permit Requests
import PermitRequests from "./pages/permit-requests";
import AddPermitRequest from "./pages/permit-requests/add";
import AddMultiPermitRequest from "./pages/permit-requests/add-multi";
import EditPermitRequest from "./pages/permit-requests/edit";

// Operators
import Operators from "./pages/operators";
import OperatorAdd from "./pages/operators/add";
import OperatorEdit from "./pages/operators/edit";

// Devices
import DeviceList from "./pages/devices";
import DeviceEdit from "./pages/devices/edit";

// Zones
import Zones from "./pages/zones";
import ZoneAdd from "./pages/zones/add";
import ZoneEdit from "./pages/zones/edit";

// 404
import NotFound from "./pages/404";

Vue.use(VueRouter);

const routes = [
  // Monitoring
  {
    path: "/",
    alias: "/monitoring",
    name: ROUTES.MONITORING,
    component: Monitoring,
    meta: {
      auth: true,
      permissions: [],
    },
  },

  {
    path: "/monitoring/fullscreen",
    name: ROUTES.FULLSCREEN_MONITORING,
    component: FullScreenMonitoring,
    meta: {
      auth: true,
      layout: "blank",
      permissions: [RULES.MONITORING_VIEW],
    },
  },

  // Analytics
  {
    path: "/analytics",
    name: ROUTES.ANALYTICS,
    component: Analytics,
    meta: {
      auth: true,
      permissions: [RULES.JOURNAL_VIEW],
    },

    children: [
      // Passes journal
      {
        path: "passes",
        name: ROUTES.PASSES_JOURNAL,
        component: PassesJournal,
      },
      // Events journal
      {
        path: "events",
        name: ROUTES.EVENTS_JOURNAL,
        component: EventsJournal,
      },
    ],
  },

  // Settings
  {
    path: "/settings",
    name: ROUTES.SETTINGS,
    component: Settings,
    meta: {
      auth: true,
      isNarrowContainer: true,
      permissions: [RULES.SETTINGS_VIEW],
    },
    children: [
      // General
      {
        path: "general-settings",
        name: ROUTES.GENERAL_SETTINGS,
        component: GeneralSettings,
      },
      // Import
      {
        path: "import",
        name: ROUTES.IMPORT,
        component: ImportPage,
      },
      // Notifications

      {
        path: "notifications",
        name: ROUTES.NOTIFICATIONS,
        component: Notifications,
      },
      // Log

      {
        path: "log",
        name: ROUTES.LOG,
        component: Log,
      },
    ],
  },

  // Personal
  {
    path: "/personal",
    name: ROUTES.EDIT_PERSONAL,
    component: EditPersonal,
    meta: {
      auth: true,
      isNarrowContainer: true,
    },
  },

  // Admission
  {
    path: "/admission",
    name: ROUTES.ADMISSION,
    component: Admission,
    meta: {
      auth: true,
      permissions: [
        RULES.PERSON_VIEW,
        RULES.OPERATOR_VIEW,
        RULES.DEPARTMENT_VIEW,
        RULES.ACCESS_LEVELS_VIEW,
      ],
    },

    children: [
      // Persons
      {
        path: "persons",
        name: ROUTES.PERSONS,
        component: Persons,
        meta: {
          auth: true,
          permissions: [RULES.PERSON_VIEW],
        },
      },
      // Departments
      {
        path: "departments",
        name: ROUTES.DEPARTMENTS,
        component: Departments,
        meta: {
          auth: true,
          permissions: [RULES.DEPARTMENT_VIEW],
        },
      },
      // Access Levels
      {
        path: "access-levels",
        name: ROUTES.ACCESS_LEVELS,
        component: AccessLevels,
        meta: {
          auth: true,
          permissions: [RULES.ACCESS_LEVELS_VIEW],
        },
      },
      // Operators
      {
        path: "operators",
        name: ROUTES.OPERATORS,
        component: Operators,
        meta: {
          auth: true,
          permissions: [RULES.OPERATOR_VIEW],
        },
      },
    ],
  },

  // Persons
  {
    path: "/admission/person/add",
    name: ROUTES.ADD_PERSON,
    component: PersonAdd,
    meta: {
      auth: true,
      isNarrowContainer: true,
      permissions: [RULES.PERSON_ADD, RULES.REQUEST_ADD],
    },
  },
  {
    path: "/admission/person/:id/edit",
    name: ROUTES.EDIT_PERSON,
    component: PersonEdit,
    meta: {
      auth: true,
      isNarrowContainer: true,
      permissions: [RULES.PERSON_EDIT],
    },
  },
  {
    path: "/admission/person/:id",
    name: ROUTES.SHOW_PERSON,
    component: PersonShow,
    meta: {
      auth: true,
      isNarrowContainer: true,
      permissions: [RULES.PERSON_VIEW],
    },
  },

  // Departments
  {
    path: "/admission/department/add",
    name: ROUTES.ADD_DEPARTMENT,
    component: DepartmentAdd,
    meta: {
      auth: true,
      isNarrowContainer: true,
      permissions: [RULES.DEPARTMENT_ADD],
    },
  },
  {
    path: "/admission/department/:id/edit",
    name: ROUTES.EDIT_DEPARTMENT,
    component: DepartmentEdit,
    meta: {
      auth: true,
      isNarrowContainer: true,
      permissions: [RULES.DEPARTMENT_EDIT],
    },
  },

  // Access Levels
  {
    path: "/admission/access-level/add",
    name: ROUTES.ADD_ACCESS_LEVEL,
    component: AccessLevelAdd,
    meta: {
      auth: true,
      isNarrowContainer: true,
      permissions: [RULES.ACCESS_LEVELS_ADD],
    },
  },
  {
    path: "/admission/access-level/:id/edit",
    name: ROUTES.EDIT_ACCESS_LEVEL,
    component: AccessLevelEdit,
    meta: {
      auth: true,
      isNarrowContainer: true,
      permissions: [RULES.ACCESS_LEVELS_EDIT],
    },
  },

  // Operators
  {
    path: "/admission/operators/add",
    name: ROUTES.ADD_OPERATOR,
    component: OperatorAdd,
    meta: {
      auth: true,
      isNarrowContainer: true,
      permissions: [RULES.OPERATOR_ADD],
    },
  },
  {
    path: "/admission/operators/:id/edit",
    name: ROUTES.EDIT_OPERATOR,
    component: OperatorEdit,
    meta: {
      auth: true,
      isNarrowContainer: true,
      permissions: [RULES.OPERATOR_EDIT],
    },
  },

  // Object
  {
    path: "/object",
    name: ROUTES.OBJECT,
    component: ObjectPage,
    meta: {
      auth: true,
      permissions: [RULES.DEVICE_VIEW, RULES.ZONE_VIEW],
    },

    children: [
      // Devices
      {
        path: "devices",
        name: ROUTES.DEVICES,
        component: DeviceList,
        meta: {
          auth: true,
          permissions: [RULES.DEVICE_VIEW],
        },
      },
      // Zones
      {
        path: "zones",
        name: ROUTES.ZONES,
        component: Zones,
        meta: {
          auth: true,
          permissions: [RULES.ZONE_VIEW],
        },
      },
    ],
  },

  // Devices
  {
    path: "/object/devices/add",
    name: ROUTES.ADD_DEVICE,
    component: DeviceEdit,
    meta: {
      auth: true,
      isNarrowContainer: true,
      permissions: [RULES.DEVICE_ADD],
    },
  },
  {
    path: "/object/devices/:id/edit",
    name: ROUTES.EDIT_DEVICE,
    component: DeviceEdit,
    meta: {
      auth: true,
      isNarrowContainer: true,
      permissions: [RULES.DEVICE_EDIT],
    },
  },

  // Zones
  {
    path: "/object/zones/add",
    name: ROUTES.ADD_ZONE,
    component: ZoneAdd,
    meta: {
      auth: true,
      isNarrowContainer: true,
      permissions: [RULES.ZONE_ADD],
    },
  },
  {
    path: "/object/zones/:id/edit",
    name: ROUTES.EDIT_ZONE,
    component: ZoneEdit,
    meta: {
      auth: true,
      isNarrowContainer: true,
      permissions: [RULES.ZONE_EDIT],
    },
  },

  // Documents
  {
    path: "/documents",
    name: ROUTES.DOCUMENTS,
    component: Documents,
    meta: {
      auth: true,
      permissions: [RULES.REQUEST_VIEW],
    },

    children: [
      // Permit Requests
      {
        path: "permit-requests",
        name: ROUTES.PERMIT_REQUESTS,
        component: PermitRequests,
        meta: {
          auth: true,
          permissions: [RULES.REQUEST_VIEW],
        },
      },
    ],
  },

  // Permit Requests
  {
    path: "/documents/permit-requests/add",
    name: ROUTES.ADD_PERMIT_REQUEST,
    component: AddPermitRequest,
    meta: {
      auth: true,
      isNarrowContainer: true,
      permissions: [RULES.REQUEST_ADD],
    },
  },
  {
    path: "/documents/permit-requests/add-multi",
    name: ROUTES.ADD_MULTI_PERMIT_REQUEST,
    component: AddMultiPermitRequest,
    meta: {
      auth: true,
      isNarrowContainer: true,
      permissions: [RULES.REQUEST_ADD],
    },
  },
  {
    path: "/documents/permit-requests/:id",
    name: ROUTES.EDIT_PERMIT_REQUEST,
    component: EditPermitRequest,
    meta: {
      auth: true,
      isNarrowContainer: true,
      permissions: [RULES.REQUEST_VIEW],
    },
  },

  // Not found
  {
    path: "*",
    name: "notFound",
    component: NotFound,
  },
];

const router = new VueRouter({
  routes,
  linkActiveClass: "active",
  mode: "history",
});

router.beforeEach((to, from, next) => {
  if (!from.name) {
    next();
  }

  if (!router.app.$store.state.user.isSuccess) {
    const checkAuth = setInterval(() => {
      if (router.app.$store.state.user.isSuccess) {
        clearInterval(checkAuth);
        const userPermissions =
          router.app.$store.getters["user/getRole"].permissions || [];
        const isSuperAdmin =
          router.app.$store.state.user.data?.is_admin || false;
        const isSetNewPassword =
          !router.app.$store.state.user.data?.password_upd;
        if (!isSuperAdmin) {
          // Проверяем есть ли права доступа к странице
          if (to.meta.permissions && to.meta.permissions.length > 0) {
            let isAllowed = userPermissions.some((p) =>
              to.meta.permissions.includes(p)
            );
            if (!isAllowed) return next("/personal");
          }

          // Проверяем нужно ли установить новый пароль
          if (isSetNewPassword) {
            return next("/personal");
          }
        }

        next();
      }
    }, 100);
  } else {
    const userPermissions =
      router.app.$store.getters["user/getRole"].permissions || [];
    const isSuperAdmin = router.app.$store.state.user.data?.is_admin || false;

    // Проверяем есть ли права доступа к странице
    if (
      !isSuperAdmin &&
      to.meta.permissions &&
      to.meta.permissions.length > 0
    ) {
      let isAllowed = userPermissions.some((p) =>
        to.meta.permissions.includes(p)
      );
      if (!isAllowed) return next("/personal");
    }
    next();
  }
});

export default router;
