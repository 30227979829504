<template>
  <v-card :loading="isPending">
    <v-card-title>
      <span class="headline">{{ $t("devices.deviceErrorLogs") }}</span>
    </v-card-title>

    <v-card-text>
      <v-text-field
        v-model="search"
        :label="$t('datatable.enter_search_string')"
        dense
        outlined
        @input="onSearch"
      />
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">{{ $t("advanced.createdAt") }}</th>
              <th class="text-left">{{ $t("users.fullname") }}</th>
              <th class="text-left"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in data" :key="item.name">
              <td>{{ item.created_at | moment("DD.MM.YYYY HH:mm:ss") }}</td>
              <td>
                <router-link
                  :to="{ name: 'person-show', params: { id: item.users_id } }"
                >
                  {{ item.fullname }}
                </router-link>
              </td>
              <td>
                {{ item.message }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-pagination
        v-show="pagination.total > 1"
        v-model="pagination.current_page"
        :length="pagination.total"
        :total-visible="7"
        class="mt-5"
        @input="onChangePage"
      />
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="$emit('close')">{{ $t("button.close") }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import debounce from "lodash.debounce";

export default {
  props: {
    id: {
      type: Number,
      require: true,
    },
  },

  data() {
    return {
      isPending: false,
      isSuccess: false,
      isFailure: false,
      search: "",
      data: [],
      pagination: {
        current_page: 1,
        total: 0,
        per_page: 10,
      },
    };
  },

  methods: {
    getDeviceErrors() {
      const params = {
        page: this.pagination.current_page,
      };

      if (this.search) {
        params.search = this.search;
      }

      this.isPending = true;
      this.isSuccess = false;
      this.isFailure = false;

      this.axios(`device/${this.id}/errors`, { params })
        .then((response) => {
          this.isPending = false;
          this.isSuccess = true;
          this.isFailure = false;

          this.data = response.data.data;
          this.pagination = response.data.meta.pagination;
        })
        .catch(() => {
          this.isPending = false;
          this.isSuccess = false;
          this.isFailure = true;
        });
    },

    onChangePage() {
      this.getDeviceErrors();
    },

    onSearch: debounce(function () {
      this.pagination.current_page = 1;
      this.getDeviceErrors();
    }, 300),
  },

  created() {
    this.getDeviceErrors();
  },
};
</script>
