<template>
  <v-dialog :value="isOpen" max-width="400" @click:outside="$emit('close')">
    <v-card :loading="isLoadingDepartments" :disabled="isLoadingDepartments">
      <template slot="progress">
        <v-progress-linear color="primary" height="10" indeterminate />
      </template>

      <v-card-title class="text-h5">
        {{ $t("users.setDepartment") }}
      </v-card-title>

      <v-card-text>
        <v-treeview
          :active="department"
          :items="departments"
          item-key="id"
          activatable
          transition
          @update:active="department = $event"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn color="red" text @click="$emit('close')">
          <v-icon left>mdi-close</v-icon>
          {{ $t("button.close") }}
        </v-btn>

        <v-btn
          color="success"
          text
          @click="onMultiSetDepartment"
          :loading="isPendingMultiSetDepartment"
        >
          <v-icon left>mdi-check</v-icon>
          {{ $t("button.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "SetDepartmentDialog",

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    selectedIds: {
      type: Array,
      default: () => [],
    },
    filter: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      department: [],
    };
  },

  methods: {
    ...mapActions({
      getDepartments: "departments/getTree",
      multiSetDepartment: "persons/multiSetDepartment",
    }),

    async onMultiSetDepartment() {
      let params = {
        department_id: this.department[0],
      };

      if (this.selectedIds.length) {
        params.ids = this.selectedIds;
      } else {
        params = {
          ...params,
          ...this.filter,
        };
      }

      await this.multiSetDepartment(params);

      this.department = [];
      this.$emit("save");
    },
  },

  computed: {
    ...mapState({
      isLoadingDepartments: (state) => state.departments.isLoading,
      departments: (state) => state.departments.tree,
      isPendingMultiSetDepartment: (state) =>
        state.persons.isPendingMultiSetDepartment,
    }),
  },

  created() {
    this.getDepartments();
  },
};
</script>
