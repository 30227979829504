<template>
  <FilterBlock
    :loading="loading"
    :minify="minify"
    :canMinify="canMinify"
    @saveSettings="$emit('saveSettings', $event)"
  >
    <Alert v-if="!loading && !data.length" type="warning" class="mb-0">
      {{ $t("message.BadRequest") }}
    </Alert>

    <template v-else>
      <AsyncItem
        v-for="(item, index) in data"
        :value="value[item.param]"
        :key="index"
        :data="item"
        :minify="minify"
        @input="$emit('input', $event)"
      />
    </template>
  </FilterBlock>
</template>

<script>
import Alert from "@/components/Alert";

import FilterBlock from "./Filter";
import AsyncItem from "./AsyncItem";

export default {
  name: "FilterAsync",

  components: {
    Alert,
    FilterBlock,
    AsyncItem,
  },

  props: {
    value: {
      type: Object,
      default: () => ({}),
    },

    data: {
      type: Array,
      default: () => [],
    },

    loading: {
      type: Boolean,
      default: false,
    },

    minify: {
      type: Boolean,
      default: false,
    },

    canMinify: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
