<template>
  <div>
    <ZoomImg v-show="isOpenZoomImg" :url="zoomImgUrl" />
    <v-row v-if="errorMessage">
      <v-col>
        <Alert type="error">
          {{ errorMessage }}
        </Alert>
      </v-col>
    </v-row>

    <v-row>
      <v-spacer />
      <v-col cols="12" md="auto">
        <v-btn color="primary" @click="isAllEventsOpen = true">
          <v-icon left> mdi-file-chart </v-icon>
          {{ $t("advanced.allEvents") }}
        </v-btn>

        <ModalAllEventsSettings
          v-if="isAllEventsOpen"
          :loading="isPendingReport"
          @close="isAllEventsOpen = false"
          @submit="getEventsReport"
        />
      </v-col>
    </v-row>

    <v-data-table
      :loading="isPending"
      :headers="headers"
      :items="events"
      :server-items-length="pagination.per_page"
      :options.sync="options"
      hide-default-footer
    >
      <template v-slot:top>
        <EventsFilter
          v-model="filter"
          @input="onChangeFilter"
          class="mb-2 mt-5"
        />
      </template>

      <template #[`item.pass_type`]="{ item }">
        <v-tooltip v-if="item.data.type === 'before'" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small> mdi-check </v-icon>
          </template>
          <span>{{ $t("users.recognized") }}</span>
        </v-tooltip>

        <v-tooltip v-if="item.data.type === 'pass'" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small color="info">
              mdi-check-all
            </v-icon>
          </template>
          <span>{{ $t("users.recognizedAndPass") }}</span>
        </v-tooltip>

        <v-tooltip v-if="item.data.type === 'alarm'" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small color="warning">
              mdi-account-remove-outline
            </v-icon>
          </template>
          <span>{{ $t("users.notRecognized") }}</span>
        </v-tooltip>

        <v-tooltip v-if="item.data.type === 'error'" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small color="error">
              mdi-alert-circle-outline
            </v-icon>
          </template>
          <span>{{ $t("users.error") }}</span>
        </v-tooltip>
      </template>

      <template #[`item.identity_types`]="{ item }">
        <span v-if="item.purpose">
          <v-tooltip
            v-if="item.purpose === 'entrance' || item.purpose === 'duplex'"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" small color="success"
                >mdi-location-enter</v-icon
              >
            </template>
            <span>{{ $t("device.purpose_entrance") }}</span>
          </v-tooltip>
          <v-tooltip v-if="item.purpose === 'exit'" top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                color="error"
                class="rotate-180"
                >mdi-location-exit</v-icon
              >
            </template>
            <span>{{ $t("device.purpose_exit") }}</span>
          </v-tooltip>
          <v-tooltip v-if="item.purpose === 'basiclock'" top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" small color="warning"
                >mdi-key</v-icon
              >
            </template>
            <span>{{ $t("device.purpose_basiclock") }}</span>
          </v-tooltip>
        </span>

        <template v-if="item.data.identity_types">
          <span v-for="type in item.data.identity_types.split(',')" :key="type">
            <v-tooltip v-if="type === 'face'" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  :color="item.blacklisted ? 'white' : ''"
                >
                  mdi-emoticon-happy-outline
                </v-icon>
              </template>
              <span>{{ $t("users.face") }}</span>
            </v-tooltip>

            <v-tooltip v-if="type === 'qr'" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  :color="item.blacklisted ? 'white' : ''"
                >
                  mdi-qrcode-scan
                </v-icon>
              </template>
              <span>{{ $t("users.qr") }}</span>
            </v-tooltip>

            <v-tooltip v-if="type === 'card'" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  v-clipboard:copy="copyCard(item.data.identity_value)"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                  small
                  :color="item.blacklisted ? 'white' : ''"
                >
                  mdi-card-bulleted-outline
                </v-icon>
              </template>
              <span>
                {{ $t("users.card") }}<br />
                <template
                  v-if="
                    item.data.identity_value &&
                    getIdentityValueType(item.data.identity_value) === 'card'
                  "
                >
                  HEX - {{ getIdentityValue(item.data.identity_value) }} <br />
                  DEC -
                  {{ parseInt(getIdentityValue(item.data.identity_value), 16) }}
                </template>
              </span>
            </v-tooltip>
          </span>
        </template>
      </template>

      <template #[`item.avatar`]="{ item }">
        <v-avatar v-if="item.avatarStatic" size="50" class="my-3">
          <v-img :src="item.avatarStatic" />
        </v-avatar>

        <v-avatar
          v-else-if="isPhotoBase64(item.avatar)"
          size="50"
          class="my-3"
          @mouseenter="openZoomImg(item.avatar)"
          @mouseleave="closeZoomImg"
        >
          <v-img :src="item.avatar" />
        </v-avatar>

        <v-avatar
          v-else-if="item.avatar"
          size="50"
          class="my-3"
          @mouseenter="openZoomImg(`/avatar/profile/${item.avatar}`, true)"
          @mouseleave="closeZoomImg"
        >
          <ImgProtected
            :src="`/avatar/small/${item.avatar}`"
            :alt="item.name"
          />
        </v-avatar>

        <v-avatar v-else color="primary" size="50" class="my-3">
          <v-icon dark> mdi-account-circle </v-icon>
        </v-avatar>
      </template>

      <template #[`item.full_name`]="{ item }">
        <div v-if="item.eventType === 'pressBtn'">
          {{ $t("devices.btnPress") }}
        </div>
        <div v-else>
          {{ item.data.name || $t("users.unrecognized") }}
        </div>
        <small class="blue-grey--text text--lighten-1 d-block">
          {{ item.data.zone }} / {{ item.data.device_name }}
        </small>
      </template>

      <template #[`item.message`]="{ item }">
        {{ item.data.message }}
      </template>

      <template #[`item.identity_value`]="{ item }">
        <v-chip
          v-if="item.data.identity_value"
          color="primary"
          outlined
          class="my-1"
          small
        >
          <v-icon left small>
            {{ getIdentityValueIconName(item.data.identity_value) }}
          </v-icon>
          {{ getIdentityValue(item.data.identity_value) }}
        </v-chip>
      </template>

      <template #[`item.created_at`]="{ item }">
        {{ item.created_at | moment("DD.MM.YY HH:mm:ss") }}
      </template>

      <template #footer>
        <DataTableFooter
          ref="tableFooter"
          :pagination="pagination"
          :perPage="limit"
          @changePagination="onChangePagination"
          @changePerPage="onChangePerPage"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import { isBase64 } from "validator";

import ModalAllEventsSettings from "./ModalAllEventsSettings";
import EventsFilter from "./Filter";
import ZoomImg from "@/components/ZoomImg";

import DataTableFooter from "@/components/DataTableFooter";
import ImgProtected from "@/components/ImgProtected";

import imgProtectedMixin from "@/mixins/imgProtected";
import filter from "@/mixins/filter";

export default {
  name: "EventList",

  mixins: [imgProtectedMixin, filter],

  components: {
    ModalAllEventsSettings,
    EventsFilter,
    DataTableFooter,
    ImgProtected,
    ZoomImg,
  },

  data() {
    return {
      page: parseInt(this.$route.query.page) || 1,

      headers: [
        {
          value: "pass_type",
          width: "16px",
          sortable: false,
        },
        {
          value: "identity_types",
          width: "16px",
          sortable: false,
        },
        {
          value: "avatar",
          sortable: false,
        },
        {
          text: this.$t("users.visitor"),
          value: "full_name",
        },
        {
          text: this.$t("advanced.code"),
          value: "message",
          sortable: false,
        },
        {
          text: this.$t("advanced.identityValue"),
          value: "identity_value",
          sortable: false,
        },
        {
          text: this.$t("users.visit_time"),
          value: "created_at",
        },
      ],
      options: {},
      isOpenZoomImg: false,
      zoomImgUrl: "",

      isAllEventsOpen: false,
    };
  },

  watch: {
    options: {
      handler() {
        this.getEvents();
      },
      deep: true,
    },
  },

  methods: {
    ...mapMutations({
      setLimit: "events/setLimit",
    }),

    ...mapActions({
      getEventsAction: "events/get",
      report: "events/report",
    }),

    getIdentityValueType(identityValue) {
      const separator = identityValue.indexOf(":");
      const type = identityValue.substring(0, separator);
      return type;
    },

    getIdentityValue(identityValue) {
      const separator = identityValue.indexOf(":");
      return identityValue.substring(identityValue.length, separator + 1);
    },

    onCopy: function (e) {
      this.$notify({
        group: "info",
        type: "success",
        text: this.$t("message.copied"),
      });
    },
    onError: function (e) {
      this.$notify({
        group: "info",
        type: "error",
        text: this.$t("message.copied-error"),
      });
    },
    copyCard(item) {
      return String(parseInt(this.getIdentityValue(item), 16));
    },
    async openZoomImg(img, isProtected) {
      this.isOpenZoomImg = true;
      let image = img;
      if (isProtected) {
        await this.getImage(img);
        image = this.imgProtected;
      }
      this.zoomImgUrl = image;
    },

    closeZoomImg() {
      this.isOpenZoomImg = false;
      this.zoomImgUrl = "";
    },

    onChangeFilter(filter) {
      this.updateFilter({ ...filter });
      this.page = 1;
      this.$refs.tableFooter.onChangePage(this.page);
    },

    onChangePerPage(limit) {
      this.setLimit(limit);
      this.page = 1;
      this.$refs.tableFooter.onChangePage(this.page);
    },

    onChangePagination(page) {
      this.page = page;

      this.getEvents();
    },

    async getEvents() {
      const params = { ...this.filterToParams };

      if (this.page) params.page = this.page;
      if (this.limit) params.limit = this.limit;
      if (this.options.sortBy && this.options.sortBy.length)
        params.sortName = this.options.sortBy[0];
      if (
        params.sortName &&
        this.options.sortDesc &&
        this.options.sortDesc.length
      )
        params.sortOrder = this.options.sortDesc[0] ? "desc" : "asc";

      await this.getEventsAction(params);
    },

    getEventsReport(list) {
      const params = {
        ...this.filter,
      };

      const data = {
        header: list
          .filter((e) => e.checked)
          .map((e) => {
            const result = {
              title: e.name,
              field: e.value,
              width: e.width,
            };

            if (e.type) {
              result.type = e.type;
            }

            return result;
          }),
      };

      this.report({ params, data });
    },

    isPhotoBase64(photo) {
      if (photo) {
        const index = photo.indexOf(",");
        const photoBase64 = photo.slice(index + 1);

        return isBase64(photoBase64);
      }

      return false;
    },

    getIdentityValueIconName(identityValue) {
      const separator = identityValue.indexOf(":");
      const type = identityValue.substring(0, separator);

      switch (type) {
        case "qr":
          return "mdi-qrcode-scan";
        case "card":
          return "mdi-card-bulleted-outline";
        default:
          break;
      }
    },
  },

  computed: {
    ...mapState({
      isPending: (state) => state.events.isPending,
      limit: (state) => state.events.limit,
      pagination: (state) => state.events.pagination,
      errorMessage: (state) => state.events.errorMessage,
      isPendingReport: (state) => state.events.isPendingReport,
    }),

    ...mapGetters({
      events: "events/getCorrectEvents",
    }),

    filterToParams() {
      const params = { ...this.filter };

      return params;
    },
  },

  created() {
    this.initFilter({
      identityTypes: [],
      type: [],
      search: "",
      startDate: "",
      endDate: "",
    });
  },
};
</script>
