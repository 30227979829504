<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card elevation="2" :loading="isPendingStore">
      <template slot="progress">
        <v-progress-linear color="primary" height="10" indeterminate />
      </template>

      <v-card-text>
        <v-row>
          <v-col>
            <v-switch v-model="isGuest" :label="$t('access-levels.isGuest')" />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="name"
              :label="`${$t('access-levels.name')} *`"
              :rules="nameRules"
              outlined
            />
          </v-col>

          <v-col cols="12" md="4">
            <v-select
              v-model.number="parentId"
              :items="accessLevels(id)"
              :label="$t('access-levels.parent')"
              item-value="id"
              item-text="name"
              outlined
              clearable
            />
          </v-col>

          <v-col cols="12" md="4">
            <v-select
              v-model="zonesId"
              :items="zones"
              :label="$t('access-levels.zone')"
              multiple
              item-value="id"
              item-text="name"
              outlined
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-switch
              v-model="isAccessByTime"
              :label="$t('access-levels.isAccessByTime')"
            />
          </v-col>
        </v-row>

        <AccessByTime
          v-if="isAccessByTime"
          :data="access"
          @change="onChangeAccess"
        />
      </v-card-text>

      <v-card-actions>
        <v-btn :loading="isPendingStore" @click="cancel">
          <v-icon left>mdi-cancel</v-icon>
          {{ $t("button.cancel") }}
        </v-btn>
        <v-spacer />
        <v-btn color="green" dark :loading="isPendingStore" @click="save">
          <v-icon left>mdi-content-save-outline</v-icon>
          {{ $t("button.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import AccessByTime from "./AccessByTime";

export default {
  name: "AccessLevelForm",

  components: { AccessByTime },

  props: {
    id: {
      type: Number,
      default: 0,
    },
    data: {
      type: Object,
      default: null,
    },
    isPendingStore: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const name = this.data?.name || "";
    const parentId = this.data?.parent?.id || null;
    const isGuest = this.data?.is_guest || false;
    const zonesId = this.data?.zones_id || [];
    const access = this.data?.access.length ? this.data?.access : [];
    const isAccessByTime = access.length ? true : false;

    return {
      valid: true,

      name,
      parentId,
      isGuest,
      zonesId,
      access,
      isAccessByTime,

      nameRules: [(v) => !!v || this.$t("message.NameIsRequired")],
    };
  },

  computed: {
    ...mapGetters({
      accessLevels: "accessLevels/getListExcludeById",
    }),

    ...mapState({
      zones: (state) => state.zones.list,
    }),
  },

  methods: {
    ...mapActions({
      getAccessLevels: "accessLevels/getList",
      getZones: "zones/getList",
    }),

    cancel() {
      this.$emit("cancel");
    },

    save() {
      if (!this.$refs.form.validate()) return;

      const data = {
        name: this.name,
        parent_id: this.parentId,
        is_guest: this.isGuest,
        zones_id: this.zonesId,
      };

      if (this.isAccessByTime) {
        data.access = this.access;
      } else {
        data.access = [];
      }

      this.$emit("store", data);
    },

    onChangeAccess(data) {
      this.access = data;
    },
  },

  created() {
    const params = {};

    if (this.id) {
      params.exclude_id_nested = this.id;
    }

    this.getAccessLevels(params);
    this.getZones();
  },
};
</script>
