<template>
  <v-dialog v-model="dialog" :max-width="width">
    <v-card>
      <v-card-title class="text-h5">
        {{ title }}
      </v-card-title>

      <v-card-text>
        {{ message }}
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn v-if="btnDisagree" color="red darken-1" text @click="disagree">
          {{ btnDisagree }}
        </v-btn>

        <v-btn v-if="btnAgree" color="green darken-1" text @click="agree">
          {{ btnAgree }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PromptDialog",

  data() {
    return {
      dialog: false,
      title: "",
      message: "",
      width: 300,
      btnDisagree: "",
      btnAgree: "",
      agreeCallback: null,
      disagreeCallback: null,
    };
  },

  methods: {
    open({
      title,
      message,
      width,
      btnDisagree,
      btnAgree,
      agree = () => {},
      disagree = () => {},
    }) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.width = width ? width : 300;
      this.btnDisagree = btnDisagree ? btnDisagree : this.$t("button.cancel");
      this.btnAgree = btnAgree ? btnAgree : this.$t("button.accept");
      this.agreeCallback = agree;
      this.disagreeCallback = disagree;
    },
    disagree() {
      this.dialog = false;
      this.disagreeCallback();
    },
    agree() {
      this.dialog = false;
      this.agreeCallback();
    },
  },
};
</script>
