export default {
  setList(state, { data, pagination }) {
    state.list = data;
    state.pagination = pagination;
  },

  setTree(state, payload) {
    state.tree = payload || [];
  },

  setLimit(state, limit) {
    localStorage.setItem("departmentsLimit", limit);
    state.limit = limit;
  },
};
