<template>
  <div>
    <v-row v-if="errorMessageClearDB">
      <v-col>
        <Alert type="error">
          {{ errorMessageClearDB }}
        </Alert>
      </v-col>
    </v-row>

    <v-row v-if="errorMessageServerUrl">
      <v-col>
        <Alert type="error">
          {{ errorMessageServerUrl }}
        </Alert>
      </v-col>
    </v-row>

    <v-row v-if="errorMessageStore">
      <v-col>
        <Alert type="error">
          {{ errorMessageStore }}
        </Alert>
      </v-col>
    </v-row>

    <v-row v-if="errorMessageSettingsData">
      <v-col>
        <Alert type="error">
          {{ errorMessageSettingsData }}
        </Alert>
      </v-col>
    </v-row>

    <v-row v-if="errorMessageResetTerminal">
      <v-col>
        <Alert type="error">
          {{ errorMessageResetTerminal }}
        </Alert>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h3>{{ $t("setting.basic") }}</h3>
      </v-col>
      <v-col cols="12" md="6">
        <v-row no-gutters>
          <v-col>
            <v-text-field
              v-model.lazy="serverUrl"
              :rules="serverRules"
              :label="`${$t('setting.ServerUrl')} *`"
              outlined
              required
            />
          </v-col>
          <v-col cols="auto">
            <v-btn color="success" height="55" @click.prevent="setDefaultUrl">
              {{ $t("setting.url_auto") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-select
          name="lang"
          v-if="globSettings.show"
          :items="langlist"
          :label="$t('setting.lang')"
          v-model.lazy="lang"
          item-text="name"
          item-value="value"
          outlined
          @change="setLocal"
        />

        <v-switch v-model="antipassback" :label="$t('setting.anti-passback')" />
        <v-switch v-model="syncDevices" :label="$t('setting.syncDevices')" />
        <v-switch
          v-if="globSettings.ssl_enable"
          v-model="ssl"
          :label="$t('setting.ssl')"
        />
        <v-switch v-model="autostart" :label="$t('setting.autostart')" />
      </v-col>

      <v-col cols="12" md="6">
        <h3>{{ $t("setting.TemperatureMeasure") }}</h3>
        <v-range-slider
          v-model="temperatureRange"
          :label="labelRange"
          :min="calculateCalcFeel(20)"
          :max="calculateCalcFeel(39)"
          step="0.1"
          thumb-label="always"
        />
        <v-switch
          v-model="temperatureBlocked"
          :label="$t('setting.temperatureBlocked')"
        />
        <v-switch
          v-model="temperatureRise"
          :label="$t('setting.temperatureRise')"
        />

        <v-select
          v-model="temperatureMeasure"
          :items="measure"
          :label="$t('setting.temperature_units')"
          item-text="name"
          item-value="value"
          outlined
        />

        <h3>{{ $t("setting.maskoption") }}</h3>
        <v-switch
          v-model="maskRecognition"
          :label="$t('setting.maskRecognition')"
        />

        <h3>{{ $t("setting.alarm") }}</h3>
        <v-switch v-model="lightAlarm" :label="$t('setting.lightAlarm')" />
        <v-switch v-model="beepAlarm" :label="$t('setting.beepAlarm')" />
      </v-col>
    </v-row>

    <v-divider class="my-5" />

    <v-row>
      <v-col>
        <h3>{{ $t("setting.limits") }}</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          v-model="countVisits"
          type="number"
          min="30"
          max="3600"
          :label="`${$t('setting.countVisits')} *`"
          outlined
          required
          :append-icon="showSaveBtnCountVisits ? 'mdi-content-save' : ''"
          @click:append="onSaveCountVisits"
          @input="onChangeCountVisits"
        />
        <v-alert v-show="showWarningCountVisits" type="warning">
          {{ $t("setting.warningCountVisits", { lastCount: lastCountVisits }) }}
        </v-alert>
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <v-text-field
          v-model="countAlarms"
          type="number"
          min="0"
          max="10000"
          :label="`${$t('setting.countAlarms')} *`"
          outlined
          required
          :append-icon="showSaveBtnCountAlarms ? 'mdi-content-save' : ''"
          @click:append="onSaveCountAlarms"
          @input="onChangeCountAlarms"
        />
        <v-alert v-show="showWarningCountAlarms" type="warning">
          {{ $t("setting.warningCountAlarms", { lastCount: lastCountAlarms }) }}
        </v-alert>
      </v-col>
    </v-row>

    <v-divider class="my-5" />

    <v-row>
      <v-col>
        <h3>{{ $t("setting.system") }}</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          v-if="$auth.user().is_admin"
          color="error"
          class="mr-2"
          @click.prevent="onClearDB"
        >
          {{ $t("setting.flush_db") }}
        </v-btn>
        <v-btn color="error" class="ma-2" @click.prevent="reloadTerminal">
          {{ $t("setting.reload_terminal") }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

import switchLang from "@/mixins/switchLang";
import Alert from "@/components/Alert";

export default {
  mixins: [switchLang],

  name: "General-settings",

  components: {
    Alert,
  },

  data() {
    return {
      serverRules: [(v) => !!v || "Address is required"],
      antipassback: "",
      antispoofing: "",
      temperatureRange: [34, 37.4],
      temperatureBlocked: false,
      ssl: false,
      lock: true,
      lockTime: false,
      autostart: false,
      maskRecognition: false,
      lightAlarm: false,
      beepAlarm: false,
      maskEnabled: false,
      temperatureRise: false,
      syncDevices: true,
      countAlarms: "10000",
      lastCountAlarms: "10000",
      showSaveBtnCountAlarms: false,
      showWarningCountAlarms: false,
      countVisits: "3600",
      lastCountVisits: "3600",
      showSaveBtnCountVisits: false,
      showWarningCountVisits: false,
      trange: [],
      lang: "en",
      langlist: [
        { name: "English", value: "en" },
        { name: "Русский", value: "ru" },
      ],
      temperatureMeasure: "c",
      serverUrl: this.setServerUrl,
      settings: [],
    };
  },

  methods: {
    ...mapMutations({
      setUrl: "settings/setUrl",
    }),
    ...mapActions({
      clearDB: "system/clearDB",
      getUrl: "settings/getUrl",
      setStore: "settings/setStore",
      resetTerminal: "settings/resetTerminal",
      getSettings: "settings/getSettings",
    }),

    load() {
      this.lock = true;
      this.serverUrl = this.settings["SERVER_URL"];
      this.antipassback = this.settings["ANTIPASSBACK"] == "on";
      this.antispoofing = this.settings["ANTISPOOFING"] == "on";
      this.temperatureRise = this.settings["TEMPERATURE_RISE"] == "on";
      this.autostart = this.settings["AUTOSTART"] == "on";
      this.ssl = this.settings["SSL"] == "on";
      this.temperatureBlocked = this.settings["TEMPERATURE_BLOCKED"] == "on";
      this.temperatureMeasure = this.settings["TEMPERATURE_MEASURE"] || "c";
      this.maskRecognition = this.settings["MASK_RECOGNITION"] == "on";
      this.lightAlarm = this.settings["LIGHT_PERSON_ALARM"] == "on";
      this.beepAlarm = this.settings["BEEP_PERSON_ALARM"] == "on";
      this.maskEnabled = this.settings["MASK_ENABLED"] == "on";
      this.lang = this.settings["LANGUAGE"] || "en";
      this.countAlarms = this.settings["ALARM_COUNT"] || 10000;
      this.countVisits = this.settings["VISIT_COUNT"] || 3600;
      this.syncDevices = this.settings["SYNC_DEVICES"] != "off";
      let tempRange = this.settings["TEMPERATURE_RANGE"];
      this.lastCountVisits = this.settings["VISIT_COUNT"] || 3600;
      this.lastCountAlarms = this.settings["ALARM_COUNT"] || 10000;
      let temp = [34, 37.4];
      if (tempRange) {
        temp = tempRange.split(",");
      }
      this.setTemperatureRange(temp);
    },

    setTemperatureRange(range) {
      this.temperatureRange = [
        this.calculateCalcFeel(range[0]),
        this.calculateCalcFeel(range[1]),
      ];
    },

    calculateCalcFeel(t) {
      if (this.temperatureMeasure === undefined) {
        return;
      }
      if (this.temperatureMeasure == "f") {
        return Math.round(((t * 9) / 5 + 32) * 10) / 10;
      } else {
        return parseFloat(t);
      }
    },
    calculateFeelCalc(t) {
      if (this.temperatureMeasure === undefined) {
        return;
      }
      if (this.temperatureMeasure == "f") {
        return Math.round((((t - 32) * 5) / 9) * 10) / 10;
      } else {
        return t;
      }
    },
    setDefaultUrl() {
      this.getUrl().then(() => {
        this.serverUrl = this.setServerUrl;
      });
    },
    reloadTerminal() {
      this.$root.$prompt({
        message: this.$t("message.reloadTerminal"),
        btnAgree: this.$t("button.apply"),

        agree: () => {
          this.$notify({
            group: "info",
            type: "info",
            text: this.$t("message.terminalIsReloading"),
          });
          this.resetTerminal();

          const checkWsConnection = setInterval(() => {
            if (window.$ws.readyState === 1) {
              clearInterval(checkWsConnection);
              location.reload();
            }
          }, 1000);
        },
      });
    },

    onClearDB() {
      this.$root.$prompt({
        message: this.$t("message.clearDB"),
        btnAgree: this.$t("button.apply"),

        agree: () => {
          this.clearDB();
        },
      });
    },

    setLocal(lang) {
      if (!this.lock) {
        this.setStore([{ key: "LANGUAGE", value: lang }]).then(() => {
          this.switchLang(lang);
        });
      }
    },

    onChangeCountVisits() {
      this.showSaveBtnCountVisits = true;
      if (
        !this.countVisits ||
        parseInt(this.countVisits) < parseInt(this.lastCountVisits)
      ) {
        this.showWarningCountVisits = true;
      } else {
        this.showWarningCountVisits = false;
      }
    },

    onSaveCountVisits() {
      this.showSaveBtnCountVisits = false;
      this.showWarningCountVisits = false;
      this.lastCountVisits = this.countVisits;
      this.setStore([{ key: "VISIT_COUNT", value: this.countVisits }]);
    },

    onChangeCountAlarms() {
      this.showSaveBtnCountAlarms = true;

      if (
        !this.countAlarms ||
        parseInt(this.countAlarms) < parseInt(this.lastCountAlarms)
      ) {
        this.showWarningCountAlarms = true;
      } else {
        this.showWarningCountAlarms = false;
      }
    },

    onSaveCountAlarms() {
      this.showSaveBtnCountAlarms = false;
      this.showWarningCountAlarms = false;
      this.lastCountAlarms = this.countAlarms;
      this.setStore([{ key: "ALARM_COUNT", value: this.countAlarms }]);
    },
  },

  watch: {
    settings() {
      this.load();
    },
    serverUrl(val) {
      // Запись в базу с задержкой 2 сек.
      let _this = this;
      if (!this.lock && !this.lockTime) {
        this.lockTime = true;
        setTimeout(function () {
          _this.lockTime = false;
          _this.setStore([
            {
              Key: "SERVER_URL",
              Value: _this.serverUrl,
            },
          ]);
          _this.setUrl(_this.serverUrl);
        }, 2000);
      }
    },
    antipassback(val) {
      if (!this.lock) {
        this.setStore([{ key: "ANTIPASSBACK", value: val ? "on" : "off" }]);
      }
    },
    antispoofing(val) {
      if (!this.lock) {
        this.setStore([{ key: "ANTISPOOFING", value: val ? "on" : "off" }]);
      }
    },
    autostart(val) {
      if (!this.lock) {
        this.setStore([{ key: "AUTOSTART", value: val ? "on" : "off" }]);
      }
    },
    temperatureRange(val) {
      let _this = this;
      if (val[0] > val[1]) val[0] = val[1] - 0.1;
      this.trange[0] = _this.calculateFeelCalc(val[0]);
      if (this.trange[0] == 20) this.trange[0] = 0;
      this.trange[1] = _this.calculateFeelCalc(val[1]);
      if (!this.lock && !this.lockTime) {
        this.lockTime = true;
        setTimeout(function () {
          _this.lockTime = false;
          _this.setStore([
            {
              key: "TEMPERATURE_RANGE",
              value: _this.trange.join(",").toString(),
            },
          ]);
        }, 2000);
      }
    },
    temperatureRise(val) {
      if (!this.lock) {
        this.setStore([{ key: "TEMPERATURE_RISE", value: val ? "on" : "off" }]);
      }
    },
    temperatureBlocked(val) {
      if (!this.lock) {
        this.setStore([
          { key: "TEMPERATURE_BLOCKED", value: val ? "on" : "off" },
        ]);
      }
    },
    temperatureMeasure(val) {
      if (!this.lock) {
        this.setStore([{ key: "TEMPERATURE_MEASURE", value: val }]);
        document.location.reload();
      }
    },
    maskRecognition(val) {
      if (!this.lock) {
        this.setStore([{ key: "MASK_RECOGNITION", value: val ? "on" : "off" }]);
      }
    },
    lightAlarm(val) {
      if (!this.lock) {
        this.setStore([
          { key: "LIGHT_PERSON_ALARM", value: val ? "on" : "off" },
        ]);
      }
    },
    beepAlarm(val) {
      if (!this.lock) {
        this.setStore([
          { key: "BEEP_PERSON_ALARM", value: val ? "on" : "off" },
        ]);
      }
    },
    maskEnabled(val) {
      if (!this.lock) {
        this.setStore([{ key: "MASK_ENABLED", value: val ? "on" : "off" }]);
      }
    },
    syncDevices(val) {
      if (!this.lock) {
        this.setStore([{ key: "SYNC_DEVICES", value: val ? "on" : "off" }]);
      }
    },
    ssl(val) {
      if (this.ssl && this.globSettings.ssl_enable) {
        this.serverUrl = this.serverUrl.replace("http://", "https://");
      } else {
        this.serverUrl = this.serverUrl.replace("https://", "http://");
      }
      if (!this.lock) {
        this.setStore([{ key: "SSL", value: val ? "on" : "off" }]);
      }
    },
  },

  computed: {
    ...mapState({
      setSettings: (state) => state.settings.settingsData,
      setServerUrl: (state) => state.settings.serverUrl,
      globSettings: (state) => state.settings.data,
      errorMessageClearDB: (state) => state.system.errorMessageClearDB,
      errorMessageServerUrl: (state) => state.settings.errorMessageServerUrl,
      errorMessageStore: (state) => state.settings.errorMessageStore,
      errorMessageSettingsData: (state) =>
        state.settings.errorMessageSettingsData,
      errorMessageResetTerminal: (state) =>
        state.settings.errorMessageResetTerminal,
    }),

    labelRange() {
      return (
        this.$t("setting.temperatureRange") +
        " " +
        (this.temperatureMeasure == "f" ? "(°F)" : "(°C)")
      );
    },

    measure() {
      return [
        { name: this.$t("setting.celsia"), value: "c" },
        { name: this.$t("setting.fahrenheit"), value: "f" },
      ];
    },
  },
  mounted() {
    this.getSettings().then(() => {
      this.settings = this.setSettings;
      this.load();
    });
  },

  updated() {
    this.lock = false;
  },
};
</script>
