<template>
  <CheckboxList
    v-if="data.type === 'list' && data.multiple"
    v-bind="$attrs"
    :value="value || data.default || []"
    :items="values || []"
    :label="data.name"
    :minify="minify"
    :loading="isPending"
    :errorMessage="errorMessage"
    :itemName="data.values.itemName"
    :itemValue="data.values.itemValue"
    @input="$emit('input', { [data.param]: $event })"
  />

  <MultiTree
    v-else-if="data.type === 'tree' && data.multiple"
    v-bind="$attrs"
    :value="value || data.default || []"
    :items="values || []"
    :label="data.name"
    :minify="minify"
    :searchable="data.searchable"
    selection-type="independent"
    :loading="isPending"
    :errorMessage="errorMessage"
    :itemName="data.values.itemName"
    :itemValue="data.values.itemValue"
    :itemChildren="data.values.itemChildren"
    open-all
    @input="$emit('input', { [data.param]: $event })"
  />
</template>

<script>
import CheckboxList from "./CheckboxList";
import MultiTree from "./MultiTree";

export default {
  name: "FilterAsyncItem",

  components: {
    CheckboxList,
    MultiTree,
  },

  props: {
    value: {
      type: [String, Number, Array],
      default: null,
    },

    data: {
      type: Object,
      require: true,
    },

    minify: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      values: null,
      isPending: false,
      errorMessage: "",
    };
  },

  methods: {
    getValues() {
      this.isPending = true;
      const { source } = this.data.values;

      if (source === "static") {
        const { data } = this.data.values;

        this.values = data;
        this.isPending = false;
      } else {
        this.axios(this.data.values.source)
          .then((response) => {
            this.values = response.data.data;
            this.isPending = false;
          })
          .catch((error) => {
            this.errorMessage =
              error?.response?.data?.status?.message ||
              this.$t("message.errorLoading");
            this.isPending = false;
          });
      }
    },
  },

  created() {
    this.getValues();
  },
};
</script>
