import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  maxLength,
  sameAs,
  helpers,
} from "vuelidate/lib/validators";

const validUserName = helpers.regex("validUserName", /^[a-zA-Z0-9\\-]+$/);
const validPassword = helpers.regex("validPassword", /^[^а-яА-Я]*$/);

export default {
  mixins: [validationMixin],

  validations() {
    const data = {};

    if (!this.id || this.isChangeAuth) {
      data.username = {
        required,
        validUserName,
        minLength: minLength(6),
        maxLength: maxLength(100),
      };

      data.password = {
        required,
        validPassword,
        minLength: minLength(6),
        maxLength: maxLength(100),
      };

      data.passwordConfirm = {
        required,
        sameAsPassword: sameAs("password"),
      };
    }

    return data;
  },

  computed: {
    usernameErrors() {
      const errors = [];
      if (!this.$v.username.$dirty) return errors;

      !this.$v.username.validUserName &&
        errors.push(this.$t("auth.username_char_valid"));

      (!this.$v.username.minLength || !this.$v.username.maxLength) &&
        errors.push(this.$t("auth.lenghtn_username_8"));

      !this.$v.username.required &&
        errors.push(this.$t("message.NameIsRequired"));

      return errors;
    },

    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;

      !this.$v.password.validPassword &&
        errors.push(this.$t("auth.password_char_valid"));

      (!this.$v.password.minLength || !this.$v.password.maxLength) &&
        errors.push(this.$t("auth.lenghtn_password"));

      !this.$v.password.required &&
        errors.push(this.$t("message.NameIsRequired"));

      return errors;
    },

    passwordConfirmErrors() {
      const errors = [];
      if (!this.$v.passwordConfirm.$dirty) return errors;

      !this.$v.passwordConfirm.sameAsPassword &&
        errors.push(this.$t("auth.passwords_must_match"));

      !this.$v.passwordConfirm.required &&
        errors.push(this.$t("message.NameIsRequired"));

      return errors;
    },
  },

  methods: {
    validateForm() {
      let isInvalid = false;

      this.$v.$touch();
      isInvalid = this.$v.$invalid;

      for (let i = 0; i < this.$refs.roles.roles.length; i++) {
        const element =
          this.$refs.roles.$refs[`role-${this.$refs.roles.roles[i].loopId}`][0]
            .$v;
        element.$touch();
        if (element.$invalid) {
          isInvalid = true;
        }
      }

      return !isInvalid;
    },
  },
};
