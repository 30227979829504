<template>
  <v-img :src="imgProtected" v-bind="$attrs" />
</template>

<script>
import imgProtectedMixin from "@/mixins/imgProtected";

export default {
  name: "ImageProtected",

  mixins: [imgProtectedMixin],

  props: {
    src: {
      type: String,
      required: true,
    },
  },

  watch: {
    src: {
      handler(src) {
        this.getImage(src);
      },
      immediate: true,
    },
  },
};
</script>
