<template>
  <div>
    <v-row align="center" class="mb-5">
      <v-col cols="auto">
        <h2>
          {{ $t("operators.changeAuth") }}
        </h2>
      </v-col>
      <v-spacer />
      <v-col cols="auto">
        <v-btn @click="onGeneratePassword">{{
          $t("operators.generatePassword")
        }}</v-btn>
      </v-col>
    </v-row>

    <v-text-field
      :type="isShowPass ? 'text' : 'password'"
      v-model="pass"
      :rules="passwordRules"
      :label="`${$t('auth.newPassword')} *`"
      autocomplete="off"
      :append-icon="isShowPass ? 'mdi-eye' : 'mdi-eye-off'"
      @click:append="isShowPass = !isShowPass"
      outlined
    />

    <v-text-field
      ref="passwordConfirmation"
      :type="isShowConfirmPass ? 'text' : 'password'"
      v-model="confirmPass"
      :rules="passwordConfirmRules"
      :label="`${$t('auth.passwordConfirm')} *`"
      autocomplete="off"
      :append-icon="isShowConfirmPass ? 'mdi-eye' : 'mdi-eye-off'"
      @click:append="isShowConfirmPass = !isShowConfirmPass"
      outlined
    />
  </div>
</template>

<script>
import passwordGenerator from "generate-password-browser";

export default {
  name: "PasswordForm",

  data() {
    return {
      pass: "",
      confirmPass: "",
      isShowPass: false,
      isShowConfirmPass: false,

      passwordRules: [
        (v) => this.validPassword(v) || this.$t("auth.password_char_valid"),
        (v) => v.length >= 6 || this.$t("auth.lenghtn_password"),
      ],
      passwordConfirmRules: [
        (v) => !!v || this.$t("message.NameIsRequired"),
        (v) => v == this.pass || this.$t("auth.passwords_must_match"),
      ],
    };
  },

  watch: {
    pass() {
      this.$emit("input", this.pass);
    },
  },

  methods: {
    getPassword() {
      return passwordGenerator.generate({
        length: 8,
        numbers: true,
        excludeSimilarCharacters: true,
        strict: true,
      });
    },

    onGeneratePassword() {
      this.$notify({
        group: "info",
        type: "success",
        text: this.$t("operators.passwordGeneratedMessage"),
      });

      const generatedPassword = this.getPassword();
      this.pass = generatedPassword;
      this.confirmPass = generatedPassword;
    },

    validPassword(v) {
      var re = /^[^а-яА-Я]*$/;
      return re.test(v);
    },
  },
};
</script>
