<template>
  <div>
    <v-row v-if="errorMessage">
      <v-col>
        <Alert type="error">
          {{ errorMessage }}
        </Alert>
      </v-col>
    </v-row>

    <v-row v-if="errorMessageDelete">
      <v-col>
        <Alert type="error">
          {{ errorMessageDelete }}
        </Alert>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="auto">
        <ListActions
          :filter="filter"
          :selected="selected"
          @block="onBlock({ ids: selectedIds })"
          @blockByFilter="onBlock({ filter: filterToParams })"
          @unblock="onUnblock({ ids: selectedIds })"
          @unblockByFilter="onUnblock({ filter: filterToParams })"
          @black="onBlack({ ids: selectedIds })"
          @blackByFilter="onBlack({ filter: filterToParams })"
          @unblack="onUnblack({ ids: selectedIds })"
          @unblackByFilter="onUnblack({ filter: filterToParams })"
          @remove="onRemove({ ids: selectedIds })"
          @removeByFilter="onRemove({ filter: filterToParams })"
          @restore="onRestore({ ids: selectedIds })"
          @restoreByFilter="onRestore({ filter: filterToParams })"
          @openDepartmentDialog="isOpenDepartmentDialog = true"
          @openAccessLevelsDialog="isOpenAccessLevelsDialog = true"
          @report="report({ ...filterToParams })"
        />
      </v-col>
      <v-spacer />
      <v-col v-if="isAddPerson" cols="12" md="auto">
        <v-btn color="primary" @click="goToAdd">
          <v-icon left> mdi-plus </v-icon>
          {{ $t("users.addUser") }}
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      v-model="selected"
      :loading="isPending"
      :headers="headers"
      :items="persons"
      :server-items-length="pagination.per_page"
      :options.sync="options"
      show-select
      hide-default-footer
    >
      <template v-slot:top>
        <PersonsFilter
          v-model="filter"
          @input="onChangeFilter"
          class="mb-2 mt-5"
        />
      </template>

      <template #[`item.avatar`]="{ item }">
        <div class="my-3">
          <v-badge
            :value="item.operator_id"
            bordered
            color="primary"
            icon="mdi-star"
            overlap
            avatar
            bottom
          >
            <v-avatar v-if="item.photo" color="primary" size="50">
              <ImgProtected
                :src="`avatar/small/${item.photo}`"
                :alt="item.full_name"
              />
            </v-avatar>

            <v-avatar v-else color="primary" size="50">
              <v-icon dark> mdi-account-circle </v-icon>
            </v-avatar>
          </v-badge>
        </div>
      </template>

      <template #[`item.full_name`]="{ item }">
        <div>
          <v-chip
            v-if="item.blocked"
            x-small
            color="red"
            text-color="white"
            class="mr-1"
          >
            <v-icon left x-small>mdi-cancel</v-icon>
            {{ $t("users.blocked") }}
          </v-chip>
          <v-chip
            v-if="item.blacklisted"
            x-small
            color="primary"
            text-color="white"
            class="mr-1"
          >
            <v-icon left x-small>mdi-eye</v-icon>
            {{ $t("users.blacklist") }}
          </v-chip>
        </div>
        <router-link
          :to="{ name: $const.ROUTES.SHOW_PERSON, params: { id: item.id } }"
          >{{ item.full_name }}</router-link
        ><br />
        {{ item.phone }}
      </template>

      <template #[`item.updated_at`]="{ item }">
        {{ item.updated_at | moment("DD.MM.YYYY HH:mm") }}
      </template>

      <template #[`item.actions`]="{ item }">
        <v-tooltip v-if="personErrors[item.id]" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              small
              icon
              color="red"
              @click="openPersonErrorLogs(item.id)"
            >
              <v-icon small>mdi-alert-circle-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("users.errorLogs") }}</span>
        </v-tooltip>

        <v-tooltip v-if="isViewPerson" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              small
              icon
              color="green"
              @click="goToShow(item.id)"
            >
              <v-icon small>mdi-card-account-details-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("user.show") }}</span>
        </v-tooltip>

        <v-tooltip v-if="isEditPerson" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              small
              icon
              color="primary"
              @click="goToEdit(item.id)"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("user.edit") }}</span>
        </v-tooltip>

        <v-tooltip
          v-if="getIsDeletePersonByData(item) && filter.mode !== 'deleted'"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              small
              icon
              color="red"
              @click="
                onRemove({
                  ids: [item.id],
                  isBindOperator: item.operator_id ? true : false,
                })
              "
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("button.delete") }}</span>
        </v-tooltip>

        <v-tooltip v-if="isRestorePerson && filter.mode === 'deleted'" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              small
              icon
              color="blue"
              @click="onRestore({ ids: [item.id] })"
            >
              <v-icon small>mdi-restore</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("button.restore") }}</span>
        </v-tooltip>
      </template>

      <template #footer>
        <DataTableFooter
          ref="tableFooter"
          :pagination="pagination"
          :totalText="$t('users.total')"
          :perPage="limit"
          @changePagination="onChangePagination"
          @changePerPage="onChangePerPage"
        />
      </template>
    </v-data-table>

    <SetDepartmentDialog
      :isOpen="isOpenDepartmentDialog"
      :selectedIds="selectedIds"
      :filter="filterToParams"
      @save="onMultiSetDepartment"
      @close="isOpenDepartmentDialog = false"
    />

    <SetAccessLevelDialog
      :isOpen="isOpenAccessLevelsDialog"
      :selectedIds="selectedIds"
      :filter="filterToParams"
      @save="onMultiSetAccessLevel"
      @close="isOpenAccessLevelsDialog = false"
    />

    <ErrorLogsDialog
      :isOpen="isOpenPersonErrorLogs"
      :data="personErrors[personErrorsId]"
      @close="isOpenPersonErrorLogs = false"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

import PersonsFilter from "./Filter";
import ErrorLogsDialog from "./ErrorLogsDialog";
import SetDepartmentDialog from "./SetDepartmentDialog";
import SetAccessLevelDialog from "./SetAccessLevelDialog";
import ListActions from "./ListActions";

import Alert from "@/components/Alert";
import ImgProtected from "@/components/ImgProtected";
import DataTableFooter from "@/components/DataTableFooter";

import permissions from "@/mixins/permissions";
import filter from "@/mixins/filter";

export default {
  name: "PersonsList",

  mixins: [permissions, filter],

  components: {
    PersonsFilter,
    Alert,
    ImgProtected,
    DataTableFooter,
    ErrorLogsDialog,
    SetDepartmentDialog,
    SetAccessLevelDialog,
    ListActions,
  },

  data() {
    return {
      selected: [],

      search: "",
      page: parseInt(this.$route.query.page) || 1,

      headers: [
        {
          value: "avatar",
          sortable: false,
        },
        {
          text: this.$t("users.fullname"),
          value: "full_name",
        },
        {
          text: this.$t("users.department"),
          value: "department",
          sortable: false,
        },
        {
          text: this.$t("users.updatedAt"),
          value: "updated_at",
        },
        {
          text: "ID",
          value: "id",
        },
        {
          value: "actions",
          sortable: false,
          align: "end",
          width: 162,
        },
      ],
      options: {},

      personErrors: {},
      personErrorsId: null,
      isOpenPersonErrorLogs: false,

      isOpenDepartmentDialog: false,
      isOpenAccessLevelsDialog: false,
    };
  },

  watch: {
    options: {
      handler() {
        this.getPersons();
      },
      deep: true,
    },
  },

  methods: {
    ...mapMutations({
      setLimit: "persons/setLimit",
    }),

    ...mapActions({
      getPersonsAction: "persons/get",
      report: "persons/report",
      remove: "persons/remove",
      block: "persons/block",
      unblock: "persons/unblock",
      black: "persons/black",
      unblack: "persons/unblack",
      restore: "persons/restore",
    }),

    connectWebSockets() {
      this.$root.$on(this.$const.WS_CHANNELS.PERSON_ERRORS, (item) => {
        this.setPersonErrors(item);
      });
    },

    disconnectedWebSockets() {
      this.$root.$off(this.$const.WS_CHANNELS.PERSON_ERRORS);
    },

    setPersonErrors(item) {
      this.personErrors = {};

      item.forEach((error) => {
        const data = {
          type: "error",
          device: error.DeviceType,
          message: error.Error,
        };

        if (this.personErrors[error.ID]) {
          this.$set(this.personErrors, error.ID, [
            ...this.personErrors[error.ID],
            data,
          ]);
        } else {
          this.$set(this.personErrors, error.ID, [data]);
        }
      });
    },

    setWarningsPermits() {
      if (this.persons.length) {
        this.persons.forEach((person) => {
          if (!person.permits) {
            this.$set(this.personErrors, person.id, [
              {
                type: "warning",
                device: "",
                message: this.$t("message.permitsIsEmpty"),
              },
            ]);
          }
        });
      }
    },

    onChangeFilter(filter) {
      this.updateFilter({ ...filter });
      this.page = 1;
      this.$refs.tableFooter.onChangePage(this.page);
    },

    onChangePerPage(limit) {
      this.setLimit(limit);
      this.page = 1;
      this.$refs.tableFooter.onChangePage(this.page);
    },

    onChangePagination(page) {
      this.page = page;

      this.getPersons();
    },

    async getPersons() {
      const params = { ...this.filterToParams };

      if (this.page) params.page = this.page;
      if (this.limit) params.limit = this.limit;
      if (this.options.sortBy && this.options.sortBy.length)
        params.sortName = this.options.sortBy[0];
      if (
        params.sortName &&
        this.options.sortDesc &&
        this.options.sortDesc.length
      )
        params.sortOrder = this.options.sortDesc[0] ? "desc" : "asc";

      await this.getPersonsAction(params);

      this.setWarningsPermits();
    },

    goToAdd() {
      this.$router.push({
        name: this.$const.ROUTES.ADD_PERSON,
        params: {
          query: this.$route.query,
        },
      });
    },

    goToEdit(id) {
      this.$router.push({
        name: this.$const.ROUTES.EDIT_PERSON,
        params: {
          id,
          query: this.$route.query,
        },
      });
    },

    goToShow(id) {
      this.$router.push({
        name: this.$const.ROUTES.SHOW_PERSON,
        params: { id },
      });
    },

    onRemove(data) {
      this.$root.$prompt({
        title: this.$t("message.Delete"),
        message: this.$t("message.confirmDelete"),
        btnAgree: this.$t("button.delete"),
        agree: () => this.delete(data),
      });
    },

    async delete({ ids, filter, isBindOperator = false }) {
      const deletePerson = async (isDeleteOperator = false) => {
        if (ids && ids.length) {
          const params = { ids };

          if (isDeleteOperator) {
            params.is_delete_operator = true;
          }
          await this.remove(params);
        } else {
          const params = { ...filter };

          if (isDeleteOperator) {
            params.is_delete_operator = true;
          }
          await this.remove(params);
        }

        this.getPersons();
        this.selected = [];
      };

      if (this.isDeleteOperator && isBindOperator) {
        this.$root.$prompt({
          title: this.$t("user.deleteBindingOperator"),
          message: this.$t("user.deleteBindingOperatorMessage"),
          width: 500,
          btnAgree: this.$t("common.yes"),
          btnDisagree: this.$t("common.no"),

          agree: async () => {
            deletePerson(true);
          },

          disagree: async () => {
            deletePerson(false);
          },
        });
      } else {
        deletePerson();
      }
    },

    async onBlock({ ids, filter }) {
      if (ids && ids.length) {
        await this.block({ ids });
      } else {
        await this.block({ ...filter });
      }

      this.getPersons();
      this.selected = [];
    },

    async onBlack({ ids, filter }) {
      if (ids && ids.length) {
        await this.black({ ids });
      } else {
        await this.black({ ...filter });
      }

      this.getPersons();
      this.selected = [];
    },

    async onUnblock({ ids, filter }) {
      if (ids && ids.length) {
        await this.unblock({ ids });
      } else {
        await this.unblock({ ...filter });
      }

      this.getPersons();
      this.selected = [];
    },

    async onUnblack({ ids, filter }) {
      if (ids && ids.length) {
        await this.unblack({ ids });
      } else {
        await this.unblack({ ...filter });
      }

      this.getPersons();
      this.selected = [];
    },

    async onRestore({ ids, filter }) {
      if (ids && ids.length) {
        await this.restore({ ids });
      } else {
        await this.restore({ ...filter });
      }

      this.getPersons();
      this.selected = [];
    },

    getIsDeletePersonByData(data) {
      if (data.operators_id === this.$auth.user().id) {
        return false;
      }

      if (!this.isDeletePerson) {
        return false;
      }

      return true;
    },

    onMultiSetDepartment() {
      this.getPersons();
      this.selected = [];
      this.isOpenDepartmentDialog = false;
    },

    onMultiSetAccessLevel() {
      this.getPersons();
      this.selected = [];
      this.isOpenAccessLevelsDialog = false;
    },

    openPersonErrorLogs(id) {
      this.isOpenPersonErrorLogs = true;
      this.personErrorsId = id;
    },
  },

  computed: {
    ...mapState({
      isPending: (state) => state.persons.isPending,
      persons: (state) => state.persons.data,
      limit: (state) => state.persons.limit,
      pagination: (state) => state.persons.pagination,
      errorMessage: (state) => state.persons.errorMessage,
      errorMessageDelete: (state) => state.persons.errorMessageDelete,
      settings: (state) => state.settings.data,
    }),

    filterToParams() {
      const params = {};

      if (this.filter.departments) params.departments = this.filter.departments;
      if (this.filter.accessLevels)
        params.accesslevels = this.filter.accessLevels;
      if (this.filter.mode) params.mode = this.filter.mode;
      if (this.filter.permit) params.permittype = this.filter.permit;
      if (this.filter.search) params.search = this.filter.search;

      return params;
    },

    isViewPerson() {
      return (
        !this.settings.cloud_connect && this.can(this.$const.RULES.PERSON_VIEW)
      );
    },

    isAddPerson() {
      return (
        !this.settings.cloud_connect && this.can(this.$const.RULES.PERSON_ADD)
      );
    },

    isDeleteOperator() {
      return (
        !this.settings.cloud_connect &&
        this.can(this.$const.RULES.OPERATOR_DELETE)
      );
    },

    isEditPerson() {
      return (
        !this.settings.cloud_connect && this.can(this.$const.RULES.PERSON_EDIT)
      );
    },

    isDeletePerson() {
      return (
        !this.settings.cloud_connect &&
        this.can(this.$const.RULES.PERSON_DELETE)
      );
    },

    isRestorePerson() {
      return (
        !this.settings.cloud_connect &&
        this.can(this.$const.RULES.PERSON_RESTORE)
      );
    },

    selectedIds() {
      return this.selected.map((item) => item.id);
    },
  },

  created() {
    this.connectWebSockets();
    this.initFilter({
      departments: [],
      accessLevels: [],
      search: "",
      mode: "",
      permit: "",
    });
  },

  destroyed() {
    this.disconnectedWebSockets();
  },
};
</script>
