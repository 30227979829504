import "@mdi/font/css/materialdesignicons.css";

import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import ru from "vuetify/src/locale/ru.ts";
import en from "vuetify/src/locale/en.ts";

Vue.use(Vuetify);

const theme = {
  primary: "#7165FF",
  secondary: "#473AD0",
  accent: "#7165FF",
  info: "#22A8D7",
  success: "#27AE60",
  warning: "#f6993f",
  orange: "#f6993f",
  error: "#F06B86",
  blue: "#22A8D7",
  green: "#27AE60",
  red: "#F06B86",
};

const opts = {
  icons: {
    iconfont: "mdi",
  },
  lang: {
    locales: { ru, en },
    current: "ru",
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
};

export default new Vuetify(opts);
