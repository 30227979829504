export default {
  set(state, payload) {
    state.data = payload;
  },

  setVisits(state, payload) {
    state.visits = payload;
  },

  setStoreErrorData(state, payload) {
    state.errorDataStore = payload;
  },

  setLoadingVisits(state, { type, payload = "" }) {
    state.isPendingVisits = type === "IS_PENDING";
    state.isSuccessVisits = type === "IS_SUCCESS";
    state.isFailureVisits = type === "IS_FAILURE";
    state.errorMessageVisits = payload;
  },

  setLoadingRefeature(state, { type, payload = "" }) {
    state.isPendingRefeature = type === "IS_PENDING";
    state.isSuccessRefeature = type === "IS_SUCCESS";
    state.isFailureRefeature = type === "IS_FAILURE";
    state.errorMessageRefeature = payload;
  },
};
