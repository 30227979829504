export default {
  set(state, { data, pagination }) {
    state.data = data;
    state.pagination = pagination;
  },

  setLimit(state, limit) {
    localStorage.setItem("passesLimit", limit);
    state.limit = limit;
  },

  setLoadingReport(state, { type, payload = "" }) {
    state.isPendingReport = type === "IS_PENDING";
    state.isSuccessReport = type === "IS_SUCCESS";
    state.isFailureReport = type === "IS_FAILURE";
    state.errorMessageReport = payload;
  },
};
