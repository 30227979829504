<template>
  <div class="d-flex align-center mt-5">
    <div v-show="total > 1">
      <v-pagination
        :value="currentPage"
        :length="total"
        :total-visible="7"
        @input="onChangePage"
      />
    </div>

    <v-spacer />

    <v-row
      v-show="totalItems"
      class="align-center justify-end"
      no-gutters
      style="width: 200px"
    >
      <v-col cols="auto" class="pr-2">
        <small class="blue-grey--text text--lighten-1 text-no-wrap">
          {{ $t("datatable.per_page") }}
        </small>
      </v-col>
      <v-col cols="3">
        <v-select
          :value="perPage"
          :items="[10, 30, 50, 100]"
          dense
          hide-details
          @change="$emit('changePerPage', $event)"
        />
      </v-col>
    </v-row>

    <div v-show="totalItems" class="ml-5">
      <small class="blue-grey--text text--lighten-1 text-no-wrap">
        {{ `${totalText}: ${totalItems}` }}
      </small>
    </div>
  </div>
</template>

<script>
import i18n from "@/plugins/i18n";

export default {
  name: "DataTableFooter",

  props: {
    perPage: {
      type: Number,
      default: 30,
    },

    totalText: {
      type: String,
      default: () => i18n.t("datatable.total"),
    },

    pagination: {
      type: Object,
      default: () => ({
        current_page: 1,
        per_page: 30,
        total: 0,
        total_items: 0,
      }),
    },

    isSetUrlQuery: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      currentPage: 1,
      total: 0,
      totalItems: 0,
    };
  },

  watch: {
    "$route.query.page": {
      handler(page) {
        const newPage = page ? parseInt(page) : 1;

        if (newPage && this.currentPage != newPage) {
          this.onChangePage(newPage, false);
        }
      },
    },

    pagination: {
      handler(pagination) {
        this.currentPage = pagination.current_page;
        this.total = pagination.total;
        this.totalItems = pagination.total_items;
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    setPageToQuery(page) {
      const query = {
        ...this.$route.query,
        page,
      };

      if (page === 1) {
        delete query.page;
      }

      this.$router.push({ query }).catch(() => {});
    },

    onChangePage(page, isSetUrlQuery = this.isSetUrlQuery) {
      if (isSetUrlQuery) {
        this.setPageToQuery(page);
      }

      this.currentPage = page;
      this.$emit("changePagination", page);
    },
  },
};
</script>
