import Vue from "vue";
import i18n from "@/plugins/i18n";

export default {
  get({ commit }, params) {
    commit("setLoading", { type: "IS_PENDING" });

    return Vue.axios("devices", { params })
      .then((response) => {
        const {
          data,
          meta: { filter },
        } = response.data;

        commit("set", data);
        commit("setFilter", filter);
        commit("setLoading", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.errorLoading"),
        });
      });
  },

  syncDevice({ commit }, id) {
    return Vue.axios
      .post(`device/${id}/sync`, {
        timestamp: Vue.moment().unix(),
      })
      .then(() => {
        Vue.prototype.$notify({
          group: "info",
          type: "success",
          text: i18n.t("devices.syncDeviceSuccess"),
        });
      })
      .catch(() => {
        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("devices.syncDeviceError"),
        });
      });
  },

  sort({ commit }, { id, idPlace, isBeforeIdPlace }) {
    return Vue.axios
      .post("devices/sort", {
        id,
        order_id: idPlace,
        is_before: isBeforeIdPlace,
      })
      .catch(() => {
        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("devices.sortDeviceError"),
        });
      });
  },

  scan({ commit }) {
    commit("setLoadingScan", { type: "IS_PENDING" });

    const urls = ["device/scan", "devices/ip"];

    const requests = urls.map((url) => Vue.axios(url));

    return Promise.all(requests)
      .then((responses) => {
        responses.forEach((response, index) => {
          const { data } = response.data;

          switch (index) {
            case 0:
              commit("setScannedDevices", data);
              break;
            case 1:
              commit("setAllDevicesIp", data);
              break;
            default:
              break;
          }
        });

        commit("setLoadingScan", { type: "IS_SUCCESS" });
      })
      .catch((errors) => {
        commit("setLoadingScan", {
          type: "IS_FAILURE",
          payload: errors[0]?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.errorLoading"),
        });
      });
  },

  getMonitoring({ commit }) {
    commit("setLoadingMonitoring", { type: "IS_PENDING" });

    return Vue.axios
      .get("devices/monitoring")
      .then((response) => {
        commit("setLoadingMonitoring", { type: "IS_SUCCESS" });
        commit("setMonitoring", response.data.data);
      })
      .catch((errors) => {
        commit("setLoadingMonitoring", {
          type: "IS_FAILURE",
          payload: errors[0]?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.errorLoading"),
        });
      });
  },

  remove({ commit }, ids) {
    commit("setLoading", { type: "IS_PENDING_REMOVE" });

    return Vue.axios
      .post("devices/delete", { ids })
      .then(() => {
        commit("setLoading", { type: "IS_SUCCESS_REMOVE" });

        Vue.prototype.$notify({
          group: "info",
          type: "success",
          text:
            ids.length > 1
              ? i18n.t("message.devicesDeleteSuccess")
              : i18n.t("message.deviceDeleteSuccess"),
        });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE_REMOVE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.deleteError"),
        });
      });
  },
};
