<template>
  <v-card class="mx-auto" outlined color="#F3F2FF">
    <v-card-text>
      <v-row>
        <!-- Status -->
        <v-col cols="auto">
          <v-menu offset-y :max-width="400" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-badge
                color="primary"
                bordered
                overlap
                :content="excludedStatus.length"
                :value="excludedStatus.length ? true : false"
              >
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  depressed
                  color="blue"
                  dark
                >
                  {{ $t("requests.status") }}&nbsp;
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </v-badge>
            </template>

            <v-card max-height="400" max-width="500">
              <v-list>
                <v-treeview
                  :value="excludedStatus"
                  selectable
                  :items="statuses"
                  item-text="name"
                  selection-type="independent"
                  @input="submit('excludedStatus', $event)"
                />
              </v-list>
            </v-card>
          </v-menu>
        </v-col>

        <!-- Permit type -->
        <v-col cols="auto">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" small depressed color="blue" dark>
                {{ $t("permits.permits") }}
                <template v-if="value.userType"
                  >[{{ getPermitsLabel(value.userType) }}]</template
                >
                &nbsp;
                <v-icon right> mdi-menu-down </v-icon>
              </v-btn>
            </template>

            <v-card :loading="isPendingTemplates">
              <v-list>
                <v-list-item-group
                  :value="value.userType"
                  @change="submit('userType', $event)"
                >
                  <template v-if="value.userType">
                    <v-list-item :value="null" dense>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("button.clear")
                        }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon>mdi-close-circle-outline</v-icon>
                      </v-list-item-icon>
                    </v-list-item>

                    <v-divider class="m-0" />
                  </template>

                  <v-list-item
                    v-for="(template, index) in templates"
                    :key="index"
                    :value="template.type"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ template.name }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-menu>
        </v-col>

        <!-- One pass -->
        <v-col cols="auto">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" small depressed color="blue" dark>
                {{ $t("requests.typePass") }}
                <template v-if="onePass !== null && onePass === true"
                  >[{{ $t("requests.onepass") }}]</template
                >
                <template v-if="onePass !== null && onePass === false"
                  >[{{ $t("requests.multiPass") }}]</template
                >
                &nbsp;
                <v-icon right> mdi-menu-down </v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-list>
                <v-list-item-group
                  :value="onePass"
                  @change="submit('onePass', $event)"
                >
                  <template v-if="onePass !== null">
                    <v-list-item :value="null" dense>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("button.clear")
                        }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-icon>
                        <v-icon>mdi-close-circle-outline</v-icon>
                      </v-list-item-icon>
                    </v-list-item>

                    <v-divider class="m-0" />
                  </template>

                  <v-list-item :value="true">
                    <v-list-item-content>
                      <v-list-item-title>{{
                        $t("requests.onepass")
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item :value="false">
                    <v-list-item-content>
                      <v-list-item-title>{{
                        $t("requests.multiPass")
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-menu>
        </v-col>

        <!-- Only active -->
        <v-col cols="auto">
          <v-switch
            :input-value="onlyActive"
            :label="$t('requests.activePass')"
            hide-details
            class="mt-0 pt-0"
            @change="submit('onlyActive', $event)"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <Search
            :value="value.search"
            :searchParams="searchParams"
            :saveSearchHistory="$const.SEARCH_HISTORY.PERMIT_REQUESTS"
            @search="submit('search', $event)"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Search from "@/components/Search";

export default {
  name: "RequestsFilter",

  components: { Search },

  props: {
    value: {
      type: Object,
      default: () => ({
        excludedStatus: [1, 2],
        userType: null,
        onlyActive: false,
        onePass: null,
        search: "",
      }),
    },
  },

  data() {
    return {
      searchParams: [
        {
          value: "name",
          text: this.$t("user.name"),
        },
        {
          value: "id",
          text: "ID",
        },
        {
          value: "email",
          text: this.$t("user.email"),
        },
        {
          value: "tel",
          text: this.$t("user.phone"),
        },
      ],

      statuses: [
        // TODO: uncomment when statusNew will be implemented
        // {
        //   id: 0,
        //   name: this.$t("requests.statusNew"),
        // },
        {
          id: 1,
          name: this.$t("requests.statusWait"),
        },
        {
          id: 2,
          name: this.$t("requests.statusDone"),
        },
        {
          id: 3,
          name: this.$t("requests.statusCancel"),
        },
      ],
    };
  },

  methods: {
    ...mapActions({
      getTemplates: "permitTemplates/get",
    }),

    onSearch(search) {
      this.search = search;
      this.submit();
    },

    submit(key, value) {
      this.$emit("input", { ...this.value, [key]: value });
    },

    getPermitsLabel(type) {
      const typeObject = this.templates.find((item) => {
        return item.type === type;
      });

      return typeObject ? typeObject.name : "";
    },
  },

  computed: {
    ...mapState({
      isPendingTemplates: (state) => state.permitTemplates.isPending,
      templates: (state) => state.permitTemplates.data,
    }),

    excludedStatus() {
      let excludedStatus = [];

      if (Array.isArray(this.value.excludedStatus)) {
        excludedStatus = this.value.excludedStatus.map((item) =>
          parseInt(item)
        );
      } else {
        excludedStatus = [parseInt(this.value.excludedStatus)];
      }

      return excludedStatus;
    },

    onlyActive() {
      return this.value.onlyActive === "true" ? true : false;
    },

    onePass() {
      let result = null;

      if (this.value.onePass === "true") {
        result = true;
      } else if (this.value.onePass === "false") {
        result = false;
      } else {
        result = null;
      }

      return result;
    },
  },

  created() {
    this.getTemplates();
  },
};
</script>
