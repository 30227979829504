<template>
  <div>
    <v-row v-if="errorMessage">
      <v-col>
        <Alert type="error">
          {{ errorMessage }}
        </Alert>
      </v-col>
    </v-row>

    <v-row v-if="errorMessageDelete">
      <v-col>
        <Alert type="error">
          {{ errorMessageDelete }}
        </Alert>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-if="!isMinifyFilter" cols="12" md="3">
        <FilterAsync
          v-model="filter"
          :loading="isPending"
          :data="filterData"
          :minify="isMinifyFilter"
          :canMinify="canChangeFilterMode"
          @saveSettings="onSaveFilterSettings"
          @input="onFilter"
        />
      </v-col>

      <v-col cols="12" :md="!isMinifyFilter ? 9 : 12">
        <v-row>
          <v-col
            v-if="can(this.$const.RULES.DEVICE_ACTIONS)"
            cols="12"
            md="auto"
          >
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" :disabled="!selected.length">
                  {{ $t("menu.actions") }}&nbsp;
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item link @click="onEmergency('open')">
                  <v-list-item-title>{{
                    $t("devices.emergency.open")
                  }}</v-list-item-title>
                </v-list-item>
                <v-list-item link @click="onEmergency('default')">
                  <v-list-item-title>{{
                    $t("devices.emergency.close")
                  }}</v-list-item-title>
                </v-list-item>

                <v-divider />

                <v-list-item link @click="onBlocked('off')">
                  <v-list-item-title>{{
                    $t("devices.ogatestate.off")
                  }}</v-list-item-title>
                </v-list-item>
                <v-list-item link @click="onBlocked('on')">
                  <v-list-item-title>{{
                    $t("devices.ogatestate.on")
                  }}</v-list-item-title>
                </v-list-item>

                <v-divider />

                <v-list-item link @click="onDisabledBiometricStatus(true)">
                  <v-list-item-title>{{
                    $t("devices.biometric.off")
                  }}</v-list-item-title>
                </v-list-item>
                <v-list-item link @click="onDisabledBiometricStatus(false)">
                  <v-list-item-title>{{
                    $t("devices.biometric.on")
                  }}</v-list-item-title>
                </v-list-item>

                <v-divider />

                <v-list-item link @click="onReboot">
                  <v-list-item-title>{{
                    $t("devices.reboot")
                  }}</v-list-item-title>
                </v-list-item>
                <v-list-item link @click="onSetDefault">
                  <v-list-item-title>{{
                    $t("devices.toDefault")
                  }}</v-list-item-title>
                </v-list-item>

                <v-divider />

                <v-list-item link @click="onDelete">
                  <v-list-item-title>{{
                    $t("devices.delete")
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-spacer />
          <v-col v-if="can($const.RULES.DEVICE_ADD)" cols="12" md="auto">
            <v-menu offset-y left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" v-bind="attrs" v-on="on">
                  <v-icon left> mdi-plus </v-icon>
                  {{ $t("devices.addDevice") }}&nbsp;
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  link
                  :to="{
                    name: $const.ROUTES.ADD_DEVICE,
                    query: { device: 'OGATE' },
                    params: {
                      query: $route.query,
                    },
                  }"
                >
                  <v-list-item-title>{{
                    $t("devices.addNewDeviceOgate")
                  }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  link
                  :to="{
                    name: $const.ROUTES.ADD_DEVICE,
                    query: { device: 'UFACE' },
                    params: {
                      query: $route.query,
                    },
                  }"
                >
                  <v-list-item-title>{{
                    $t("devices.addNewDeviceUface")
                  }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  link
                  :to="{
                    name: $const.ROUTES.ADD_DEVICE,
                    query: { device: 'CONTRL' },
                    params: {
                      query: $route.query,
                    },
                  }"
                >
                  <v-list-item-title>{{
                    $t("devices.addNewDeviceController")
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-data-table
              ref="table"
              v-model="selected"
              :loading="isPending"
              :headers="headers"
              :items="devices"
              :server-items-length="pagination.per_page"
              show-select
              hide-default-footer
              disable-sort
            >
              <template v-if="isMinifyFilter" v-slot:top>
                <FilterAsync
                  v-model="filter"
                  :loading="isPending"
                  :data="filterData"
                  :minify="isMinifyFilter"
                  :canMinify="canChangeFilterMode"
                  @saveSettings="onSaveFilterSettings"
                  @input="onFilter"
                />
              </template>

              <template #body="props">
                <Draggable
                  v-if="$refs.table"
                  tag="tbody"
                  :list="devicesState"
                  handle=".drag-item"
                  @end="onDragEnd"
                >
                  <v-nodes
                    :vnodes="$refs.table.genItems(devicesState, props)"
                  />
                </Draggable>
              </template>

              <template #[`item.drag`]>
                <v-icon class="drag-item"> mdi-drag-horizontal-variant </v-icon>
              </template>

              <template #[`item.avatar`]="{ item }">
                <RadialProgress
                  :size="78"
                  :progress="
                    syncDeviceData[item.id]
                      ? syncDeviceData[item.id].progress
                      : 0
                  "
                  class="mt-3 mb-3"
                >
                  <v-badge
                    :value="
                      syncDeviceData[item.id]
                        ? syncDeviceData[item.id].personNum
                        : false
                    "
                    :content="
                      syncDeviceData[item.id]
                        ? syncDeviceData[item.id].personNum
                        : 0
                    "
                    bordered
                    color="primary"
                    class="pt-2 pb-2"
                    offset-x="15"
                    offset-y="30"
                  >
                    <v-avatar size="70">
                      <v-img :src="item.avatar" :alt="item.name" />
                    </v-avatar>
                  </v-badge>
                </RadialProgress>
              </template>

              <template #[`item.status`]="{ item }">
                <v-chip
                  v-if="!item.isEnabled"
                  x-small
                  color="grey"
                  class="mr-1 my-1"
                >
                  <v-icon left x-small>mdi-close-circle</v-icon>
                  {{ $t("devices.disabled") }}
                </v-chip>
                <template v-else>
                  <div v-if="syncDeviceData[item.id]">
                    <v-chip
                      v-if="!syncDeviceData[item.id].status"
                      x-small
                      color="grey darken-3"
                      text-color="white"
                      class="mr-1 my-1"
                    >
                      <v-icon left x-small>mdi-access-point-network-off</v-icon>
                      Offline
                    </v-chip>

                    <v-chip
                      v-else
                      x-small
                      color="green"
                      text-color="white"
                      class="mr-1 my-1"
                    >
                      <v-icon left x-small>mdi-access-point-network</v-icon>
                      Online
                    </v-chip>

                    <v-chip
                      v-if="
                        syncDeviceData[item.id].isBlock &&
                        syncDeviceData[item.id].status
                      "
                      x-small
                      color="red"
                      text-color="white"
                      class="mr-1 my-1"
                    >
                      <v-icon left x-small>mdi-cancel</v-icon>
                      {{ $t("devices.blocked") }}
                    </v-chip>

                    <v-chip
                      v-if="
                        syncDeviceData[item.id].isBiometricDisabled &&
                        syncDeviceData[item.id].status
                      "
                      x-small
                      color="purple"
                      text-color="white"
                      class="mr-1 my-1"
                    >
                      <v-icon left x-small>mdi-account-off</v-icon>
                      {{ $t("devices.biometricDisabled") }}
                    </v-chip>
                  </div>
                  <v-skeleton-loader
                    v-else
                    class="mx-auto"
                    max-width="100"
                    type="text"
                  />
                </template>
              </template>

              <template #[`item.doorStatus`]="{ item }">
                <template v-if="item.isEnabled">
                  <div v-if="syncDeviceData[item.id]">
                    <div
                      v-for="(door, index) in getDoors(item)"
                      :key="index"
                      class="text-no-wrap d-flex align-center"
                    >
                      <template v-if="door.enable">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              v-bind="attrs"
                              v-on="on"
                              x-small
                              :color="getDoorStatusColor(door.status)"
                              text-color="white"
                              class="mr-1 my-1"
                            >
                              <v-icon left x-small>
                                <template v-if="door.status === 'default'">
                                  mdi-lock
                                </template>
                                <template v-else-if="door.status === 'open'">
                                  mdi-lock-open-variant
                                </template>
                                <template
                                  v-else-if="door.status === 'unavailable'"
                                >
                                  mdi-close-circle
                                </template>
                              </v-icon>
                              {{ door.name }}
                            </v-chip>
                          </template>
                          <span v-if="door.status === 'default'">{{
                            $t("device.doorClose")
                          }}</span>
                          <span v-else-if="door.status === 'open'">{{
                            $t("device.doorOpen")
                          }}</span>
                          <span v-else-if="door.status === 'unavailable'">{{
                            $t("device.doorUnavailable")
                          }}</span>
                        </v-tooltip>

                        <v-tooltip v-if="door.status === 'default'" top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              small
                              color="green"
                              v-bind="attrs"
                              v-on="on"
                              @click="onChangeDoorStatus(item, index, 'open')"
                            >
                              <v-icon small>mdi-key</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t("device.doorUnlock") }}</span>
                        </v-tooltip>
                        <v-tooltip v-else-if="door.status === 'open'" top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              small
                              color="red"
                              v-bind="attrs"
                              v-on="on"
                              @click="
                                onChangeDoorStatus(item, index, 'default')
                              "
                            >
                              <v-icon small>mdi-key</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ $t("device.doorLock") }}</span>
                        </v-tooltip>
                      </template>
                    </div>
                  </div>
                  <v-skeleton-loader
                    v-else
                    class="mx-auto"
                    max-width="100"
                    type="text"
                  />
                </template>
              </template>

              <template #[`item.name`]="{ item }">
                <router-link
                  v-if="can($const.RULES.DEVICE_EDIT)"
                  :to="{
                    name: $const.ROUTES.EDIT_DEVICE,
                    params: { id: item.id, query: $route.query },
                  }"
                  >{{ item.name }}</router-link
                >
                <template v-else>
                  {{ item.name }}
                </template>

                <small
                  v-if="item.ipAddress !== item.name"
                  class="blue-grey--text text--lighten-1 d-block"
                >
                  {{ item.ipAddress }}
                </small>

                <small class="blue-grey--text text--lighten-1 d-block">
                  {{ item.deviceType }}
                </small>
              </template>

              <template #[`item.purpose`]="{ item }">
                <v-tooltip
                  v-for="(channel, index) in item.channels.filter(
                    (channel) => channel.enable
                  )"
                  :key="index"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <div>
                      <v-chip
                        v-bind="attrs"
                        v-on="on"
                        :color="getPurposeColor(channel.purpose)"
                        outlined
                        class="my-1"
                        small
                      >
                        <v-icon left small>
                          {{ getPurposeIcon(channel.purpose) }}
                        </v-icon>
                        {{ getPurposeFrom(channel) }}
                        <v-icon x-small class="mx-1">mdi-arrow-right</v-icon>
                        {{ getPurposeTo(channel) }}
                      </v-chip>
                    </div>
                  </template>
                  <span>{{ $t(`device.purpose_${channel.purpose}`) }}</span>
                </v-tooltip>
              </template>

              <template #[`item.updatedAt`]="{ item }">
                <template v-if="item.isEnabled">
                  <template v-if="syncDeviceData[item.id]">
                    {{
                      syncDeviceData[item.id].time | moment("DD.MM.YY HH:mm:ss")
                    }}
                  </template>

                  <v-skeleton-loader
                    v-else
                    class="mx-auto"
                    max-width="100"
                    type="text"
                  />
                </template>
              </template>

              <template #[`item.actions`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      small
                      icon
                      :disabled="!item.isEnabled"
                      @click="syncDevice(item.id)"
                    >
                      <v-icon small>mdi-sync</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("devices.syncroDevice") }}</span>
                </v-tooltip>

                <v-tooltip v-if="can($const.RULES.DEVICE_EDIT)" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      small
                      icon
                      color="primary"
                      @click="goToEdit(item.id)"
                    >
                      <v-icon small>mdi-cog</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("devices.configureDevice") }}</span>
                </v-tooltip>
              </template>

              <template #[`item.errorLogs`]="{ item }">
                <v-tooltip v-if="item.isErrorLogs" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      small
                      icon
                      color="red"
                      @click="openDeviceErrorLogs(item)"
                    >
                      <v-icon small>mdi-alert-circle-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("devices.deviceErrorLogs") }}</span>
                </v-tooltip>
              </template>

              <template #footer>
                <DataTableFooter
                  ref="tableFooter"
                  :pagination="pagination"
                  :totalText="$t('devices.total')"
                  :perPage="limit"
                  @changePagination="onChangePagination"
                  @changePerPage="onChangePerPage"
                />
              </template>
            </v-data-table>

            <v-card
              v-if="can($const.RULES.DEVICE_ADD)"
              class="mt-5 mx-auto"
              outlined
              :loading="isPendingScan"
              :disabled="isPendingScan"
            >
              <v-card-actions>
                <v-btn color="primary" text @click="scanDevices">
                  <v-icon left>mdi-magnify-scan</v-icon>
                  {{ $t("devices.scan") }}
                </v-btn>
                <v-spacer />
                <v-btn
                  v-if="selectedScanDevices.length"
                  color="success"
                  text
                  @click="addSelectedDevice"
                >
                  <v-icon left>mdi-plus</v-icon>
                  {{ $t("devices.addscandevices") }}
                </v-btn>
              </v-card-actions>

              <v-card-text v-if="scannedDevices.length">
                <Alert v-if="errorMessageScan" type="error">
                  {{ errorMessageScan }}
                </Alert>

                <v-chip-group v-model="selectedScanDevices" column multiple>
                  <v-chip
                    v-for="device in scannedDevices"
                    :key="device.ip"
                    :value="device"
                    :filter="!allDevicesIp.includes(device.ip)"
                    :color="allDevicesIp.includes(device.ip) ? 'success' : ''"
                    :disabled="isDisabledScanDevice(device)"
                    outlined
                  >
                    <strong>{{ device.type }}</strong
                    >: {{ device.ip }}
                    <v-tooltip v-if="device.stream" top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          :color="
                            allDevicesIp.includes(device.ip) ? 'success' : ''
                          "
                          @click.prevent.stop="showCameraDialog(device)"
                        >
                          <v-icon>mdi-video-account</v-icon>
                        </v-btn>
                      </template>
                      {{ $t("devices.viewStream") }}
                    </v-tooltip>
                  </v-chip>
                </v-chip-group>
              </v-card-text>
              <CameraBox
                v-if="isCameraShow"
                @toggle="isCameraShow = $event"
                :devices="cameraShowDevices"
                :showcontrol="false"
                isSelectedFirstDevice
              />
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-dialog
        v-if="selectedDeviceErrorLogs"
        :value="true"
        max-width="700px"
        @click:outside="closeDeviceErrorLogs"
      >
        <ModalViewErrorLogs
          :id="selectedDeviceErrorLogs"
          @close="closeDeviceErrorLogs"
        />
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import Draggable from "vuedraggable";

import Alert from "@/components/Alert";
import RadialProgress from "@/components/RadialProgress";
import ModalViewErrorLogs from "./ModalViewErrorLogs";
import CameraBox from "@/components/CameraBox";
import { FilterAsync } from "@/components/Filter";
import DataTableFooter from "@/components/DataTableFooter";

import permissions from "@/mixins/permissions";
import filter from "@/mixins/filter";

// Constants
const DISABLED_ALL = "DISABLED_ALL";
const DISABLED_UFACE = "DISABLED_UFACE";
const DISABLED_CONTRL = "DISABLED_CONTRL";
const UFACE = "UFACE";
const CONTRL = "CONTRL";
const OGATE = "OGATE";

export default {
  name: "DevicesList",

  mixins: [permissions, filter],

  components: {
    Alert,
    ModalViewErrorLogs,
    RadialProgress,
    Draggable,
    CameraBox,
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
    FilterAsync,
    DataTableFooter,
  },

  data() {
    return {
      selected: [],
      selectedScanDevices: [],
      syncDeviceInterval: null,

      page: parseInt(this.$route.query.page) || 1,

      selectedDeviceErrorLogs: null,
      syncDeviceData: {},
      devicesState: [],

      isCameraShow: false,
      cameraShowDevices: [{ ip_address: "", purpose: "all", name: "", id: 1 }],
    };
  },

  watch: {
    devices(devices) {
      this.devicesState = devices;
    },

    filterData(filterData) {
      this.initFilterByTemplate(filterData);
    },
  },

  methods: {
    ...mapActions({
      getDevicesAction: "devices/get",
      syncDevice: "devices/syncDevice",
      sort: "devices/sort",
      scanDevices: "devices/scan",
      store: "device/store",
      emergency: "device/emergency",
      blocked: "device/blocked",
      disabledBiometricStatus: "device/disabledBiometricStatus",
      reboot: "device/reboot",
      remove: "devices/remove",
      getZones: "zones/getList",
      getMonitoringAction: "devices/getMonitoring",
    }),

    ...mapMutations({
      setFilterMinify: "devices/setFilterMinify",
      setLimit: "devices/setLimit",
    }),

    onChangePerPage(limit) {
      this.setLimit(limit);
      this.page = 1;
      this.$refs.tableFooter.onChangePage(this.page);
    },

    onChangePagination(page) {
      this.page = page;

      this.getDevices();
    },

    getDevices() {
      const params = {
        ...this.filter,
      };

      if (this.page) params.page = this.page;
      if (this.limit) params.limit = this.limit;

      this.getDevicesAction(params).then(() => {
        this.devices.forEach((device) => {
          this.setSyncDevice(device.monitoring);
        });
      });
    },

    goToAdd() {
      this.$router.push({ name: this.$const.ROUTES.ADD_DEVICE });
    },

    goToEdit(id) {
      this.$router.push({
        name: this.$const.ROUTES.EDIT_DEVICE,
        params: { id },
      });
    },

    getPurposeColor(purpose) {
      switch (purpose) {
        case "entrance":
          return "green";
        case "exit":
          return "red";
        case "duplex":
          return "blue";
        default:
          return "grey";
      }
    },

    getPurposeIcon(purpose) {
      switch (purpose) {
        case "entrance":
          return "mdi-location-enter";
        case "exit":
          return "mdi-location-exit";
        case "duplex":
          return "mdi-swap-horizontal-circle-outline";
        default:
          return "mdi-close-circle-outline";
      }
    },

    getPurposeFrom(channel) {
      switch (channel.purpose) {
        case "exit":
          return this.getZoneById(channel.zones_id).name;
        default:
          return channel.source_id
            ? this.getZoneById(channel.source_id).name
            : this.$t("devices.zoneEmpty");
      }
    },

    getPurposeTo(channel) {
      switch (channel.purpose) {
        case "exit":
          return channel.source_id
            ? this.getZoneById(channel.source_id).name
            : this.$t("devices.zoneEmpty");
        default:
          return this.getZoneById(channel.zones_id).name;
      }
    },

    openDeviceErrorLogs(e) {
      this.selectedDeviceErrorLogs = e.id;
    },

    closeDeviceErrorLogs() {
      this.selectedDeviceErrorLogs = null;
    },

    setSyncDevice(item) {
      const data = {
        ...this.syncDeviceData[item.id],

        time: item.time,
        progress: item.status ? 100 : 0,
        personNum: item.person_num,
        status: item.status,
        doorStatus: item.gate_command,
        isBlock: item.system_state === "on" ? false : true,
        isBiometricDisabled: item.biometric_lock,
      };

      this.$set(this.syncDeviceData, item.id, data);
    },

    setLoadFeatures(item) {
      let progress = Math.round(item.proc * 100);
      if (progress > 100) progress = 100;

      const data = {
        ...this.syncDeviceData[item.id],

        progress,
        personNum: item.person_num || this.syncDeviceData[item.id].personNum,
      };

      this.$set(this.syncDeviceData, item.id, data);
    },

    connectWebSockets() {
      this.syncDeviceInterval = setInterval(() => {
        this.getMonitoringAction().then(() => {
          this.monitoringData.forEach((item) => {
            this.setSyncDevice(item);
          });
        });
      }, 10000);

      this.$root.$on(this.$const.WS_CHANNELS.LOAD_FEATURES, (item) => {
        this.setLoadFeatures(item);
      });
    },

    disconnectedWebSockets() {
      clearInterval(this.syncDeviceInterval);
      this.$root.$off(this.$const.WS_CHANNELS.LOAD_FEATURES);
    },

    onDragEnd(e) {
      if (e.newIndex !== e.oldIndex) {
        const id = this.devicesState[e.newIndex].id;
        let idPlace = 0;
        let isBeforeIdPlace = false;

        if (this.devicesState[e.newIndex - 1]) {
          idPlace = this.devicesState[e.newIndex - 1].id;
        } else {
          idPlace = this.devicesState[e.newIndex + 1].id;
          isBeforeIdPlace = true;
        }

        if (idPlace) {
          this.sort({ id, idPlace, isBeforeIdPlace });
        }
      }
    },

    showCameraDialog(device) {
      this.cameraShowDevices[0].ip_address = device.ip;
      this.cameraShowDevices[0].name = device.ip;
      this.cameraShowDevices[0].device_type = device.type;
      this.isCameraShow = true;
    },

    async addSelectedDevice() {
      if (this.selectedScanDevices[0]) {
        let promises = [];

        switch (this.selectedScanDevices[0].type) {
          case UFACE:
            this.$router.push({
              name: this.$const.ROUTES.ADD_DEVICE,
              query: { device: UFACE },
              params: { device: this.selectedScanDevices[0] },
            });
            break;

          case CONTRL:
            this.$router.push({
              name: this.$const.ROUTES.ADD_DEVICE,
              query: { device: CONTRL },
              params: { device: this.selectedScanDevices[0] },
            });
            break;

          default:
            promises = this.selectedScanDevices.map((device) =>
              this.addOneDevice(device.ip)
            );
            await Promise.all(promises);
            this.selectedScanDevices = [];
            this.getDevices();
            this.scanDevices();
            break;
        }
      }
    },

    addOneDevice(ip) {
      const data = {
        timestamp: this.$moment().unix(),
        name: ip,
        purpose: "entrance",
        ip_address: ip,
        enabled: true,
        data: JSON.stringify({
          temperature_enable: "off",
          recognition_range: 2,
          control_mode: "only_led",
          card_type: "",
          pass_timeout: 2,
          system_mode: "default",
          recognition_face: 0.7,
          recognition_mask: 0.62,
          mask_enabled: false,
          show_name: "short",
          show_themp_info: false,
          show_access: false,
          antispoofing_mode: "print",
          display_mode: "static",
          autoregistration_mode: false,
          authentication_type: "face",
        }),
        channels: [
          {
            channel: 0,
            purpose: "entrance",
            zones_id: 1,
            source_id: null,
            enable: true,
          },
        ],
      };

      return this.store(data);
    },

    isDisabledScanDevice(device) {
      if (!this.selectedScanDevices.includes(device)) {
        if (this.allDevicesIp.includes(device.ip)) {
          return true;
        }

        if (this.disabledScanDeviceBySelected.includes(DISABLED_ALL)) {
          return true;
        }

        if (
          this.disabledScanDeviceBySelected.includes(DISABLED_UFACE) &&
          device.type === UFACE
        )
          return true;
      }

      if (
        this.disabledScanDeviceBySelected.includes(DISABLED_CONTRL) &&
        device.type === CONTRL
      ) {
        return true;
      }

      return false;
    },

    async onChangeDoorStatus(item, index, status) {
      const doorStatus = this.syncDeviceData[item.id].doorStatus;
      const command = this.getArrayGateCommand(doorStatus, index, status);

      this.$set(this.syncDeviceData, item.id, {
        ...this.syncDeviceData[item.id],
        doorStatus: command,
      });

      await this.emergency({
        id: item.id,
        deviceName: item.name,
        command,
      });

      this.getDevices();
    },

    async onEmergency(command) {
      const promises = this.selected
        .map((device) => {
          if (
            this.syncDeviceData[device.id].doorStatus &&
            this.syncDeviceData[device.id].doorStatus.length
          ) {
            const newCommand = this.syncDeviceData[device.id].doorStatus.map(
              () => command
            );

            this.$set(this.syncDeviceData, device.id, {
              ...this.syncDeviceData[device.id],
              doorStatus: newCommand,
            });

            return this.emergency({
              id: device.id,
              deviceName: device.name,
              command: newCommand,
            });
          }

          return undefined;
        })
        .filter((device) => device);

      await Promise.all(promises);

      this.getDevices();
    },

    async onBlocked(state) {
      const promises = this.selected.map((device) =>
        this.blocked({ id: device.id, deviceName: device.name, state })
      );

      await Promise.all(promises);

      this.getDevices();
    },

    onSetDefault() {
      this.onEmergency("default");
      this.onBlocked("on");
      this.onDisabledBiometricStatus(false);
    },

    async onDisabledBiometricStatus(disable) {
      const promises = this.selected.map((device) =>
        this.disabledBiometricStatus({
          id: device.id,
          deviceName: device.name,
          disable,
        })
      );

      await Promise.all(promises);

      this.getDevices();
    },

    async onReboot() {
      const promises = this.selected.map((device) =>
        this.reboot({ id: device.id, deviceName: device.name })
      );

      await Promise.all(promises);

      this.getDevices();
    },

    onSaveFilterSettings(filterSettings) {
      this.setFilterMinify(filterSettings.minify);
    },

    async onDelete() {
      await this.remove(this.selected.map((device) => device.id));

      this.getDevices();
      this.scanDevices();
    },

    onFilter(filter) {
      this.updateFilter({ ...filter });
      this.page = 1;
      this.$refs.tableFooter.onChangePage(this.page);
    },

    getArrayGateCommand(arr, index, status) {
      const newCommand = arr.map((item, i) => {
        if (i == index) {
          return status;
        }
        return item;
      });

      return newCommand;
    },

    getDoors(item) {
      const getDoorData = (channel, index) => {
        let name = channel.name;

        if (!name) {
          if (item.channels.length > 1) {
            name = `${this.$t("devices.door")} ${index + 1}`;
          } else {
            name = this.$t("devices.door");
          }
        }

        const doorStatus = this.syncDeviceData[item.id].doorStatus;
        let status = "unavailable";

        if (doorStatus && doorStatus.length) {
          status = doorStatus[index];
        }

        const data = {
          enable: channel.enable,
          status,
          name,
        };

        return data;
      };

      if (item.type === CONTRL && item.doorsNumber === 2) {
        const doors = [];

        doors.push(
          getDoorData(
            {
              name: "",
              enable: item.channels[0].enable || item.channels[1].enable,
            },
            0
          )
        );

        doors.push(
          getDoorData(
            {
              name: "",
              enable: item.channels[2].enable || item.channels[3].enable,
            },
            1
          )
        );

        return doors;
      } else {
        return item.channels.map(getDoorData);
      }
    },

    getDoorStatusColor(status) {
      switch (status) {
        case "default":
          return "info";
        case "open":
          return "warning";
        case "unavailable":
          return "grey darken-3";
        default:
          return "grey darken-3";
      }
    },
  },

  computed: {
    ...mapGetters({
      devices: "devices/getListFormatted",
      getZoneById: "zones/getZoneById",
    }),

    ...mapState({
      limit: (state) => state.devices.limit,
      isPending: (state) => state.devices.isPending,
      isPendingScan: (state) => state.devices.isPendingScan,
      isPendingZones: (state) => state.zones.isPending,
      pagination: (state) => state.devices.pagination,
      filterData: (state) => state.devices.filter,
      isMinifyFilter: (state) => state.devices.isMinifyFilter,
      canChangeFilterMode: (state) => state.devices.canChangeFilterMode,
      scannedDevices: (state) => state.devices.scannedDevices,
      allDevicesIp: (state) => state.devices.allDevicesIp,
      errorMessage: (state) => state.devices.errorMessage,
      errorMessageDelete: (state) => state.devices.errorMessageDelete,
      errorMessageScan: (state) => state.devices.errorMessageScan,
      zonesTree: (state) => state.zones.tree,
      errorMessageZones: (state) => state.zones.errorMessage,
      monitoringData: (state) => state.devices.dataMonitoring,
    }),

    selectedIds() {
      return this.selected.map((item) => item.id);
    },

    headers() {
      const headers = [];

      if (this.can(this.$const.RULES.DEVICE_EDIT) && !this.isSetFilter) {
        headers.push({
          value: "drag",
          align: "start",
          width: 50,
        });
      }

      headers.push({
        value: "avatar",
      });
      headers.push({
        text: this.$t("devices.nameDevice"),
        value: "name",
      });
      headers.push({
        text: this.$t("devices.status"),
        value: "status",
      });

      if (this.can(this.$const.RULES.DEVICE_ACTIONS)) {
        headers.push({
          text: this.$t("devices.gateCommand"),
          value: "doorStatus",
        });
      }

      headers.push({
        text: this.$t("devices.purpose"),
        value: "purpose",
      });
      headers.push({
        text: this.$t("devices.updatedAt"),
        value: "updatedAt",
      });
      headers.push({
        value: "actions",
        align: "end",
        width: 90,
      });
      headers.push({
        value: "errorLogs",
        align: "end",
        width: 50,
      });

      return headers;
    },

    isSetFilter() {
      let isSetFilter = false;

      for (const key in this.filter) {
        if (Object.hasOwnProperty.call(this.filter, key)) {
          const element = this.filter[key];
          if (element.length) {
            isSetFilter = true;
            break;
          }
        }
      }

      return isSetFilter;
    },

    disabledScanDeviceBySelected() {
      let result = "";

      if (this.selectedScanDevices[0]) {
        switch (this.selectedScanDevices[0].type) {
          case OGATE:
            result = [DISABLED_UFACE, DISABLED_CONTRL];
            break;
          case UFACE:
          case CONTRL:
            result = [DISABLED_ALL];
            break;
          default:
            break;
        }
      }

      return result;
    },
  },

  created() {
    this.connectWebSockets();
    this.getDevices();
    this.scanDevices();
    this.getZones();
  },

  destroyed() {
    this.disconnectedWebSockets();
  },
};
</script>
