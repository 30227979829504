<template>
  <List />
</template>

<script>
import List from "./components/List";

export default {
  name: "Zones",

  components: {
    List,
  },
};
</script>
