import Vue from "vue";
import i18n from "@/plugins/i18n";

export default {
  get({ commit }, { page, limit, search } = {}) {
    const params = {};

    if (page) params.page = page;
    if (limit) params.limit = limit;
    if (search) params.search = search;

    commit("setLoading", { type: "IS_PENDING" });

    return Vue.axios("operators", { params })
      .then((response) => {
        const {
          data,
          meta: { pagination },
        } = response.data;

        commit("set", { data, pagination });
        commit("setLoading", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.errorLoading"),
        });
      });
  },
};
