<template>
  <v-form ref="form" v-model="valid" lazy-validation class="mt-5">
    <v-row v-if="errorMessageSettingsData">
      <v-col>
        <Alert type="error">
          {{ errorMessageSettingsData }}
        </Alert>
      </v-col>
    </v-row>

    <v-row v-if="errorMessageDelAllPersons">
      <v-col>
        <Alert type="error">
          {{ errorMessageDelAllPersons }}
        </Alert>
      </v-col>
    </v-row>

    <v-row v-if="errorMessagePersonPhoto">
      <v-col>
        <Alert type="error">
          {{ errorMessagePersonPhoto }}
        </Alert>
      </v-col>
    </v-row>

    <v-row v-if="errorMessage">
      <v-col>
        <Alert type="error">
          {{ errorMessage }}
        </Alert>
      </v-col>
    </v-row>

    <v-row v-if="errorMessageBackup">
      <v-col>
        <Alert type="error">
          {{ errorMessageBackup }}
        </Alert>
      </v-col>
    </v-row>

    <v-row v-if="errorMessageRestore">
      <v-col>
        <Alert type="error">
          {{ errorMessageRestore }}
        </Alert>
      </v-col>
    </v-row>

    <v-row v-if="errorMessageRestoreBackup">
      <v-col>
        <Alert type="error">
          {{ errorMessageRestoreBackup }}
        </Alert>
      </v-col>
    </v-row>

    <v-row v-if="errorMessageBackupStop">
      <v-col>
        <Alert type="error">
          {{ errorMessageBackupStop }}
        </Alert>
      </v-col>
    </v-row>

    <v-row v-if="errorMessageDeleteBackup">
      <v-col>
        <Alert type="error">
          {{ errorMessageDeleteBackup }}
        </Alert>
      </v-col>
    </v-row>

    <v-row v-if="errorMessageDownloadBackup">
      <v-col>
        <Alert type="error">
          {{ errorMessageDownloadBackup }}
        </Alert>
      </v-col>
    </v-row>

    <v-row v-if="errorMessageBackupList">
      <v-col>
        <Alert type="error">
          {{ errorMessageBackupList }}
        </Alert>
      </v-col>
    </v-row>

    <v-row v-if="errorMessageStatus">
      <v-col>
        <Alert type="error">
          {{ errorMessageStatus }}
        </Alert>
      </v-col>
    </v-row>

    <v-row v-if="errorMessageSaveSettingsData">
      <v-col>
        <Alert type="error">
          {{ errorMessageSaveSettingsData }}
        </Alert>
      </v-col>
    </v-row>

    <v-row v-if="formDataError">
      <v-col>
        <Alert type="error">
          {{ formDataError }}
        </Alert>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h3 class="mb-3">{{ $t("setting.importPhoto") }}</h3>

        <v-row align="center">
          <v-col cols="12" sm="9">
            <treeselect
              v-model="company"
              :multiple="true"
              :options="companies"
              :placeholder="$t('setting.import-in-company')"
              :searchable="true"
              :show-count="true"
              flat
            />
          </v-col>
          <v-col cols="12" sm="3">
            <div v-if="company.length">
              <v-col cols="12">
                <v-file-input
                  :label="$t('setting.importPhotoButton')"
                  accept=".bmp, .png, .jpg, .jpeg"
                  @change="importPhotos"
                  multiple
                >
                </v-file-input>
              </v-col>
            </div>
          </v-col>
        </v-row>

        <template v-if="progress2">
          <v-progress-linear
            :value="progress2"
            class="mt-2"
          ></v-progress-linear>
        </template>
      </v-col>
    </v-row>

    <v-divider class="my-5" />

    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <h3>{{ $t("setting.backUps") }}</h3>
          </v-col>
          <v-spacer />
          <v-col>
            <v-switch
              v-model="autobackup.enabled"
              :label="$t('setting.backup.autobackup')"
              hide-details
              class="mt-0 pt-0"
              @change="store"
            />
          </v-col>
        </v-row>

        <v-row v-if="autobackup.enabled">
          <v-col>
            <v-text-field
              v-if="!isAutoBackupDayOfWeek"
              v-model="autobackup.time_day"
              :label="$t('setting.backup.timeDay')"
              type="number"
              min="0"
              max="31"
              outlined
              :rules="timeDayRules"
              @change="store"
              @keypress="isNumber($event)"
            />

            <v-btn-toggle
              v-if="isAutoBackupDayOfWeek"
              v-model="autobackup.time_day_of_week"
              color="primary"
              multiple
              @change="store"
            >
              <v-btn value="1">
                {{ $t("dtpicker.Week_1") }}
              </v-btn>
              <v-btn value="2">
                {{ $t("dtpicker.Week_2") }}
              </v-btn>
              <v-btn value="3">
                {{ $t("dtpicker.Week_3") }}
              </v-btn>
              <v-btn value="4">
                {{ $t("dtpicker.Week_4") }}
              </v-btn>
              <v-btn value="5">
                {{ $t("dtpicker.Week_5") }}
              </v-btn>
              <v-btn value="6">
                {{ $t("dtpicker.Week_6") }}
              </v-btn>
              <v-btn value="0">
                {{ $t("dtpicker.Week_0") }}
              </v-btn>
            </v-btn-toggle>

            <v-checkbox
              v-model="isAutoBackupDayOfWeek"
              :label="$t('setting.backup.byDayOfWeek')"
              @change="store"
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="autobackup.time_hour"
              :label="$t('setting.backup.timeHour')"
              type="number"
              min="0"
              max="23"
              outlined
              :rules="timeHourRules"
              @change="store"
              @keypress="isNumber($event)"
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="autobackup.time_minute"
              :label="$t('setting.backup.timeMinute')"
              type="number"
              min="0"
              max="59"
              outlined
              :rules="timeMinuteRules"
              @change="store"
              @keypress="isNumber($event)"
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="autobackup.max_count"
              :label="$t('setting.backup.maxCount')"
              min="1"
              max="30"
              type="number"
              outlined
              :rules="timeCountRules"
              @change="store"
              @keypress="isNumber($event)"
            />
          </v-col>
        </v-row>

        <v-row align="center" justify="space-between">
          <v-col cols="12" md="6">
            <v-btn
              v-if="isBackuping"
              color="error"
              :disabled="isRestoring"
              @click="cancelBackup"
            >
              {{ $t("setting.cancelBackupButton") }}
            </v-btn>
            <v-btn
              v-else
              color="primary"
              :disabled="isRestoring"
              @click="createBackup"
            >
              {{ $t("setting.backupButton") }}
            </v-btn>
          </v-col>

          <v-col cols="12" md="6">
            <v-file-input
              truncate-length="50"
              accept=".ovtb"
              chips
              show-size
              :label="$t('setting.restoreButton')"
              :disabled="isBackuping || isRestoring"
              :loading="isUpload"
              @change="restore"
            />
          </v-col>
        </v-row>

        <v-row v-if="backupProgress">
          <v-col cols="12">
            <template>
              <v-progress-linear
                color="blue-grey lighten-1"
                :value="backupProgress"
                height="25"
                rounded
                >{{ $t("setting.backuping") }}
                <strong>&nbsp;{{ Math.ceil(backupProgress) }}%</strong>
              </v-progress-linear>
            </template>
          </v-col>
        </v-row>

        <v-row v-if="restoreProgress">
          <v-col cols="12">
            <template>
              <v-progress-linear
                color="blue-grey lighten-1"
                :value="restoreProgress"
                height="25"
                rounded
                >{{ $t("setting.restoring") }}
                <strong>&nbsp;{{ Math.ceil(restoreProgress) }}%</strong>
              </v-progress-linear>
            </template>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-data-table
              :items="setBackupList"
              :headers="backupListHeaders"
              :loading="isPendingBackupList"
              dense
            >
              <template v-slot:[`item.size`]="{ item }">
                {{ getSize(item.size) }}
              </template>

              <template v-slot:[`item.date_create`]="{ item }">
                {{ item.date_create | moment("DD.MM.YYYY HH:mm:ss") }}
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-progress-circular
                  v-if="downloadingBackupName === item.name"
                  :size="16"
                  indeterminate
                  color="blue-grey"
                  class="mr-2"
                />
                <v-tooltip v-else top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      small
                      class="mr-2"
                      @click="downloadBackup(item.name)"
                    >
                      mdi-download
                    </v-icon>
                  </template>
                  <span>{{ $t("button.download") }}</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      small
                      class="mr-2"
                      :disabled="isBackuping || isRestoring"
                      @click="restoreBackup(item.name)"
                    >
                      mdi-backup-restore
                    </v-icon>
                  </template>
                  <span>{{ $t("button.restore") }}</span>
                </v-tooltip>

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      small
                      :disabled="isBackuping || isRestoring"
                      @click="deleteBackup(item.name)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>{{ $t("button.delete") }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

import Treeselect from "@riophae/vue-treeselect";
import moment from "moment-timezone";
import faceValidate from "@/mixins/faceValidate";
import Alert from "@/components/Alert";

export default {
  name: "Import-export",

  mixins: [faceValidate],

  components: {
    Alert,
    Treeselect,
  },

  data() {
    return {
      valid: true,

      isAutoBackupDayOfWeek: false,
      autobackup: {
        enabled: false,
        time_day: 0,
        time_day_of_week: [],
        time_hour: 0,
        time_minute: 0,
        max_count: 20,
      },
      company: [],
      companies: [],
      lockimport: true,
      file: {},
      progress: 0,
      progress1: 0,
      progress2: 0,
      pageExport: 1,

      backupListHeaders: [
        { text: this.$t("setting.backupName"), value: "name" },
        { text: this.$t("setting.backupSize"), value: "size" },
        { text: this.$t("setting.backupDate"), value: "date_create" },
        { text: "", value: "actions", sortable: false, align: "right" },
      ],

      backupProgress: 0,
      isBackuping: false,
      restoreProgress: 0,
      isRestoring: false,
      isUpload: false,

      downloadingBackupName: "",

      isExport: false,

      timeDayRules: [
        (v) =>
          v?.toString()?.length <= 2 ||
          this.$t("message.lengthIsLarge", { length: 2 }),
        (v) =>
          (parseInt(v) >= 0 && parseInt(v) <= 31) ||
          this.$t("message.numberRange", { start: 0, end: 31 }),
      ],
      timeHourRules: [
        (v) =>
          v?.toString()?.length <= 2 ||
          this.$t("message.lengthIsLarge", { length: 2 }),
        (v) =>
          (parseInt(v) >= 0 && parseInt(v) <= 23) ||
          this.$t("message.numberRange", { start: 0, end: 23 }),
      ],
      timeMinuteRules: [
        (v) =>
          v?.toString()?.length <= 2 ||
          this.$t("message.lengthIsLarge", { length: 2 }),
        (v) =>
          (parseInt(v) >= 0 && parseInt(v) <= 59) ||
          this.$t("message.numberRange", { start: 0, end: 59 }),
      ],
      timeCountRules: [
        (v) =>
          v?.toString()?.length <= 2 ||
          this.$t("message.lengthIsLarge", { length: 2 }),
        (v) =>
          (parseInt(v) >= 1 && parseInt(v) <= 30) ||
          this.$t("message.numberRange", { start: 1, end: 30 }),
      ],
      settings: [],
      formDataError: "",
    };
  },
  methods: {
    ...mapMutations({
      setLoadingBackup: "settings/setLoadingBackup",
      setLoadingSaveSettingsData: "settings/setLoadingSaveSettingsData",
    }),
    ...mapActions({
      getSettings: "settings/getSettings",
      dropAll: "persons/dropAll",
      importByPhoto: "persons/importByPhoto",
      getTree: "accessLevels/getTree",
      setSuccessSettings: "settings/setSettings",
      backup: "settings/backup",
      getRestore: "settings/restore",
      getRestoreBackup: "settings/restoreBackup",
      backupStop: "settings/backupStop",
      delBackup: "settings/deleteBackup",
      loadBackup: "settings/downloadBackup",
      getBackupList: "settings/getBackupList",
      getBackupStatus: "settings/getBackupStatus",
    }),

    isNumber(evt) {
      if (!/\d/.test(evt.key)) return evt.preventDefault();
    },

    importusers: function (item) {
      this.progress = 0;
      if (item.total) {
        this.progress = Math.ceil((item.item / item.total) * 100);
        if (item.item == item.total) {
          this.$notify({
            group: "info",
            type: "success",
            text: this.$t("message.import_success"),
          });
        }
      }
    },
    deleteAllUsers() {
      this.$root.$prompt({
        title: this.$t("message.Delete"),
        message: this.$t("message.confirmDeleteAllUsers"),
        btnAgree: this.$t("button.delete"),
        agree: () => {
          this.dropAll();
        },
      });
    },
    importPhotos(e) {
      console.log(e);
      this.progress2 = 0;
      let photos = [];
      photos = e;
      if (photos) {
        let files = Array.from(photos);
        this.nextPostPhoto(files, 0);
      }
    },
    nextPostPhoto(files, i) {
      let isCallNext = true;
      let count = files.length;
      if (i >= count) return;
      let file = files[i];
      let formData = new FormData();
      formData.append("file", file);

      const params = {
        accesslevels: this.company,
      };
      this.importByPhoto(formData, {
        params,
        header: {
          "Content-Type": "multipart/form-data",
        },
      })
        .catch((error) => {
          const { message } = this.addPhotoError();

          if (message === "Not found device") {
            isCallNext = false;
          }
        })
        .finally(() => {
          i++;
          this.progress2 = Math.ceil((i / count) * 100);
          if (i == count) {
            this.$notify({
              group: "info",
              type: "success",
              text: this.$t("message.import_success"),
            });
          } else {
            if (isCallNext) {
              this.nextPostPhoto(files, i);
            } else {
              this.progress2 = 0;
            }
          }
        });
    },

    findTreeGroupId(id, tree) {
      let fl = false;
      tree.forEach((it) => {
        if (it.id == id) {
          fl = true;
          return;
        } else if (it.children !== undefined) {
          if (this.findTreeGroupId(id, it.children)) {
            fl = true;
            return;
          }
        }
      });
      return fl;
    },
    getCompanies() {
      this.getTree().then(() => {
        this.companies = this.setCompanies;
        this.company.forEach((it, i) => {
          if (!this.findTreeGroupId(it, this.companies)) {
            this.company.splice(i, 1);
          }
        });
      });
    },
    load() {
      this.lockimport = true;
      if (this.settings["AUTOBACKUP"]) {
        this.autobackup = JSON.parse(this.settings["AUTOBACKUP"]);

        if (this.autobackup.time_day_of_week) {
          this.isAutoBackupDayOfWeek = true;
        } else {
          this.isAutoBackupDayOfWeek = false;
        }
      }
      if (this.settings["IMPORT_SUBDIVISIONS"]) {
        this.company = this.settings["IMPORT_SUBDIVISIONS"]
          .split(",")
          .map(function (val) {
            return parseInt(val.trim(), 10);
          });
      } else {
        this.company = [];
      }
    },
    store() {
      let values = [];
      const autoBackup = {};
      this.formDataError = "";

      if (this.$refs.form.validate()) {
        autoBackup.time_hour = parseInt(this.autobackup.time_hour);
        autoBackup.time_minute = parseInt(this.autobackup.time_minute);
        autoBackup.max_count = parseInt(this.autobackup.max_count);

        if (this.isAutoBackupDayOfWeek) {
          autoBackup.time_day_of_week = this.autobackup.time_day_of_week;
        } else {
          autoBackup.time_day = parseInt(this.autobackup.time_day);
        }
      } else {
        this.formDataError = this.$t("message.CheckIsRequired");
      }

      autoBackup.enabled = this.autobackup.enabled;

      values.push({
        key: "AUTOBACKUP",
        value: JSON.stringify(autoBackup),
      });

      values.push({
        key: "IMPORT_SUBDIVISIONS",
        value: this.company.join(","),
      });

      this.setSuccessSettings(values);
    },

    createBackup() {
      this.setLoadingBackup({ type: "IS_PENDING" });
      this.backup().then(() => {
        this.isBackuping = true;
      });
    },

    restore(file) {
      if (file) {
        this.isUpload = true;

        const formData = new FormData();
        formData.append("file", file);

        const config = {
          header: {
            "Content-Type": "multipart/form-data",
          },
        };
        this.getRestore(formData, config)
          .then(() => {
            this.isUpload = false;
            this.isRestoring = true;
          })
          .catch(() => {
            this.isUpload = false;
          });
      }
    },

    restoreBackup(name) {
      this.isRestoring = true;
      this.getRestoreBackup(name);
    },

    deleteBackup(name) {
      this.$root.$prompt({
        title: this.$t("message.Delete"),
        message: this.$t("message.confirmDelete"),
        btnAgree: this.$t("button.delete"),

        agree: () => {
          this.delBackup(name).then(() => {
            this.getBackupList();
          });
        },
      });
    },

    cancelBackup() {
      this.backupStop().then(() => {
        this.isBackuping = false;
        this.backupProgress = 0;
      });
    },

    downloadBackup(name) {
      this.downloadingBackupName = name;
      this.loadBackup(name).then(() => {
        this.downloadingBackupName = "";
      });
    },

    onBackupEvent(event) {
      if (event.proc >= 100) {
        this.isBackuping = false;
        this.backupProgress = 0;
        this.getBackupList();

        this.$notify({
          group: "info",
          type: "success",
          text: this.$t("setting.backupSuccessMessage"),
        });
      } else if (event.proc <= 0) {
        this.isBackuping = false;
        this.backupProgress = 0;
      } else {
        this.isBackuping = true;
        this.backupProgress = event.proc;
      }
    },

    onRestoreEvent(event) {
      if (event.proc >= 100) {
        this.isRestoring = false;
        this.restoreProgress = 0;

        this.$notify({
          group: "info",
          type: "success",
          text: this.$t("setting.restoreSuccessMessage"),
        });
      } else if (event.proc <= 0) {
        this.isRestoring = false;
        this.restoreProgress = 0;
      } else {
        this.isRestoring = true;
        this.restoreProgress = event.proc;
      }
    },

    onBackupLogEvent(event) {
      if (event.status === "error") {
        this.setLoadingBackup({
          type: "IS_FAILURE",
          payload: event.message,
        });
        this.cancelBackup();
      }
    },

    getSize(bytes) {
      let Kbytes, Mbytes, Gbytes;

      let size = `${bytes} B`;

      if (bytes >= 1024) {
        Kbytes = parseFloat(bytes / 1024).toFixed(1);
        size = `${Kbytes} KB`;
      }

      if (Kbytes && Kbytes >= 1024) {
        Mbytes = parseFloat(Kbytes / 1024).toFixed(1);
        size = `${Mbytes} MB`;
      }

      if (Mbytes && Mbytes >= 1024) {
        Gbytes = parseFloat(Mbytes / 1024).toFixed(1);
        size = `${Gbytes} GB`;
      }

      return size;
    },
  },

  watch: {
    company(val) {
      if (!this.lockimport) {
        this.store();
      }
    },
  },

  computed: {
    ...mapState({
      setSettings: (state) => state.settings.settingsData,
      addPhotoError: (state) => state.persons.errorMessagePersonPhoto,
      setCompanies: (state) => state.accessLevels.tree,
      setBackupList: (state) => state.settings.backupList,
      errorMessageSettingsData: (state) =>
        state.settings.errorMessageSettingsData,
      errorMessageDelAllPersons: (state) =>
        state.persons.errorMessageDelAllPersons,
      errorMessagePersonPhoto: (state) => state.persons.errorMessagePersonPhoto,
      errorMessage: (state) => state.errorMessage,
      errorMessageBackup: (state) => state.settings.errorMessageBackup,
      errorMessageRestore: (state) => state.settings.errorMessageRestore,
      errorMessageRestoreBackup: (state) =>
        state.settings.errorMessageRestoreBackup,
      errorMessageBackupStop: (state) => state.settings.errorMessageBackupStop,
      errorMessageDeleteBackup: (state) =>
        state.settings.errorMessageDeleteBackup,
      errorMessageDownloadBackup: (state) =>
        state.settings.errorMessageDownloadBackup,
      isPendingBackupList: (state) => state.settings.isPendingBackupList,
      isFailureBackupList: (state) => state.settings.isFailureBackupList,
      errorMessageBackupList: (state) => state.settings.errorMessageBackupList,
      errorMessageStatus: (state) => state.settings.errorMessageStatus,
      errorMessageSaveSettingsData: (state) =>
        state.settings.errorMessageSaveSettingsData,
    }),
  },

  mounted() {
    this.getSettings().then(() => {
      this.settings = this.setSettings;
      this.load();
    });
  },

  created() {
    this.load();

    this.$root.$on("importusers", (item) => {
      this.importusers(item);
    });
    this.$root.$on("restore", (item) => {
      this.onRestoreEvent(item);
    });
    this.$root.$on("backup", (item) => {
      this.onBackupEvent(item);
    });
    this.$root.$on("backupLog", (item) => {
      this.onBackupLogEvent(item);
    });

    this.getCompanies();
    this.getBackupList();
    this.getBackupStatus();
  },

  destroyed() {
    this.$root.$off("importusers");
    this.$root.$off("restore");
    this.$root.$off("backup");
    this.$root.$off("backupLog");
  },

  updated() {
    this.lockimport = false;
  },
};
</script>
