import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const limit = window.localStorage.getItem("departmentsLimit") || 30;

const state = {
  isPending: false,
  isSuccess: false,
  isFailure: false,
  errorMessage: "",

  list: [],
  tree: [],

  limit: parseInt(limit),
  pagination: {
    current_page: 1,
    per_page: 30,
    total: 0,
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
