<template>
  <v-card class="mx-auto" outlined color="#F3F2FF">
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <v-switch
            v-model="isTimes"
            :label="$t('access-levels.times')"
            @change="submit"
          />
        </v-col>
        <v-col cols="6" class="d-flex align-center justify-end">
          <v-row>
            <v-col
              v-for="item in timeList"
              :key="item.id"
              class="d-flex align-center justify-end"
              cols="12"
            >
              <Time
                :value="item"
                :disabled="!isTimes"
                @input="onChangeTime"
                @cancel="onCancel"
              />
            </v-col>
            <v-col
              v-if="timeList.length < 3"
              class="d-flex align-center justify-end"
            >
              <v-btn color="primary" text @click="addTime" :disabled="!isTimes">
                <v-icon left> mdi-plus </v-icon>
                {{ $t("access-levels.addAccessTime") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-switch
            v-model="isDates"
            :label="$t('access-levels.dates')"
            @change="submit"
          />
        </v-col>
        <v-col cols="6" class="d-flex align-center justify-end">
          <date-picker
            v-model="dateRange"
            type="date"
            format="DD.MM.YYYY"
            value-type="format"
            range
            confirm
            :disabled="!isDates"
            @confirm="submit"
            @clear="submit"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="6">
          <v-switch
            v-model="isWeekdays"
            :label="$t('access-levels.weekdays')"
            @change="submit"
          />
        </v-col>
        <v-col cols="6" class="d-flex align-center justify-end">
          <v-btn-toggle
            v-model="weekdays"
            multiple
            color="primary"
            @change="submit"
          >
            <v-btn :disabled="!isWeekdays">
              {{ $t("dtpicker.Week_1") }}
            </v-btn>
            <v-btn :disabled="!isWeekdays">
              {{ $t("dtpicker.Week_2") }}
            </v-btn>
            <v-btn :disabled="!isWeekdays">
              {{ $t("dtpicker.Week_3") }}
            </v-btn>
            <v-btn :disabled="!isWeekdays">
              {{ $t("dtpicker.Week_4") }}
            </v-btn>
            <v-btn :disabled="!isWeekdays">
              {{ $t("dtpicker.Week_5") }}
            </v-btn>
            <v-btn :disabled="!isWeekdays">
              {{ $t("dtpicker.Week_6") }}
            </v-btn>
            <v-btn :disabled="!isWeekdays">
              {{ $t("dtpicker.Week_0") }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer />

      <v-btn color="red" dark @click="cancel">
        <v-icon left>mdi-close</v-icon>
        {{ $t("button.cancel") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Time from "./Time";

export default {
  name: "AccessByTimeItem",

  components: {
    Time,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    let timeList = [];

    if (this.data.time_list && this.data.time_list.length) {
      timeList = this.data.time_list.map((time, index) => ({
        id: index + 1,
        ...time,
      }));
    }

    return {
      isWeekdays: this.data.weekdays ? true : false,
      isDates: this.data.date_from ? true : false,
      isTimes: this.data.time_list && this.data.time_list.length ? true : false,

      id: this.data.id,
      weekdays: this.weekdaysToArray(this.data.weekdays),
      dateRange: this.dateRangeToArray(this.data.date_from, this.data.date_to),
      timeList,
    };
  },

  methods: {
    cancel() {
      this.$emit("cancel", this.id);
    },

    weekdaysToArray(weekdays) {
      let weekdaysArr = [0, 1, 2, 3, 4];

      if (weekdays) {
        weekdaysArr = weekdays
          .split("")
          .map((day, index) => {
            if (day === "1") {
              return index;
            }
          })
          .filter((day) => day !== undefined);
      }

      return weekdaysArr;
    },

    dateRangeToArray(dateFrom, dateTo) {
      let range = ["", ""];

      if (dateFrom && dateTo) {
        range = [
          this.$moment(dateFrom, "YYYY-MM-DD").format("DD.MM.YYYY"),
          this.$moment(dateTo, "YYYY-MM-DD").format("DD.MM.YYYY"),
        ];
      }

      return range;
    },

    weekdaysToString(weekdays) {
      let weekdaysString = "";

      for (let i = 0; i < 7; i++) {
        if (weekdays.includes(i)) {
          weekdaysString += "1";
        } else {
          weekdaysString += "0";
        }
      }

      return weekdaysString;
    },

    addTime() {
      let id = 1;
      if (this.timeList.length > 0) {
        id = this.timeList[this.timeList.length - 1].id + 1;
      }

      this.timeList.push({
        id,
        time_from: null,
        time_to: null,
      });

      this.submit();
    },

    onChangeTime(data) {
      const index = this.timeList.findIndex((p) => p.id === data.id);
      this.timeList.splice(index, 1, data);

      this.submit();
    },

    onCancel(id) {
      this.timeList = this.timeList.filter((p) => p.id !== id);

      this.submit();
    },

    submit() {
      const data = { id: this.id };

      if (this.isWeekdays) {
        data.weekdays = this.weekdaysToString(this.weekdays);
      }

      if (this.isDates) {
        if (this.dateRange[0]) {
          data.date_from = this.$moment(
            this.dateRange[0].split(" ")[0],
            "DD.MM.YYYY"
          ).format("YYYY-MM-DD");
        }

        if (this.dateRange[1]) {
          data.date_to = this.$moment(
            this.dateRange[1].split(" ")[0],
            "DD.MM.YYYY"
          ).format("YYYY-MM-DD");
        }
      }

      if (this.isTimes) {
        data.time_list = this.timeList;
      }

      this.$emit("change", data);
    },
  },
};
</script>
