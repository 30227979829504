<template>
  <div class="mt-5">
    <v-row v-if="errorMessageDownloadAllLog">
      <v-col>
        <Alert type="error">
          {{ errorMessageDownloadAllLog }}
        </Alert>
      </v-col>
    </v-row>

    <v-row v-if="errorMessageGetLog">
      <v-col>
        <Alert type="error">
          {{ errorMessageGetLog }}
        </Alert>
      </v-col>
    </v-row>

    <v-row v-if="errorMessageGetUserLog">
      <v-col>
        <Alert type="error">
          {{ errorMessageGetUserLog }}
        </Alert>
      </v-col>
    </v-row>

    <v-row v-if="errorMessageDownloadAllUserLog">
      <v-col>
        <Alert type="error">
          {{ errorMessageDownloadAllUserLog }}
        </Alert>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h3>{{ $t("setting.log") }}</h3>
      </v-col>
      <v-col cols="12">
        <v-textarea
          outlined
          class="form-control"
          rows="15"
          v-model="log"
          readonly
        />
      </v-col>
    </v-row>

    <v-row justify="end">
      <v-col cols="12" md="auto">
        <v-btn color="success" @click="downloadAllLog">
          {{ $t("setting.download_logs") }}
        </v-btn>
      </v-col>
      <v-col cols="12" md="auto">
        <v-text-field
          v-model="maxsize"
          type="number"
          max="20000"
          min="1000"
          width="100"
          outlined
          required
          dense
          hide-details
        />
      </v-col>
      <v-col cols="12" md="auto">
        <v-btn color="primary" @click="getLog">
          {{ $t("setting.log_reload") }}
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <h3>{{ $t("setting.user_log") }}</h3>
      </v-col>
      <v-col cols="12">
        <v-textarea
          outlined
          class="form-control"
          rows="15"
          v-model="userLog"
          readonly
        />
      </v-col>
    </v-row>

    <v-row justify="end">
      <v-col cols="12" md="auto">
        <v-btn color="success" @click="getLogAllUser">
          {{ $t("setting.download_logs_user") }}
        </v-btn>
      </v-col>
      <v-col cols="12" md="auto">
        <v-text-field
          v-model="maxsizeUser"
          type="number"
          max="20000"
          min="1000"
          width="100"
          outlined
          required
          dense
          hide-details
        />
      </v-col>
      <v-col cols="12" md="auto">
        <v-btn color="primary" @click="getUserLog">
          {{ $t("setting.user_log_reload") }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";

import Alert from "@/components/Alert";

export default {
  components: {
    Alert,
  },

  data() {
    return {
      maxsize: 1500,
      maxsizeUser: 1500,
    };
  },
  methods: {
    ...mapActions({
      downloadAllLog: "system/downloadAllLog",
      updateLog: "system/getLog",
      updateUserLog: "system/getUserLog",
      downloadAllUserLog: "system/downloadAllUserLog",
    }),
    getLog() {
      this.updateLog(this.maxsize);
    },
    getUserLog() {
      this.updateUserLog(this.maxsizeUser);
    },
    getLogAllUser() {
      this.downloadAllUserLog(this.maxsizeUser);
    },
  },
  computed: {
    ...mapState({
      log: (state) => state.system.log,
      userLog: (state) => state.system.userLog,
      errorMessageDownloadAllLog: (state) =>
        state.system.errorMessageDownloadAllLog,
      errorMessageGetLog: (state) => state.system.errorMessageGetLog,
      errorMessageGetUserLog: (state) => state.system.errorMessageGetUserLog,
      errorMessageDownloadAllUserLog: (state) =>
        state.system.errorMessageDownloadAllUserLog,
    }),
  },
  mounted() {
    this.getLog();
    this.getUserLog();
  },
};
</script>
