<template>
  <div v-if="loading" class="device-edit-page px-0 py-0">
    <v-card elevation="2">
      <v-card-text>
        <v-row
          align="center"
          :style="{
            marginBottom: isEdit ? '12px' : '0',
          }"
        >
          <v-col>
            <v-row>
              <v-col cols="12" sm="6">
                <template v-if="!isEdit || isNameEdit">
                  <v-row align="center">
                    <v-col cols="12">
                      <v-text-field
                        v-model="name"
                        :rules="nameRules"
                        :label="`${$t('devices.nameDevice')} *`"
                        outlined
                        required
                        hide-details
                        :append-icon="isEdit ? 'mdi-check-bold' : ''"
                        @click:append="isNameEdit = !isNameEdit"
                      />
                    </v-col>
                  </v-row>
                </template>
                <template v-else>
                  <v-row align="center">
                    <v-col cols="auto">
                      <h4 class="text-h4" style="color: #7165ff !important">
                        {{ name }}
                      </h4>
                    </v-col>
                    <v-col cols="2">
                      <v-btn
                        v-if="isEdit"
                        @click="isNameEdit = !isNameEdit"
                        icon
                        color="indigo"
                      >
                        <v-icon> mdi-pencil </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
                <v-row
                  :style="{
                    marginTop: isEdit ? '-20px' : '12px',
                  }"
                >
                  <v-col cols="12">
                    <v-text-field
                      v-if="!isEdit"
                      class="mt-2"
                      v-model="ipAddress"
                      :rules="ipRules"
                      :label="`${$t('devices.ipDevice')} *`"
                      outlined
                      required
                    />
                    <h5 class="text-subtitle-1" v-else>IP: {{ ipAddress }}</h5>
                  </v-col>
                </v-row>
              </v-col>
              <v-spacer />
              <v-col cols="auto">
                <v-btn color="primary" @click="goToPanel">
                  {{ $t("devices.goToPanel") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row v-if="errorMessage">
            <v-col>
              <Alert type="error">
                {{ errorMessage }}
              </Alert>
            </v-col>
          </v-row>

          <v-row v-if="cardReader !== undefined">
            <v-col>
              <v-switch
                v-model="cardReader"
                :label="$t('devices.cardReader')"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <DeviceOgate
                v-if="deviceType == 'OGATE'"
                v-model="data"
                :channels="channels"
                :purpose="channels[0].purpose"
                :uid="uid"
                @change-channels="channels = $event"
              />
              <DeviceUface
                v-if="deviceType == 'UFACE'"
                v-model="data"
                :channels="channels"
                :isEdit="id ? true : false"
                @change-channels="channels = $event"
              />
              <DeviceContr
                v-if="deviceType == 'CONTRL'"
                v-model="data"
                :channels="channels"
                :id="id"
                :isEdit="id ? true : false"
                :ipAddress="ipAddress"
                @change-ip-address="ipAddress = ipAddressFixed = $event"
                @change-channels="channels = $event"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="auto">
              <v-btn @click.prevent="cancel">
                <v-icon left>mdi-cancel</v-icon>
                {{ $t("button.cancel") }}
              </v-btn>
            </v-col>
            <v-spacer />
            <v-col v-if="isEdit" cols="12" sm="auto">
              <v-btn color="error" @click.prevent="remove">
                {{ $t("button.delete") }}
              </v-btn>
            </v-col>
            <v-col cols="6" sm="auto">
              <v-btn
                color="primary"
                :loading="isPending"
                :disabled="isPending"
                @click.prevent="store(false)"
              >
                {{ $t("button.save") }}
              </v-btn>
            </v-col>
            <v-col v-if="isEdit" cols="6" sm="auto">
              <v-btn
                color="primary"
                :loading="isPending"
                :disabled="isPending"
                @click.prevent="store(true)"
              >
                {{ $t("button.apply") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-snackbar v-model="isShowSuccessToast" :timeout="2000" color="success">
      {{ $t("message.saveSuccess") }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="isShowSuccessToast = false">
          {{ $t("button.close") }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapState } from "vuex";
import { isIP } from "validator";

import DeviceOgate from "./components/ogate/deviceOgate";
import DeviceUface from "./components/uface/deviceUface";
import DeviceContr from "./components/contr/deviceContr";
import Alert from "@/components/Alert";

export default {
  components: {
    DeviceOgate,
    DeviceUface,
    DeviceContr,
    Alert,
  },

  data() {
    const deviceType = this.$route.query.device;
    const { device, id, query } = this.$route.params;

    let data = null;

    if (device?.mac) {
      data = {
        mac_code: device.mac,
      };
    }

    return {
      isPending: false,
      isSuccess: false,
      isFailure: false,

      valid: true,

      isShowSuccessToast: false,
      isNameEdit: id ? false : true,
      data,
      uid: null,
      pdlg: false,
      loading: false,
      channels: [
        {
          channel: 0,
          purpose: "entrance",
          zones_id: null,
          source_id: null,
          enable: true,
        },
      ],
      id: id || 0,
      cardReader: undefined,
      name: device?.ip || "",
      ipAddress: device?.ip || "192.168.0.100",
      ipAddressFixed: "",
      enabled: true,
      mltimeout: 0,
      nameRules: [
        (v) => !!v || this.$t("message.NameIsRequired"),
        (v) =>
          v.length <= 20 || this.$t("message.lengthIsLarge", { length: 20 }),
      ],
      ipRules: [(v) => v === "localhost" || isIP(v) || this.$t("message.isIP")],
      errorMessage: "",

      deviceType,

      query,
    };
  },

  watch: {
    async zonesId() {
      await this.$nextTick();
      this.$refs.sources.validate();
    },
  },

  methods: {
    cancel() {
      this.$router.push({
        name: this.$const.ROUTES.DEVICES,
        query: this.query,
      });
    },

    remove() {
      this.$root.$prompt({
        title: this.$t("message.Delete"),
        message: this.$t("message.confirmDelete"),
        btnAgree: this.$t("button.delete"),

        agree: () => {
          this.axios
            .delete(`device/${this.id}`)
            .then(() => {
              this.$router.push({
                name: this.$const.ROUTES.DEVICES,
                query: this.query,
              });
            })
            .catch((error) => {
              this.errorMessage = error.response.data.status.message;

              this.$notify({
                group: "info",
                type: "error",
                text: error?.response?.data?.status?.message,
              });
            });
        },
      });
    },
    store(isApply) {
      if (this.$refs.form.validate()) {
        this.errorMessage = "";
        this.isPending = true;
        this.isSuccess = false;
        this.isFailure = false;

        const params = {
          timestamp: moment().unix(),
          name: this.name,
          ip_address: this.ipAddress,
          enabled: this.enabled,
          device_type: this.deviceType,
          channels: this.channels,
          synchronize: this.id ? true : false,
          data: JSON.stringify({
            ...this.data,
            card_reader: this.cardReader,
          }),
        };

        if (!this.id) {
          this.axios
            .post("devices/add", params)
            .then((response) => {
              if (isApply) {
                this.$router.push({
                  name: this.$const.ROUTES.EDIT_DEVICE,
                  params: { id: response.data.data.id, query: this.query },
                });
              } else {
                this.$router.push({
                  name: this.$const.ROUTES.DEVICES,
                  query: this.query,
                });
              }

              this.isPending = false;
              this.isSuccess = true;
              this.isFailure = false;
            })
            .catch((error) => {
              this.errorMessage = error.response.data.status.message;
              this.isPending = false;
              this.isSuccess = false;
              this.isFailure = true;

              this.$notify({
                group: "info",
                type: "error",
                text: error?.response?.data?.status?.message,
              });
            });
        } else {
          this.axios
            .put(`device/${this.id}`, params)
            .then(() => {
              if (isApply) {
                this.isShowSuccessToast = true;
              } else {
                this.$router.push({
                  name: this.$const.ROUTES.DEVICES,
                  query: this.query,
                });
              }

              this.isPending = false;
              this.isSuccess = true;
              this.isFailure = false;
            })
            .catch((error) => {
              this.errorMessage = error.response.data.status.message;
              this.isPending = false;
              this.isSuccess = false;
              this.isFailure = true;

              this.$notify({
                group: "info",
                type: "error",
                text: error?.response?.data?.status?.message,
              });
            });
        }
      }
    },

    getDevice() {
      if (!this.isEdit) return;

      this.axios(`device/${this.id}`).then((response) => {
        let item = response.data.data;
        this.uid = item.uid;
        this.cardReader = JSON.parse(item.data).card_reader;
        this.name = item.name;
        this.deviceType = item.device_type || "OGATE";
        this.channels = item.channels ? item.channels : this.channels;
        this.ipAddress = this.ipAddressFixed = item.ip_address;
        this.enabled = item.enabled;
        if (item.data != "") {
          this.data = JSON.parse(item.data);
        }
      });
    },
    getChannels() {
      const channels = [];

      const defaultParams = {
        channel: 0,
        enable: true,
        purpose: "entrance",
        zones_id: 1,
        source_id: null,
      };

      const additionalParams = {
        name: "",
        is_fixed_pass: true,
        relay_id: 1,
      };

      switch (this.deviceType) {
        case "CONTRL":
          for (let i = 0; i < 4; i++) {
            channels.push({
              ...defaultParams,
              ...additionalParams,
              channel: i,
              enable: i === 0 ? true : false,
              relay_id: i === 0 || i === 1 ? 1 : 2,
            });
          }
          break;

        default:
          channels.push({
            ...defaultParams,
          });
          break;
      }

      this.channels = channels;
    },

    goToPanel() {
      window.open(`http://${this.ipAddressFixed}:4011`, "_blank");
    },
  },

  computed: {
    ...mapState({
      l: (state) => state.settings.data,
    }),

    isEdit() {
      return this.id > 0;
    },
  },

  created() {
    this.getChannels();
    this.getDevice();
    this.loading = true;
  },
};
</script>
