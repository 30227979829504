<template>
  <v-text-field
    :value="value"
    :label="`${$t('permits.number')} *`"
    dense
    outlined
    :append-icon="value ? 'mdi-qrcode' : 'mdi-autorenew'"
    :error-messages="errorMessages"
    @click:append="clickAppend"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
  >
    <template v-slot:message="{ message }">
      <span v-html="message" />
    </template>
  </v-text-field>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    clickAppend() {
      if (this.value) {
        this.openQrCodeDialog();
      } else {
        this.generateQRCode();
      }
    },

    openQrCodeDialog() {
      this.$emit("openQrCodeDialog", this.value);
    },

    generateQRCode() {
      this.$emit("generateQRCode", this.value);
    },
  },
};
</script>
