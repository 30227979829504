import { mapState, mapGetters } from "vuex";

export default {
  methods: {
    can(...key) {
      if (this.user?.is_admin) {
        return true;
      } else {
        return key.some((k) => this.role.permissions.includes(k));
      }
    },
  },

  computed: {
    ...mapState({
      user: (state) => state.user.data,
    }),

    ...mapGetters({
      role: "user/getRole",
    }),
  },
};
