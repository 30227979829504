<template>
  <v-col>
    <v-tabs v-model="settingTab" background-color="grey lighten-3 mb-5">
      <v-tab>{{ $t("devices.mainParameters") }}</v-tab>
      <v-tab>{{ $t("devices.recognitionParameters") }}</v-tab>
      <v-tab>{{ $t("devices.recognitionSettings") }}</v-tab>
      <v-tab>{{ $t("devices.other") }}</v-tab>
      <v-tab>{{ $t("devices.hard") }}</v-tab>
    </v-tabs>

    <v-tabs-items v-model="settingTab">
      <!-- Main -->
      <v-tab-item>
        <v-row>
          <v-col cols="12">
            <v-subheader>{{ $t("devices.delayTimeForCloseDoor") }}</v-subheader>
            <v-slider
              v-model="delayTimeForCloseDoor"
              class="align-center"
              max="25500"
              min="100"
              hide-details
              @change="inputValue"
            >
              <template v-slot:append>
                <v-text-field
                  v-model="delayTimeForCloseDoor"
                  hide-details
                  max="25500"
                  min="100"
                  step="1"
                  type="number"
                  style="width: 100px"
                  outlined
                  @change="inputValue"
                />
              </template>
            </v-slider>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-select
              v-model="identifyDistance"
              :items="identifyDistanceOptions"
              :label="$t('devices.identifyDistance')"
              outlined
              hide-details
              @change="inputValue"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-select
              v-model="recDisplayImageMode"
              :items="recDisplayImageModeOptions"
              :label="$t('devices.recDisplayImageMode')"
              outlined
              hide-details
              @change="inputValue"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-switch
              v-model="recRank"
              :label="$t('devices.recRank')"
              hide-details
              @change="inputValue"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-switch
              v-model="isMaskOpen"
              :label="$t('devices.isMaskOpen')"
              hide-details
              @change="inputValue"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-switch
              v-model="isVoiceOpen"
              :label="$t('devices.isVoiceOpen')"
              hide-details
              @change="inputValue"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-switch
              v-model="asReader"
              :label="$t('devices.readerMode')"
              hide-details
              @change="inputValue"
            />
          </v-col>
        </v-row>

        <!--h4 class="mt-5">{{ $t("devices.savePhoto") }}</h4>
            <v-row>
              <v-col cols="12" md="4">
                <v-switch
                  v-model="recSucSaveSpotImage"
                  :label="$t('devices.recSucSaveSpotImage')"
                  @change="inputValue"
                />
              </v-col>

              <v-col cols="12" md="4">
                <v-switch
                  v-model="recFailSaveSpotImage"
                  :label="$t('devices.recFailSaveSpotImage')"
                  @change="inputValue"
                />
              </v-col>

              <v-col cols="12" md="4">
                <v-switch
                  v-model="recNoPerSaveSpotImage"
                  :label="$t('devices.recNoPerSaveSpotImage')"
                  @change="inputValue"
                />
              </v-col>
            </v-row-->
      </v-tab-item>

      <!-- Recognition -->
      <v-tab-item>
        <v-row>
          <v-col cols="12" md="4">
            <v-switch
              v-model="recModeFaceEnable"
              :label="$t('devices.recModeFaceEnable')"
              @change="inputValue"
            />
          </v-col>

          <v-col cols="12" md="4">
            <v-switch
              v-model="recModeCardEnable"
              :label="$t('devices.recModeCardEnable')"
              @change="inputValue"
            />
          </v-col>

          <v-col cols="12" md="4">
            <v-switch
              v-model="recModeCardFaceEnable"
              :label="$t('devices.recModeCardFaceEnable')"
              @change="inputValue"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-slider
              v-model="identifyScores"
              class="align-center"
              max="100"
              min="50"
              :label="$t('devices.identifyScores')"
              hide-details
              @change="inputValue"
            >
              <template v-slot:append>
                <v-text-field
                  v-model="identifyScores"
                  hide-details
                  max="100"
                  min="50"
                  step="1"
                  type="number"
                  style="width: 100px"
                  outlined
                  @change="inputValue"
                />
              </template>
            </v-slider>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-select
              v-model="multiplayerDetection"
              :items="multiplayerDetectionOptions"
              :label="$t('devices.multiplayerDetection')"
              outlined
              @change="inputValue"
            />
          </v-col>
        </v-row>
      </v-tab-item>

      <!-- Settings -->
      <v-tab-item>
        <v-row>
          <v-col cols="12" md="6">
            <h4 class="mb-5">{{ $t("devices.onSucRec") }}</h4>
            <v-select
              v-model="voiceType"
              :items="voiceTypeOptions"
              :label="$t('devices.voiceType')"
              outlined
              @change="inputValue"
            />

            <v-select
              v-model="comModType"
              :items="comModTypeOptions"
              :label="$t('devices.comModType')"
              outlined
              @change="inputValue"
            />

            <v-select
              v-model="recSucWiegandType"
              :items="recWiegandTypeOptions"
              :label="$t('devices.recSucWiegandType')"
              outlined
              @change="inputValue"
            />
            <div v-if="recSucWiegandType != 1">
              <v-select
                v-model="recSucWiegandContentType"
                :items="recSucWiegandContentTypeOptions"
                :label="$t('devices.recSucWiegandContent')"
                outlined
                @change="onChangeRecSucWiegandContentType"
              />

              <v-text-field
                v-if="recSucWiegandContentType === 'custom'"
                v-model="recSucWiegandContent"
                :label="$t('devices.custom')"
                outlined
                :rules="recSucWiegandContentRules"
                @change="inputValue"
              />
            </div>
            <v-select
              v-model="isOpenRelay"
              :items="relayOptions"
              :label="$t('devices.isOpenRelay')"
              outlined
              @change="inputValue"
            />
          </v-col>

          <v-col cols="12" md="6">
            <h4 class="mb-5">{{ $t("devices.onFailRec") }}</h4>
            <!--v-switch
                  v-model="recStrangerType"
                  :label="$t('devices.recStrangerType')"
                  @change="inputValue"
                /-->

            <v-select
              v-model="relaySwitch"
              :items="relayOptions"
              :label="$t('devices.relaySwitch')"
              outlined
              @change="inputValue"
            />

            <v-subheader>{{
              $t("devices.recStrangerTimesThreshold")
            }}</v-subheader>
            <v-slider
              v-model="recStrangerTimesThreshold"
              class="align-center"
              max="20"
              min="1"
              hide-details
              @change="inputValue"
            >
              <template v-slot:append>
                <v-text-field
                  v-model="recStrangerTimesThreshold"
                  hide-details
                  max="20"
                  min="1"
                  step="1"
                  type="number"
                  style="width: 100px"
                  outlined
                  @change="inputValue"
                />
              </template>
            </v-slider>
          </v-col>
        </v-row>
      </v-tab-item>

      <!-- Other -->
      <v-tab-item>
        <v-row>
          <v-col>
            <v-text-field
              v-model="companyName"
              :label="$t('devices.companyName')"
              outlined
              @change="inputValue"
            />

            <v-select
              v-model="wgInputType"
              :items="wgInputTypeOptions"
              :label="$t('devices.wgInputType')"
              outlined
              @change="inputValue"
            />

            <v-select
              v-model="serialOutMode"
              :items="serialOutModeOptions"
              :label="$t('devices.serialOutMode')"
              outlined
              @change="inputValue"
            />

            <v-select
              v-model="recModeCardIntf"
              :items="recModeCardIntfOptions"
              :label="$t('devices.recModeCardIntf')"
              outlined
              @change="inputValue"
            />

            <v-select
              v-model="recModeCardFaceIntf"
              :items="recModeCardIntfOptions"
              :label="$t('devices.recModeCardFaceIntf')"
              outlined
              @change="inputValue"
            />

            <v-select
              v-model="recFailWiegandType"
              :items="recWiegandTypeOptions"
              :label="$t('devices.recFailWiegandType')"
              outlined
              @change="inputValue"
            />

            <v-select
              v-model="recNoPerWiegandType"
              :items="recWiegandTypeOptions"
              :label="$t('devices.recNoPerWiegandType')"
              outlined
              @change="inputValue"
            />

            <v-switch
              v-model="recModeCardPhoto"
              :label="$t('devices.recModeCardPhoto')"
              @change="inputValue"
            />

            <v-switch
              v-model="uniquenessRegImage"
              :label="$t('devices.uniquenessRegImage')"
              @change="inputValue"
            />
          </v-col>
        </v-row>
      </v-tab-item>

      <!-- System -->
      <v-tab-item>
        <v-row>
          <v-col cols="12" md="6">
            <v-subheader>{{ $t("devices.audioVolume") }}</v-subheader>
            <v-slider
              v-model="audioVolume"
              class="align-center"
              max="100"
              min="1"
              hide-details
              @change="inputValue"
            >
              <template v-slot:append>
                <v-text-field
                  v-model="audioVolume"
                  hide-details
                  max="100"
                  min="1"
                  step="1"
                  type="number"
                  style="width: 100px"
                  outlined
                  @change="inputValue"
                />
              </template>
            </v-slider>
          </v-col>

          <v-col cols="12" md="6">
            <v-subheader>{{ $t("devices.lightBrightness") }}</v-subheader>
            <v-slider
              v-model="lightBrightness"
              class="align-center"
              max="100"
              min="1"
              hide-details
              @change="inputValue"
            >
              <template v-slot:append>
                <v-text-field
                  v-model="lightBrightness"
                  hide-details
                  max="100"
                  min="1"
                  step="1"
                  type="number"
                  style="width: 100px"
                  outlined
                  @change="inputValue"
                />
              </template>
            </v-slider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-switch
              v-model="antiTamper"
              :label="$t('devices.antiTamper')"
              @change="inputValue"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              v-model="language"
              :items="languageOptions"
              :label="$t('setting.lang')"
              outlined
              hide-details
              @change="inputValue"
            />
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </v-col>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      settingTab: null,

      language: this.value?.language || "ru",

      // Main
      asReader: this.value?.as_reader,
      recRank: this.value?.config?.recRank === 1 ? false : true,
      identifyDistance: this.value?.config?.identifyDistance || 2,
      delayTimeForCloseDoor: this.value?.config?.delayTimeForCloseDoor || 500,
      recDisplayImageMode: this.value?.config?.recDisplayImageMode || 1,
      isMaskOpen: this.value?.config?.mask?.isMaskOpen || false,
      isVoiceOpen: this.value?.config?.mask?.isVoiceOpen || false,
      // recFailSaveSpotImage: this.formatToBoolean(
      //   this.value?.config?.recFailSaveSpotImage
      // ),
      // recNoPerSaveSpotImage: this.formatToBoolean(
      //   this.value?.config?.recNoPerSaveSpotImage
      // ),
      // recSucSaveSpotImage: this.formatToBoolean(
      //   this.value?.config?.recSucSaveSpotImage
      // ),

      // Recognition
      recModeFaceEnable: this.formatToBoolean(
        this.value?.config?.recModeFaceEnable
      ),
      recModeCardEnable: this.formatToBoolean(
        this.value?.config?.recModeCardEnable
      ),
      recModeCardFaceEnable: this.formatToBoolean(
        this.value?.config?.recModeCardFaceEnable
      ),

      identifyScores: this.value?.config?.identifyScores || 80,
      multiplayerDetection: this.value?.config?.multiplayerDetection || 1,

      // Settings
      voiceType: this.value?.config?.voiceType || 1,
      recSucDisplayText2Content:
        this.value?.config?.recSucDisplayText2Content || "",
      isOpenRelay: this.value?.config?.isOpenRelay || 1,
      recSucWiegandType: this.value?.config?.recSucWiegandType || 1,
      comModType: this.value?.config?.comModType || 1,
      //recStrangerType: this.formatToBoolean(this.value?.config?.recStrangerType),
      recStrangerTimesThreshold:
        this.value?.config?.recStrangerTimesThreshold || 3,
      relaySwitch: this.value.config?.relaySwitch || 2,
      recSucWiegandContentType:
        this.value?.config?.recSucWiegandContent === "{idcardNum}"
          ? "device"
          : "custom",
      recSucWiegandContentTypeOptions: [
        {
          text: this.$t("devices.valueFromDevice"),
          value: "device",
        },
        {
          text: this.$t("devices.custom"),
          value: "custom",
        },
      ],
      recSucWiegandContent: this.value?.config?.recSucWiegandContent,

      // Other
      companyName: this.value?.config?.companyName || "",
      wgInputType: this.value?.config?.wgInputType || 1,
      serialOutMode: this.value?.config?.serialOutMode || 1,
      recModeCardIntf: this.value?.config?.recModeCardIntf || 1,
      recModeCardFaceIntf: this.value?.config?.recModeCardFaceIntf || 1,
      recModeCardPhoto: this.formatToBoolean(
        this.value?.config?.recModeCardPhoto
      ),
      recNoPerRelayType: 2,
      recFailWiegandType: this.value?.config?.recFailWiegandType || 1,
      uniquenessRegImage: this.formatToBoolean(
        this.value?.config?.uniquenessRegImage
      ),
      recNoPerWiegandType: this.value?.config?.recNoPerWiegandType || 1,

      // System
      audioVolume: this.value?.hard_config?.audioVolume || 1,
      lightBrightness: this.value?.hard_config?.lightBrightness || 100,
      antiTamper: this.formatToBoolean(
        this.value?.hard_config?.antiTamper || 2
      ),

      // Options
      // Main
      languageOptions: [
        {
          text: "English",
          value: "en",
        },
        {
          text: "Русский",
          value: "ru",
        },
      ],

      identifyDistanceOptions: [
        {
          text: this.$t("devices.noLimit"),
          value: 0,
        },
        {
          text: this.$t("devices.within05m"),
          value: 1,
        },
        {
          text: this.$t("devices.within1m"),
          value: 2,
        },
        {
          text: this.$t("devices.within15m"),
          value: 3,
        },
        {
          text: this.$t("devices.within2m"),
          value: 4,
        },
        {
          text: this.$t("devices.within3m"),
          value: 5,
        },
        {
          text: this.$t("devices.within4m"),
          value: 6,
        },
      ],
      recDisplayImageModeOptions: [
        {
          text: this.$t("devices.displayTakePhoto"),
          value: 1,
        },
        {
          text: this.$t("devices.displaySavePhoto"),
          value: 2,
        },
        {
          text: this.$t("devices.notDisplayPhoto"),
          value: 3,
        },
      ],

      // Recognition
      multiplayerDetectionOptions: [
        {
          text: this.$t("devices.multiPeople"),
          value: 1,
        },
        {
          text: this.$t("devices.singlePerson"),
          value: 2,
        },
      ],

      // Settings
      voiceTypeOptions: [
        {
          text: this.$t("devices.maleVoice"),
          value: 1,
        },
        {
          text: this.$t("devices.femaleVoice"),
          value: 2,
        },
      ],
      recWiegandTypeOptions: [
        {
          text: this.$t("devices.notOutput"),
          value: 1,
        },
        {
          text: "Wiegand 26",
          value: 2,
        },
        {
          text: "Wiegand 34",
          value: 3,
        },
        {
          text: "Wiegand 50",
          value: 4,
        },
        {
          text: "Wiegand 66",
          value: 5,
        },
      ],
      comModTypeOptions: [
        {
          text: this.$t("devices.openTheDoor"),
          value: 1,
        },
        {
          text: this.$t("devices.notOutput"),
          value: 2,
        },
        {
          text: this.$t("devices.outputPersonId"),
          value: 3,
        },
        {
          text: this.$t("devices.outputIdCardNum"),
          value: 4,
        },
        {
          text: this.$t("devices.outputPhone"),
          value: 5,
        },
      ],

      // Other
      relayOptions: [
        {
          text: this.$t("devices.output"),
          value: 1,
        },
        {
          text: this.$t("devices.notOutput"),
          value: 2,
        },
      ],
      wgInputTypeOptions: [
        {
          text: "wg26 format 1",
          value: 1,
        },
        {
          text: "wg26 format 2",
          value: 2,
        },
        {
          text: "wg34",
          value: 3,
        },
        {
          text: "wg50",
          value: 4,
        },
        {
          text: "wg66",
          value: 5,
        },
      ],
      serialOutModeOptions: [
        {
          text: "Open the door",
          value: 1,
        },
        {
          text: "Custom",
          value: 2,
        },
        {
          text: "Close",
          value: 3,
        },
      ],
      recModeCardIntfOptions: [
        {
          text: this.$t("devices.ttlSerialPort"),
          value: 1,
        },
        {
          text: this.$t("devices.serialPort232"),
          value: 2,
        },
        {
          text: this.$t("devices.usbSerialPort"),
          value: 3,
        },
        {
          text: this.$t("devices.i2cSerialPort"),
          value: 4,
        },
        {
          text: this.$t("devices.wiegandInput"),
          value: 5,
        },
      ],
      recSucWiegandContentRules: [
        (v) => {
          if (v && v.length <= 12) {
            return /^\d+$/.test(v) || this.$t("message.invalidValue");
          } else {
            return /^[a-fA-F0-9]*$/.test(v) || this.$t("message.invalidValue");
          }
        },
      ],
    };
  },

  computed: {
    ...mapState({
      settings: (state) => state.settings.data,
    }),
  },

  methods: {
    inputValue() {
      const {
        language,
        // Main
        asReader,
        recRank,
        identifyDistance,
        delayTimeForCloseDoor,
        recDisplayImageMode,
        isMaskOpen,
        isVoiceOpen,
        //recFailSaveSpotImage,
        //recSucSaveSpotImage,
        //recNoPerSaveSpotImage,

        // Recognition
        recModeFaceEnable,
        recModeCardEnable,
        recModeCardFaceEnable,

        identifyScores,
        multiplayerDetection,

        // Settings
        voiceType,
        recSucDisplayText2Content,
        isOpenRelay,
        recSucWiegandType,
        recSucWiegandContent,
        comModType,
        //recStrangerType,
        recStrangerTimesThreshold,
        relaySwitch,

        // Other
        companyName,
        wgInputType,
        serialOutMode,
        recModeCardIntf,
        recModeCardFaceIntf,
        recModeCardPhoto,
        recNoPerRelayType,
        recFailWiegandType,
        uniquenessRegImage,
        recNoPerWiegandType,

        // System
        audioVolume,
        lightBrightness,
        antiTamper,
      } = this;

      const data = {
        language,
        as_reader: asReader,
        hard_config: {
          audioVolume,
          lightBrightness,
          antiTamper: this.formatFromNumToBoolean(antiTamper),
        },
        config: {
          // Main
          recRank: this.formatFromNumToBoolean(recRank),
          identifyDistance,
          delayTimeForCloseDoor,
          recDisplayImageMode,
          mask: {
            isMaskOpen: isMaskOpen,
            isVoiceOpen: isVoiceOpen,
          },
          //recFailSaveSpotImage:
          //  this.formatFromNumToBoolean(recFailSaveSpotImage),
          //recSucSaveSpotImage: this.formatFromNumToBoolean(recSucSaveSpotImage),
          // recNoPerSaveSpotImage: this.formatFromNumToBoolean(
          //   recNoPerSaveSpotImage
          // ),

          // Recognition
          recModeFaceEnable: this.formatFromNumToBoolean(recModeFaceEnable),
          recModeCardEnable: this.formatFromNumToBoolean(recModeCardEnable),
          recModeCardFaceEnable: this.formatFromNumToBoolean(
            recModeCardFaceEnable
          ),
          identifyScores,
          multiplayerDetection,

          // Settings
          voiceType,
          recSucDisplayText2Content,
          isOpenRelay,
          recSucWiegandType,
          recSucWiegandContent,
          comModType,
          recStrangerType: 2,
          recStrangerTimesThreshold,
          relaySwitch,

          // Other
          companyName,
          wgInputType,
          serialOutMode,
          recModeCardIntf,
          recModeCardFaceIntf,
          recModeCardPhoto: this.formatFromNumToBoolean(recModeCardPhoto),
          recNoPerRelayType,
          recFailWiegandType,
          uniquenessRegImage: this.formatFromNumToBoolean(uniquenessRegImage),
          recNoPerWiegandType,
        },
      };

      this.$emit("input", data);
      this.$emit("change", data);
    },

    formatToBoolean(prop) {
      switch (prop) {
        case 1:
          return false;
        case 2:
          return true;
        default:
          return false;
      }
    },

    formatFromNumToBoolean(prop) {
      switch (prop) {
        case false:
          return 1;
        case true:
          return 2;
        default:
          return 1;
      }
    },

    onChangeRecSucWiegandContentType(type) {
      switch (type) {
        case "device":
          this.recSucWiegandContent = "{idcardNum}";
          break;
        case "custom":
          this.recSucWiegandContent = "";
          break;
        default:
          this.recSucWiegandContent = "";
          break;
      }
      this.inputValue();
    },
  },
};
</script>
