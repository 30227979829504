<template>
  <div>
    <SkeletonLoader v-if="isPending" />

    <Alert v-if="isFailure" type="error">
      <template v-if="errorMessage">
        {{ errorMessage }}
      </template>
      <template v-else>
        {{ $t("message.errorLoading") }}
      </template>
    </Alert>

    <Alert v-if="isFailureStore" type="error" prominent>
      <v-row align="center">
        <v-col v-if="errorMessageStore">
          {{ getLangMessage(errorMessageStore) }}
        </v-col>
        <v-col v-else>
          {{ $t("message.errorLoading") }}
        </v-col>

        <v-spacer />

        <v-col v-if="errorDataStore" cols="auto">
          <router-link
            :to="{
              name: $const.ROUTES.SHOW_PERSON,
              params: { id: errorDataStore.id },
            }"
            target="_blank"
          >
            <span class="mr-2">
              {{ errorDataStore.full_name }}
            </span>
            <v-avatar v-if="errorDataStore.photo" size="40">
              <ImgProtected :src="`avatar/small/${errorDataStore.photo}`" />
            </v-avatar>
            <v-avatar v-else color="primary" size="40">
              <v-icon dark> mdi-account-circle </v-icon>
            </v-avatar>
          </router-link>
        </v-col>
      </v-row>
    </Alert>

    <Form
      v-if="isSuccess"
      :id="id"
      :data="data"
      :isPendingStore="isPendingStore"
      @store="onEdit({ id, data: $event })"
      @cancel="onCancel"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

import Form from "./components/Form";
import Alert from "@/components/Alert";
import ImgProtected from "@/components/ImgProtected";

import SkeletonLoader from "./components/SkeletonLoader";

import faceValidate from "@/mixins/faceValidate";

export default {
  name: "EditPerson",

  mixins: [faceValidate],

  components: { Form, Alert, SkeletonLoader, ImgProtected },

  data() {
    const { id, query } = this.$route.params;

    return {
      id,
      query,
    };
  },

  methods: {
    ...mapActions({ edit: "person/edit", get: "person/get" }),

    ...mapMutations({
      setLoading: "person/setLoading",
    }),

    async onEdit({ id, data }) {
      await this.edit({ id, data });

      if (!this.isFailureStore) {
        this.$router.push({
          name: this.$const.ROUTES.SHOW_PERSON,
          params: { id },
        });
      }
    },

    onCancel() {
      this.$router.push({
        name: this.$const.ROUTES.PERSONS,
        query: this.query,
      });
    },
  },

  computed: {
    ...mapState({
      data: (state) => state.person.data,
      isPending: (state) => state.person.isPending,
      isSuccess: (state) => state.person.isSuccess,
      isFailure: (state) => state.person.isFailure,
      errorMessage: (state) => state.person.errorMessage,
      isPendingStore: (state) => state.person.isPendingStore,
      isFailureStore: (state) => state.person.isFailureStore,
      errorMessageStore: (state) => state.person.errorMessageStore,
      errorDataStore: (state) => state.person.errorDataStore,
    }),
  },

  created() {
    this.get({ id: this.id });
  },

  beforeDestroy() {
    this.setLoading({ type: "IS_RESET_STORE" });
    this.setLoading();
  },
};
</script>
