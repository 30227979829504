<template>
  <v-card class="mx-auto" outlined color="#F3F2FF">
    <v-card-text>
      <v-row>
        <v-col cols="12">
          <v-select
            v-model="type"
            :items="templates"
            :label="$t('operators.typeRole')"
            item-text="name"
            item-value="type"
            outlined
            @change="onChange"
          />
        </v-col>

        <v-col cols="12" md="4">
          <label>{{ departmentsLabel }}</label>
          <TreeSelect
            v-model="departments"
            :multiple="true"
            :options="departmentsList"
            :searchable="true"
            :show-count="true"
            :placeholder="$t('operators.all')"
            :noOptionsText="$t('datatable.noData')"
            @input="onChange"
          />
        </v-col>

        <v-col cols="12" md="4">
          <label>{{ accessLevelLabel }}</label>
          <TreeSelect
            v-model="accessLevels"
            :multiple="true"
            :options="accessLevelsList"
            :searchable="true"
            :show-count="true"
            :placeholder="$t('operators.all')"
            :noOptionsText="$t('datatable.noData')"
            @input="onChange"
          />
        </v-col>

        <v-col cols="12" md="4">
          <label>{{ zonesLabel }}</label>
          <TreeSelect
            v-model="zones"
            :multiple="true"
            :options="zonesList"
            :searchable="true"
            :show-count="true"
            :normalizer="normalizer"
            :placeholder="$t('operators.all')"
            :noOptionsText="$t('datatable.noData')"
            @input="onChange"
          />
        </v-col>

        <v-col cols="12">
          <v-card class="mb-4" elevation="0">
            <v-toolbar
              :color="this.$v.rules.$invalid ? 'red' : 'primary'"
              dark
              flat
            >
              <v-toolbar-title>{{ $t("operators.rules") }}</v-toolbar-title>
            </v-toolbar>

            <v-row>
              <v-col cols="12" md="6">
                <v-card-text>
                  <v-treeview
                    v-model="rules"
                    :items="items"
                    selected-color="indigo"
                    open-on-click
                    selectable
                  >
                    <template v-slot:label="{ item }">
                      {{ item.name }}
                      <v-tooltip v-if="item.info" right max-width="250">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            color="grey"
                            size="16"
                            >mdi-information</v-icon
                          >
                        </template>
                        <span>{{ item.info }}</span>
                      </v-tooltip>
                    </template>
                  </v-treeview>
                </v-card-text>
              </v-col>

              <v-divider vertical />

              <v-col cols="12" md="6">
                <v-card-text>
                  <div
                    v-if="rules.length === 0"
                    key="title"
                    class="text-h6 font-weight-light grey--text pa-4 text-center"
                  >
                    {{ $t("operators.chooseRules") }}
                  </div>

                  <v-scroll-x-transition group hide-on-leave>
                    <div v-for="rule in items" :key="rule.id">
                      <div v-if="getRulesByRegex(rule.id).length" class="mb-5">
                        <h2 class="mb-3">{{ rule.name }}</h2>
                        <v-chip
                          v-for="(selection, i) in getRulesByRegex(rule.id)"
                          :key="i"
                          color="info"
                          dark
                          small
                          close
                          outlined
                          @click:close="removeRuleByID(selection)"
                          class="ma-1"
                        >
                          {{ $t(`rules.full.${selection}`) }}
                        </v-chip>
                      </div>
                    </div>
                  </v-scroll-x-transition>
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions v-if="isDelete">
      <v-spacer />
      <v-btn color="red" text @click="onCancel">
        {{ $t("button.delete") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import TreeSelect from "@riophae/vue-treeselect";

import roleMixin from "../mixins/role";
import validator from "./validator";

export default {
  name: "OperatorRole",

  mixins: [roleMixin, validator],

  components: { TreeSelect },

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    isDelete: {
      type: Boolean,
      default: true,
    },
    templates: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    const type = this.data?.type || this.$const.ROLES.OPERATOR;
    const departments = this.data?.departments || [];
    const accessLevels = this.data?.accesslevels || [];
    const zones = this.data?.allowed_zones || [];
    const rules = this.data?.permissions.length
      ? this.data.permissions
      : [...this.getDefaultPermissions()];

    return {
      type,
      departments,
      accessLevels,
      zones,
      rules,

      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
          children: node.children,
        };
      },
    };
  },

  watch: {
    type() {
      this.rules = [...this.getDefaultPermissions()];
    },

    rules() {
      this.$v.rules.$touch();

      this.onChange();
    },
  },

  computed: {
    ...mapState({
      departmentsList: (state) => state.departments.tree,
      accessLevelsList: (state) => state.accessLevels.tree,
      zonesList: (state) => state.zones.list,
    }),

    isAccessLevelsRequired() {
      let isRequired = false;

      const roleArr = this.templates.filter((role) => role.type === this.type);

      if (roleArr.length) {
        isRequired = roleArr[0].req_accesslevels;
      }

      return isRequired;
    },

    isDepartmentsRequired() {
      let isRequired = false;

      const roleArr = this.templates.filter((role) => role.type === this.type);

      if (roleArr.length) {
        isRequired = roleArr[0].req_departments;
      }

      return isRequired;
    },

    isZonesRequired() {
      let isRequired = false;

      const roleArr = this.templates.filter((role) => role.type === this.type);

      if (roleArr.length) {
        isRequired = roleArr[0].req_zones;
      }

      return isRequired;
    },

    accessLevelLabel() {
      let label = this.$t("operators.accessLevel");

      if (this.isAccessLevelsRequired) {
        label += " *";
      }

      return label;
    },

    zonesLabel() {
      let label = this.$t("operators.zone");

      if (this.isZonesRequired) {
        label += " *";
      }

      return label;
    },

    departmentsLabel() {
      let label = this.$t("operators.department");

      if (this.isDepartmentsRequired) {
        label += " *";
      }

      return label;
    },
  },

  methods: {
    onChange() {
      this.$emit("change", {
        loopId: this.data.loopId,
        type: this.type,
        departments: this.departments,
        accesslevels: this.accessLevels,
        allowed_zones: this.zones,
        permissions: this.rules,
      });
    },

    getRulesByRegex(regex) {
      return this.rules.filter((item) => item.match(regex));
    },

    removeRuleByID(ruleID) {
      this.rules.splice(this.rules.indexOf(ruleID), 1);
    },

    onCancel() {
      this.$emit("cancel", this.data.loopId);
    },

    getDefaultPermissions() {
      let permissions = [];

      const roleArr = this.templates.filter(
        (role) => role.type === this.data.type
      );

      if (roleArr.length) {
        permissions = roleArr[0].permissions;
      }

      return permissions;
    },
  },

  created() {
    this.onChange();
  },
};
</script>
