export default {
  set(state, { data, pagination }) {
    state.data = data;
    state.pagination = pagination;
  },

  setLimit(state, limit) {
    localStorage.setItem("requestsLimit", limit);
    state.limit = limit;
  },
};
