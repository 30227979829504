<template>
  <div>
    <ZoomImg v-show="isOpenZoomImg" :url="zoomImgUrl" />
    <v-row v-if="errorMessage">
      <v-col>
        <Alert type="error">
          {{ errorMessage }}
        </Alert>
      </v-col>
    </v-row>

    <v-row>
      <v-spacer />
      <v-col cols="12" md="auto">
        <v-menu offset-y bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              v-bind="attrs"
              v-on="on"
              :loading="isPendingReport"
              :disabled="isPendingReport"
            >
              <v-icon left> mdi-file-chart </v-icon>
              {{ $t("advanced.allEvents") }}&nbsp;
              <v-icon right> mdi-menu-down </v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item link @click="getEventsReport('report/worktime')">
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("advanced.worktime")
                }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-tooltip left max-width="250">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="grey lighten-1"
                      >mdi-information</v-icon
                    >
                  </template>
                  <span>{{ $t("advanced.onlyZoneWithWorktime") }}</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>

            <v-list-item link @click="getEventsReport('report/iotime')">
              <v-list-item-content>
                <v-list-item-title>{{
                  $t("advanced.iotime")
                }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-tooltip left max-width="250">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" color="grey lighten-1"
                      >mdi-information</v-icon
                    >
                  </template>
                  <span>{{ $t("advanced.onlyZoneWithWorktime") }}</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
            <v-list-item link @click="getEventsReport('report/passusers')">
              <v-list-item-title>{{
                $t("advanced.passUsers")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="getEventsReport('report/passed')">
              <v-list-item-title>{{ $t("advanced.passed") }}</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="getEventsReport('report/passed', true)">
              <v-list-item-title>{{
                $t("advanced.passedTemp")
              }}</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="getEventsReport('report/stat')">
              <v-list-item-title>{{
                $t("advanced.analytic")
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <v-data-table
      :loading="isPending"
      :headers="headers"
      :items="passes"
      :server-items-length="pagination.per_page"
      :options.sync="options"
      hide-default-footer
    >
      <template v-slot:top>
        <PassesFilter
          v-model="filter"
          @input="onChangeFilter"
          class="mb-2 mt-5"
        />
      </template>

      <template #[`item.identity_types`]="{ item }">
        <span v-if="item.purpose">
          <v-tooltip
            v-if="item.purpose === 'entrance' || item.purpose === 'duplex'"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" small color="success"
                >mdi-location-enter</v-icon
              >
            </template>
            <span>{{ $t("device.purpose_entrance") }}</span>
          </v-tooltip>
          <v-tooltip v-if="item.purpose === 'exit'" top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                small
                color="error"
                class="rotate-180"
                >mdi-location-exit</v-icon
              >
            </template>
            <span>{{ $t("device.purpose_exit") }}</span>
          </v-tooltip>
        </span>

        <template v-if="item.data.identity_types">
          <span v-for="type in item.data.identity_types.split(',')" :key="type">
            <v-tooltip v-if="type === 'face'" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  :color="item.blacklisted ? 'white' : ''"
                >
                  mdi-emoticon-happy-outline
                </v-icon>
              </template>
              <span>{{ $t("users.face") }}</span>
            </v-tooltip>

            <v-tooltip v-if="type === 'qr'" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  small
                  :color="item.blacklisted ? 'white' : ''"
                >
                  mdi-qrcode-scan
                </v-icon>
              </template>
              <span>{{ $t("users.qr") }}</span>
            </v-tooltip>

            <v-tooltip v-if="type === 'card'" top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  v-clipboard:copy="copyCard(item.identity_value)"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                  small
                  :color="item.blacklisted ? 'white' : ''"
                >
                  mdi-card-bulleted-outline
                </v-icon>
              </template>
              <span>
                {{ $t("users.card") }}<br />
                <template
                  v-if="
                    item.identity_value &&
                    getIdentityValueType(item.identity_value) === 'card'
                  "
                >
                  HEX - {{ getIdentityValue(item.identity_value) }} <br />
                  DEC -
                  {{ parseInt(getIdentityValue(item.identity_value), 16) }}
                </template>
              </span>
            </v-tooltip>
          </span>
        </template>
      </template>

      <template #[`item.avatar`]="{ item }">
        <v-avatar v-if="item.avatarStatic" size="50" class="my-3">
          <v-img :src="item.avatarStatic" />
        </v-avatar>

        <v-avatar
          v-else-if="isPhotoBase64(item.avatar)"
          size="50"
          class="my-3"
          @mouseenter="openZoomImg(`/avatar/profile/${item.avatar}`, false)"
          @mouseleave="closeZoomImg"
        >
          <v-img :src="item.avatar" />
        </v-avatar>

        <v-avatar
          v-else-if="item.avatar"
          size="50"
          class="my-3"
          @mouseenter="openZoomImg(`/avatar/profile/${item.avatar}`, true)"
          @mouseleave="closeZoomImg"
        >
          <ImgProtected
            :src="`/avatar/small/${item.avatar}`"
            :alt="item.name"
          />
        </v-avatar>

        <v-avatar v-else color="primary" size="50" class="my-3">
          <v-icon dark> mdi-account-circle </v-icon>
        </v-avatar>
      </template>

      <template #[`item.full_name`]="{ item }">
        <div>
          {{ item.full_name || $t("users.unrecognized") }}
        </div>
        <small class="blue-grey--text text--lighten-1 d-block">
          {{ item.data.zone }} / {{ item.data.device_name }}
        </small>
      </template>

      <template #[`item.accesslevels`]="{ item }">
        <template v-if="item.accesslevels && item.accesslevels.length">
          {{ item.accesslevels.map((item) => item.name).join(" / ") }}
        </template>
      </template>

      <template #[`item.created_at`]="{ item }">
        {{ item.created_at | moment("DD.MM.YY HH:mm:ss") }}
      </template>

      <template #footer>
        <DataTableFooter
          ref="tableFooter"
          :pagination="pagination"
          :perPage="limit"
          @changePagination="onChangePagination"
          @changePerPage="onChangePerPage"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import { isBase64 } from "validator";

import PassesFilter from "./Filter";

import ZoomImg from "@/components/ZoomImg";
import DataTableFooter from "@/components/DataTableFooter";
import ImgProtected from "@/components/ImgProtected";

import imgProtectedMixin from "@/mixins/imgProtected";
import filter from "@/mixins/filter";

export default {
  name: "PassesList",

  mixins: [imgProtectedMixin, filter],

  components: {
    PassesFilter,
    DataTableFooter,
    ImgProtected,
    ZoomImg,
  },

  data() {
    return {
      page: parseInt(this.$route.query.page) || 1,

      headers: [
        {
          value: "identity_types",
          width: "16px",
          sortable: false,
        },
        {
          value: "avatar",
          sortable: false,
        },
        {
          text: this.$t("users.visitor"),
          value: "full_name",
        },
        {
          text: this.$t("users.subdivision"),
          value: "accesslevels",
          sortable: false,
        },
        {
          text: this.$t("users.visit_time"),
          value: "created_at",
        },
      ],
      isOpenZoomImg: false,
      zoomImgUrl: "",
      options: {},
    };
  },

  watch: {
    options: {
      handler() {
        this.getPasses();
      },
      deep: true,
    },
  },

  methods: {
    ...mapMutations({
      setLimit: "passes/setLimit",
    }),

    ...mapActions({
      getPassesAction: "passes/get",
      report: "passes/report",
    }),
    async openZoomImg(img, isProtected) {
      this.isOpenZoomImg = true;
      let image = img;
      if (isProtected) {
        await this.getImage(img);
        image = this.imgProtected;
      }
      this.zoomImgUrl = image;
    },

    closeZoomImg() {
      this.isOpenZoomImg = false;
      this.zoomImgUrl = "";
    },

    getIdentityValueType(identityValue) {
      const separator = identityValue.indexOf(":");
      const type = identityValue.substring(0, separator);
      return type;
    },

    getIdentityValue(identityValue) {
      const separator = identityValue.indexOf(":");
      return identityValue.substring(identityValue.length, separator + 1);
    },

    onCopy: function (e) {
      this.$notify({
        group: "info",
        type: "success",
        text: this.$t("message.copied"),
      });
    },

    onError: function (e) {
      this.$notify({
        group: "info",
        type: "error",
        text: this.$t("message.copied-error"),
      });
    },

    copyCard(item) {
      return String(parseInt(this.getIdentityValue(item), 16));
    },

    onChangeFilter(filter) {
      this.updateFilter({ ...filter });
      this.page = 1;
      this.$refs.tableFooter.onChangePage(this.page);
    },

    onChangePerPage(limit) {
      this.setLimit(limit);
      this.page = 1;
      this.$refs.tableFooter.onChangePage(this.page);
    },

    onChangePagination(page) {
      this.page = page;

      this.getPasses();
    },

    async getPasses() {
      const params = { ...this.filter };

      if (this.page) params.page = this.page;
      if (this.limit) params.limit = this.limit;
      if (this.options.sortBy && this.options.sortBy.length)
        params.sortName = this.options.sortBy[0];
      if (
        params.sortName &&
        this.options.sortDesc &&
        this.options.sortDesc.length
      )
        params.sortOrder = this.options.sortDesc[0] ? "desc" : "asc";

      await this.getPassesAction(params);
    },

    getEventsReport(url, istemp = false) {
      const params = {
        istemp,
        ...this.filter,
      };

      this.report({ params, url });
    },

    isPhotoBase64(photo) {
      if (photo) {
        const index = photo.indexOf(",");
        const photoBase64 = photo.slice(index + 1);

        return isBase64(photoBase64);
      }

      return false;
    },
  },

  computed: {
    ...mapState({
      isPending: (state) => state.passes.isPending,
      limit: (state) => state.passes.limit,
      pagination: (state) => state.passes.pagination,
      errorMessage: (state) => state.passes.errorMessage,
      isPendingReport: (state) => state.passes.isPendingReport,
    }),

    ...mapGetters({
      passes: "passes/getCorrectPasses",
    }),
  },

  created() {
    this.initFilter({
      identityTypes: [],
      userType: [],
      subdivisions: [],
      departments: [],
      isOnObject: false,
      search: "",
      startDate: this.$moment().startOf("month").toISOString(),
      endDate: this.$moment().endOf("month").toISOString(),
    });
  },
};
</script>
