<template>
  <div>
    <Alert v-if="isFailure" type="error">
      <template v-if="errorMessage">
        {{ errorMessage }}
      </template>
      <template v-else>
        {{ $t("message.errorLoading") }}
      </template>
    </Alert>

    <Form ref="form" :isPendingStore="isPending" @store="onStore">
      <template v-slot:actions>
        <v-btn :loading="isPending" @click="cancel">
          <v-icon left>mdi-cancel</v-icon>
          {{ $t("button.cancel") }}
        </v-btn>
        <v-spacer />
        <v-btn color="green" dark :loading="isPending" @click="save">
          <v-icon left>mdi-content-save-outline</v-icon>
          {{ $t("button.save") }}
        </v-btn>
      </template>
    </Form>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

import Form from "./components/Form";
import Alert from "@/components/Alert";

export default {
  name: "AddRequest",

  components: { Form, Alert },

  data() {
    const { query } = this.$route.params;

    return {
      query,
    };
  },

  methods: {
    ...mapActions({ store: "request/store" }),

    ...mapMutations({
      setLoading: "request/setLoading",
    }),

    cancel() {
      this.$router.push({
        name: this.$const.ROUTES.PERMIT_REQUESTS,
        query: this.query,
      });
    },

    save() {
      this.$refs.form.save();
    },

    async onStore(data) {
      await this.store(data);

      if (!this.isFailure) {
        this.$router.push({
          name: this.$const.ROUTES.PERMIT_REQUESTS,
          query: this.query,
        });
      }
    },
  },

  computed: {
    ...mapState({
      isPending: (state) => state.request.isPendingStore,
      isFailure: (state) => state.request.isFailureStore,
      errorMessage: (state) => state.request.errorMessageStore,
    }),
  },

  destroyed() {
    this.setLoading({ type: "IS_RESET_STORE" });
  },
};
</script>
