<template>
  <div v-if="type">
    <div class="monitoring-fullscreen">
      <div class="monitoring-fullscreen__top">
        <div
          v-if="image"
          class="monitoring-fullscreen__photo"
          :style="{ backgroundImage: `url(${image})` }"
        />
        <v-img v-else class="monitoring-fullscreen__photo">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-icon size="500" color="primary"> mdi-account-circle </v-icon>
            </v-row>
          </template>
        </v-img>
        <div class="monitoring-fullscreen__info">
          <span>
            <div class="monitoring-fullscreen__meta">
              <div v-if="identityTypes.length" class="mr-2">
                <span
                  v-for="type in identityTypes"
                  :key="type"
                  class="mr-1 ml-1"
                >
                  <template v-if="type === 'face'">
                    <v-icon small class="mr-1">
                      mdi-emoticon-happy-outline
                    </v-icon>
                    <span>{{ $t("users.face") }}</span>
                  </template>

                  <template v-if="type === 'qr'">
                    <v-icon small class="mr-1"> mdi-qrcode-scan </v-icon>
                    <span>{{ $t("users.qr") }}</span>
                  </template>

                  <template v-if="type === 'card'">
                    <v-icon small class="mr-1">
                      mdi-card-bulleted-outline
                    </v-icon>
                    <span>{{ $t("users.card") }}</span>
                  </template>
                </span>
              </div>

              <div v-if="purpose">
                <template v-if="purpose === 'entrance'">
                  <v-icon small color="success" class="mr-1"
                    >mdi-location-enter</v-icon
                  >
                  <span>{{ $t("device.purpose_entrance") }}</span>
                </template>

                <template v-if="purpose === 'exit'">
                  <v-icon small color="error" class="rotate-180 mr-1"
                    >mdi-location-exit</v-icon
                  >
                  <span>{{ $t("device.purpose_exit") }}</span>
                </template>
              </div>
            </div>

            <div v-if="deviceName" class="monitoring-fullscreen__zone">
              {{ zone }} ({{ deviceName }})
            </div>

            <div
              v-if="temperature"
              :class="[
                'monitoring-fullscreen__temperature',
                {
                  'monitoring-fullscreen__temperature--success':
                    isNormalTemperature,
                },
                {
                  'monitoring-fullscreen__temperature--danger':
                    !isNormalTemperature,
                },
              ]"
            >
              {{ temperatureWithUnit }}
            </div>

            <strong>
              <router-link
                v-if="user_id && can($const.RULES.PERSON_VIEW)"
                :to="{
                  name: $const.ROUTES.SHOW_PERSON,
                  params: { id: user_id },
                }"
              >
                {{ name }}
              </router-link>
              <template v-else>
                {{ name }}
              </template>
            </strong>

            <br />
            {{ createdAt | moment("DD.MM.YYYY HH:mm:ss") }}
          </span>
        </div>
      </div>
      <div
        v-if="typeText"
        :class="[
          'monitoring-fullscreen__bottom',
          {
            'monitoring-fullscreen__bottom--error': type === 'error',
          },
          {
            'monitoring-fullscreen__bottom--warning': type === 'alarm',
          },
          {
            'monitoring-fullscreen__bottom--success':
              type === 'pass' || type === 'before',
          },
          {
            'monitoring-fullscreen__bottom--black': isBlacked,
          },
          {
            'monitoring-fullscreen__bottom--black': isBlocked,
          },
        ]"
      >
        {{ typeText }}
      </div>
    </div>

    <v-row justify="center">
      <v-dialog
        v-if="isShowAlarmDialog"
        :value="true"
        max-width="700px"
        persistent
      >
        <ModalAlarmDialog :data="deviceAlarm" @close="closeAlarmDialog" />
      </v-dialog>
    </v-row>
  </div>

  <div v-else>
    <div class="monitoring-fullscreen monitoring-fullscreen--center">
      <v-alert type="info" class="mb-0">
        {{ $t("monitoring.nextEvent") }}
      </v-alert>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import ModalAlarmDialog from "./ModalAlarmDialog";

import permissions from "@/mixins/permissions";

export default {
  mixins: [permissions],

  components: { ModalAlarmDialog },

  data() {
    return {
      user_id: 0,
      name: "",
      type: "",
      isBlocked: false,
      isBlacked: false,
      image: "",
      createdAt: this.$moment().format(),
      identityTypes: [],
      purpose: "",
      zone: "",
      deviceName: "",
      temperature: 0.0,
      isNormalTemperature: true,

      isShowAlarmDialog: false,
      deviceAlarm: null,
    };
  },

  methods: {
    onGetVisit(visit, isFirst = false) {
      const data = visit.event;

      if (
        (!this.role.allowed_zones.length ||
          this.role.allowed_zones.includes(parseInt(data.zone_id))) &&
        (isFirst ||
          this.$moment(visit.created_at).isAfter(this.$moment(this.createdAt)))
      ) {
        this.user_id = visit.person_id;
        this.name = data.name || data.message || this.$t("users.unrecognized");
        this.type = data.type || "error";
        this.isBlocked = visit.Blocked;
        this.isBlacked = visit.blacklisted;
        this.createdAt = visit.created_at;
        this.identityTypes = this.getIdentityTypesArr(data.identity_types);
        this.purpose = visit.purpose;
        this.zone = data.zone;
        this.deviceName = data.device_name;
        this.temperature = parseFloat(data.temperature);

        switch (data.temperature_status) {
          case "norm":
            this.isNormalTemperature = true;
            break;
          case "excess":
            this.isNormalTemperature = false;
            break;
          default:
            this.isNormalTemperature = true;
            break;
        }

        let eventType;

        if (parseInt(data.code) === 350) {
          eventType = "pressBtn";
        } else if (parseInt(data.code) >= 301) {
          eventType = "deviceAlarm";
        } else {
          eventType = "userAlarm";
        }

        if (data["image_encode"]) {
          this.image = `data:image/png;base64,${data["image_encode"]}`;
        } else if (visit.photo) {
          this.image = `/avatar/profile/${visit.photo}`;
        } else {
          switch (eventType) {
            case "deviceAlarm":
              this.image = require("@/assets/img/error.svg");
              break;
            case "pressBtn":
              this.image = require("@/assets/img/press-btn.svg");
              break;
          }
        }

        if (eventType === "deviceAlarm") {
          this.showAlarmDialog({
            full_name: this.name,
            purpose: `${this.zone} (${this.deviceName})`,
          });
        }
      }
    },

    onGetNewVisit(visit) {
      const data = visit.event;

      if (
        (!this.role.allowed_zones.length ||
          this.role.allowed_zones.includes(parseInt(data.zone_id))) &&
        this.$moment(visit.created_at).isAfter(this.$moment(this.createdAt))
      ) {
        this.user_id = visit.person_id;
        this.name = visit.full_name || this.$t("users.unrecognized");
        this.type = "pass";
        this.isBlocked = visit.Blocked;
        this.isBlacked = visit.blacklisted;
        this.createdAt = visit.created_at;
        this.image = `/avatar/profile/${visit.photo}`;
        this.identityTypes = this.getIdentityTypesArr(data.identity_types);
        this.purpose = visit.purpose;
        this.zone = data.zone;
        this.deviceName = data.device_name;
      }
    },

    getIdentityTypesArr(types) {
      let result = [];

      if (types) {
        result = types.split(",");
      }

      return result;
    },

    connectWebSockets() {
      this.$root.$on(this.$const.WS_CHANNELS.NEW_VISIT, this.onGetNewVisit);
      this.$root.$on(this.$const.WS_CHANNELS.BEFORE_VISIT, this.onGetVisit);
      this.$root.$on(this.$const.WS_CHANNELS.ERROR_VISIT, this.onGetVisit);
      this.$root.$on(this.$const.WS_CHANNELS.ALARM_VISIT, this.onGetVisit);
      this.$root.$on(this.$const.WS_CHANNELS.EVENT_VISIT, this.onGetVisit);
    },

    disconnectedWebSockets() {
      this.$root.$off(this.$const.WS_CHANNELS.NEW_VISIT);
      this.$root.$off(this.$const.WS_CHANNELS.BEFORE_VISIT);
      this.$root.$off(this.$const.WS_CHANNELS.ERROR_VISIT);
      this.$root.$off(this.$const.WS_CHANNELS.ALARM_VISIT);
      this.$root.$off(this.$const.WS_CHANNELS.EVENT_VISIT);
    },

    getEventLog() {
      const params = {
        limit: 1,
      };

      this.axios("alarmvisits", { params }).then((response) => {
        const visits = response.data.data;

        if (visits.length) {
          this.onGetVisit(visits[0], true);
        }
      });
    },

    closeAlarmDialog() {
      this.isShowAlarmDialog = false;
      this.deviceAlarm = null;
    },

    showAlarmDialog(alarm) {
      this.deviceAlarm = alarm;
      this.isShowAlarmDialog = true;
    },
  },

  computed: {
    ...mapGetters({
      role: "user/getRole",
    }),

    ...mapState({
      settings: (state) => state.settings.data,
    }),

    typeText() {
      let text = "";

      switch (this.type) {
        case "pass":
          text = this.$t("users.recognizedAndPass");
          break;
        case "before":
          text = this.$t("users.recognized");
          break;
        case "error":
          text = this.$t("users.error");
          break;
        case "alarm":
        case "event":
          text = this.$t("users.notRecognized");
          break;
        default:
          text = "";
          break;
      }

      if (this.isBlocked) {
        text += ` (${this.$t("users.blocked")})`;
      } else if (this.isBlacked) {
        text += ` (${this.$t("users.blacklist")})`;
      }

      return text;
    },

    temperatureWithUnit() {
      return `${this.temperature} °${this.settings.temp_meas.toUpperCase()}`;
    },
  },

  destroyed() {
    this.disconnectedWebSockets();
  },

  created() {
    this.connectWebSockets();
    this.getEventLog();
  },
};
</script>
