import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],

  validations: {
    personId: { required },
    permits: { required, minLength: minLength(1) },
  },

  computed: {
    personIdErrors() {
      const errors = [];
      if (!this.$v.personId.$dirty) return errors;

      !this.$v.personId.required &&
        errors.push(this.$t("message.NameIsRequired"));

      return errors;
    },

    permitsErrors() {
      const errors = [];
      if (!this.$v.permits.$dirty) return errors;

      (!this.$v.permits.required || !this.$v.permits.minLength) &&
        errors.push(this.$t("message.NameIsRequired"));

      return errors;
    },
  },

  methods: {
    validateForm() {
      this.$v.$touch();

      return !this.$v.$invalid;
    },
  },
};
