<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card elevation="2" :loading="isPendingStore">
      <template slot="progress">
        <v-progress-linear color="primary" height="10" indeterminate />
      </template>

      <v-card-text>
        <PasswordForm @input="pass = $event" />
      </v-card-text>

      <v-card-actions>
        <v-btn
          v-if="$auth.user().password_upd"
          :loading="isPendingStore"
          @click="cancel"
        >
          <v-icon left>mdi-cancel</v-icon>
          {{ $t("button.cancel") }}
        </v-btn>
        <v-spacer />
        <v-btn color="green" dark :loading="isPendingStore" @click="save">
          <v-icon left>mdi-content-save-outline</v-icon>
          {{ $t("button.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import PasswordForm from "./PasswordForm";

export default {
  name: "Form",

  components: { PasswordForm },

  props: {
    isPendingStore: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      valid: true,
      pass: "",
    };
  },

  methods: {
    save() {
      if (!this.$refs.form.validate()) return;

      this.$emit("store", {
        password: this.pass,
      });
    },

    cancel() {
      this.$router.push({ name: this.$const.ROUTES.PERSONS });
    },
  },
};
</script>
