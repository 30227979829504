<template>
  <div>
    <v-row class="mb-2 mt-5">
      <v-col v-if="errorMessage" cols="12">
        <Alert type="error">
          {{ errorMessage }}
        </Alert>
      </v-col>
      <v-col cols="12">
        <DepartmentsFilter @change="onChangeFilter" />
      </v-col>
    </v-row>

    <v-skeleton-loader v-if="isPending" class="mx-auto" type="list-item@3" />

    <v-treeview
      v-if="isSuccess"
      :items="departments"
      :search="search"
      dense
      hoverable
      open-on-click
      transition
    >
      <template v-slot:label="{ item }">
        <router-link
          v-if="can($const.RULES.DEPARTMENT_EDIT)"
          :to="{ name: $const.ROUTES.EDIT_DEPARTMENT, params: { id: item.id } }"
          >{{ item.name }}</router-link
        >
        <template v-else>
          {{ item.name }}
        </template>
      </template>

      <template v-slot:prepend="{ item }">
        <v-tooltip v-if="can($const.RULES.DEPARTMENT_DELETE)" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              small
              icon
              color="red"
              @click="onRemove(item.id)"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("departments.delete") }}</span>
        </v-tooltip>
      </template>
    </v-treeview>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import Alert from "@/components/Alert";
import DepartmentsFilter from "./Filter";

import permissions from "@/mixins/permissions";

export default {
  name: "DepartmentsTree",

  mixins: [permissions],

  components: { Alert, DepartmentsFilter },

  data() {
    return {
      search: "",
    };
  },

  methods: {
    ...mapActions({
      get: "departments/getTree",
      remove: "department/remove",
    }),

    goToEdit(id) {
      this.$router.push({
        name: this.$const.ROUTES.EDIT_DEPARTMENT,
        params: { id },
      });
    },

    onRemove(id) {
      if (!id) return;

      this.$root.$prompt({
        title: this.$t("message.Delete"),
        message: this.$t("message.confirmDelete"),
        btnAgree: this.$t("button.delete"),

        agree: async () => {
          await this.remove(id);
          this.get();
        },
      });
    },

    getParentNameById(id) {
      const department = this.departments.filter(
        (department) => department.id === id
      );

      return department.name;
    },

    onChangeFilter(filter) {
      this.search = filter.search;
    },
  },

  computed: {
    ...mapState({
      isPending: (state) => state.departments.isPending,
      isSuccess: (state) => state.departments.isSuccess,
      errorMessage: (state) => state.departments.errorMessage,
      departments: (state) => state.departments.tree,
    }),
  },

  created() {
    this.get();
  },
};
</script>
