<template>
  <div>
    <v-row>
      <v-col v-for="item in items" :key="item.id" cols="12">
        <Item :data="item" @cancel="onCancel" @change="onChange" />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn color="primary" dark @click="add">
          <v-icon left> mdi-plus </v-icon>
          {{ $t("access-levels.addAccessMode") }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Item from "./Item";

export default {
  name: "AccessByTime",

  components: { Item },

  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    const items = this.data.map((item, index) => ({
      id: index + 1,
      ...item,
    }));

    return {
      items,
    };
  },

  methods: {
    add() {
      let id = 1;
      if (this.items.length > 0) {
        id = this.items[this.items.length - 1].id + 1;
      }

      this.items.push({
        id,
        full_access: true,
        weekdays: "1111100",
        date_from: null,
        date_to: null,
        time_list: [
          {
            id: 1,
            time_from: null,
            time_to: null,
          },
        ],
      });

      this.$emit("change", this.items);
    },

    onCancel(id) {
      this.items = this.items.filter((p) => p.id !== id);

      this.$emit("change", this.items);
    },

    onChange(data) {
      const index = this.items.findIndex((p) => p.id === data.id);
      this.items.splice(index, 1, data);

      this.$emit("change", this.items);
    },
  },
};
</script>
