var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.errorMessage)?_c('v-row',[_c('v-col',[_c('Alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])],1)],1):_vm._e(),(_vm.can(_vm.$const.RULES.OPERATOR_ADD))?_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"auto"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.goToAdd}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_vm._v(" "+_vm._s(_vm.$t("button.add"))+" ")],1)],1)],1):_vm._e(),_c('v-data-table',{attrs:{"loading":_vm.isPending,"headers":_vm.headers,"items":_vm.operators,"server-items-length":_vm.pagination.per_page,"hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('OperatorsFilter',{staticClass:"mb-2 mt-5",on:{"input":_vm.onChangeFilter},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})]},proxy:true},{key:`item.avatar`,fn:function({ item }){return [(item.photo)?_c('v-avatar',{staticClass:"mt-3 mb-3"},[_c('ImgProtected',{attrs:{"src":`avatar/small/${item.photo}`,"alt":item.name}})],1):_c('v-avatar',{staticClass:"mt-3 mb-3",attrs:{"color":"primary","size":"50"}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-account-circle ")])],1)]}},{key:`item.name`,fn:function({ item }){return [(_vm.can(_vm.$const.RULES.OPERATOR_EDIT))?_c('router-link',{attrs:{"to":{
          name: _vm.$const.ROUTES.EDIT_OPERATOR,
          params: {
            id: item.id,
            query: _vm.$route.query,
          },
        }}},[_vm._v(_vm._s(item.name))]):[_vm._v(" "+_vm._s(item.name)+" ")]]}},{key:`item.actions`,fn:function({ item }){return [(_vm.can(_vm.$const.RULES.OPERATOR_EDIT))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":"primary"},on:{"click":function($event){return _vm.goToEdit(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("button.edit")))])]):_vm._e(),(_vm.can(_vm.$const.RULES.OPERATOR_DELETE))?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":"red"},on:{"click":function($event){return _vm.onRemove({
                id: item.id,
                isBindPerson: item.persons_id ? true : false,
              })}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("button.delete")))])]):_vm._e()]}},{key:"footer",fn:function(){return [_c('DataTableFooter',{ref:"tableFooter",attrs:{"pagination":_vm.pagination,"totalText":_vm.$t('operators.total'),"perPage":_vm.limit},on:{"changePagination":_vm.onChangePagination,"changePerPage":_vm.onChangePerPage}})]},proxy:true}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }