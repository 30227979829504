import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],

  validations: {
    rules: {
      required,
      minLength: minLength(1),
    },
  },
};
