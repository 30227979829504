<template>
  <v-dialog :value="true" max-width="400px" @click:outside="close">
    <v-card>
      <v-toolbar color="primary" dark>
        <v-toolbar-title>{{ $t("advanced.allEvents") }}</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text class="pt-5">
        <draggable
          v-model="list"
          v-bind="dragOptions"
          @start="drag = true"
          @end="drag = false"
        >
          <transition-group
            type="transition"
            :name="!drag ? 'flip-list' : null"
          >
            <v-row
              align="center"
              justify="space-between"
              dense
              v-for="element in list"
              :key="element.value"
            >
              <v-col cols="auto">
                <v-switch
                  v-model="element.checked"
                  :label="element.name"
                  :disabled="element.readonly"
                  hide-details
                  class="mt-0"
                />
              </v-col>
              <v-col cols="auto">
                <v-btn text icon>
                  <v-icon>mdi-drag-horizontal-variant</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </transition-group>
        </draggable>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :loading="loading" color="primary" text @click="submit">
          {{ $t("button.download") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import draggable from "vuedraggable";

const ALL_EVENTS_REPORT_SETTINGS_KEY = "allEventsReportSettings";

export default {
  components: { draggable },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      list: [
        {
          name: `${this.$t("advanced.id")}`,
          value: "ID",
          checked: false,
          width: 5,
        },
        {
          name: `${this.$t("advanced.fullname")}`,
          value: "Fullname",
          readonly: true,
          checked: true,
          width: 20,
        },
        {
          name: this.$t("advanced.purpose"),
          value: "Purpose",
          checked: false,
          width: 7,
        },
        {
          name: this.$t("advanced.temperature"),
          value: "Temperature",
          checked: false,
          width: 10,
        },
        {
          name: this.$t("advanced.zone"),
          value: "Zone",
          checked: false,
          width: 20,
        },
        {
          name: this.$t("advanced.deviceName"),
          value: "DeviceName",
          checked: false,
          width: 20,
        },
        {
          name: this.$t("advanced.identityTypes"),
          value: "IdentityTypes",
          checked: false,
          width: 10,
        },
        {
          name: this.$t("advanced.typeStatus"),
          value: "Type",
          checked: false,
          width: 10,
        },
        {
          name: this.$t("advanced.identityValue"),
          value: "IdentityValue",
          checked: false,
          width: 10,
        },
        {
          name: this.$t("advanced.code"),
          value: "Message",
          checked: false,
          width: 40,
        },
        {
          name: this.$t("advanced.photo"),
          value: "Photo",
          checked: false,
          width: 8,
          type: "image",
        },
        {
          name: `${this.$t("advanced.createdAt")}`,
          value: "CreatedAt",
          readonly: true,
          checked: true,
          width: 15,
        },
      ],
      drag: false,
    };
  },

  methods: {
    close() {
      this.$emit("close");
    },

    submit() {
      this.saveSettingsToLocalStorage();
      this.$emit("submit", this.list);
    },

    sort() {
      this.list = this.list.sort((a, b) => a.order - b.order);
    },

    saveSettingsToLocalStorage() {
      localStorage.setItem(
        ALL_EVENTS_REPORT_SETTINGS_KEY,
        JSON.stringify(this.list)
      );
    },

    getSettingsFromLocalStorage() {
      const settings = JSON.parse(
        localStorage.getItem(ALL_EVENTS_REPORT_SETTINGS_KEY)
      );

      if (Array.isArray(settings)) {
        if (settings.length == this.list.length) {
          this.list = settings;
        }
      }
    },
  },

  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },

  created() {
    this.getSettingsFromLocalStorage();
  },
};
</script>
