<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on">
        {{ $t("menu.actions") }}&nbsp;
        <v-icon right> mdi-menu-down </v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <template v-if="selected.length">
        <template v-if="isBlockedPerson">
          <v-list-item v-if="isHasUnblockedUser" link @click="$emit('block')">
            <v-list-item-title>{{ $t("button.block") }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="isHasBlockedUser" link @click="$emit('unblock')">
            <v-list-item-title>{{ $t("button.unblock") }}</v-list-item-title>
          </v-list-item>

          <v-divider />
        </template>

        <template v-if="isBlackedPerson">
          <v-list-item v-if="isHasUnblackedUser" link @click="$emit('black')">
            <v-list-item-title>{{
              $t("button.add_blacklist")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="isHasBlackedUser" link @click="$emit('unblack')">
            <v-list-item-title>{{
              $t("button.remove_blacklist")
            }}</v-list-item-title>
          </v-list-item>

          <v-divider />
        </template>

        <template v-if="isDeletePerson || isRestorePerson">
          <v-list-item
            v-if="isDeletePerson && filter.mode !== 'deleted'"
            link
            @click="$emit('remove')"
          >
            <v-list-item-title>{{ $t("button.delete") }}</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="isRestorePerson && filter.mode === 'deleted'"
            link
            @click="$emit('restore')"
          >
            <v-list-item-title>{{ $t("button.restore") }}</v-list-item-title>
          </v-list-item>

          <v-divider />
        </template>

        <template v-if="isEditPerson">
          <v-list-item link @click="$emit('openDepartmentDialog')">
            <v-list-item-title>{{
              $t("users.setDepartment")
            }}</v-list-item-title>
          </v-list-item>

          <v-list-item link @click="$emit('openAccessLevelsDialog')">
            <v-list-item-title>{{
              $t("users.setAccessLevels")
            }}</v-list-item-title>
          </v-list-item>

          <v-divider />
        </template>
      </template>

      <template v-else>
        <template v-if="isBlockedPerson">
          <v-list-item link @click="$emit('blockByFilter')">
            <v-list-item-title>{{
              $t("button.blockByFilter")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="$emit('unblockByFilter')">
            <v-list-item-title>{{
              $t("button.unblockByFilter")
            }}</v-list-item-title>
          </v-list-item>

          <v-divider />
        </template>

        <template v-if="isBlackedPerson">
          <v-list-item link @click="$emit('blackByFilter')">
            <v-list-item-title>{{
              $t("button.add_blacklistByFilter")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item link @click="$emit('unblackByFilter')">
            <v-list-item-title>{{
              $t("button.remove_blacklistByFilter")
            }}</v-list-item-title>
          </v-list-item>

          <v-divider />
        </template>

        <template v-if="isDeletePerson || isRestorePerson">
          <v-list-item
            v-if="isDeletePerson"
            link
            @click="$emit('removeByFilter')"
          >
            <v-list-item-title>{{
              $t("button.deleteByFilter")
            }}</v-list-item-title>
          </v-list-item>

          <v-list-item
            v-if="isRestorePerson"
            link
            @click="$emit('restoreByFilter')"
          >
            <v-list-item-title>{{
              $t("button.restoreByFilter")
            }}</v-list-item-title>
          </v-list-item>

          <v-divider />
        </template>

        <template v-if="isEditPerson">
          <v-list-item link @click="$emit('openDepartmentDialog')">
            <v-list-item-title>{{
              $t("users.setDepartmentByFilter")
            }}</v-list-item-title>
          </v-list-item>

          <v-list-item link @click="$emit('openAccessLevelsDialog')">
            <v-list-item-title>{{
              $t("users.setAccessLevelsByFilter")
            }}</v-list-item-title>
          </v-list-item>

          <v-divider />
        </template>
      </template>

      <v-list-item link @click="$emit('report')">
        <v-list-item-title>{{ $t("button.reportUsers") }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import permissions from "@/mixins/permissions";

export default {
  name: "ListActions",

  mixins: [permissions],

  props: {
    selected: {
      type: Array,
      required: true,
    },

    filter: {
      type: Object,
      require: true,
    },
  },

  computed: {
    isEditPerson() {
      return this.can(this.$const.RULES.PERSON_EDIT);
    },

    isBlockedPerson() {
      return this.can(this.$const.RULES.PERSON_BLOCKED);
    },

    isBlackedPerson() {
      return this.can(this.$const.RULES.PERSON_BLACKED);
    },

    isRestorePerson() {
      return this.can(this.$const.RULES.PERSON_RESTORE);
    },

    isDeletePerson() {
      return this.can(this.$const.RULES.PERSON_DELETE);
    },

    isHasBlockedUser() {
      let isBlock = false;

      this.selected.forEach((item) => {
        if (item.blocked) isBlock = true;
      });

      return isBlock;
    },

    isHasUnblockedUser() {
      let isUnblock = false;

      this.selected.forEach((item) => {
        if (!item.blocked) isUnblock = true;
      });

      return isUnblock;
    },

    isHasBlackedUser() {
      let isBlack = false;

      this.selected.forEach((item) => {
        if (item.blacklisted) isBlack = true;
      });

      return isBlack;
    },

    isHasUnblackedUser() {
      let isUnblack = false;

      this.selected.forEach((item) => {
        if (!item.blacklisted) isUnblack = true;
      });

      return isUnblack;
    },
  },
};
</script>
