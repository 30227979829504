import DatePicker from "vue2-datepicker";

export default {
  methods: {
    switchLang(lang) {
      this.$vuetify.lang.current = lang;
      this.$moment.locale(lang);
      this.$i18n.locale = lang;
      DatePicker.locale(lang);
    },
  },
};
