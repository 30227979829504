import actions from "./actions";
import mutations from "./mutations";

const state = {
  isPending: false,
  isSuccess: false,
  isFailure: false,
  data: {
    lang: "en",
    show: false,
    temp_meas: "c",
    temperature_range: [],
    temperature_enabled: false,
    temperature_rise: false,
    version: "",
  },

  isPendingServerUrl: false,
  isSuccessServerUrl: false,
  isFailureServerUrl: false,
  errorMessageServerUrl: "",
  serverUrl: "",

  isPendingStore: false,
  isSuccessStore: false,
  isFailureStore: false,
  errorMessageStore: "",

  isPendingResetTerminal: false,
  isSuccessResetTerminal: false,
  isFailureResetTerminal: false,
  errorMessageResetTerminal: "",

  isPendingSettingsData: false,
  isSuccessSettingsData: false,
  isFailureSettingsData: false,
  errorMessageSettingsData: "",
  settingsData: {},

  isPendingBackup: false,
  isSuccessBackup: false,
  isFailureBackup: false,
  errorMessageBackup: "",

  isPendingRestore: false,
  isSuccessRestore: false,
  isFailureRestore: false,
  errorMessageRestore: "",

  isPendingRestoreBackup: false,
  isSuccessRestoreBackup: false,
  isFailureRestoreBackup: false,
  errorMessageRestoreBackup: "",

  isPendingBackupStop: false,
  isSuccessBackupStop: false,
  isFailureBackupStop: false,
  errorMessageBackupStop: "",

  isPendingDeleteBackup: false,
  isSuccessDeleteBackup: false,
  isFailureDeleteBackup: false,
  errorMessageDeleteBackup: "",

  isPendingDownloadBackup: false,
  isSuccessDownloadBackup: false,
  isFailureDownloadBackup: false,
  errorMessageDownloadBackup: "",

  isPendingBackupList: false,
  isSuccessBackupList: false,
  isFailureBackupList: false,
  errorMessageBackupList: "",
  backupList: [],

  isPendingBackupStatus: false,
  isSuccessBackupStatus: false,
  isFailureBackupStatus: false,
  errorMessageBackupStatus: "",
};

export default {
  state,
  mutations,
  actions,
};
