<template>
  <v-card color="#B0BEC5" elevation="0">
    <v-card-title class="text-h5">
      {{ $t("devices.relay") }} {{ relayState.id }}
    </v-card-title>

    <v-card-text>
      <v-row>
        <!--v-col cols="12">
          <v-select
            v-model="relayState.type_alarm_input"
            :items="typeAlarmInputOptions"
            :label="$t('devices.typeAlarmInput')"
            outlined
            hide-details
          />
        </v-col>

        <v-col cols="12">
          <v-subheader>{{ $t("devices.delay") }}</v-subheader>
          <v-slider
            v-model.number="relayState.open_relay_delay"
            class="align-center"
            max="255"
            min="0"
            hide-details
          >
            <template v-slot:append>
              <v-text-field
                v-model.number="relayState.open_relay_delay"
                hide-details
                max="255"
                min="0"
                step="1"
                type="number"
                style="width: 100px"
                outlined
              />
            </template>
          </v-slider>
        </v-col-->

        <!--v-col cols="12">
          <v-divider />
          <h5 class="mb-0">{{ $t("devices.alarmSettings") }}</h5>
        </v-col-->

        <v-col cols="12">
          <v-switch
            v-model="relayState.sync_relay_state"
            :label="$t('devices.syncRelayState')"
            hide-details
            class="mt-0"
          />
        </v-col>

        <v-col v-if="!relayState.sync_relay_state" cols="12">
          <p>{{ $t("devices.alarmTimeout") }}</p>
          <v-slider
            v-model="relayState.alarm_relay_delay"
            class="align-center"
            max="255"
            min="1"
            hide-details
          >
            <template v-slot:append>
              <v-text-field
                v-model="relayState.alarm_relay_delay"
                hide-details
                max="255"
                min="1"
                step="1"
                type="number"
                style="width: 100px"
                outlined
              />
            </template>
          </v-slider>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import debounce from "lodash.debounce";

export default {
  name: "Relay",

  watch: {
    relayState: {
      handler: debounce(function () {
        this.$emit("change", this.relayState);
      }, 300),
      deep: true,
    },
  },

  props: {
    relay: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      relayState: { ...this.relay },
      typeAlarmInputOptions: [
        { value: "open", text: this.$t("devices.normalOpen") },
        { value: "close", text: this.$t("devices.normalClose") },
      ],
    };
  },
};
</script>
