import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

const instance = axios.create({
  baseURL: "/api/v2/",
});

instance.interceptors.response.use(
  (res) => {
    if (
      res.data.status &&
      typeof res.data.status === "object" &&
      res.data.status.code !== 0
    ) {
      const error = new Error(res.data.status.message);
      error.response = res;

      throw error;
    }

    return res;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

const authHttp = axios.create({
  baseURL: "/",
});

const imgHttp = axios.create({
  baseURL: "/",
});

Vue.use(VueAxios, instance);

export { instance, authHttp, imgHttp };
