export default {
  set(state, payload) {
    state.data = payload;
  },

  setToken(state, payload) {
    state.token = payload;
  },

  setSuccess(state) {
    state.isSuccess = true;
  },

  setRole(state, payload) {
    state.selectedRole = payload;
    localStorage.setItem("selectedRole", payload);
  },

  resetRole(state) {
    state.selectedRole = 0;
    localStorage.removeItem("selectedRole");
  },

  setPasswordUpdate(state) {
    state.data.password_upd = true;
  },

  setLoadingCheckPass(state, payload) {
    state.isPendingCheckPass = payload.type === "IS_PENDING";
    state.isSuccessCheckPass = payload.type === "IS_SUCCESS";
    state.isFailureCheckPass = payload.type === "IS_FAILURE";
    state.errorMessageCheckPass = payload.payload;
  },
};
