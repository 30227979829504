import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const isMinifyFilter =
  window.localStorage.getItem("minifyDevicesFilter") || false;
const limit = window.localStorage.getItem("devicesLimit") || 10;

const state = {
  isPending: false,
  isSuccess: false,
  isFailure: false,
  errorMessage: "",

  isPendingRemove: false,
  isSuccessRemove: false,
  isFailureRemove: false,
  errorMessageRemove: "",

  data: [],
  limit: parseInt(limit),
  pagination: {
    current_page: 1,
    per_page: 30,
    total: 0,
  },
  canChangeFilterMode: false,
  isMinifyFilter: isMinifyFilter === "true" ? true : false,
  filter: [],

  isPendingScan: false,
  isSuccessScan: false,
  isFailureScan: false,
  errorMessageScan: "",

  isPendingMonitoring: false,
  isSuccessMonitoring: false,
  isFailureMonitoring: false,
  errorMessageMonitoring: "",

  dataMonitoring: [],

  scannedDevices: [],
  allDevicesIp: [],
};

export default {
  state,
  mutations,
  actions,
  getters,
};
