<template>
  <v-data-table
    :loading="isPending"
    :headers="headers"
    :items="departments"
    :items-per-page="pagination.per_page"
    hide-default-footer
    disable-sort
  >
    <template v-slot:top>
      <v-row class="mb-2 mt-5">
        <v-col v-if="errorMessage" cols="12">
          <Alert type="error">
            {{ errorMessage }}
          </Alert>
        </v-col>
        <v-col cols="12">
          <DepartmentsFilter v-model="filter" @input="onChangeFilter" />
        </v-col>
      </v-row>
    </template>

    <template #[`item.name`]="{ item }">
      <router-link
        v-if="can($const.RULES.DEPARTMENT_EDIT)"
        :to="{
          name: $const.ROUTES.EDIT_DEPARTMENT,
          params: {
            id: item.id,
            query: $route.query,
          },
        }"
        >{{ item.name }}</router-link
      >
      <template v-else>
        {{ item.name }}
      </template>
    </template>

    <template #[`item.parent`]="{ item }">
      <template v-if="item.parent">
        <router-link
          v-if="can($const.RULES.DEPARTMENT_EDIT)"
          :to="{
            name: $const.ROUTES.EDIT_DEPARTMENT,
            params: {
              id: item.parent.id,
              query: $route.query,
            },
          }"
        >
          {{ item.parent.name }}
        </router-link>
        <template v-else>
          {{ item.name }}
        </template>
      </template>
      <span v-else>
        {{ $t("departments.withoutParent") }}
      </span>
    </template>

    <template #[`item.actions`]="{ item }">
      <v-tooltip v-if="can($const.RULES.DEPARTMENT_EDIT)" top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            small
            icon
            color="primary"
            @click="goToEdit(item.id)"
          >
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("departments.change") }}</span>
      </v-tooltip>

      <v-tooltip v-if="can($const.RULES.DEPARTMENT_DELETE)" top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            small
            icon
            color="red"
            @click="onRemove(item.id)"
          >
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("departments.delete") }}</span>
      </v-tooltip>
    </template>

    <template #footer>
      <DataTableFooter
        ref="tableFooter"
        :pagination="pagination"
        :totalText="$t('departments.total')"
        :perPage="limit"
        @changePagination="onChangePagination"
        @changePerPage="onChangePerPage"
      />
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

import Alert from "@/components/Alert";
import DataTableFooter from "@/components/DataTableFooter";
import DepartmentsFilter from "./Filter";

import permissions from "@/mixins/permissions";
import filter from "@/mixins/filter";

export default {
  name: "DepartmentsList",

  mixins: [permissions, filter],

  components: { Alert, DepartmentsFilter, DataTableFooter },

  data() {
    return {
      page: parseInt(this.$route.query.page) || 1,

      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: this.$t("departments.name"),
          value: "name",
        },
        {
          text: this.$t("departments.parent"),
          value: "parent",
        },
        {
          value: "actions",
          align: "end",
          width: 100,
        },
      ],
    };
  },

  methods: {
    ...mapMutations({
      setLimit: "departments/setLimit",
    }),

    ...mapActions({
      get: "departments/getList",
      remove: "department/remove",
    }),

    onChangeFilter(filter) {
      this.updateFilter({ ...filter });
      this.page = 1;
      this.$refs.tableFooter.onChangePage(this.page);
    },

    onChangePerPage(limit) {
      this.setLimit(limit);
      this.page = 1;
      this.$refs.tableFooter.onChangePage(this.page);
    },

    onChangePagination(page) {
      this.page = page;

      this.getDepartments();
    },

    getDepartments() {
      const params = { ...this.filterToParams };

      if (this.page) params.page = this.page;
      if (this.limit) params.limit = this.limit;

      this.get(params);
    },

    goToEdit(id) {
      this.$router.push({
        name: this.$const.ROUTES.EDIT_DEPARTMENT,
        params: {
          id,
          query: this.$route.query,
        },
      });
    },

    onRemove(id) {
      if (!id) return;

      this.$root.$prompt({
        title: this.$t("message.Delete"),
        message: this.$t("message.confirmDelete"),
        btnAgree: this.$t("button.delete"),

        agree: async () => {
          await this.remove(id);
          this.getDepartments();
        },
      });
    },
  },

  computed: {
    ...mapState({
      isPending: (state) => state.departments.isPending,
      errorMessage: (state) => state.departments.errorMessage,
      departments: (state) => state.departments.list,
      pagination: (state) => state.departments.pagination,
      limit: (state) => state.departments.limit,
    }),

    filterToParams() {
      const params = {};

      if (this.filter.search) params.search = this.filter.search;

      return params;
    },
  },

  created() {
    this.initFilter({
      search: "",
    });

    this.getDepartments();
  },
};
</script>
