<template>
  <div>
    <SkeletonLoader v-if="isPending && isFirstPending" />

    <Alert v-if="isFailure" type="error">
      <template v-if="errorMessage">
        {{ errorMessage }}
      </template>
      <template v-else>
        {{ $t("message.errorLoading") }}
      </template>
    </Alert>

    <v-row v-if="isSuccess || !isFirstPending" type="flex">
      <v-col cols="12" md="4">
        <v-card elevation="2">
          <ImgProtected
            v-if="person.photo"
            :src="`/avatar/profile/${person.photo}`"
          />

          <v-img v-else height="200" class="primary">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-icon size="150" dark> mdi-account-circle </v-icon>
              </v-row>
            </template>
          </v-img>

          <v-card-actions>
            <v-tooltip v-if="person.error && person.error.length" right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  color="red"
                  @click="isOpenPersonErrorLogs = true"
                >
                  <v-icon>mdi-alert-circle-outline</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("users.errorLogs") }}</span>
            </v-tooltip>

            <ErrorLogsDialog
              :isOpen="isOpenPersonErrorLogs"
              :data="[...this.emptyPermitWarning, ...person.error]"
              @close="isOpenPersonErrorLogs = false"
            />

            <v-spacer />

            <v-tooltip v-if="!person.blacklisted && isBlackedPerson" top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon @click="onBlack">
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("button.add_blacklist") }}</span>
            </v-tooltip>
            <v-tooltip v-else-if="person.blacklisted && isBlackedPerson" top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  color="blue"
                  @click="onUnblack"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("button.remove_blacklist") }}</span>
            </v-tooltip>

            <v-tooltip v-if="!person.blocked && isBlockedPerson" top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon @click="onBlock">
                  <v-icon>mdi-cancel</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("button.block") }}</span>
            </v-tooltip>
            <v-tooltip v-else-if="person.blocked && isBlockedPerson" top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  color="blue"
                  @click="onUnblock"
                >
                  <v-icon>mdi-cancel</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("button.unblock") }}</span>
            </v-tooltip>

            <v-tooltip v-if="!person.request_id && isAddRequest" top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon @click="goToAddRequest">
                  <v-icon>mdi-lock-check</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("user.createRequest") }}</span>
            </v-tooltip>
            <v-tooltip v-else-if="person.request_id && isViewRequest" top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  color="blue"
                  @click="goToRequest(person.request_id)"
                >
                  <v-icon>mdi-lock-check</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("user.toRequestPage") }}</span>
            </v-tooltip>

            <v-tooltip v-if="!person.deleted && isDeletePerson" top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  color="red"
                  @click="onDelete"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("button.delete") }}</span>
            </v-tooltip>
            <v-tooltip v-else-if="person.deleted && isRestorePerson" top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  color="blue"
                  @click="onRestore"
                >
                  <v-icon>mdi-restore</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("button.restore") }}</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" md="8">
        <Alert v-if="person.deleted" type="warning">
          {{ $t("user.deleted") }}
        </Alert>

        <Alert v-if="person.is_twin" type="info">
          {{ $t("user.isTwin") }}
        </Alert>

        <Alert v-if="person.on_object" type="success">
          {{ $t("statistic.advanced") }}
        </Alert>

        <v-card elevation="2" :loading="isPending">
          <template slot="progress">
            <v-progress-linear color="primary" height="10" indeterminate />
          </template>

          <v-card-title>
            <v-row>
              <v-col cols="auto">
                {{ person.last_name }} {{ person.first_name }}
                {{ person.middle_name }}
              </v-col>
              <v-spacer />

              <v-col v-if="isEditOperator && person.operator_id" cols="auto">
                <v-btn color="primary" dark @click="goToEditOperator">
                  <v-icon left>mdi-account-star</v-icon>
                  {{ $t("users.guard") }}
                </v-btn>
              </v-col>
              <v-col v-if="isEditPerson" cols="auto">
                <v-btn color="green" dark @click="goToEdit">
                  <v-icon left>mdi-pencil</v-icon>
                  {{ $t("user.edit") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="6" class="grey--text">ID</v-col>
              <v-col cols="6">{{ person.id }}</v-col>
            </v-row>
            <v-row v-if="person.department">
              <v-col cols="6" class="grey--text">{{
                $t("users.department")
              }}</v-col>
              <v-col cols="6">{{ person.department.name }}</v-col>
            </v-row>
            <v-row v-if="person.phone">
              <v-col cols="6" class="grey--text">{{ $t("user.phone") }}</v-col>
              <v-col cols="6">{{ person.phone }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="grey--text">{{
                $t("user.createdAt")
              }}</v-col>
              <v-col cols="6">{{
                person.created_at | moment("DD.MM.YYYY HH:mm")
              }}</v-col>
            </v-row>
            <v-row v-if="person.updated_at">
              <v-col cols="6" class="grey--text">{{
                $t("user.updatedAt")
              }}</v-col>
              <v-col cols="6">{{
                person.updated_at | moment("DD.MM.YYYY HH:mm")
              }}</v-col>
            </v-row>
            <v-row v-if="person.deleted_at">
              <v-col cols="6" class="grey--text">{{
                $t("user.deletedAt")
              }}</v-col>
              <v-col cols="6">{{
                person.deleted_at | moment("DD.MM.YYYY HH:mm")
              }}</v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="grey--text">{{ $t("user.note") }}</v-col>
              <v-col cols="6">
                <AbbreviatedTextArea
                  v-if="person.note"
                  :value="person.note"
                  bidirectional
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <v-row align-content="center">
              <v-col>{{ $t("user.visitStatistics") }}</v-col>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="auto">
                <date-picker
                  v-model="dateRange"
                  type="datetime"
                  range
                  :format="DATE_FORMAT"
                  value-type="format"
                  :placeholder="$t('advanced.dateTime')"
                  confirm
                  :clearable="false"
                  :disabled-date="notBeforeAndAfter100Days"
                  @confirm="submit"
                  @pick="onPickDate"
                  @close="onCloseDatepicker"
                >
                  <template v-slot:header>
                    {{ $t("advanced.range100days") }}
                  </template>
                </date-picker>
              </v-col>
              <v-spacer />
              <v-col cols="12" sm="auto">
                <v-menu offset-y bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="primary" v-bind="attrs" v-on="on">
                      {{ $t("advanced.allEvents") }}&nbsp;
                      <v-icon right> mdi-menu-down </v-icon>
                    </v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item
                      link
                      @click="getEventsReport('report/worktime')"
                    >
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("advanced.worktime")
                        }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-tooltip left max-width="250">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              color="grey lighten-1"
                              >mdi-information</v-icon
                            >
                          </template>
                          <span>{{ $t("advanced.onlyZoneWithWorktime") }}</span>
                        </v-tooltip>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item link @click="getEventsReport('report/iotime')">
                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("advanced.iotime")
                        }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-tooltip left max-width="250">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              v-bind="attrs"
                              v-on="on"
                              color="grey lighten-1"
                              >mdi-information</v-icon
                            >
                          </template>
                          <span>{{ $t("advanced.onlyZoneWithWorktime") }}</span>
                        </v-tooltip>
                      </v-list-item-action>
                    </v-list-item>
                    <v-list-item link @click="getEventsReport('report/passed')">
                      <v-list-item-title>{{
                        $t("advanced.passed")
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-tabs v-model="tab" color="primary">
                  <v-tabs-slider></v-tabs-slider>
                  <v-tab href="#tab-1">
                    {{ this.$t("user.allPassages") }}
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <v-tab-item value="tab-1">
                    <v-card flat>
                      <v-data-table :headers="passesHeaders" :items="passesLog">
                      </v-data-table>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import SkeletonLoader from "./components/SkeletonLoader";
import ErrorLogsDialog from "./components/ErrorLogsDialog";
import Alert from "@/components/Alert";
import ImgProtected from "@/components/ImgProtected";
import AbbreviatedTextArea from "@/components/AbbreviatedTextArea.vue";

import permissions from "@/mixins/permissions";

const DATE_FORMAT = "DD.MM.YYYY HH:mm";
const DATE_LOG_FORMAT = "DD.MM.YYYY";
const TIME_FORMAT = "HH:mm";

export default {
  name: "PersonShow",

  mixins: [permissions],

  components: {
    SkeletonLoader,
    Alert,
    ImgProtected,
    ErrorLogsDialog,
    AbbreviatedTextArea,
  },

  data() {
    const { id } = this.$route.params;

    return {
      tab: null,

      dateRange: [
        this.$moment().startOf("month").format(DATE_FORMAT),
        this.$moment().format(DATE_FORMAT),
      ],
      id: parseInt(id),
      DATE_FORMAT,

      isFirstPending: true,

      isOpenPersonErrorLogs: false,

      startDate: this.$moment().startOf("month").format(DATE_FORMAT),
      endDate: this.$moment().endOf("month").format(DATE_FORMAT),

      thisMonth: "",
      months: [],
      filters: {},
      passesLog: [],
    };
  },

  methods: {
    ...mapActions({
      getPerson: "person/get",
      block: "persons/block",
      unblock: "persons/unblock",
      black: "persons/black",
      unblack: "persons/unblack",
      remove: "persons/remove",
      restore: "persons/restore",
      report: "passes/report",
      getPassesAction: "passes/get",
    }),

    getEventsReport(url, istemp = false) {
      const params = {
        istemp,
        startDate: this.$moment(this.startDate, DATE_FORMAT).toISOString(),
        endDate: this.$moment(this.endDate, DATE_FORMAT).toISOString(),
        personId: this.id,
        lang: this.settings.lang,
      };

      this.report({ params, url });
    },
    getEventsLog() {
      const params = {
        startDate: this.$moment(this.startDate, DATE_FORMAT).toISOString(),
        endDate: this.$moment(this.endDate, DATE_FORMAT).toISOString(),
        personId: this.id,
        lang: this.settings.lang,
      };

      this.getPassesAction(params).then(() => {
        const list = [];
        this.passes.forEach((element) => {
          const item = {
            ...element,
            date: this.$moment(element.created_at).format(DATE_LOG_FORMAT),
            time: this.$moment(element.created_at).format(TIME_FORMAT),
            zone: element.event.zone,
            temperature:
              element.event.temperature != "0.0"
                ? this.calculateCalcFeel(element.event.temperature)
                : null,
          };
          list.unshift(item);
        });
        this.passesLog = list;
      });
    },

    setMonths() {
      let m = {
        "01": "January",
        "02": "February",
        "03": "March",
        "04": "April",
        "05": "May",
        "06": "June",
        "07": "July",
        "08": "August",
        "09": "September",
        10: "October",
        11: "November",
        12: "December",
      };

      let tm = new this.$moment();
      //.format("YYYY-MM")
      for (let i = 0; i < 12; i++) {
        this.months.push({
          name: tm.format("YYYY-MM"),
          title:
            tm.format("YYYY") +
            " - " +
            this.$t("dtpicker." + m[tm.format("MM")]),
        });
        tm.add(-1, "M");
      }
      if (this.months.length > 0) {
        this.thisMonth = this.months[0].name;
      }
    },

    submit() {
      if (this.dateRange[0]) {
        this.startDate = this.$moment(this.dateRange[0], DATE_FORMAT);
      }

      if (this.dateRange[1]) {
        this.endDate = this.$moment(this.dateRange[1], DATE_FORMAT);
      }

      this.getEventsLog();
    },

    onPickDate(date) {
      if (this.isFirstPickDate) {
        this.pickDate = date;
        this.isFirstPickDate = false;
      }
    },

    onCloseDatepicker() {
      this.pickDate = "";
      this.isFirstPickDate = true;
    },

    notBeforeAndAfter100Days(day) {
      let isDisabled = false;

      if (this.pickDate) {
        const currentDay = this.$moment(day);
        const pickerDay = this.$moment(this.pickDate);
        const before100Day = this.$moment(pickerDay).add(-99, "days");
        const after100Day = this.$moment(pickerDay).add(99, "days");

        if (
          currentDay.isAfter(after100Day) ||
          currentDay.isBefore(before100Day)
        ) {
          isDisabled = true;
        }
      }

      return isDisabled;
    },

    goToEditOperator() {
      this.$router.push({
        name: this.$const.ROUTES.EDIT_OPERATOR,
        params: { id: this.person.operator_id },
      });
    },

    goToEdit() {
      this.$router.push({
        name: this.$const.ROUTES.EDIT_PERSON,
        params: { id: this.id },
      });
    },

    goToAddRequest() {
      this.$router.push({ name: this.$const.ROUTES.ADD_PERMIT_REQUEST });
    },

    goToRequest(id) {
      this.$router.push({
        name: this.$const.ROUTES.EDIT_PERMIT_REQUEST,
        params: { id },
      });
    },

    onDelete() {
      this.$root.$prompt({
        title: this.$t("message.Delete"),
        message: this.$t("message.confirmDelete"),
        btnAgree: this.$t("button.delete"),
        agree: this.delete,
      });
    },
    calculateCalcFeel(t) {
      if (this.settings && this.settings.temp_meas == "f") {
        return Math.round(((t * 9) / 5 + 32) * 10) / 10;
      } else {
        return t;
      }
    },

    delete() {
      const deletePerson = async (isDeleteOperator = false) => {
        const params = { ids: [this.id] };

        if (isDeleteOperator) {
          params.is_delete_operator = true;
        }

        await this.remove(params);
        this.getPerson({ id: this.id });
      };

      if (this.isDeleteOperator && this.person.operator_id) {
        this.$root.$prompt({
          title: this.$t("user.deleteBindingOperator"),
          message: this.$t("user.deleteBindingOperatorMessage"),
          width: 500,
          btnAgree: this.$t("common.yes"),
          btnDisagree: this.$t("common.no"),

          agree: async () => {
            deletePerson(true);
          },

          disagree: async () => {
            deletePerson(false);
          },
        });
      } else {
        deletePerson();
      }
    },

    async onBlack() {
      await this.black({ ids: [this.id] });
      this.getPerson({ id: this.id });
    },

    async onUnblack() {
      await this.unblack({ ids: [this.id] });
      this.getPerson({ id: this.id });
    },

    async onBlock() {
      await this.block({ ids: [this.id] });
      this.getPerson({ id: this.id });
    },

    async onUnblock() {
      await this.unblock({ ids: [this.id] });
      this.getPerson({ id: this.id });
    },

    async onRestore() {
      await this.restore({ ids: [this.id] });
      this.getPerson({ id: this.id });
    },
  },

  computed: {
    ...mapState({
      isPending: (state) => state.person.isPending,
      isSuccess: (state) => state.person.isSuccess,
      isFailure: (state) => state.person.isFailure,
      errorMessage: (state) => state.person.errorMessage,
      person: (state) => state.person.data,
      passes: (state) => state.passes.data,

      isPendingVisits: (state) => state.person.isPendingVisits,
      isFailureVisits: (state) => state.person.isFailureVisits,
      errorMessageVisits: (state) => state.person.errorMessageVisits,

      settings: (state) => state.settings.data,
    }),

    isEditOperator() {
      return (
        !this.settings.cloud_connect &&
        this.can(this.$const.RULES.OPERATOR_EDIT)
      );
    },

    passesHeaders() {
      return [
        {
          text: this.$t("user.statDate"),
          value: "date",
        },
        {
          text: this.$t("users.visit_time"),
          value: "time",
        },
        {
          text: this.$t("statistic.zone"),
          value: "zone",
        },
        {
          text: this.settings
            ? `°${this.settings.temp_meas.toUpperCase()}`
            : null,
          value: "temperature",
        },
      ];
    },

    isDeleteOperator() {
      return (
        !this.settings.cloud_connect &&
        this.can(this.$const.RULES.OPERATOR_DELETE)
      );
    },

    isEditPerson() {
      return (
        this.$auth.user().id != this.id &&
        !this.settings.cloud_connect &&
        this.can(this.$const.RULES.PERSON_EDIT)
      );
    },

    isDeletePerson() {
      return (
        this.$auth.user().id != this.id &&
        !this.settings.cloud_connect &&
        this.can(this.$const.RULES.PERSON_DELETE)
      );
    },

    isRestorePerson() {
      return (
        this.$auth.user().id != this.id &&
        !this.settings.cloud_connect &&
        this.can(this.$const.RULES.PERSON_RESTORE)
      );
    },

    isBlockedPerson() {
      return (
        this.$auth.user().id != this.id &&
        !this.settings.cloud_connect &&
        this.can(this.$const.RULES.PERSON_BLOCKED)
      );
    },

    isBlackedPerson() {
      return (
        this.$auth.user().id != this.id &&
        !this.settings.cloud_connect &&
        this.can(this.$const.RULES.PERSON_BLACKED)
      );
    },

    isViewRequest() {
      return this.can(this.$const.RULES.REQUEST_VIEW);
    },

    isAddRequest() {
      return this.can(this.$const.RULES.REQUEST_ADD);
    },

    emptyPermitWarning() {
      let result = [
        {
          type: "warning",
          message: this.$t("message.permitsIsEmpty"),
        },
      ];

      for (let i = 0; i < this.person.permits.length; i++) {
        const element = this.person.permits[i];

        if (element.is_active) {
          result = [];

          break;
        }
      }

      return result;
    },
  },

  async created() {
    await this.getPerson({ id: this.id });
    this.isFirstPending = false;
    this.setMonths();
    this.getEventsLog();
  },
};
</script>
