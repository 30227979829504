import { validationMixin } from "vuelidate";
import {
  required,
  integer,
  minValue,
  minLength,
} from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],

  validations: {
    type: { required },
    pass: { required, integer, minValue: minValue(0) },
    passChange: { integer, minValue: minValue(0) },
    permitAccess: { required, minLength: minLength(1) },
  },

  computed: {
    typeErrors() {
      const errors = [];
      if (!this.$v.type.$dirty) return errors;

      !this.$v.type.required && errors.push(this.$t("message.NameIsRequired"));

      return errors;
    },

    passErrors() {
      const errors = [];
      if (!this.$v.pass.$dirty) return errors;

      !this.$v.pass.minValue &&
        errors.push(
          this.$t("message.minValue", {
            value: this.$v.pass.$params.minValue.min,
          })
        );
      !this.$v.pass.integer && errors.push(this.$t("message.isInt"));
      !this.$v.pass.required && errors.push(this.$t("message.NameIsRequired"));

      return errors;
    },

    passChangeErrors() {
      const errors = [];
      if (!this.$v.passChange.$dirty) return errors;

      !this.$v.passChange.minValue &&
        errors.push(
          this.$t("message.minValue", {
            value: this.$v.passChange.$params.minValue.min,
          })
        );
      !this.$v.passChange.integer && errors.push(this.$t("message.isInt"));

      return errors;
    },

    permitAccessErrors() {
      const errors = [];
      if (!this.$v.permitAccess.$dirty) return errors;

      (!this.$v.permitAccess.required || !this.$v.permitAccess.minLength) &&
        errors.push(this.$t("message.NameIsRequired"));

      return errors;
    },
  },

  methods: {
    validateForm() {
      let isInvalid = false;
      this.$v.$touch();
      isInvalid = this.$v.$invalid;

      for (let i = 0; i < this.tokens.length; i++) {
        const element = this.$refs[`token-${this.tokens[i].loopId}`][0].$v;
        element.$touch();
        if (element.$invalid) {
          isInvalid = true;
        }
      }

      return !isInvalid;
    },
  },
};
