<template>
  <v-card class="mx-auto" outlined color="#F3F2FF">
    <v-card-text>
      <v-row>
        <v-col>
          <Search
            :value="value.search"
            :debounce="0"
            :saveSearchHistory="$const.SEARCH_HISTORY.ACCESS_LEVELS"
            @search="submit('search', $event)"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Search from "@/components/Search";

export default {
  name: "DepartmentsFilter",

  components: { Search },

  props: {
    value: {
      type: Object,
      default: () => ({
        search: "",
      }),
    },
  },

  methods: {
    submit(key, value) {
      this.$emit("input", { ...this.value, [key]: value });
    },
  },
};
</script>
