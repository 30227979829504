<template>
  <div>
    <Alert v-if="isFailure" type="error">
      <template v-if="errorMessage">
        {{ errorMessage }}
      </template>
      <template v-else>
        {{ $t("message.errorLoading") }}
      </template>
    </Alert>

    <Form
      ref="form"
      isMulti
      :isPendingStore="isPending"
      :isShowForm="isParsedContacts"
      :isShowPermitTokens="false"
      @store="onStore"
    >
      <template v-slot:prepend>
        <v-textarea
          v-if="!isParsedContacts"
          v-model="contacts"
          :label="$t('requests.insertContacts')"
          :placeholder="$t('requests.insertContactsExample')"
          outlined
        />
        <template v-else>
          <v-expansion-panels focusable class="mt-5">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-row align="center">
                  <v-col>
                    {{ $t("requests.parsedRequestItems") }} -
                    {{ contactsParsed.length }}
                  </v-col>
                  <v-spacer />
                  <v-col cols="auto">
                    <v-btn small icon @click="isParsedContacts = false">
                      <v-icon small>mdi-pencil</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          {{ $t("requests.name") }}
                        </th>
                        <th class="text-left">
                          {{ $t("requests.email") }}
                        </th>
                        <th class="text-left">
                          {{ $t("requests.phone") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in contactsParsed" :key="index">
                        <td>{{ item.full_name }}</td>
                        <td>{{ item.email }}</td>
                        <td>{{ item.phone }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
      </template>

      <template v-slot:append>
        <v-row v-if="isSuccess">
          <v-col>
            <v-progress-linear
              :value="importProgress"
              :color="importProgress === 100 ? 'success' : 'primary'"
              height="28"
              rounded
            >
              <strong
                >{{ $t("requests.import") }} -
                {{ Math.ceil(importProgress) }}%</strong
              >
            </v-progress-linear>
          </v-col>

          <v-col v-if="importErrors.length" cols="auto">
            <v-dialog v-model="isShowImportErrorDialog" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-badge
                  color="primary"
                  :content="importErrors.length"
                  bordered
                  overlap
                >
                  <v-btn small color="error" v-bind="attrs" v-on="on">
                    <v-icon left>mdi-alert-circle-outline</v-icon>
                    {{ $t("requests.errors") }}
                  </v-btn>
                </v-badge>
              </template>

              <v-card>
                <v-card-title>
                  <span class="headline"
                    >{{ $t("requests.errors") }} -
                    {{ importErrors.length }}</span
                  >
                </v-card-title>

                <v-card-text>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <tbody>
                        <tr v-for="(item, index) in importErrors" :key="index">
                          <td>{{ item.full_name }}</td>
                          <td>
                            {{ item.message }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>

                <v-divider />

                <v-card-actions>
                  <v-spacer />
                  <v-btn color="success" text @click="correct">
                    {{ $t("button.refeature") }}
                  </v-btn>
                  <v-btn text @click="isShowImportErrorDialog = false">
                    {{ $t("button.close") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>

          <v-col v-if="importInfo.length" cols="auto">
            <v-dialog v-model="isShowImportInfoDialog" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-badge
                  color="primary"
                  :content="importInfo.length"
                  bordered
                  overlap
                >
                  <v-btn small color="info" v-bind="attrs" v-on="on">
                    <v-icon left>mdi-information-outline</v-icon>
                    {{ $t("requests.info") }}
                  </v-btn>
                </v-badge>
              </template>

              <v-card>
                <v-card-title>
                  <span class="headline"
                    >{{ $t("requests.info") }} - {{ importInfo.length }}</span
                  >
                </v-card-title>

                <v-card-text>
                  <v-simple-table dense>
                    <template v-slot:default>
                      <tbody>
                        <tr v-for="(item, index) in importInfo" :key="index">
                          <td>{{ item.full_name }}</td>
                          <td>
                            {{ item.message }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>

                <v-divider />

                <v-card-actions>
                  <v-spacer />
                  <v-btn text @click="isShowImportInfoDialog = false">
                    {{ $t("button.close") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
        </v-row>
      </template>

      <template v-slot:actions>
        <v-btn :loading="isPending" @click="cancel">
          <template v-if="importProgress < 100">
            <v-icon left>mdi-cancel</v-icon>
            {{ $t("button.cancel") }}
          </template>
          <template v-else>
            <v-icon left>mdi-arrow-left</v-icon>
            {{ $t("button.exit") }}
          </template>
        </v-btn>
        <v-spacer />
        <v-btn v-if="!isParsedContacts" color="primary" @click="parseContacts">
          {{ $t("button.continue") }}
          <v-icon right>mdi-arrow-right</v-icon>
        </v-btn>
        <v-btn
          v-else-if="importProgress < 100"
          color="green"
          dark
          :loading="isPending"
          @click="save"
        >
          <v-icon left>mdi-content-save-outline</v-icon>
          {{ $t("button.save") }}
        </v-btn>
      </template>
    </Form>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

import Form from "./components/Form";
import Alert from "@/components/Alert";

export default {
  name: "AddMultiRequest",

  components: { Form, Alert },

  data() {
    const { query } = this.$route.params;

    return {
      isParsedContacts: false,
      contacts: "",
      contactsParsed: [],

      importProgress: 0,
      importErrors: [],
      importInfo: [],
      isShowImportErrorDialog: false,
      isShowImportInfoDialog: false,

      query,
    };
  },

  methods: {
    ...mapMutations({
      setLoading: "request/setLoading",
    }),

    ...mapActions({ store: "request/multiStore" }),

    cancel() {
      this.$router.push({
        name: this.$const.ROUTES.PERMIT_REQUESTS,
        query: this.query,
      });
    },

    save() {
      this.$refs.form.save();
    },

    parseContacts() {
      const newLineRegex = /[\n\r]/g;
      const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
      const phoneRegex = /^[+]?\d+$/;
      const nameRegex =
        /^[a-zA-ZА-Яа-яЁё]+(([',. -][a-zA-ZА-Яа-яЁё ])?[a-zA-ZА-Яа-яЁё]*)*$/;

      const lines = this.contacts.split(newLineRegex);

      this.contactsParsed = lines
        .map((line) => {
          const contact = {};

          const splittedLine = line
            .replace(/\s*[,]\s*/, ";")
            .replace(/\s*[;]\s*/, ";")
            .replace(/\s*[\r\t]\s*/g, ";")
            .split(";");

          const fullName = splittedLine.find((value) => nameRegex.test(value));

          if (!fullName) {
            return undefined;
          }

          contact.full_name = fullName;
          contact.email = splittedLine.find((value) => emailRegex.test(value));
          contact.phone = splittedLine.find((value) => phoneRegex.test(value));

          return contact;
        })
        .filter((contact) => contact);

      this.isParsedContacts = true;
    },

    correct() {
      this.setLoading({ type: "IS_RESET_STORE" });
      this.isShowImportErrorDialog = false;
      this.isParsedContacts = false;
      this.importProgress = 0;

      const contactsErrorList = this.importErrors.map((error) => {
        const contact = this.contactsParsed.find(
          (contact) => contact.full_name === error.full_name
        );

        let result = contact.full_name;

        if (contact.phone) {
          result += `;${contact.phone}`;
        }

        if (contact.email) {
          result += `;${contact.email}`;
        }

        return result;
      });

      this.contactsParsed = [];
      this.importErrors = [];
      this.importInfo = [];
      this.contacts = contactsErrorList.join("\n");
    },

    async onStore(data) {
      await this.store({
        ...data,
        contacts: this.contactsParsed,
      });
    },

    onRequestProgress({ proc }) {
      this.importProgress = proc;
    },

    onRequestLog(log) {
      switch (log.type) {
        case "error":
          this.importErrors.push(log);
          break;
        case "info":
          this.importInfo.push(log);
          break;
        default:
          break;
      }
    },
  },

  computed: {
    ...mapState({
      isPending: (state) => state.request.isPendingStore,
      isSuccess: (state) => state.request.isSuccessStore,
      isFailure: (state) => state.request.isFailureStore,
      errorMessage: (state) => state.request.errorMessageStore,
    }),
  },

  created() {
    this.$root.$on(
      this.$const.WS_CHANNELS.PERMIT_REQUEST_LOG,
      this.onRequestLog
    );

    this.$root.$on(
      this.$const.WS_CHANNELS.PERMIT_REQUEST_PROGRESS,
      this.onRequestProgress
    );
  },

  destroyed() {
    this.$root.$off(this.$const.WS_CHANNELS.PERMIT_REQUEST_LOG);
    this.$root.$off(this.$const.WS_CHANNELS.PERMIT_REQUEST_PROGRESS);
  },
};
</script>
