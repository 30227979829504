<template>
  <div>
    <Alert v-if="isFailure" type="error" prominent>
      <v-row align="center">
        <v-col v-if="errorMessage">
          {{ getLangMessage(errorMessage) }}
        </v-col>
        <v-col v-else>
          {{ $t("message.errorLoading") }}
        </v-col>

        <v-spacer />

        <v-col v-if="errorData" cols="auto">
          <router-link
            :to="{
              name: $const.ROUTES.SHOW_PERSON,
              params: { id: errorData.id },
            }"
            target="_blank"
          >
            <span class="mr-2">
              {{ errorData.full_name }}
            </span>
            <v-avatar v-if="errorData.photo" size="40">
              <ImgProtected :src="`avatar/small/${errorData.photo}`" />
            </v-avatar>
            <v-avatar v-else color="primary" size="40">
              <v-icon dark> mdi-account-circle </v-icon>
            </v-avatar>
          </router-link>
        </v-col>
      </v-row>
    </Alert>

    <Form
      :data="data"
      :isPendingStore="isPending"
      @store="onStore"
      @cancel="onCancel"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

import Form from "./components/Form";
import Alert from "@/components/Alert";
import ImgProtected from "@/components/ImgProtected";

import faceValidate from "@/mixins/faceValidate";

export default {
  name: "AddPerson",

  mixins: [faceValidate],

  components: { Form, Alert, ImgProtected },

  data() {
    const { person, query } = this.$route.params;

    return {
      data: person || null,
      query,
    };
  },

  methods: {
    ...mapActions({ store: "person/store" }),

    ...mapMutations({
      setLoading: "person/setLoading",
    }),

    async onStore(data) {
      await this.store(data);

      if (!this.isFailure) {
        this.$router.push({
          name: this.$const.ROUTES.PERSONS,
          query: this.query,
        });
      }
    },

    onCancel() {
      this.$router.push({
        name: this.$const.ROUTES.PERSONS,
        query: this.query,
      });
    },
  },

  computed: {
    ...mapState({
      isPending: (state) => state.person.isPendingStore,
      isFailure: (state) => state.person.isFailureStore,
      errorMessage: (state) => state.person.errorMessageStore,
      errorData: (state) => state.person.errorDataStore,
    }),
  },

  destroyed() {
    this.setLoading({ type: "IS_RESET_STORE" });
  },
};
</script>
