import Vue from "vue";
import Vuex from "vuex";
import { createStore } from "vuex-extensions";
import modules from "./modules";

Vue.use(Vuex);

const store = createStore(Vuex.Store, {
  modules,
  strict: process.env.NODE_ENV !== "production",
  mixins: {
    mutations: {
      setLoading(state, { type, payload = "" }) {
        switch (type) {
          // Common
          case "IS_PENDING":
            state.isPending = true;
            state.isSuccess = false;
            state.isFailure = false;
            state.errorMessage = "";
            break;
          case "IS_SUCCESS":
            state.isPending = false;
            state.isSuccess = true;
            state.isFailure = false;
            state.errorMessage = "";
            break;
          case "IS_FAILURE":
            state.isPending = false;
            state.isSuccess = false;
            state.isFailure = true;
            state.errorMessage = payload;
            break;

          // Store
          case "IS_RESET_STORE":
            state.isPendingStore = false;
            state.isSuccessStore = false;
            state.isFailureStore = false;
            state.errorMessageStore = "";
            break;
          case "IS_PENDING_STORE":
            state.isPendingStore = true;
            state.isSuccessStore = false;
            state.isFailureStore = false;
            state.errorMessageStore = "";
            break;
          case "IS_SUCCESS_STORE":
            state.isPendingStore = false;
            state.isSuccessStore = true;
            state.isFailureStore = false;
            state.errorMessageStore = "";
            break;
          case "IS_FAILURE_STORE":
            state.isPendingStore = false;
            state.isSuccessStore = false;
            state.isFailureStore = true;
            state.errorMessageStore = payload;
            break;

          // Edit
          case "IS_PENDING_EDIT":
            state.isPendingEdit = true;
            state.isSuccessEdit = false;
            state.isFailureEdit = false;
            state.errorMessageEdit = "";
            break;
          case "IS_SUCCESS_EDIT":
            state.isPendingEdit = false;
            state.isSuccessEdit = true;
            state.isFailureEdit = false;
            state.errorMessageEdit = "";
            break;
          case "IS_FAILURE_EDIT":
            state.isPendingEdit = false;
            state.isSuccessEdit = false;
            state.isFailureEdit = true;
            state.errorMessageEdit = payload;
            break;

          // Remove
          case "IS_PENDING_REMOVE":
            state.isPendingRemove = true;
            state.isSuccessRemove = false;
            state.isFailureRemove = false;
            state.errorMessageRemove = "";
            break;
          case "IS_SUCCESS_REMOVE":
            state.isPendingRemove = false;
            state.isSuccessRemove = true;
            state.isFailureRemove = false;
            state.errorMessageRemove = "";
            break;
          case "IS_FAILURE_REMOVE":
            state.isPendingRemove = false;
            state.isSuccessRemove = false;
            state.isFailureRemove = true;
            state.errorMessageRemove = payload;
            break;

          // Pagination
          case "IS_PENDING_PAGINATION":
            state.isPendingPagination = true;
            state.isSuccessPagination = false;
            state.isFailurePagination = false;
            state.errorMessagePagination = "";
            break;
          case "IS_SUCCESS_PAGINATION":
            state.isPendingPagination = false;
            state.isSuccessPagination = true;
            state.isFailurePagination = false;
            state.errorMessagePagination = "";
            break;
          case "IS_FAILURE_PAGINATION":
            state.isPendingPagination = false;
            state.isSuccessPagination = false;
            state.isFailurePagination = true;
            state.errorMessagePagination = payload;
            break;

          // Reset
          default:
            state.isPending = false;
            state.isSuccess = false;
            state.isFailure = false;
            state.errorMessage = "";
            break;
        }
      },
    },
  },
});

// Автоматически запустить действие `init` для каждого существующего модуля
for (const moduleName of Object.keys(modules)) {
  if (modules[moduleName].actions.init) {
    store.dispatch(`${moduleName}/init`);
  }
}

export default store;
