export default {
  set(state, payload) {
    state.data = payload;
  },

  setRoles(state, payload) {
    state.roles = payload;
  },

  setLoadingRoles(state, payload) {
    state.isPendingRoles = payload.type === "IS_PENDING";
    state.isSuccessRoles = payload.type === "IS_SUCCESS";
    state.isFailureRoles = payload.type === "IS_FAILURE";
    state.errorMessageRoles = payload.payload;
  },
};
