import Vue from "vue";
import i18n from "@/plugins/i18n";

export default {
  get({ commit }, { id }) {
    commit("setLoading", { type: "IS_PENDING" });

    return Vue.axios(`request/${id}`)
      .then((response) => {
        const { data } = response.data;

        commit("set", data);
        commit("setLoading", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.errorLoading"),
        });
      });
  },

  store({ commit }, data) {
    commit("setLoading", { type: "IS_PENDING_STORE" });

    return Vue.axios
      .post("requests/add", data)
      .then(() => {
        commit("setLoading", { type: "IS_SUCCESS_STORE" });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE_STORE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.saveError"),
        });
      });
  },

  multiStore({ commit }, data) {
    commit("setLoading", { type: "IS_PENDING_STORE" });

    return Vue.axios
      .post("requests/multi", data)
      .then(() => {
        commit("setLoading", { type: "IS_SUCCESS_STORE" });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE_STORE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.saveError"),
        });
      });
  },

  edit({ commit }, { id, data }) {
    commit("setLoading", { type: "IS_PENDING_STORE" });

    return Vue.axios
      .put(`request/${id}`, data)
      .then(() => {
        commit("setLoading", { type: "IS_SUCCESS_STORE" });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE_STORE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.saveError"),
        });
      });
  },

  setStatus({ commit }, { id, status }) {
    Vue.axios
      .put(`request/${id}/status`, {
        status,
      })
      .then(() => {
        commit("setStatus", status);

        Vue.prototype.$notify({
          group: "info",
          type: "success",
          text: i18n.t("requests.changeStatusSuccessMessage"),
        });
      })
      .catch((error) => {
        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: error.response.data.status.message,
        });
      });
  },

  resend({ commit }, { id }) {
    Vue.axios(`request/resend/${id}`)
      .then(() => {
        Vue.prototype.$notify({
          group: "info",
          type: "success",
          text: i18n.t("requests.resendSuccessMessage"),
        });
      })
      .catch((error) => {
        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: error.response.data.status.message,
        });
      });
  },
};
