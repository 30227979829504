<template>
  <v-alert
    border="left"
    :color="color"
    elevation="2"
    colored-border
    :prominent="prominent"
    :icon="computedIcon"
  >
    <slot />

    <template v-if="hasItemSlot('append')" v-slot:append>
      <slot name="append" />
    </template>
  </v-alert>
</template>

<script>
export default {
  name: "Alert",

  props: {
    type: {
      type: String,
      default: "info",
    },

    prominent: {
      type: Boolean,
      default: false,
    },

    icon: {
      type: String,
      default: "",
    },
  },

  computed: {
    color() {
      switch (this.type) {
        case "error":
          return "red";
        case "info":
          return "blue";
        case "warning":
          return "orange";
        case "success":
          return "green";
        default:
          return "blue";
      }
    },

    computedIcon() {
      if (this.icon) {
        return this.icon;
      }

      switch (this.type) {
        case "error":
          return "mdi-alert-circle-outline";
        case "info":
          return "mdi-information-outline";
        case "warning":
          return "mdi-alert-outline";
        case "success":
          return "mdi-check-circle-outline";
        default:
          return "mdi-information-outline";
      }
    },
  },

  methods: {
    hasItemSlot(name) {
      return !!this.$scopedSlots[name];
    },
  },
};
</script>
