<template>
  <div>
    <v-text-field
      v-if="data.type === 'text'"
      v-model="valueState"
      type="text"
      :label="label"
      :rules="rules"
      outlined
    />

    <v-select
      v-if="data.type === 'select'"
      v-model="valueState"
      :items="data.items"
      item-text="label"
      :label="label"
      :rules="rules"
      outlined
    />
  </div>
</template>

<script>
export default {
  name: "Field",

  props: {
    data: {
      type: Object,
      required: true,
    },

    value: {
      type: [String, Number],
      default: "",
    },
  },

  data() {
    return {
      valueState: this.value,
    };
  },

  watch: {
    valueState(value) {
      this.$emit("change", { key: this.data.name, value });
    },
  },

  computed: {
    label() {
      let label = this.data.placeholder;

      if (this.data.required) {
        label += " *";
      }

      return label;
    },

    rules() {
      const rules = [];

      if (this.data.required) {
        rules.push((v) => !!v || this.$t("message.NameIsRequired"));
      }

      return rules;
    },
  },
};
</script>
