<template>
  <div>
    <Alert v-if="isFailure" type="error">
      <template v-if="errorMessage">
        {{ errorMessage }}
      </template>
      <template v-else>
        {{ $t("message.errorLoading") }}
      </template>
    </Alert>

    <Form :isPendingStore="isPending" @store="onStore" @cancel="onCancel" />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

import Form from "./components/Form";
import Alert from "@/components/Alert";

export default {
  name: "AddOperator",

  components: { Form, Alert },

  data() {
    const { query } = this.$route.params;

    return {
      query,
    };
  },

  methods: {
    ...mapActions({ store: "operator/store" }),

    ...mapMutations({
      setLoading: "operator/setLoading",
    }),

    async onStore(data) {
      await this.store(data);

      if (!this.isFailure) {
        this.$router.push({
          name: this.$const.ROUTES.OPERATORS,
          query: this.query,
        });
      }
    },

    onCancel() {
      this.$router.push({
        name: this.$const.ROUTES.OPERATORS,
        query: this.query,
      });
    },
  },

  computed: {
    ...mapState({
      isPending: (state) => state.operator.isPendingStore,
      isFailure: (state) => state.operator.isFailureStore,
      errorMessage: (state) => state.operator.errorMessageStore,
    }),
  },

  destroyed() {
    this.setLoading({ type: "IS_RESET_STORE" });
  },
};
</script>
