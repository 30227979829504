export default {
  set(state, payload) {
    state.data = payload;
  },

  setStatus(state, payload) {
    if (state.data) {
      state.data.status = payload;
    }
  },
};
