import Vue from "vue";
import i18n from "@/plugins/i18n";

export default {
  clearDB({ commit }) {
    commit("setLoadingClearDB", { type: "IS_PENDING" });

    return Vue.axios
      .delete("system/flush_all")
      .then(() => {
        commit("setLoadingClearDB", { type: "IS_SUCCESS" });

        Vue.prototype.$notify({
          group: "info",
          type: "success",
          text: i18n.t("message.clearDBSuccess"),
        });
      })
      .catch((error) => {
        commit("setLoadingClearDB", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.clearDBFailure"),
        });
      });
  },
  downloadAllLog({ commit }) {
    commit("setLoadingDownloadAllLog", { type: "IS_PENDING" });
    Vue.axios
      .get("/system/log_all", { responseType: "arraybuffer" })
      .then((response) => {
        commit("setLoadingDownloadAllLog", { type: "IS_SUCCESS" });
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/zip" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "system.log.zip");
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        commit("setLoadingDownloadAllLog", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("setting.download_logs_error"),
        });
      });
  },
  getLog({ commit }, maxSize) {
    commit("setLoadingGetLog", { type: "IS_PENDING" });
    Vue.axios
      .get("/system/log", {
        params: {
          size: maxSize,
        },
      })
      .then((response) => {
        commit("setLoadingGetLog", { type: "IS_SUCCESS" });
        commit("setLog", response.data.data.text);
      })
      .catch((error) => {
        commit("setLoadingClearDB", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("setting.download_logs_error"),
        });
      });
  },
  getUserLog({ commit }, maxSizeUser) {
    commit("setLoadingGetUserLog", { type: "IS_PENDING" });
    Vue.axios
      .get("/change/log", {
        params: {
          size: maxSizeUser,
        },
      })
      .then((response) => {
        commit("setLoadingGetUserLog", { type: "IS_SUCCESS" });

        commit("setUserLog", response.data.data.text);
      })
      .catch((error) => {
        commit("setLoadingGetUserLog", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("setting.download_logs_error"),
        });
      });
  },
  downloadAllUserLog({ commit }, maxSizeUser) {
    commit("setLoadingDownloadAllUserLog", { type: "IS_PENDING" });
    Vue.axios
      .get("/change/log_all", {
        responseType: "arraybuffer",
        params: {
          size: maxSizeUser,
        },
      })
      .then((response) => {
        commit("setLoadingDownloadAllUserLog", { type: "IS_SUCCESS" });

        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/zip" })
        );

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "updateuser.log.zip");
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        commit("setLoadingDownloadAllUserLog", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("setting.download_logs_error"),
        });
      });
  },
};
