<template>
  <div>
    <SkeletonLoader v-if="isPending" />

    <Alert v-if="isFailure" type="error">
      <template v-if="errorMessage">
        {{ errorMessage }}
      </template>
      <template v-else>
        {{ $t("message.errorLoading") }}
      </template>
    </Alert>

    <Alert v-if="isFailureStore" type="error">
      <template v-if="errorMessageStore">
        {{ errorMessageStore }}
      </template>
      <template v-else>
        {{ $t("message.errorLoading") }}
      </template>
    </Alert>

    <Form
      v-if="isSuccess"
      ref="form"
      isEdit
      :data="data"
      :isPendingStore="isPendingStore"
      @store="onEdit({ id, data: $event })"
    >
      <template v-slot:actions>
        <v-btn :loading="isPendingStore" @click="cancel">
          <v-icon left>mdi-cancel</v-icon>
          {{ $t("button.cancel") }}
        </v-btn>
        <v-spacer />
        <v-btn
          v-if="isConfirmRequest"
          color="green"
          dark
          @click="confirmRequest"
        >
          <v-icon left>mdi-lock-check</v-icon>
          {{ $t("requests.confirmRequest") }}
        </v-btn>

        <v-btn v-if="isCancelRequest" color="red" dark @click="cancelRequest">
          <v-icon left>mdi-lock-remove</v-icon>
          {{ $t("requests.cancelRequest") }}
        </v-btn>

        <v-btn v-if="isResendRequest" color="primary" @click="resend">
          <v-icon left>mdi-reload</v-icon>
          {{ $t("requests.resend") }}
        </v-btn>

        <v-btn
          v-if="isSaveRequest"
          color="green"
          dark
          :loading="isPendingStore"
          @click="save"
        >
          <v-icon left>mdi-content-save-outline</v-icon>
          {{ $t("button.save") }}
        </v-btn>
      </template>
    </Form>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

import Form from "./components/Form";
import Alert from "@/components/Alert";
import SkeletonLoader from "./components/SkeletonLoader";

import permissions from "@/mixins/permissions";

export default {
  name: "EditRequest",

  mixins: [permissions],

  components: { Form, Alert, SkeletonLoader },

  data() {
    const { id, query } = this.$route.params;

    return {
      id,
      query,
    };
  },

  methods: {
    ...mapActions({
      edit: "request/edit",
      get: "request/get",
      setStatus: "request/setStatus",
      resend: "request/resend",
    }),

    ...mapMutations({
      setLoading: "request/setLoading",
    }),

    cancel() {
      this.$router.push({
        name: this.$const.ROUTES.PERMIT_REQUESTS,
        query: this.query,
      });
    },

    save() {
      this.$refs.form.save();
    },

    async onEdit(data) {
      await this.edit(data);

      if (!this.isFailureStore) {
        this.$router.push({
          name: this.$const.ROUTES.PERMIT_REQUESTS,
          query: this.query,
        });
      }
    },

    confirmRequest() {
      this.setStatus({ id: this.data.id, status: 1 });
    },

    async cancelRequest() {
      await this.setStatus({ id: this.data.id, status: 3 });

      this.$router.push({
        name: this.$const.ROUTES.PERMIT_REQUESTS,
        query: this.query,
      });
    },
  },

  computed: {
    ...mapState({
      data: (state) => state.request.data,
      isPending: (state) => state.request.isPending,
      isSuccess: (state) => state.request.isSuccess,
      isFailure: (state) => state.request.isFailure,
      errorMessage: (state) => state.request.errorMessage,
      isPendingStore: (state) => state.request.isPendingStore,
      isFailureStore: (state) => state.request.isFailureStore,
      errorMessageStore: (state) => state.request.errorMessageStore,
    }),

    isConfirmRequest() {
      return (
        this.data.status === 0 && this.can(this.$const.RULES.REQUEST_CONFIRM)
      );
    },

    isCancelRequest() {
      return (
        this.data.status !== 3 &&
        this.data.status !== 4 &&
        (this.$auth.user().admin_id === this.data.owner_id ||
          this.can(this.$const.RULES.REQUEST_CANCEL))
      );
    },

    isResendRequest() {
      return this.data.status === 1 && this.can(this.$const.RULES.REQUEST_ADD);
    },

    isSaveRequest() {
      return this.data.status === 0;
    },
  },

  created() {
    this.get({ id: this.id });
  },

  destroyed() {
    this.setLoading({ type: "IS_RESET_STORE" });
    this.setLoading();
  },
};
</script>
