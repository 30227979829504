export default {
  set(state, payload) {
    state.data = payload;
  },

  setLimit(state, limit) {
    localStorage.setItem("devicesLimit", limit);
    state.limit = limit;
  },

  setFilter(state, payload) {
    state.filter = payload.data;
    state.canChangeFilterMode = payload.settings.canChangeMode;

    if (localStorage.getItem("minifyDevicesFilter") === null) {
      state.isMinifyFilter = payload.settings.isMinifyMode;
    }
  },

  setLoadingScan(state, payload) {
    state.isPendingScan = payload.type === "IS_PENDING";
    state.isSuccessScan = payload.type === "IS_SUCCESS";
    state.isFailureScan = payload.type === "IS_FAILURE";
    state.errorMessageScan = payload.payload;
  },

  setLoadingMonitoring(state, payload) {
    state.isPendingMonitoring = payload.type === "IS_PENDING";
    state.isSuccessMonitoring = payload.type === "IS_SUCCESS";
    state.isFailureMonitoring = payload.type === "IS_FAILURE";
    state.errorMessageMonitoring = payload.payload;
  },

  setMonitoring(state, payload) {
    state.dataMonitoring = payload;
  },

  setScannedDevices(state, payload) {
    state.scannedDevices = payload;
  },

  setAllDevicesIp(state, payload) {
    state.allDevicesIp = payload;
  },

  setFilterMinify(state, payload) {
    state.isMinifyFilter = payload;
    localStorage.setItem("minifyDevicesFilter", payload);
  },
};
