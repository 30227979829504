<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-row>
      <v-col cols="12" md="4">
        <v-card elevation="2">
          <v-img v-if="isPhotoBase64" :src="photo" />

          <ImgProtected v-else-if="photo" :src="photo" />

          <v-img v-else height="200" class="primary">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-icon size="150" dark> mdi-account-circle </v-icon>
              </v-row>
            </template>
          </v-img>

          <v-card-text v-if="faceValidErrorMessage">
            <Alert type="error" class="m-0">{{ faceValidErrorMessage }}</Alert>
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="isCameraShow = true"
                >
                  <v-icon>mdi-cctv</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("user.takePhotoDevice") }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click="isWebCamShow = true"
                >
                  <v-icon>mdi-webcam</v-icon>
                </v-btn>

                <div v-if="isWebCamShow">
                  <v-dialog v-model="isWebCamShow" max-width="666">
                    <WebCamBox @capture="setImage" />
                  </v-dialog>
                </div>
              </template>
              <span>{{ $t("user.takePhotoWebCam") }}</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click.prevent="$refs.file.click()"
                >
                  <v-icon>mdi-upload</v-icon>
                </v-btn>

                <input
                  type="file"
                  accept="image/*"
                  ref="file"
                  style="display: none"
                  @change="onUploadPhoto"
                />
              </template>
              <span>{{ $t("user.uploadPhoto") }}</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col cols="12" md="8">
        <v-card elevation="2" :loading="isPendingStore">
          <template slot="progress">
            <v-progress-linear color="primary" height="10" indeterminate />
          </template>

          <v-card-text>
            <v-row>
              <v-col cols="12">
                <FullName
                  :surname="lastName"
                  :name="firstName"
                  :patronymic="middleName"
                  @change="onChangeName"
                />
              </v-col>

              <v-col cols="12" md="6">
                <vue-tel-input-vuetify
                  ref="phone"
                  outlined
                  v-model="phoneState"
                  autocomplete="off"
                  :rules="isCabinetEnable ? phoneRules : []"
                  :label="phoneLabel"
                  :preferredCountries="[
                    'RU',
                    'BY',
                    'KZ',
                    'MD',
                    'AM',
                    'AZ',
                    'GE',
                    'KG',
                    'TJ',
                    'TM',
                    'UZ',
                  ]"
                  :loading="isPendingCheckPhone"
                  placeholder=""
                  append-icon="mdi-menu-down"
                  @input="onChangePhone"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="email"
                  ref="email"
                  outlined
                  autocomplete="off"
                  :rules="isCabinetEnable ? emailRules : []"
                  :label="emailLabel"
                  :loading="isPendingCheckEmail"
                  @input="onChangeEmail"
                />
              </v-col>
            </v-row>

            <v-divider class="mb-5" />

            <v-btn v-if="!isSetNewPass" @click="isSetNewPass = true">
              {{ $t("operators.changeAuth") }}
            </v-btn>

            <PasswordForm v-if="isSetNewPass" @input="pass = $event" />
          </v-card-text>

          <v-card-actions>
            <v-btn
              v-if="$auth.user().password_upd"
              :loading="isPendingStore"
              @click="cancel"
            >
              <v-icon left>mdi-cancel</v-icon>
              {{ $t("button.cancel") }}
            </v-btn>
            <v-spacer />
            <v-btn color="green" dark :loading="isPendingStore" @click="save">
              <v-icon left>mdi-content-save-outline</v-icon>
              {{ $t("button.save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <template v-if="isCameraShow">
      <CameraBox
        :devices="devices"
        @toggle="isCameraShow = $event"
        @image="setImage"
      />
    </template>
  </v-form>
</template>

<script>
import { mapActions, mapState } from "vuex";
import debounce from "lodash.debounce";
import { isEmail, isBase64 } from "validator";

import PasswordForm from "./PasswordForm";

import FullName from "@/components/fullname";
import CameraBox from "@/components/CameraBox";
import WebCamBox from "@/components/WebCamBox";
import Alert from "@/components/Alert";
import ImgProtected from "@/components/ImgProtected";

import faceValidate from "@/mixins/faceValidate";

export default {
  name: "PersonForm",

  mixins: [faceValidate],

  components: {
    FullName,
    CameraBox,
    WebCamBox,
    Alert,
    ImgProtected,
    PasswordForm,
  },

  props: {
    id: {
      type: [String, Number],
      default: null,
    },
    data: {
      type: Object,
      default: null,
    },
    isPendingStore: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const lastName = this.data?.last_name || "";
    const firstName = this.data?.first_name || "";
    const middleName = this.data?.middle_name || "";
    const phone = this.data?.phone || "";
    const phoneState = this.data?.phone || "";
    const email = this.data?.email || "";
    const photo = this.data?.photo
      ? `/avatar/profile/${this.data.photo}`
      : null;
    const isCabinetEnable =
      this.data?.is_cabinet_enable !== undefined
        ? this.data.is_cabinet_enable
        : true;

    return {
      valid: true,
      validPhone: true,

      isWebCamShow: false,
      isCameraShow: false,

      lastName,
      firstName,
      middleName,
      phone,
      phoneState,
      email,
      photo,
      isCabinetEnable,
      noCheckPhoto: false,

      isSetNewPass: !this.$auth.user().password_upd ? true : false,
      pass: "",

      emailRules: [
        () => {
          if (!this.email && !this.phone) {
            return this.$t("requests.oneOfContactIsRequired");
          }

          return true;
        },
        (v) => {
          if (this.email) {
            return isEmail(v) || this.$t("user.email_char_valid");
          }

          return true;
        },
        () => !this.isEmailExist || this.$t("requests.emailExist"),
      ],
      phoneRules: [
        (v) => {
          if (!v.length) {
            return true;
          }
          return (
            (!/.?[^ 0-9()\-+]/.test(v) && this.validPhone) ||
            this.$t("user.notPhone")
          );
        },
        () => {
          if (!this.email && !this.phone) {
            return this.$t("requests.oneOfContactIsRequired");
          }

          return true;
        },
        () => !this.isPhoneExist || this.$t("requests.phoneExist"),
      ],
    };
  },

  computed: {
    ...mapState({
      devices: (state) => state.devices.data,

      isPendingCheckPhone: (state) => state.persons.isPendingCheckPhone,
      isPhoneExist: (state) => state.persons.isPhoneExist,

      isPendingCheckEmail: (state) => state.persons.isPendingCheckEmail,
      isEmailExist: (state) => state.persons.isEmailExist,
    }),

    emailLabel() {
      let label = this.$t("user.email");

      if (this.isCabinetEnable && !this.phone) {
        label += " *";
      }

      return label;
    },

    phoneLabel() {
      let label = this.$t("user.phone");

      if (this.isCabinetEnable && !this.email) {
        label += " *";
      }

      return label;
    },

    isPhotoBase64() {
      if (this.photo) {
        const index = this.photo.indexOf(",");
        const photo = this.photo.slice(index + 1);

        return isBase64(photo);
      }

      return false;
    },
  },

  watch: {
    photo(photo) {
      this.validateFace(photo);
    },

    async email() {
      await this.$nextTick();
      this.$refs.phone.$refs.input.validate();
    },

    async phone() {
      await this.$nextTick();
      this.$refs.email.validate();
    },
  },

  methods: {
    ...mapActions({
      getDevices: "devices/get",
      checkPhone: "persons/checkPhone",
      checkEmail: "persons/checkEmail",
    }),

    onChangeName({ surname, name, patronymic }) {
      this.firstName = name;
      this.middleName = patronymic;
      this.lastName = surname;
    },

    cancel() {
      this.$router.push({ name: this.$const.ROUTES.PERSONS });
    },

    save() {
      if (!this.$refs.form.validate()) return;

      if (this.faceValidErrorMessage || !this.photo) {
        this.$root.$prompt({
          message: this.$t("message.notValidateFace"),
          btnAgree: this.$t("button.save"),

          agree: () => {
            this.noCheckPhoto = true;
            this.store();
          },
        });

        return;
      }

      this.store();
    },

    store() {
      const data = {
        data: {
          last_name: this.lastName,
          first_name: this.firstName,
          middle_name: this.middleName,
          phone: this.phone,
          email: this.email,
          photo_encrypted: this.photo,
          no_check_photo: this.noCheckPhoto,
          is_cabinet_enable: this.isCabinetEnable,
        },
      };

      if (this.isSetNewPass) {
        data.password = this.pass;
      }

      this.$emit("store", data);
    },

    onUploadPhoto(e) {
      const photoBlob = e.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(photoBlob);

      reader.onload = () => {
        this.photo = reader.result;
      };

      reader.onerror = (error) => {
        console.error("Error: ", error);
      };
    },

    setImage(e) {
      this.photo = e;
      this.isWebCamShow = false;
    },

    onChangePhone(formattedNumber, { number, valid }) {
      this.phone = number.international;
      this.validPhone = valid;

      if (this.validPhone) {
        this.checkPhoneDebounced(this.phone);
      }
    },

    checkPhoneDebounced: debounce(async function (phone) {
      const params = { phone };

      if (this.id) {
        params.id = this.id;
      }

      await this.checkPhone(params);
      this.$refs.phone.$refs.input.validate();
    }, 300),

    onChangeEmail: debounce(async function (email) {
      const params = { email };

      if (this.id) {
        params.id = this.id;
      }

      await this.checkEmail(params);
      this.$refs.email.validate();
    }, 300),
  },

  created() {
    this.getDevices();
  },
};
</script>
