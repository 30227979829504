<template>
  <div>
    <v-row v-if="errorMessage">
      <v-col>
        <Alert type="error">
          {{ errorMessage }}
        </Alert>
      </v-col>
    </v-row>

    <v-row v-if="can($const.RULES.ZONE_ADD)">
      <v-spacer />
      <v-col cols="12" md="auto">
        <v-btn color="primary" @click="goToAdd">
          <v-icon left> mdi-plus </v-icon>
          {{ $t("zones.add") }}
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :loading="isPending"
      :headers="headers"
      :items="zones"
      :search="search"
      hide-default-footer
      disable-sort
    >
      <template #[`item.name`]="{ item }">
        <router-link
          v-if="can($const.RULES.ZONE_EDIT)"
          :to="{ name: $const.ROUTES.EDIT_ZONE, params: { id: item.id } }"
          >{{ item.name }}</router-link
        >
        <template v-else>
          {{ item.name }}
        </template>
      </template>

      <template #[`item.parent_id`]="{ item }">
        <template v-if="item.parent_id">
          <router-link
            v-if="can($const.RULES.ZONE_EDIT)"
            :to="{
              name: $const.ROUTES.EDIT_ZONE,
              params: { id: item.parent_id },
            }"
          >
            {{ getParentNameById(item.parent_id) }}
          </router-link>
          <template v-else>
            {{ item.name }}
          </template>
        </template>
        <span v-else>
          {{ $t("zones.withoutParent") }}
        </span>
      </template>

      <template #[`item.actions`]="{ item }">
        <v-tooltip v-if="can($const.RULES.ZONE_EDIT)" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              small
              icon
              color="primary"
              @click="goToEdit(item.id)"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("zones.change") }}</span>
        </v-tooltip>

        <v-tooltip v-if="can($const.RULES.ZONE_DELETE)" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              small
              icon
              color="red"
              @click="onRemove(item.id)"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("zones.delete") }}</span>
        </v-tooltip>
      </template>

      <template #footer>
        <v-row>
          <v-spacer />
          <v-col cols="12" md="3" class="text-right">
            <small class="blue-grey--text text--lighten-1">
              {{ `${$t("zones.total")}: ${zones.length}` }}
            </small>
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import Alert from "@/components/Alert";

import permissions from "@/mixins/permissions";

export default {
  name: "ZonesList",

  mixins: [permissions],

  components: { Alert },

  data() {
    return {
      search: "",

      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: this.$t("zones.name"),
          value: "name",
        },
        {
          text: this.$t("zones.parent"),
          value: "parent_id",
        },
        {
          value: "actions",
          align: "end",
          width: 100,
        },
      ],
    };
  },

  methods: {
    ...mapActions({
      get: "zones/getList",
      remove: "zone/remove",
    }),

    goToAdd() {
      this.$router.push({ name: this.$const.ROUTES.ADD_ZONE });
    },

    goToEdit(id) {
      this.$router.push({
        name: this.$const.ROUTES.EDIT_ZONE,
        params: { id },
      });
    },

    onRemove(id) {
      if (!id) return;

      this.$root.$prompt({
        title: this.$t("message.Delete"),
        message: this.$t("message.confirmDelete"),
        btnAgree: this.$t("button.delete"),

        agree: async () => {
          await this.remove(id);
          this.get();
        },
      });
    },

    getParentNameById(id) {
      const zone = this.zones.filter((zone) => zone.id === id);

      return zone[0].name;
    },

    onChangeFilter(filter) {
      this.search = filter.search;
    },
  },

  computed: {
    ...mapState({
      isPending: (state) => state.zones.isPending,
      errorMessage: (state) => state.zones.errorMessage,
      zones: (state) => state.zones.list,
    }),
  },

  created() {
    this.get();
  },
};
</script>
