<template>
  <v-row>
    <v-col :cols="12" :md="2">
      <v-row>
        <v-col cols="auto" :md="12">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                fab
                small
                link
                @click="goToFullscreen"
              >
                <v-icon dark> mdi-monitor-screenshot </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("users.monitoringFullScreen") }}</span>
          </v-tooltip>
        </v-col>

        <v-col cols="auto" :md="12">
          <div class="view-switcher">
            <v-btn-toggle v-model="viewType">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on">
                    <v-icon>mdi-card-outline</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("users.combinedMode") }}</span>
              </v-tooltip>

              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on">
                    <v-icon>mdi-card-multiple-outline</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("users.separateMode") }}</span>
              </v-tooltip>
            </v-btn-toggle>
          </div>
        </v-col>
      </v-row>
    </v-col>

    <v-col :cols="12" :md="10">
      <div v-if="viewType === 0" class="user-card-wrapper">
        <v-card
          v-if="lastVisit"
          :class="[
            'user-card',
            'user-card--once',
            {
              'user-card--alarm': isTempAlarm(lastVisit.temperatureValue),
            },
          ]"
        >
          <div
            v-if="lastVisit.purpose"
            class="user-card__badge user-card__badge--full-width"
          >
            <span
              v-if="lastVisit.purposeType == 'exit'"
              class="user-card__badge-title"
            >
              {{ $t("device.purpose_exit") }}
            </span>
            <span v-else class="user-card__badge-title">
              {{ $t("device.purpose_entrance") }}
            </span>
            ({{ lastVisit.purpose }})
          </div>

          <v-img
            v-if="lastVisit.avatarStatic"
            :src="lastVisit.avatarStatic"
            height="400"
          />

          <v-img
            v-else-if="isPhotoBase64(lastVisit.avatar)"
            :src="lastVisit.avatar"
            height="400"
          />

          <ImgProtected
            v-else-if="lastVisit.avatar"
            :src="`/avatar/profile/${lastVisit.avatar}`"
            height="400"
          />

          <v-img v-else height="200" class="primary">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-icon size="150" dark> mdi-account-circle </v-icon>
              </v-row>
            </template>
          </v-img>

          <div class="user-card__content">
            <div
              v-if="lastVisit.Blocked"
              style="text-align: center; margin-bottom: 10px"
            >
              <v-btn color="success" @click="guestUnblock(lastVisit)">
                {{ $t("users.unblock") }}
              </v-btn>
            </div>

            <div
              v-if="
                !lastVisit.person_id &&
                lastVisit.identity_types &&
                lastVisit.identity_types.includes('face')
              "
              style="text-align: center; margin-bottom: 10px"
            >
              <v-btn color="primary" @click="guestReg(lastVisit)">
                {{ $t("users.guestPass") }}
              </v-btn>
            </div>

            <div class="mb-2 text-center">
              <span
                v-if="
                  lastVisit.purposeType === 'entrance' ||
                  lastVisit.purposeType === 'duplex'
                "
                class="mr-1 ml-1"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" small color="success"
                      >mdi-location-enter</v-icon
                    >
                  </template>
                  <span>{{ $t("device.purpose_entrance") }}</span>
                </v-tooltip>
              </span>
              <span v-if="lastVisit.purposeType === 'exit'" class="mr-1 ml-1">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      small
                      color="error"
                      class="rotate-180"
                      >mdi-location-exit</v-icon
                    >
                  </template>
                  <span>{{ $t("device.purpose_exit") }}</span>
                </v-tooltip>
              </span>

              <template v-if="lastVisit.eventType !== 'deviceAlarm'">
                <span
                  v-for="type in lastVisit.identity_types"
                  :key="type"
                  class="mr-2 ml-2"
                >
                  <v-tooltip v-if="type === 'face'" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>
                        mdi-emoticon-happy-outline
                      </v-icon>
                    </template>
                    <span>{{ $t("users.face") }}</span>
                  </v-tooltip>

                  <v-tooltip v-if="type === 'qr'" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>
                        mdi-qrcode-scan
                      </v-icon>
                    </template>
                    <span>{{ $t("users.qr") }}</span>
                  </v-tooltip>

                  <v-tooltip v-if="type === 'card'" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>
                        mdi-card-bulleted-outline
                      </v-icon>
                    </template>
                    <span>{{ $t("users.card") }}</span>
                  </v-tooltip>
                </span>
              </template>
            </div>

            <div class="title">{{ lastVisit.full_name }}</div>
            <div v-if="lastVisit.type_user" class="title-small">
              {{ $t("users." + lastVisit.type_user) }}
              <span
                v-if="lastVisit.temperature && !temperatureRise"
                :style="
                  isTempAlarm(lastVisit.temperatureValue)
                    ? 'color: red; font-size: 22px; font-weight: 600;'
                    : 'font-size: 22px; color: green;'
                "
                >{{ lastVisit.temperature.replace("/", "") }}</span
              >
            </div>
            <div
              v-for="org in lastVisit.organization"
              :key="org.name"
              class="title-company"
            >
              {{ org.name }}
            </div>
            <div v-if="lastVisit.info" class="title-small">
              {{ lastVisit.info }}
            </div>
            <div class="title-small">
              {{ lastVisit.visits }}
            </div>
          </div>
        </v-card>
      </div>

      <div v-if="viewType === 1" class="user-card-wrapper">
        <v-card
          v-if="entrance"
          :class="[
            'user-card',
            {
              'user-card--alarm': isTempAlarm(entrance.temperatureValue),
            },
          ]"
        >
          <div v-if="entrance.purpose" class="user-card__badge">
            <span class="user-card__badge-title">
              {{ $t("device.purpose_entrance") }}
            </span>
            ({{ entrance.purpose }})
          </div>

          <v-img
            v-if="entrance.avatarStatic"
            :src="entrance.avatarStatic"
            max-width="50%"
          />

          <v-img
            v-else-if="isPhotoBase64(entrance.avatar)"
            :src="entrance.avatar"
            max-width="50%"
          />

          <ImgProtected
            v-else-if="entrance.avatar"
            :src="`/avatar/profile/${entrance.avatar}`"
            max-width="50%"
          />

          <v-img v-else max-width="50%" class="primary">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-icon size="150" dark> mdi-account-circle </v-icon>
              </v-row>
            </template>
          </v-img>

          <div class="user-card__content">
            <div
              v-if="entrance.Blocked"
              style="text-align: center; margin-bottom: 10px"
            >
              <v-btn color="success" @click="guestUnblock(entrance)">
                {{ $t("users.unblock") }}
              </v-btn>
            </div>
            <div
              v-if="
                !entrance.person_id &&
                entrance.identity_types &&
                entrance.identity_types.includes('face')
              "
              style="text-align: center; margin-bottom: 10px"
            >
              <v-btn color="primary" @click="guestReg(entrance)">
                {{ $t("users.guestPass") }}
              </v-btn>
            </div>

            <div class="mb-2 text-center">
              <span class="mr-1 ml-1">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on" small color="success"
                      >mdi-location-enter</v-icon
                    >
                  </template>
                  <span>{{ $t("device.purpose_entrance") }}</span>
                </v-tooltip>
              </span>

              <template v-if="entrance.eventType !== 'deviceAlarm'">
                <span
                  v-for="type in entrance.identity_types"
                  :key="type"
                  class="mr-1 ml-1"
                >
                  <v-tooltip v-if="type === 'face'" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>
                        mdi-emoticon-happy-outline
                      </v-icon>
                    </template>
                    <span>{{ $t("users.face") }}</span>
                  </v-tooltip>

                  <v-tooltip v-if="type === 'qr'" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>
                        mdi-qrcode-scan
                      </v-icon>
                    </template>
                    <span>{{ $t("users.qr") }}</span>
                  </v-tooltip>

                  <v-tooltip v-if="type === 'card'" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>
                        mdi-card-bulleted-outline
                      </v-icon>
                    </template>
                    <span>{{ $t("users.card") }}</span>
                  </v-tooltip>
                </span>
              </template>
            </div>

            <div class="title">{{ entrance.full_name }}</div>
            <div v-if="entrance.type_user" class="title-small">
              {{ $t("users." + entrance.type_user) }}
              <span
                v-if="entrance.temperature && !temperatureRise"
                :style="
                  isTempAlarm(entrance.temperatureValue)
                    ? 'color: red; font-size: 22px; font-weight: 600;'
                    : 'font-size: 22px; color: green;'
                "
                >{{ entrance.temperature.replace("/", "") }}</span
              >
            </div>
            <div
              v-for="org in entrance.organization"
              :key="org.name"
              class="title-company"
            >
              {{ org.name }}
            </div>
            <div v-if="entrance.info" class="title-small">
              {{ entrance.info }}
            </div>
            <div class="title-small">
              {{ entrance.visits }}
            </div>
          </div>
        </v-card>

        <v-card
          v-if="exit"
          :class="[
            'user-card',
            {
              'user-card--alarm': isTempAlarm(exit.temperatureValue),
            },
          ]"
        >
          <div v-if="exit.purpose" class="user-card__badge">
            <span class="user-card__badge-title">
              {{ $t("device.purpose_exit") }}
            </span>
            ({{ exit.purpose }})
          </div>

          <v-img
            v-if="exit.avatarStatic"
            :src="exit.avatarStatic"
            max-width="50%"
          />

          <v-img
            v-else-if="isPhotoBase64(exit.avatar)"
            :src="exit.avatar"
            max-width="50%"
          />

          <ImgProtected
            v-else-if="exit.avatar"
            :src="`/avatar/profile/${exit.avatar}`"
            max-width="50%"
          />

          <v-img v-else max-width="50%" class="primary">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-icon size="150" dark> mdi-account-circle </v-icon>
              </v-row>
            </template>
          </v-img>

          <div class="user-card__content">
            <div
              v-if="exit.Blocked"
              style="text-align: center; margin-bottom: 10px"
            >
              <v-btn color="success" @click="guestUnblock(exit)">
                {{ $t("users.unblock") }}
              </v-btn>
            </div>
            <div
              v-if="
                !exit.person_id &&
                exit.identity_types &&
                exit.identity_types.includes('face')
              "
              style="text-align: center; margin-bottom: 10px"
            >
              <v-btn color="primary" @click="guestReg(exit)">
                {{ $t("users.guestPass") }}
              </v-btn>
            </div>

            <div class="mb-2 text-center">
              <span class="mr-1 ml-1">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      small
                      color="error"
                      class="rotate-180"
                      >mdi-location-exit</v-icon
                    >
                  </template>
                  <span>{{ $t("device.purpose_exit") }}</span>
                </v-tooltip>
              </span>

              <template v-if="exit.eventType !== 'deviceAlarm'">
                <span
                  v-for="type in exit.identity_types"
                  :key="type"
                  class="mr-1 ml-1"
                >
                  <v-tooltip v-if="type === 'face'" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>
                        mdi-emoticon-happy-outline
                      </v-icon>
                    </template>
                    <span>{{ $t("users.face") }}</span>
                  </v-tooltip>

                  <v-tooltip v-if="type === 'qr'" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>
                        mdi-qrcode-scan
                      </v-icon>
                    </template>
                    <span>{{ $t("users.qr") }}</span>
                  </v-tooltip>

                  <v-tooltip v-if="type === 'card'" top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small>
                        mdi-card-bulleted-outline
                      </v-icon>
                    </template>
                    <span>{{ $t("users.card") }}</span>
                  </v-tooltip>
                </span>
              </template>
            </div>

            <div class="title">{{ exit.full_name }}</div>
            <div v-if="exit.type_user" class="title-small">
              {{ $t("users." + exit.type_user) }}
              <span
                v-if="exit.temperature && !temperatureRise"
                :style="
                  isTempAlarm(exit.temperatureValue)
                    ? 'color: red; font-size: 22px; font-weight: 600;'
                    : 'font-size: 22px; color: green;'
                "
                >{{ exit.temperature.replace("/", "") }}</span
              >
            </div>
            <div
              v-for="org in exit.organization"
              :key="org.name"
              class="title-company"
            >
              {{ org.name }}
            </div>
            <div v-if="exit.info" class="title-small">
              {{ exit.info }}
            </div>
            <div class="title-small">
              {{ exit.visits }}
            </div>
          </div>
        </v-card>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from "vuex";
import { isBase64 } from "validator";

import faceValidate from "@/mixins/faceValidate";

import ImgProtected from "@/components/ImgProtected";

export default {
  mixins: [faceValidate],

  components: {
    ImgProtected,
  },

  props: {
    entrance: {
      type: Object,
      default: () => null,
    },

    exit: {
      type: Object,
      default: () => null,
    },

    temperatureRange: {
      type: Array,
      default: () => [],
    },

    temperatureRise: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      viewType: parseInt(localStorage.getItem("viewType")) || 0,
    };
  },

  watch: {
    viewType(type) {
      localStorage.setItem("viewType", type);
    },
  },

  methods: {
    ...mapActions({
      unblock: "persons/unblock",
    }),

    isTempAlarm(temp) {
      if (
        (temp && temp <= parseFloat(this.temperatureRange[0])) ||
        temp >= parseFloat(this.temperatureRange[1])
      ) {
        return true;
      }

      return false;
    },

    async guestUnblock(visitor) {
      if (visitor && visitor.Blocked && visitor.person_id) {
        await this.unblock({ ids: [parseInt(visitor.person_id)] });
        this.$emit("unblock", visitor.person_id);
      }
    },

    guestReg(visitor) {
      this.$emit("guestReg", visitor);
    },

    getIdentityValueType(identityValue) {
      const separator = identityValue.indexOf(":");
      const type = identityValue.substring(0, separator);
      return type;
    },

    goToFullscreen() {
      const routeData = this.$router.resolve({
        name: this.$const.ROUTES.FULLSCREEN_MONITORING,
      });
      window.open(routeData.href, "_blank");
    },

    isPhotoBase64(photo) {
      if (photo) {
        const index = photo.indexOf(",");
        const photoBase64 = photo.slice(index + 1);

        return isBase64(photoBase64);
      }

      return false;
    },
  },

  computed: {
    lastVisit() {
      let result = null;

      if (this.entrance || this.exit) {
        if (this.entrance && !this.exit) {
          result = this.entrance;
        } else if (!this.entrance && this.exit) {
          result = this.exit;
        } else {
          const isAfter = this.$moment(this.entrance.created_at).isAfter(
            this.exit.created_at
          );

          if (isAfter) {
            result = this.entrance;
          } else {
            result = this.exit;
          }
        }
      }

      return result;
    },
  },
};
</script>
