import Vue from "vue";
import i18n from "@/plugins/i18n";

export default {
  get(
    { commit },
    {
      page,
      limit,
      search,
      sortName,
      sortOrder,
      excludedStatus,
      userType,
      onlyActive,
      onePass,
    } = {}
  ) {
    const params = {};

    if (page) params.page = page;
    if (limit) params.limit = limit;
    if (search) params.search = search;
    if (sortName) params.sortName = sortName;
    if (sortOrder) params.sortOrder = sortOrder;
    if (sortOrder) params.sortOrder = sortOrder;
    if (excludedStatus) params.excluded_status = excludedStatus;
    if (userType) params.user_type = userType;
    if (onlyActive) params.onlyactive = onlyActive;
    if (onePass !== null) params.onepass = onePass;

    commit("setLoading", { type: "IS_PENDING" });

    return Vue.axios("requests", { params })
      .then((response) => {
        const {
          data,
          meta: { pagination },
        } = response.data;

        commit("set", { data, pagination });
        commit("setLoading", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.errorLoading"),
        });
      });
  },
};
