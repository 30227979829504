export default {
  data() {
    return {
      filter: {},
    };
  },

  methods: {
    initFilter(data) {
      this.filter = { ...data };

      const query = this.$route.query;

      for (const key in query) {
        if (Object.hasOwnProperty.call(query, key)) {
          const element = query[key];

          if (!element) {
            continue;
          }

          if (Array.isArray(element) && !element.length) {
            continue;
          }

          this.filter[key] = element;
        }
      }
    },

    initFilterByTemplate(template) {
      const data = {};

      template.forEach((item) => {
        switch (item.type) {
          case "list":
          case "tree":
            data[item.param] = item.default || [];
            break;
          default:
            data[item.param] = item.default || "";
            break;
        }
      });

      this.initFilter(data);
    },

    updateFilter(data) {
      this.filter = { ...this.filter, ...data };

      this.setFilterToQuery();
    },

    setFilterToQuery() {
      const query = {
        ...this.$route.query,
      };

      for (const key in this.filter) {
        if (Object.hasOwnProperty.call(this.filter, key)) {
          const element = this.filter[key];

          if (!element) {
            delete query[key];
            continue;
          }

          if (Array.isArray(element) && !element.length) {
            delete query[key];
            continue;
          }

          query[key] = element;
        }
      }

      this.$router.push({ query }).catch(() => {});
    },
  },
};
