<template>
  <div class="search-bar">
    <v-select
      v-if="searchParams && searchParams.length"
      v-model="searchType"
      :items="searchParams"
      :placeholder="$t('datatable.searchType')"
      dense
      hide-details
      class="ml-5 search-bar__type-list"
      @change="onSearch"
    />
    <v-combobox
      class="ma-0 pa-0 px-3"
      :label="$t('datatable.enter_search_string')"
      :items="searchSuggestion"
      v-model="search"
      hide-details
      single-line
      append-icon=""
      @keyup.enter="onSearch()"
      @change="onSearch()"
    >
    </v-combobox>
    <v-btn v-show="search" icon @click="clear">
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-btn icon @click="onSearch">
      <v-icon>mdi-magnify</v-icon>
    </v-btn>
  </div>
</template>

<script>
import debounce from "lodash.debounce";

export default {
  name: "Search",

  props: {
    value: {
      type: String,
      default: "",
    },

    searchParams: {
      type: Array,
      default: () => [],
    },

    debounce: {
      type: Number,
      default: 300,
    },

    saveSearchHistory: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      search: "",
      searchType: this.searchParams.length ? this.searchParams[0].value : "",
      searchSuggestion: [],
    };
  },

  methods: {
    parseSearch(search) {
      const searchArr = search.split(":");

      if (searchArr.length > 1) {
        this.searchType = searchArr[0];
        this.search = searchArr[1];
      } else {
        this.search = searchArr[0];
      }
    },

    onSearch() {
      const searchComponent = this;

      debounce(function () {
        this.$emit("search", this.searchText);
      }, this.debounce).call(searchComponent);

      this.saveHistory();
    },

    saveHistory() {
      if (this.saveSearchHistory && this.search) {
        const searchHistory = localStorage.getItem(
          `searchHistory-${this.saveSearchHistory}`
        );

        if (searchHistory) {
          const searchHistoryArr = searchHistory.split(",");

          if (searchHistoryArr.length > 5) {
            searchHistoryArr.shift();
          }

          if (!searchHistoryArr.includes(this.search)) {
            searchHistoryArr.push(this.search);
          }

          this.searchSuggestion = searchHistoryArr;

          localStorage.setItem(
            `searchHistory-${this.saveSearchHistory}`,
            searchHistoryArr.join(",")
          );
        } else {
          this.searchSuggestion = [this.search];

          localStorage.setItem(
            `searchHistory-${this.saveSearchHistory}`,
            this.search
          );
        }
      }
    },

    getSearchHistory() {
      if (this.saveSearchHistory) {
        const searchHistory = localStorage.getItem(
          `searchHistory-${this.saveSearchHistory}`
        );

        if (searchHistory) {
          this.searchSuggestion = searchHistory.split(",");
        }
      }
    },

    clear() {
      this.search = "";
      this.onSearch();
    },
  },

  computed: {
    searchText() {
      let searchText = this.search;

      if (this.searchType && searchText) {
        searchText = `${this.searchType}:${this.search}`;
      }

      return searchText;
    },
  },

  mounted() {
    this.getSearchHistory();
  },

  created() {
    this.parseSearch(this.value);
  },
};
</script>
