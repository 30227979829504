<template>
  <v-form ref="form">
    <v-card elevation="2" :loading="isPendingStore">
      <template slot="progress">
        <v-progress-linear color="primary" height="10" indeterminate />
      </template>

      <v-card-text>
        <v-row>
          <v-col>
            <slot name="prepend" />
          </v-col>
        </v-row>

        <template v-if="isShowForm">
          <v-row>
            <v-col v-if="!isMulti" cols="12" md="6">
              <v-row no-gutters>
                <v-col>
                  <v-autocomplete
                    v-model="personId"
                    :loading="isLoadingPersons"
                    :items="persons"
                    :search-input.sync="searchPersons"
                    :label="`${$t('requests.user')} *`"
                    :disabled="!isSaveRequest"
                    item-text="full_name"
                    item-value="id"
                    outlined
                    clearable
                    :error-messages="personIdErrors"
                    @input="$v.personId.$touch()"
                    @blur="$v.personId.$touch()"
                  >
                    <template v-slot:append-item>
                      <div v-intersect="endIntersect" />
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="auto" class="pt-3 pl-3">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        icon
                        color="primary"
                        :disabled="!isSaveRequest"
                        @click="goToAddPerson"
                      >
                        <v-icon>mdi-account-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("users.addNewUser") }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
            <v-col>
              <v-text-field
                v-model="description"
                :label="$t('requests.description')"
                :disabled="!isSaveRequest"
                outlined
              />
            </v-col>
          </v-row>

          <v-divider class="my-5" />

          <PermitList
            :data="permits"
            :readonly="!isSaveRequest"
            :error-messages="permitsErrors"
            :isShowTokens="isShowPermitTokens"
            @change="onChangePermits"
          />
        </template>

        <v-row>
          <v-col>
            <slot name="append" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapActions, mapState } from "vuex";
import debounce from "lodash.debounce";

import PermitList from "@/components/Permits";
import validator from "./validator";

export default {
  name: "RequestForm",

  mixins: [validator],

  components: { PermitList },

  props: {
    data: {
      type: Object,
      default: null,
    },
    isPendingStore: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    isMulti: {
      type: Boolean,
      default: false,
    },
    isShowForm: {
      type: Boolean,
      default: true,
    },
    isShowPermitTokens: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    let personId = "";
    const description = this.data?.description || "";
    const permits = this.data?.person?.permits || [];

    // ! костыль валидации персоны для множественных заявок
    if (this.isMulti) {
      personId = "0";
    } else if (this.data?.person) {
      personId = this.data.person.id;
    }

    return {
      isWebCamShow: false,
      isCameraShow: false,

      personId,
      description,
      permits,

      searchPersons: null,
    };
  },

  computed: {
    ...mapState({
      persons: (state) => state.persons.data,
      personsPagination: (state) => state.persons.pagination,
      isLoadingPersons: (state) => state.persons.isPending,
    }),

    isSaveRequest() {
      return !this.isEdit || this.data.status === 0;
    },
  },

  watch: {
    searchPersons(val) {
      this.onSearchPersons(val);
    },
  },

  methods: {
    ...mapActions({
      getPersons: "persons/get",
    }),

    endIntersect() {
      if (this.isLoadingPersons) return;
      if (this.personsPagination.current_page === this.personsPagination.total)
        return;

      this.getPersons({
        lazy: true,
        search: this.searchPersons,
        page: this.personsPagination.current_page + 1,
      });
    },

    onSearchPersons: debounce(function (name) {
      const params = {};

      if (name) {
        params.search = `name:${name}`;
      }

      this.getPersons(params);
    }, 300),

    save() {
      if (!this.validateForm()) return;

      this.store();
    },

    store() {
      const data = {
        description: this.description,
        permits: this.permits,
      };

      if (this.personId && !this.isMulti) {
        data.persons_id = this.personId;
      }

      this.$emit("store", data);
    },

    onChangePermits(permits) {
      this.permits = permits;
    },

    goToAddPerson() {
      const router = this.$router.resolve({
        name: this.$const.ROUTES.ADD_PERSON,
      });
      window.open(router.href, "_blank");
    },
  },

  created() {
    this.getPersons({ search: `id:${this.personId}` });
  },
};
</script>
