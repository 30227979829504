<template>
  <div>
    {{ getText }}
    <span v-show="!show && isCollapse">
      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <button v-on="on" class="ellipsis-button" @click="toggleShow">
            {{ "..." }}
          </button>
        </template>
        <span>{{ $t("user.expand") }}</span>
      </v-tooltip>
    </span>
    <span v-show="show && isCollapse && bidirectional">
      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <button v-on="on" class="ellipsis-button" @click="toggleShow">
            <v-icon left color="primary">mdi-arrow-collapse</v-icon>
          </button>
        </template>
        <span>{{ $t("user.collapse") }}</span>
      </v-tooltip>
    </span>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      default: "",
    },

    minLength: {
      type: Number,
      default: 15,
    },
    bidirectional: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    toggleShow() {
      this.show = !this.show;
    },
  },
  computed: {
    getText() {
      if (this.show) {
        return this.value;
      } else {
        return this.value?.slice(0, 15);
      }
    },
    isCollapse() {
      return this.value?.length > this.minLength;
    },
  },
};
</script>
