import Vue from "vue";
import i18n from "@/plugins/i18n";

export default {
  get({ commit }, id) {
    commit("setLoading", { type: "IS_PENDING" });

    return Vue.axios(`device/${id}`)
      .then((response) => {
        const { data } = response.data;

        commit("set", data);
        commit("setLoading", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.errorLoading"),
        });
      });
  },

  store({ commit }, data) {
    commit("setLoading", { type: "IS_PENDING_STORE" });

    return Vue.axios
      .post("devices/add", data)
      .then(() => {
        commit("setLoading", { type: "IS_SUCCESS_STORE" });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE_STORE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.saveError"),
        });
      });
  },

  edit({ commit }, { id, data }) {
    commit("setLoading", { type: "IS_PENDING_STORE" });

    return Vue.axios
      .put(`device/${id}`, data)
      .then(() => {
        commit("setLoading", { type: "IS_SUCCESS_STORE" });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE_STORE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.saveError"),
        });
      });
  },

  remove({ commit }, id) {
    commit("setLoading", { type: "IS_PENDING_REMOVE" });

    return Vue.axios
      .delete(`device/${id}`)
      .then(() => {
        commit("setLoading", { type: "IS_SUCCESS_REMOVE" });

        Vue.prototype.$notify({
          group: "info",
          type: "success",
          text: i18n.t("message.deleteSuccess"),
        });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE_REMOVE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.deleteError"),
        });
      });
  },

  emergency({ commit }, { id, deviceName, command }) {
    const data = {
      command,
    };

    return Vue.axios
      .put(`device/${id}/control`, data)
      .then(() => {
        Vue.prototype.$notify({
          group: "info",
          type: "success",
          text: i18n.t("devices.setNewControlMode", { deviceName }),
        });
      })
      .catch((error) => {
        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: `${error.response.data.status.message} - ${deviceName}`,
        });
      });
  },

  blocked({ commit }, { id, deviceName, state }) {
    const data = {
      state,
    };

    return Vue.axios
      .put(`device/${id}/state`, data)
      .then(() => {
        Vue.prototype.$notify({
          group: "info",
          type: "success",
          text: i18n.t("devices.setNewOgateStatus", { deviceName }),
        });
      })
      .catch((error) => {
        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: `${error.response.data.status.message} - ${deviceName}`,
        });
      });
  },

  disabledBiometricStatus({ commit }, { id, deviceName, disable }) {
    const data = {
      disable,
    };

    return Vue.axios
      .put(`device/${id}/facestate`, data)
      .then(() => {
        Vue.prototype.$notify({
          group: "info",
          type: "success",
          text: i18n.t("devices.setBiometricStatus", { deviceName }),
        });
      })
      .catch((error) => {
        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: `${error.response.data.status.message} - ${deviceName}`,
        });
      });
  },

  reboot({ commit }, { id, deviceName }) {
    return Vue.axios
      .put(`device/${id}/reboot`)
      .then(() => {
        Vue.prototype.$notify({
          group: "info",
          type: "success",
          text: i18n.t("devices.setNewOgateReboot", { deviceName }),
        });
      })
      .catch((error) => {
        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: `${error.response.data.status.message} - ${deviceName}`,
        });
      });
  },
};
