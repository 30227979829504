import Vue from "vue";
import i18n from "@/plugins/i18n";

export default {
  checkPass({ commit }, data) {
    commit("setLoadingCheckPass", { type: "IS_PENDING_STORE" });

    return Vue.axios({
      method: "POST",
      url: "/auth/checkPassword",
      baseURL: "",
      data: data,
    })
      .then(() => {
        commit("setLoadingCheckPass", { type: "IS_SUCCESS_STORE" });
      })
      .catch((error) => {
        if (error.response.status == 401) {
          commit("setLoadingCheckPass", {
            type: "IS_FAILURE_STORE",
            payload: error?.response?.data?.status?.message || "",
          });

          Vue.prototype.$notify({
            group: "info",
            type: "error",
            text: i18n.t("login.passError"),
          });
        } else {
          commit("setLoadingCheckPass", {
            type: "IS_FAILURE_STORE",
            payload: error?.response?.data?.status?.message || "",
          });

          Vue.prototype.$notify({
            group: "info",
            type: "error",
            text: i18n.t("message.error"),
          });
        }
        throw error;
      });
  },
};
