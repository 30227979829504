<template>
  <v-container fluid class="fill-height blue-grey lighten-2">
    <v-row>
      <v-col class="d-flex justify-center">
        <v-card width="400">
          <v-card-title>{{ $t("login.ToLogin") }}</v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col v-if="error" cols="12">
                  <v-alert type="error">{{ $t("login.Error") }}</v-alert>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model.trim="username"
                    :label="`${$t('login.Login')} *`"
                    :rules="requiredRules"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model.trim="password"
                    type="password"
                    :label="`${$t('login.Password')} *`"
                    :rules="requiredRules"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" block @click="login">
              <v-icon left>mdi-login</v-icon>
              {{ $t("login.Entry") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      valid: true,
      username: null,
      password: null,
      error: false,

      requiredRules: [(v) => !!v || this.$t("message.NameIsRequired")],
    };
  },
  methods: {
    login() {
      if (!this.$refs.form.validate()) return;

      var app = this;
      this.error = false;
      this.$auth
        .login({
          data: {
            username: app.username,
            password: app.password,
          },
          rememberMe: true,
          method: "POST",
          fetchUser: true,
        })
        .then(() => {
          this.$router.push({ name: this.$const.ROUTES.MONITORING });
        })
        .catch(() => {
          app.error = true;
        });
    },
  },
};
</script>
