<template>
  <div>
    <v-skeleton-loader
      v-if="isPending"
      type="card-heading, list-item-three-line, actions"
    />

    <Alert v-if="isFailure" type="error">
      <template v-if="errorMessage">
        {{ errorMessage }}
      </template>
      <template v-else>
        {{ $t("message.errorLoading") }}
      </template>
    </Alert>

    <Alert v-if="isFailureStore" type="error">
      <template v-if="errorMessageStore">
        {{ errorMessageStore }}
      </template>
      <template v-else>
        {{ $t("message.errorLoading") }}
      </template>
    </Alert>

    <Form
      v-if="isSuccess"
      :id="id"
      :data="data"
      :isPendingStore="isPendingStore"
      isEdit
      @store="onEdit({ id, data: $event })"
      @cancel="onCancel"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

import Form from "./components/Form";
import Alert from "@/components/Alert";

export default {
  name: "EditOperator",

  components: { Form, Alert },

  data() {
    const { id, query } = this.$route.params;

    return {
      id,
      query,
    };
  },

  methods: {
    ...mapActions({ edit: "operator/edit", get: "operator/get" }),

    ...mapMutations({
      setLoading: "operator/setLoading",
    }),

    async onEdit(data) {
      await this.edit(data);

      if (!this.isFailureStore) {
        this.$router.push({
          name: this.$const.ROUTES.OPERATORS,
          query: this.query,
        });
      }
    },

    onCancel() {
      this.$router.push({
        name: this.$const.ROUTES.OPERATORS,
        query: this.query,
      });
    },
  },

  computed: {
    ...mapState({
      data: (state) => state.operator.data,
      isPending: (state) => state.operator.isPending,
      isSuccess: (state) => state.operator.isSuccess,
      isFailure: (state) => state.operator.isFailure,
      errorMessage: (state) => state.operator.errorMessage,
      isPendingStore: (state) => state.operator.isPendingStore,
      isFailureStore: (state) => state.operator.isFailureStore,
      errorMessageStore: (state) => state.operator.errorMessageStore,
    }),
  },

  created() {
    this.get(this.id);
  },

  destroyed() {
    this.setLoading({ type: "IS_RESET_STORE" });
    this.setLoading();
  },
};
</script>
