<template>
  <v-card :loading="isPending">
    <v-card-title class="pb-5 mb-5">
      <span class="headline">{{
        $t("devices.changeDeviceNetworkSettings")
      }}</span>
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col>
          <v-alert v-if="isFailure && errorMessage" type="error">{{
            errorMessage
          }}</v-alert>
        </v-col>
      </v-row>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="ipAddressState"
              :label="`${$t('devices.ipDevice')} *`"
              :rules="requiredRules"
              outlined
              required
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              :value="macCodeState.toUpperCase()"
              :label="`${$t('devices.macCode')} *`"
              v-mask="'NN:NN:NN:NN:NN:NN'"
              :rules="requiredRules"
              outlined
              required
              @input="macCodeState = $event.toUpperCase()"
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="maskState"
              :label="`${$t('devices.mask')} *`"
              :rules="requiredRules"
              outlined
              required
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="gatewayState"
              :label="`${$t('devices.gateway')} *`"
              :rules="requiredRules"
              outlined
              required
            />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="onClose">{{ $t("button.close") }}</v-btn>
      <v-btn color="primary" text @click="setSettings">{{
        $t("button.save")
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    ipAddress: {
      type: String,
      required: true,
    },
    macCode: {
      type: String,
      required: true,
    },
    mask: {
      type: String,
      required: true,
    },
    gateway: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      valid: true,

      isPending: false,
      isSuccess: false,
      isFailure: false,
      errorMessage: "",

      ipAddressState: this.ipAddress,
      macCodeState: this.macCode,
      maskState: this.mask,
      gatewayState: this.gateway,

      requiredRules: [(v) => !!v || this.$t("message.NameIsRequired")],
    };
  },

  methods: {
    setSettings() {
      if (!this.$refs.form.validate()) return;

      this.isPending = true;
      this.isSuccess = false;
      this.isFailure = false;
      this.errorMessage = "";

      this.axios
        .post(`device/${this.id}/setNetworkSettings`, {
          ipAddress: this.ipAddressState,
          macCode: this.macCodeState,
          mask: this.maskState,
          gateway: this.gatewayState,
        })
        .then(() => {
          this.isPending = false;
          this.isSuccess = true;
          this.isFailure = false;

          this.$emit("submit", {
            ipAddress: this.ipAddressState,
            macCode: this.macCodeState,
            mask: this.maskState,
            gateway: this.gatewayState,
          });
        })
        .catch((error) => {
          this.isPending = false;
          this.isSuccess = false;
          this.isFailure = true;
          this.errorMessage = error.response.data.status.message;
        });
    },
    watch: {
      ipAddressState(val) {
        let g = this.gatewayState.split(".");
        let f = false;
        if (g.length < 4) {
          for (let i = g.length; i < 4; i++) {
            g.push("0");
            f = true;
          }
        }
        val.split(".").forEach((item, index) => {
          if (g.length > 3 && g[index] !== item && index < 4) {
            g[index] = item;
            if (index === 3) {
              g[index] = "1";
            }
            f = true;
          }
        });
        if (f) {
          this.gatewayState = g.join(".");
        }
      },
    },

    onClose() {
      this.$emit("close");
    },
  },
};
</script>
