import Vue from "vue";
import i18n from "@/plugins/i18n";

import { errorHandler } from "@/store/helpers";

export default {
  get({ commit }, id) {
    commit("setLoading", { type: "IS_PENDING" });

    return Vue.axios(`department/${id}`)
      .then((response) => {
        const { data } = response.data;

        commit("set", data);
        commit("setLoading", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        errorHandler(error, commit, "IS_FAILURE");
      });
  },

  store({ commit }, data) {
    commit("setLoading", { type: "IS_PENDING_STORE" });

    return Vue.axios
      .post("departments/add", data)
      .then(() => {
        commit("setLoading", { type: "IS_SUCCESS_STORE" });
      })
      .catch((error) => {
        errorHandler(error, commit, "IS_FAILURE_STORE");
      });
  },

  edit({ commit }, { id, data }) {
    commit("setLoading", { type: "IS_PENDING_STORE" });

    return Vue.axios
      .put(`department/${id}`, data)
      .then(() => {
        commit("setLoading", { type: "IS_SUCCESS_STORE" });
      })
      .catch((error) => {
        errorHandler(error, commit, "IS_FAILURE_STORE");
      });
  },

  remove({ commit }, id) {
    commit("setLoading", { type: "IS_PENDING_REMOVE" });

    return Vue.axios
      .delete(`department/${id}`)
      .then(() => {
        commit("setLoading", { type: "IS_SUCCESS_REMOVE" });

        Vue.prototype.$notify({
          group: "info",
          type: "success",
          text: i18n.t("message.deleteSuccess"),
        });
      })
      .catch((error) => {
        errorHandler(error, commit, "IS_FAILURE_REMOVE");
      });
  },
};
