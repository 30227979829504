<template>
  <DataTable
    :fields="fields"
    :rows="data"
    :click="onClick"
    :dblclick="onDblClick"
    :rowstyle="rowstyle"
  >
    {{ $t("users.no_passes_registered") }}

    <template #[`item.avatar`]="{ item }">
      <v-avatar v-if="item.avatarStatic">
        <v-img :src="item.avatarStatic" />
      </v-avatar>

      <v-avatar
        v-else-if="isPhotoBase64(item.avatar)"
        @mouseenter="item.isHasPhoto ? onHoverImg(item.avatar) : null"
        @mouseleave="onLeaveHoverImg"
      >
        <v-img :src="item.avatar" />
      </v-avatar>

      <v-avatar
        v-else-if="item.avatar"
        @mouseenter="
          item.isHasPhoto
            ? onHoverImg(`/avatar/profile/${item.avatar}`, true)
            : null
        "
        @mouseleave="onLeaveHoverImg"
      >
        <ImgProtected :src="`/avatar/small/${item.avatar}`" :alt="item.name" />
      </v-avatar>

      <v-avatar v-else color="primary" size="50">
        <v-icon dark> mdi-account-circle </v-icon>
      </v-avatar>
    </template>

    <template #[`item.identity_types`]="{ item }">
      <span>
        <v-tooltip
          v-if="
            item.purposeType === 'entrance' || item.purposeType === 'duplex'
          "
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small color="success"
              >mdi-location-enter</v-icon
            >
          </template>
          <span>{{ $t("device.purpose_entrance") }}</span>
        </v-tooltip>
        <v-tooltip v-if="item.purposeType === 'exit'" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              color="error"
              class="rotate-180"
              >mdi-location-exit</v-icon
            >
          </template>
          <span>{{ $t("device.purpose_exit") }}</span>
        </v-tooltip>
        <v-tooltip v-if="item.purposeType === 'basiclock'" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small color="warning"
              >mdi-key</v-icon
            >
          </template>
          <span>{{ $t("device.purpose_basiclock") }}</span>
        </v-tooltip>
      </span>
      <span v-for="type in item.identity_types" :key="type">
        <v-tooltip v-if="type === 'face'" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              :color="item.blacklisted ? 'white' : ''"
            >
              mdi-emoticon-happy-outline
            </v-icon>
          </template>
          <span>{{ $t("users.face") }}</span>
        </v-tooltip>

        <v-tooltip v-if="type === 'qr'" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              :color="item.blacklisted ? 'white' : ''"
            >
              mdi-qrcode-scan
            </v-icon>
          </template>
          <span>{{ $t("users.qr") }}</span>
        </v-tooltip>

        <v-tooltip v-if="type === 'card'" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-bind="attrs"
              v-on="on"
              small
              :color="item.blacklisted ? 'white' : ''"
            >
              mdi-card-bulleted-outline
            </v-icon>
          </template>
          <span>{{ $t("users.card") }}</span>
        </v-tooltip>
      </span>
    </template>
  </DataTable>
</template>

<script>
import { isBase64 } from "validator";

import DataTable from "@/components/datatable";
import ImgProtected from "@/components/ImgProtected";

import imgProtectedMixin from "@/mixins/imgProtected";

export default {
  components: { DataTable, ImgProtected },

  mixins: [imgProtectedMixin],

  props: {
    data: {
      type: Array,
      default: () => [],
    },

    rowstyle: Function,
  },

  data() {
    return {
      fields: [
        {
          name: "identity_types",
          style: "width: 16px",
        },
        {
          name: "avatar",
          style: "width: 50px;",
        },
        {
          group: [
            {
              name: "full_name",
              title: this.$t("users.fullname"),
              btag: "",
              atag: "",
            },
            {
              name: "purpose",
              title: this.$t("users.device"),
              style: "font-size: 13px; float: left; margin-right: 3px;",
            },
            {
              name: "utype",
              title: this.$t("users.status"),
              btag: " / ",
              style: "font-size: 13px; float: left;",
            },
            {
              name: "temperature",
              title: this.$t("users.temperature"),
              btag: "",
              style: "font-size: 13px; float: left; margin-left: 3px;",
            },
          ],
          header: { text: this.$t("users.visitor") },
        },
        {
          name: "visits",
          header: { text: this.$t("users.visit_time") },
          style: "width: 50px;",
        },
      ],
    };
  },

  methods: {
    onClick(item) {
      this.$emit("click", item);
    },

    onDblClick(item) {
      this.$emit("dblclick", item);
    },

    async onHoverImg(img, isProtected) {
      let image = img;

      if (isProtected) {
        await this.getImage(img);
        image = this.imgProtected;
      }

      this.$emit("hoverImg", image);
    },

    onLeaveHoverImg(img) {
      this.$emit("leaveHoverImg", img);
    },

    isPhotoBase64(photo) {
      if (photo) {
        const index = photo.indexOf(",");
        const photoBase64 = photo.slice(index + 1);

        return isBase64(photoBase64);
      }

      return false;
    },
  },
};
</script>
