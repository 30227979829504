<template>
  <div>
    <v-row v-if="errorMessage">
      <v-col>
        <Alert type="error">
          {{ errorMessage }}
        </Alert>
      </v-col>
    </v-row>

    <v-row>
      <v-spacer />
      <v-col v-if="isAddRequest" cols="12" md="auto">
        <v-menu offset-y left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="primary">
              <v-icon left> mdi-plus </v-icon>
              {{ $t("button.add") }}&nbsp;
              <v-icon right> mdi-menu-down </v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              link
              :to="{
                name: this.$const.ROUTES.ADD_PERMIT_REQUEST,
                params: {
                  query: $route.query,
                },
              }"
            >
              <v-list-item-title>{{
                $t("requests.oneRequest")
              }}</v-list-item-title>
            </v-list-item>

            <v-list-item
              link
              :to="{
                name: this.$const.ROUTES.ADD_MULTI_PERMIT_REQUEST,
                params: {
                  query: $route.query,
                },
              }"
            >
              <v-list-item-title>{{ $t("button.multiAdd") }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <v-data-table
      :loading="isPending"
      :headers="headers"
      :items="requests"
      :server-items-length="pagination.per_page"
      :options.sync="options"
      hide-default-footer
    >
      <template v-slot:top>
        <RequestsFilter
          v-model="filter"
          @input="onChangeFilter"
          class="mb-2 mt-5"
        />
      </template>

      <template #[`item.person_name`]="{ item }">
        <router-link
          :to="{
            name: $const.ROUTES.EDIT_PERMIT_REQUEST,
            params: { id: item.id, query: $route.query },
          }"
          >{{ item.person_name }}</router-link
        >
      </template>

      <template #[`item.status`]="{ item }">
        <v-chip :color="getColorByStatus(item.status)" outlined small>
          <v-icon left small>
            {{ getIconByStatus(item.status) }}
          </v-icon>
          {{ getStatusText(item.status) }}
        </v-chip>
      </template>

      <template #[`item.created_at`]="{ item }">
        {{ item.created_at | moment("DD.MM.YYYY HH:mm") }}
      </template>

      <template #[`item.actions`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              small
              icon
              color="green"
              @click="goToEdit(item.id)"
            >
              <v-icon small>mdi-eye</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("button.show") }}</span>
        </v-tooltip>

        <v-tooltip v-if="isRequestConfirm(item.status)" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              small
              color="green"
              @click="confirmRequest(item.id)"
            >
              <v-icon small> mdi-check </v-icon>
            </v-btn>
          </template>
          <span>{{ $t("requests.confirmRequest") }}</span>
        </v-tooltip>

        <v-tooltip v-if="isRequestCancel(item)" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              small
              color="red"
              @click="cancelRequest(item.id)"
            >
              <v-icon small> mdi-close </v-icon>
            </v-btn>
          </template>
          <span>{{ $t("requests.cancelRequest") }}</span>
        </v-tooltip>
      </template>

      <template #footer>
        <DataTableFooter
          ref="tableFooter"
          :pagination="pagination"
          :totalText="$t('requests.total')"
          :perPage="limit"
          @changePagination="onChangePagination"
          @changePerPage="onChangePerPage"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

import Alert from "@/components/Alert";
import RequestsFilter from "./Filter";
import DataTableFooter from "@/components/DataTableFooter";

import permissions from "@/mixins/permissions";
import filter from "@/mixins/filter";

export default {
  name: "RequestsList",

  mixins: [permissions, filter],

  components: { Alert, RequestsFilter, DataTableFooter },

  data() {
    return {
      search: "",
      page: parseInt(this.$route.query.page) || 1,

      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: this.$t("requests.name"),
          value: "person_name",
        },
        {
          text: this.$t("requests.status"),
          value: "status",
          sortable: false,
        },
        {
          text: this.$t("requests.date"),
          value: "created_at",
        },
        {
          value: "actions",
          align: "end",
          sortable: false,
          width: 121,
        },
      ],

      options: {},
    };
  },

  watch: {
    options: {
      handler() {
        this.getRequests();
      },
      deep: true,
    },
  },

  methods: {
    ...mapMutations({
      setLimit: "requests/setLimit",
    }),

    ...mapActions({
      getRequestsAction: "requests/get",
      setStatus: "request/setStatus",
    }),

    onChangeFilter(filter) {
      this.updateFilter({ ...filter });
      this.page = 1;
      this.$refs.tableFooter.onChangePage(this.page);
    },

    onChangePerPage(limit) {
      this.setLimit(limit);
      this.page = 1;
      this.$refs.tableFooter.onChangePage(this.page);
    },

    onChangePagination(page) {
      this.page = page;

      this.getRequests();
    },

    getRequests() {
      const params = {};

      if (this.page) params.page = this.page;
      if (this.limit) params.limit = this.limit;
      if (this.options.sortBy && this.options.sortBy.length)
        params.sortName = this.options.sortBy[0];
      if (
        params.sortName &&
        this.options.sortDesc &&
        this.options.sortDesc.length
      )
        params.sortOrder = this.options.sortDesc[0] ? "desc" : "asc";

      if (this.filter.search) params.search = this.filter.search;
      if (this.filter.excludedStatus)
        params.excludedStatus = this.filter.excludedStatus;
      if (this.filter.userType) params.userType = this.filter.userType;
      if (this.filter.onlyActive) params.onlyActive = this.filter.onlyActive;
      if (this.filter.onePass !== null) params.onePass = this.filter.onePass;

      this.getRequestsAction(params);
    },

    goToEdit(id) {
      this.$router.push({
        name: this.$const.ROUTES.EDIT_PERMIT_REQUEST,
        params: { id, query: this.$route.query },
      });
    },

    async confirmRequest(id) {
      await this.setStatus({ id, status: 1 });

      this.getRequests();
    },

    async cancelRequest(id) {
      await this.setStatus({ id, status: 3 });

      this.getRequests();
    },

    isRequestConfirm(status) {
      return this.can(this.$const.RULES.REQUEST_CONFIRM) && status === 0;
    },

    isRequestCancel(item) {
      return (
        (this.$auth.user().admin_id === item.owner_id ||
          this.can(this.$const.RULES.REQUEST_CANCEL)) &&
        item.status !== 3 &&
        item.status !== 4
      );
    },

    getStatusText(code) {
      switch (code) {
        case 0:
          return this.$t("requests.statusNew");
        case 1:
          return this.$t("requests.statusWait");
        case 2:
          return this.$t("requests.statusDone");
        case 3:
          return this.$t("requests.statusCancel");
        case 4:
          return this.$t("requests.statusUserDelete");
        default:
          break;
      }
    },

    getColorByStatus(status) {
      switch (status) {
        case 0:
          return "blue";
        case 1:
          return "orange";
        case 2:
          return "green";
        case 3:
          return "";
        default:
          return "";
      }
    },

    getIconByStatus(status) {
      switch (status) {
        case 0:
          return "mdi-new-box";
        case 1:
          return "mdi-clock";
        case 2:
          return "mdi-check";
        case 3:
          return "mdi-cancel";
        case 4:
          return "mdi-account-remove";
        default:
          return "";
      }
    },
  },

  computed: {
    ...mapState({
      isPending: (state) => state.requests.isPending,
      requests: (state) => state.requests.data,
      limit: (state) => state.requests.limit,
      pagination: (state) => state.requests.pagination,
      errorMessage: (state) => state.requests.errorMessage,
    }),

    isAddRequest() {
      return this.can(this.$const.RULES.REQUEST_ADD);
    },
  },

  created() {
    this.initFilter({
      excludedStatus: [1, 2],
      userType: null,
      onlyActive: false,
      onePass: null,
      search: "",
    });
  },
};
</script>
