<template>
  <v-row>
    <v-col cols="6">
      <v-select
        v-model="userTypeState"
        :items="templates"
        item-text="name"
        item-value="type"
        outlined
        clearable
        hide-details
        dense
        :label="$t('permits.permits')"
        @change="onChange"
      />
    </v-col>

    <v-col cols="6">
      <v-select
        v-model="zoneIdState"
        :items="zoneIdOptions"
        outlined
        clearable
        hide-details
        dense
        :label="$t('operators.zone')"
        @change="onChange"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
export default {
  props: {
    typeUser: {
      type: String,
      default: null,
    },

    zoneId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      userTypeState: this.typeUser,

      zoneIdState: this.zoneId,
      zoneIdOptions: [],
    };
  },

  methods: {
    ...mapActions({
      getTemplates: "permitTemplates/get",
    }),

    onChange() {
      this.$emit("change", {
        typeUser: this.userTypeState,
        zoneId: this.zoneIdState,
      });
    },

    getZones() {
      this.axios("zones").then((response) => {
        const userZones = this.role.allowed_zones;

        const zones = response.data.data.map((zone) => ({
          value: zone.id,
          text: zone.name,
        }));

        if (this.user.is_admin || userZones.length === 0) {
          this.zoneIdOptions = zones;
        } else {
          this.zoneIdOptions = zones.filter((zone) =>
            userZones.includes(zone.value)
          );
        }
      });
    },
  },

  computed: {
    ...mapState({
      isPendingTemplates: (state) => state.permitTemplates.isPending,
      templates: (state) => state.permitTemplates.data,
      user: (state) => state.user.data,
    }),
    ...mapGetters({
      role: "user/getRole",
    }),
  },

  created() {
    this.getTemplates();
    this.getZones();
  },
};
</script>
