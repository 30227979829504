import Vue from "vue";
import i18n from "@/plugins/i18n";

export default {
  get({ commit }, { id }) {
    commit("setLoading", { type: "IS_RESET_STORE" });
    commit("setLoading", { type: "IS_PENDING" });

    return Vue.axios(`person/${id}`)
      .then((response) => {
        const { data } = response.data;

        commit("set", data);
        commit("setLoading", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.errorLoading"),
        });
      });
  },

  getVisits({ commit }, { id, month }) {
    const params = {};

    if (month) params.month = month;

    commit("setLoadingVisits", { type: "IS_PENDING" });

    return Vue.axios(`person/${id}/passeds`, { params })
      .then((response) => {
        const { data } = response.data;

        commit("setVisits", data);
        commit("setLoadingVisits", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoadingVisits", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.errorLoading"),
        });
      });
  },

  store({ commit }, data) {
    commit("setLoading", { type: "IS_PENDING_STORE" });

    return Vue.axios
      .post("persons/add", data)
      .then(() => {
        commit("setLoading", { type: "IS_SUCCESS_STORE" });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE_STORE",
          payload: error?.response?.data?.status?.message || "",
        });

        commit("setStoreErrorData", error?.response?.data?.data || null);

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.saveError"),
        });
      });
  },

  edit({ commit }, { id, data }) {
    commit("setLoading", { type: "IS_PENDING_STORE" });

    return Vue.axios
      .put(`person/${id}`, data)
      .then(() => {
        commit("setLoading", { type: "IS_SUCCESS_STORE" });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE_STORE",
          payload: error?.response?.data?.status?.message || "",
        });

        commit("setStoreErrorData", error?.response?.data?.data || null);

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.saveError"),
        });
      });
  },

  refeature({ commit }, { id }) {
    commit("setLoadingRefeature", { type: "IS_PENDING" });

    return Vue.axios
      .put(`person/${id}/refeature`)
      .then(() => {
        commit("setLoadingRefeature", { type: "IS_SUCCESS" });

        Vue.prototype.$notify({
          group: "info",
          type: "success",
          text: i18n.t("message.refeature_success"),
        });
      })
      .catch((error) => {
        commit("setLoadingRefeature", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.saveError"),
        });
      });
  },
};
