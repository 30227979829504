export default {
  data() {
    const items = [
      {
        id: "PERSON",
        name: this.$t("rules.personGroup"),
        children: [
          {
            id: this.$const.RULES.PERSON_VIEW,
            name: this.$t(`rules.short.${this.$const.RULES.PERSON_VIEW}`),
          },
          {
            id: this.$const.RULES.PERSON_ADD,
            name: this.$t(`rules.short.${this.$const.RULES.PERSON_ADD}`),
            info: this.$t("rules.isDependenceWithView"),
          },
          {
            id: "personEditGroup",
            name: this.$t("rules.editGroup"),
            info: this.$t("rules.isDependenceWithView"),
            children: [
              {
                id: this.$const.RULES.PERSON_EDIT,
                name: this.$t(`rules.short.${this.$const.RULES.PERSON_EDIT}`),
              },
              {
                id: this.$const.RULES.PERSON_DELETE,
                name: this.$t(`rules.short.${this.$const.RULES.PERSON_DELETE}`),
              },
              {
                id: this.$const.RULES.PERSON_RESTORE,
                name: this.$t(
                  `rules.short.${this.$const.RULES.PERSON_RESTORE}`
                ),
              },
              {
                id: this.$const.RULES.PERSON_BLOCKED,
                name: this.$t(
                  `rules.short.${this.$const.RULES.PERSON_BLOCKED}`
                ),
              },
              {
                id: this.$const.RULES.PERSON_BLACKED,
                name: this.$t(
                  `rules.short.${this.$const.RULES.PERSON_BLACKED}`
                ),
              },
            ],
          },
        ],
      },

      // TODO: uncomment when permits will not depend on persons
      // {
      //   id: "PERMIT",
      //   name: this.$t("rules.permitsGroup"),
      //   info: this.$t("rules.isDependencePermit"),
      //   children: [
      //     {
      //       id: this.$const.RULES.PERMIT_ADD,
      //       name: this.$t(`rules.short.${this.$const.RULES.PERMIT_ADD}`),
      //     },
      //     {
      //       id: this.$const.RULES.PERMIT_EDIT,
      //       name: this.$t(`rules.short.${this.$const.RULES.PERMIT_EDIT}`),
      //     },
      //   ],
      // },

      {
        id: "DEPARTMENT",
        name: this.$t("rules.departmentsGroup"),
        children: [
          {
            id: this.$const.RULES.DEPARTMENT_VIEW,
            name: this.$t(`rules.short.${this.$const.RULES.DEPARTMENT_VIEW}`),
          },
          {
            id: this.$const.RULES.DEPARTMENT_ADD,
            name: this.$t(`rules.short.${this.$const.RULES.DEPARTMENT_ADD}`),
            info: this.$t("rules.isDependenceWithView"),
          },
          {
            id: "departmentsEditGroup",
            name: this.$t("rules.editGroup"),
            info: this.$t("rules.isDependenceWithView"),
            children: [
              {
                id: this.$const.RULES.DEPARTMENT_EDIT,
                name: this.$t(
                  `rules.short.${this.$const.RULES.DEPARTMENT_EDIT}`
                ),
              },
              {
                id: this.$const.RULES.DEPARTMENT_DELETE,
                name: this.$t(
                  `rules.short.${this.$const.RULES.DEPARTMENT_DELETE}`
                ),
              },
            ],
          },
        ],
      },

      {
        id: "ACCESS_LEVELS",
        name: this.$t("rules.accessLevelsGroup"),
        children: [
          {
            id: this.$const.RULES.ACCESS_LEVELS_VIEW,
            name: this.$t(
              `rules.short.${this.$const.RULES.ACCESS_LEVELS_VIEW}`
            ),
          },
          {
            id: this.$const.RULES.ACCESS_LEVELS_ADD,
            name: this.$t(`rules.short.${this.$const.RULES.ACCESS_LEVELS_ADD}`),
            info: this.$t("rules.isDependenceWithView"),
          },
          {
            id: "accessLevelsEditGroup",
            name: this.$t("rules.editGroup"),
            info: this.$t("rules.isDependenceWithView"),
            children: [
              {
                id: this.$const.RULES.ACCESS_LEVELS_EDIT,
                name: this.$t(
                  `rules.short.${this.$const.RULES.ACCESS_LEVELS_EDIT}`
                ),
              },
              {
                id: this.$const.RULES.ACCESS_LEVELS_DELETE,
                name: this.$t(
                  `rules.short.${this.$const.RULES.ACCESS_LEVELS_DELETE}`
                ),
              },
            ],
          },
        ],
      },

      {
        id: "REQUEST",
        name: this.$t("rules.requestsGroup"),
        children: [
          {
            id: this.$const.RULES.REQUEST_VIEW,
            name: this.$t(`rules.short.${this.$const.RULES.REQUEST_VIEW}`),
          },
          {
            id: this.$const.RULES.REQUEST_ADD,
            name: this.$t(`rules.short.${this.$const.RULES.REQUEST_ADD}`),
            info: this.$t("rules.isDependenceWithView"),
          },
          {
            id: "requestsEditGroup",
            name: this.$t("rules.editGroup"),
            info: this.$t("rules.isDependenceWithView"),
            children: [
              {
                id: this.$const.RULES.REQUEST_CONFIRM,
                name: this.$t(
                  `rules.short.${this.$const.RULES.REQUEST_CONFIRM}`
                ),
              },
              {
                id: this.$const.RULES.REQUEST_CANCEL,
                name: this.$t(
                  `rules.short.${this.$const.RULES.REQUEST_CANCEL}`
                ),
              },
            ],
          },
        ],
      },

      {
        id: "OPERATOR",
        name: this.$t("rules.operatorsGroup"),
        children: [
          {
            id: this.$const.RULES.OPERATOR_VIEW,
            name: this.$t(`rules.short.${this.$const.RULES.OPERATOR_VIEW}`),
          },
          {
            id: this.$const.RULES.OPERATOR_ADD,
            name: this.$t(`rules.short.${this.$const.RULES.OPERATOR_ADD}`),
            info: this.$t("rules.isDependenceWithView"),
          },
          {
            id: "operatorsEditGroup",
            name: this.$t("rules.editGroup"),
            info: this.$t("rules.isDependenceWithView"),
            children: [
              {
                id: this.$const.RULES.OPERATOR_EDIT,
                name: this.$t(`rules.short.${this.$const.RULES.OPERATOR_EDIT}`),
              },
              {
                id: this.$const.RULES.OPERATOR_DELETE,
                name: this.$t(
                  `rules.short.${this.$const.RULES.OPERATOR_DELETE}`
                ),
              },
            ],
          },
        ],
      },

      {
        id: "DEVICE",
        name: this.$t("rules.devicesGroup"),
        children: [
          {
            id: this.$const.RULES.DEVICE_VIEW,
            name: this.$t(`rules.short.${this.$const.RULES.DEVICE_VIEW}`),
          },
          {
            id: this.$const.RULES.DEVICE_ADD,
            name: this.$t(`rules.short.${this.$const.RULES.DEVICE_ADD}`),
            info: this.$t("rules.isDependenceWithView"),
          },
          {
            id: this.$const.RULES.DEVICE_EDIT,
            name: this.$t(`rules.short.${this.$const.RULES.DEVICE_EDIT}`),
            info: this.$t("rules.isDependenceWithView"),
          },
          {
            id: this.$const.RULES.DEVICE_ACTIONS,
            name: this.$t(`rules.short.${this.$const.RULES.DEVICE_ACTIONS}`),
          },
        ],
      },

      {
        id: "ZONE",
        name: this.$t("rules.zonesGroup"),
        children: [
          {
            id: this.$const.RULES.ZONE_VIEW,
            name: this.$t(`rules.short.${this.$const.RULES.ZONE_VIEW}`),
          },
          {
            id: this.$const.RULES.ZONE_ADD,
            name: this.$t(`rules.short.${this.$const.RULES.ZONE_ADD}`),
            info: this.$t("rules.isDependenceWithView"),
          },
          {
            id: "zonesEditGroup",
            name: this.$t("rules.editGroup"),
            info: this.$t("rules.isDependenceWithView"),
            children: [
              {
                id: this.$const.RULES.ZONE_EDIT,
                name: this.$t(`rules.short.${this.$const.RULES.ZONE_EDIT}`),
              },
              {
                id: this.$const.RULES.ZONE_DELETE,
                name: this.$t(`rules.short.${this.$const.RULES.ZONE_DELETE}`),
              },
            ],
          },
        ],
      },

      {
        id: this.$const.RULES.MONITORING_VIEW,
        name: this.$t(`rules.short.${this.$const.RULES.MONITORING_VIEW}`),
      },

      {
        id: this.$const.RULES.JOURNAL_VIEW,
        name: this.$t(`rules.short.${this.$const.RULES.JOURNAL_VIEW}`),
      },

      {
        id: this.$const.RULES.SETTINGS_VIEW,
        name: this.$t(`rules.short.${this.$const.RULES.SETTINGS_VIEW}`),
      },
    ];

    return {
      items,
    };
  },
};
