<template>
  <v-row class="d-flex align-center">
    <v-col class="d-flex align-center justify-end">
      <date-picker
        v-model="range"
        type="time"
        format="HH:mm"
        value-type="format"
        range
        confirm
        :disabled="disabled"
        @confirm="submit"
        @clear="submit"
      />
    </v-col>
    <v-col cols="auto">
      <v-btn icon color="red" @click="cancel">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      range: this.rangeToArray(this.value.time_from, this.value.time_to),
    };
  },

  methods: {
    rangeToArray(timeFrom, timeTo) {
      let range = ["", ""];

      if (timeFrom && timeTo) {
        range = [timeFrom, timeTo];
      }

      return range;
    },

    submit() {
      this.$emit("input", {
        id: this.value.id,
        time_from: this.range[0],
        time_to: this.range[1],
      });
    },

    cancel() {
      this.$emit("cancel", this.value.id);
    },
  },
};
</script>
