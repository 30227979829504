import Vue from "vue";
import i18n from "@/plugins/i18n";

export default {
  get(
    { commit },
    {
      lazy = false,
      page,
      limit,
      search,
      sortName,
      sortOrder,
      departments,
      accesslevels,
      mode,
      permittype,
    } = {}
  ) {
    const params = {};

    if (page) params.page = page;
    if (limit) params.limit = limit;
    if (search) params.search = search;
    if (sortName) params.sortName = sortName;
    if (sortOrder) params.sortOrder = sortOrder;
    if (departments) params.departments = departments;
    if (accesslevels) params.accesslevels = accesslevels;
    if (mode) params.mode = mode;
    if (permittype) params.permittype = permittype;

    commit("setLoading", { type: "IS_PENDING" });

    return Vue.axios("persons", { params })
      .then((response) => {
        const {
          data,
          meta: { pagination },
        } = response.data;

        if (lazy) {
          commit("lazySet", { data, pagination });
        } else {
          commit("set", { data, pagination });
        }
        commit("setLoading", { type: "IS_SUCCESS" });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.errorLoading"),
        });
      });
  },

  remove({ commit }, params) {
    commit("setLoading", { type: "IS_PENDING_REMOVE" });

    return Vue.axios
      .post("persons/delete", params)
      .then(() => {
        commit("setLoading", { type: "IS_SUCCESS_REMOVE" });

        Vue.prototype.$notify({
          group: "info",
          type: "success",
          text:
            params.ids && params.ids.length <= 1
              ? i18n.t("message.userDeleteSuccess")
              : i18n.t("message.usersDeleteSuccess"),
        });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE_REMOVE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.deleteError"),
        });
      });
  },

  block({ commit }, params) {
    commit("setLoading", { type: "IS_PENDING_REMOVE" });

    return Vue.axios
      .put("persons/blocked", params)
      .then(() => {
        commit("setLoading", { type: "IS_SUCCESS_REMOVE" });

        Vue.prototype.$notify({
          group: "info",
          type: "success",
          text:
            params.ids && params.ids.length <= 1
              ? i18n.t("message.userBlockSuccess")
              : i18n.t("message.usersBlockSuccess"),
        });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE_REMOVE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.saveError"),
        });
      });
  },

  unblock({ commit }, params) {
    commit("setLoading", { type: "IS_PENDING_REMOVE" });

    return Vue.axios
      .put("persons/unblocked", params)
      .then(() => {
        commit("setLoading", { type: "IS_SUCCESS_REMOVE" });

        Vue.prototype.$notify({
          group: "info",
          type: "success",
          text:
            params.ids && params.ids.length <= 1
              ? i18n.t("message.userBlockSuccess")
              : i18n.t("message.usersBlockSuccess"),
        });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE_REMOVE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.saveError"),
        });
      });
  },

  black({ commit }, params) {
    commit("setLoading", { type: "IS_PENDING_REMOVE" });

    return Vue.axios
      .put("persons/blacklisted", params)
      .then(() => {
        commit("setLoading", { type: "IS_SUCCESS_REMOVE" });

        Vue.prototype.$notify({
          group: "info",
          type: "success",
          text:
            params.ids && params.ids.length <= 1
              ? i18n.t("message.userBlacklistSuccess")
              : i18n.t("message.usersBlacklistSuccess"),
        });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE_REMOVE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.saveError"),
        });
      });
  },

  unblack({ commit }, params) {
    commit("setLoading", { type: "IS_PENDING_REMOVE" });

    return Vue.axios
      .put("persons/unblacklisted", params)
      .then(() => {
        commit("setLoading", { type: "IS_SUCCESS_REMOVE" });

        Vue.prototype.$notify({
          group: "info",
          type: "success",
          text:
            params.ids && params.ids.length <= 1
              ? i18n.t("message.userBlacklistSuccess")
              : i18n.t("message.usersBlacklistSuccess"),
        });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE_REMOVE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.saveError"),
        });
      });
  },

  clear({ commit }, ids) {
    commit("setLoading", { type: "IS_PENDING_REMOVE" });

    return Vue.axios
      .put("persons/clear", { ids })
      .then(() => {
        commit("setLoading", { type: "IS_SUCCESS_REMOVE" });

        Vue.prototype.$notify({
          group: "info",
          type: "success",
          text:
            ids.length > 1
              ? i18n.t("message.usersDeleteSuccess")
              : i18n.t("message.userDeleteSuccess"),
        });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE_REMOVE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.saveError"),
        });
      });
  },

  clearAll({ commit }) {
    commit("setLoading", { type: "IS_PENDING_REMOVE" });

    return Vue.axios
      .put("persons/trash")
      .then(() => {
        commit("setLoading", { type: "IS_SUCCESS_REMOVE" });

        Vue.prototype.$notify({
          group: "info",
          type: "success",
          text: i18n.t("message.usersDeleteSuccess"),
        });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE_REMOVE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.saveError"),
        });
      });
  },

  restore({ commit }, params) {
    commit("setLoading", { type: "IS_PENDING_REMOVE" });

    return Vue.axios
      .put("persons/restore", params)
      .then(() => {
        commit("setLoading", { type: "IS_SUCCESS_REMOVE" });

        Vue.prototype.$notify({
          group: "info",
          type: "success",
          text:
            params.ids && params.ids.length <= 1
              ? i18n.t("message.userRestoreSuccess")
              : i18n.t("message.usersRestoreSuccess"),
        });
      })
      .catch((error) => {
        commit("setLoading", {
          type: "IS_FAILURE_REMOVE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.saveError"),
        });
      });
  },

  report({ state, rootState }, data) {
    const params = {
      page: state.pagination.current_page,
      lang: rootState.settings.data.lang,
      limit: -1,
      ...data,
    };

    const request = {
      method: "get",
      params,
      url: "report/users",
      responseType: "blob",
    };

    return Vue.axios
      .request(request)
      .then((response) => {
        const filename = `users-${Vue.moment().format("YYYYMMDDHHmmss")}.xlsx`;

        const url = window.URL.createObjectURL(
          new Blob([response.data], {
            type: "application/xlsx",
          })
        );

        const link = document.createElement("a");

        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch(() => {
        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("users.notUsers"),
        });
      });
  },

  checkPhone({ commit }, { id, phone }) {
    commit("setLoadingCheckPhone", { type: "IS_PENDING" });

    return Vue.axios("persons/check/phone", { params: { id, phone } })
      .then((response) => {
        commit("setLoadingCheckPhone", { type: "IS_SUCCESS" });
        commit("setCheckPhone", response.data.data);
      })
      .catch((error) => {
        commit("setLoadingCheckPhone", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.errorLoading"),
        });
      });
  },

  checkEmail({ commit }, { id, email }) {
    commit("setLoadingCheckEmail", { type: "IS_PENDING" });

    return Vue.axios("persons/check/email", { params: { id, email } })
      .then((response) => {
        commit("setLoadingCheckEmail", { type: "IS_SUCCESS" });
        commit("setCheckEmail", response.data.data);
      })
      .catch((error) => {
        commit("setLoadingCheckEmail", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.errorLoading"),
        });
      });
  },

  checkToken({ commit }, { id, token, type = "card" }) {
    return Vue.axios("persons/check/token", {
      params: { id, token, type },
    }).catch(() => {
      Vue.prototype.$notify({
        group: "info",
        type: "error",
        text: i18n.t("message.errorLoading"),
      });
    });
  },

  multiSetDepartment({ commit }, params) {
    commit("setLoadingMultiSetDepartment", { type: "IS_PENDING" });

    return Vue.axios
      .post("persons/departments", params)
      .then(() => {
        commit("setLoadingMultiSetDepartment", { type: "IS_SUCCESS" });

        Vue.prototype.$notify({
          group: "info",
          type: "success",
          text:
            params.ids && params.ids.length <= 1
              ? i18n.t("message.userSetDepartmentSuccess")
              : i18n.t("message.usersSetDepartmentSuccess"),
        });
      })
      .catch((error) => {
        commit("setLoadingMultiSetDepartment", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.saveError"),
        });
      });
  },

  multiSetAccessLevels({ commit }, params) {
    commit("setLoadingMultiSetAccessLevel", { type: "IS_PENDING" });

    return Vue.axios
      .post("permits/accesslevels", params)
      .then(() => {
        commit("setLoadingMultiSetAccessLevel", { type: "IS_SUCCESS" });

        Vue.prototype.$notify({
          group: "info",
          type: "success",
          text:
            params.ids && params.ids.length <= 1
              ? i18n.t("message.userSetAccessLevelSuccess")
              : i18n.t("message.usersSetAccessLevelSuccess"),
        });
      })
      .catch((error) => {
        commit("setLoadingMultiSetAccessLevel", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.saveError"),
        });
      });
  },

  dropAll({ commit }) {
    commit("setLoadingDelAllPersons", { type: "IS_PENDING" });
    return Vue.axios
      .delete("/persons/all")
      .then(() => {
        commit("setLoadingDelAllPersons", { type: "IS_SUCCESS" });
        Vue.prototype.$notify({
          group: "info",
          type: "success",
          text: i18n.t("message.delete_success"),
        });
      })
      .catch((error) => {
        commit("setLoadingDelAllPersons", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });
        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.deleteError"),
        });
      });
  },

  importByPhoto({ commit }, formData, params) {
    commit("setLoadingPersonsPhoto", { type: "IS_PENDING" });

    return Vue.axios
      .post("/persons/import/photo", formData, params)

      .then((response) => {
        commit("setLoadingPersonsPhoto", { type: "IS_SUCCESS" });
      })

      .catch((error) => {
        commit("setLoadingPersonsPhoto", {
          type: "IS_FAILURE",
          payload: error?.response?.data?.status?.message || "",
        });

        Vue.prototype.$notify({
          group: "info",
          type: "error",
          text: i18n.t("message.saveError"),
        });
      });
  },
};
