<template>
  <v-card class="mx-auto" outlined color="#F3F2FF">
    <v-card-text>
      <v-row>
        <v-col cols="auto">
          <date-picker
            :value="dateRange"
            type="datetime"
            range
            :format="DATE_FORMAT"
            value-type="format"
            :placeholder="$t('advanced.dateTime')"
            confirm
            :clearable="false"
            :disabled-date="notBeforeAndAfter100Days"
            @confirm="submit('dateRange', $event)"
            @pick="onPickDate"
            @close="onCloseDatepicker"
          >
            <template v-slot:header>
              {{ $t("advanced.range100days") }}
            </template>
          </date-picker>
        </v-col>

        <v-col cols="auto">
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-badge
                color="primary"
                bordered
                overlap
                :content="identityTypes.length"
                :value="identityTypes.length ? true : false"
              >
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  depressed
                  color="blue"
                  dark
                >
                  {{ $t("advanced.identityTypes") }}&nbsp;
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </v-badge>
            </template>

            <v-card>
              <v-list>
                <v-list-item-group
                  :value="identityTypes"
                  multiple
                  @change="submit('identityTypes', $event)"
                >
                  <v-list-item value="qr">
                    <template v-slot:default="{ active }">
                      <v-list-item-action>
                        <v-checkbox :input-value="active" color="primary" />
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("advanced.qr")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-item>

                  <v-list-item value="card">
                    <template v-slot:default="{ active }">
                      <v-list-item-action>
                        <v-checkbox :input-value="active" color="primary" />
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("advanced.card")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-item>

                  <v-list-item value="face">
                    <template v-slot:default="{ active }">
                      <v-list-item-action>
                        <v-checkbox :input-value="active" color="primary" />
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>{{
                          $t("advanced.face")
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-menu>
        </v-col>

        <v-col cols="auto">
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-badge
                color="primary"
                bordered
                overlap
                :content="userType.length"
                :value="userType.length ? true : false"
              >
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  depressed
                  color="blue"
                  dark
                >
                  {{ $t("permits.permits") }}&nbsp;
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </v-badge>
            </template>

            <v-card :loading="isPendingTemplates">
              <v-list>
                <v-list-item-group
                  :value="userType"
                  multiple
                  @change="submit('userType', $event)"
                >
                  <v-list-item
                    v-for="(template, index) in templates"
                    :key="index"
                    :value="template.type"
                  >
                    <template v-slot:default="{ active }">
                      <v-list-item-action>
                        <v-checkbox :input-value="active" color="primary" />
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>{{
                          template.name
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-menu>
        </v-col>

        <v-col cols="auto">
          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-badge
                color="primary"
                bordered
                overlap
                :content="subdivisions.length"
                :value="subdivisions.length ? true : false"
              >
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  depressed
                  color="blue"
                  dark
                >
                  {{ $t("users.subdivision") }}&nbsp;
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </v-badge>
            </template>

            <v-card max-height="400" max-width="500">
              <v-text-field
                v-model="searchSubdivisions"
                :label="$t('datatable.search')"
                flat
                solo
                hide-details
                clearable
                clear-icon="mdi-close-circle-outline"
              />
              <v-divider class="m-0" />
              <v-list>
                <v-treeview
                  :value="subdivisions"
                  selectable
                  :items="subdivisionsList"
                  item-text="name"
                  selection-type="all"
                  :search="searchSubdivisions"
                  :open-all="searchSubdivisions ? true : false"
                  @input="submit('subdivisions', $event)"
                >
                  <template
                    v-if="item.is_guest"
                    slot="append"
                    slot-scope="{ item }"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" class="mr-2" small
                          >mdi-account-clock</v-icon
                        >
                      </template>
                      <span>{{ $t("subdiv.guest") }}</span>
                    </v-tooltip>
                  </template>
                </v-treeview>
              </v-list>
            </v-card>
          </v-menu>
        </v-col>

        <v-col cols="auto">
          <v-menu offset-y :max-width="400" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-badge
                color="primary"
                bordered
                overlap
                :content="departments.length"
                :value="departments.length ? true : false"
              >
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  small
                  depressed
                  color="blue"
                  dark
                >
                  {{ $t("users.department") }}&nbsp;
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </v-badge>
            </template>

            <v-card max-height="400" max-width="500">
              <v-text-field
                v-model="searchDepartments"
                :label="$t('datatable.search')"
                flat
                solo
                hide-details
                clearable
                clear-icon="mdi-close-circle-outline"
              />
              <v-divider class="m-0" />
              <v-list>
                <v-treeview
                  :value="departments"
                  selectable
                  :items="departmentsList"
                  item-text="name"
                  selection-type="independent"
                  :search="searchDepartments"
                  :open-all="searchDepartments ? true : false"
                  @input="submit('departments', $event)"
                />
              </v-list>
            </v-card>
          </v-menu>
        </v-col>

        <v-col cols="auto">
          <v-switch
            :value="value.isOnObject"
            :label="$t('menu.advanced')"
            hide-details
            class="mt-0"
            @change="submit('isOnObject', $event)"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <Search
            :value="value.search"
            :searchParams="searchParams"
            :saveSearchHistory="$const.SEARCH_HISTORY.ANALYTICS"
            @search="submit('search', $event)"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapState } from "vuex";

import Search from "@/components/Search";

const DATE_FORMAT = "DD.MM.YYYY HH:mm";

export default {
  components: {
    Search,
  },

  props: {
    value: {
      type: Object,
      default: () => ({
        identityTypes: [],
        userType: [],
        subdivisions: [],
        departments: [],
        isOnObject: false,
        search: "",
        startDate: this.$moment().startOf("month").format(DATE_FORMAT),
        endDate: this.$moment().endOf("month").format(DATE_FORMAT),
      }),
    },
  },

  data() {
    return {
      pickDate: "",
      isFirstPickDate: true,

      subdivisionsList: [],
      searchSubdivisions: "",

      departmentsList: [],
      searchDepartments: "",

      DATE_FORMAT,

      searchParams: [
        {
          value: "name",
          text: this.$t("user.name"),
        },
        {
          value: "identety_value",
          text: this.$t("advanced.identityValue"),
        },
        {
          value: "zone",
          text: this.$t("advanced.zone"),
        },
      ],
    };
  },

  methods: {
    ...mapActions({
      getTemplates: "permitTemplates/get",
    }),

    submit(key, value) {
      if (key === "dateRange") {
        const startDate = this.$moment(value[0], DATE_FORMAT).toISOString();

        const endDate = this.$moment(value[1], DATE_FORMAT).toISOString();

        this.$emit("input", { ...this.value, startDate, endDate });
      } else {
        this.$emit("input", { ...this.value, [key]: value });
      }
    },

    getSubdivisions() {
      this.axios("accesslevels/tree").then((response) => {
        this.subdivisionsList = response.data.data;
      });
    },

    getDepartments() {
      this.axios("departments/tree").then((response) => {
        this.departmentsList = response.data.data;
      });
    },

    notBeforeAndAfter100Days(day) {
      let isDisabled = false;

      if (this.pickDate) {
        const currentDay = this.$moment(day);
        const pickerDay = this.$moment(this.pickDate);
        const before100Day = this.$moment(pickerDay).add(-99, "days");
        const after100Day = this.$moment(pickerDay).add(99, "days");

        if (
          currentDay.isAfter(after100Day) ||
          currentDay.isBefore(before100Day)
        ) {
          isDisabled = true;
        }
      }

      return isDisabled;
    },

    onPickDate(date) {
      if (this.isFirstPickDate) {
        this.pickDate = date;
        this.isFirstPickDate = false;
      }
    },

    onCloseDatepicker() {
      this.pickDate = "";
      this.isFirstPickDate = true;

      this.submit("dateRange", []);
    },
  },

  computed: {
    ...mapState({
      isPendingTemplates: (state) => state.permitTemplates.isPending,
      templates: (state) => state.permitTemplates.data,
    }),

    dateRange() {
      return [
        this.$moment(this.value.startDate).format(DATE_FORMAT),
        this.$moment(this.value.endDate).format(DATE_FORMAT),
      ];
    },

    identityTypes() {
      let identityTypes = [];

      if (Array.isArray(this.value.identityTypes)) {
        identityTypes = this.value.identityTypes;
      } else {
        identityTypes = [this.value.identityTypes];
      }

      return identityTypes;
    },

    userType() {
      let userType = [];

      if (Array.isArray(this.value.userType)) {
        userType = this.value.userType;
      } else {
        userType = [this.value.userType];
      }

      return userType;
    },

    subdivisions() {
      let subdivisions = [];

      if (Array.isArray(this.value.subdivisions)) {
        subdivisions = this.value.subdivisions;
      } else {
        subdivisions = [this.value.subdivisions];
      }

      return subdivisions;
    },

    departments() {
      let departments = [];

      if (Array.isArray(this.value.departments)) {
        departments = this.value.departments;
      } else {
        departments = [this.value.departments];
      }

      return departments;
    },
  },

  created() {
    this.getSubdivisions();
    this.getDepartments();
    this.getTemplates();
  },
};
</script>
