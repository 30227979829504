<template>
  <v-dialog :value="isOpen" max-width="700px" @click:outside="$emit('close')">
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t("users.errorLogs") }}</span>
      </v-card-title>

      <v-card-text>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left" />
                <th class="text-left" />
                <th class="text-left">{{ $t("devices.deviceType") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in data" :key="index">
                <td>
                  <v-icon v-if="item.type === 'error'" color="error"
                    >mdi-alert-circle-outline</v-icon
                  >
                  <v-icon v-else-if="item.type === 'warning'" color="warning"
                    >mdi-alert-outline</v-icon
                  >
                  <v-icon v-else color="info">mdi-information-outline</v-icon>
                </td>
                <td>
                  {{ item.message }}
                </td>
                <td>
                  {{ item.device }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn text @click="$emit('close')">{{ $t("button.close") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "PersonErrorsDialog",

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
