import snakeCase from "lodash/snakeCase";

const requireModule = require.context(".", true, /^(?!.*(index)).*\.js$/);

const CONSTANTS = {};

requireModule.keys().forEach((fileName) => {
  const name = fileName.split("/")[1].replace(/(\.\/|\.js)/g, "");
  const snakeCaseName = snakeCase(name).toUpperCase();

  CONSTANTS[snakeCaseName] = {
    ...requireModule(fileName).default,
  };
});

CONSTANTS.install = function (Vue) {
  Vue.prototype.$const = CONSTANTS;
};

export default CONSTANTS;
