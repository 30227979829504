import actions from "./actions";
import mutations from "./mutations";

const state = {
  isPending: false,
  isSuccess: false,
  isFailure: false,
  errorMessage: "",

  isPendingVisits: false,
  isSuccessVisits: false,
  isFailureVisits: false,
  errorMessageVisits: "",

  isPendingStore: false,
  isSuccessStore: false,
  isFailureStore: false,
  errorMessageStore: "",
  errorDataStore: null,

  isPendingRefeature: false,
  isSuccessRefeature: false,
  isFailureRefeature: false,
  errorMessageRefeature: "",

  data: null,
  visits: null,
};

export default {
  state,
  mutations,
  actions,
};
