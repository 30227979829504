<template>
  <div>
    <v-row v-if="errorMessage">
      <v-col>
        <Alert type="error">
          {{ errorMessage }}
        </Alert>
      </v-col>
    </v-row>

    <v-row v-if="can($const.RULES.OPERATOR_ADD)">
      <v-spacer />
      <v-col cols="12" md="auto">
        <v-btn color="primary" @click="goToAdd">
          <v-icon left> mdi-plus </v-icon>
          {{ $t("button.add") }}
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :loading="isPending"
      :headers="headers"
      :items="operators"
      :server-items-length="pagination.per_page"
      hide-default-footer
      disable-sort
    >
      <template v-slot:top>
        <OperatorsFilter
          v-model="filter"
          @input="onChangeFilter"
          class="mb-2 mt-5"
        />
      </template>

      <template #[`item.avatar`]="{ item }">
        <v-avatar v-if="item.photo" class="mt-3 mb-3">
          <ImgProtected :src="`avatar/small/${item.photo}`" :alt="item.name" />
        </v-avatar>

        <v-avatar v-else color="primary" size="50" class="mt-3 mb-3">
          <v-icon dark> mdi-account-circle </v-icon>
        </v-avatar>
      </template>

      <template #[`item.name`]="{ item }">
        <router-link
          v-if="can($const.RULES.OPERATOR_EDIT)"
          :to="{
            name: $const.ROUTES.EDIT_OPERATOR,
            params: {
              id: item.id,
              query: $route.query,
            },
          }"
          >{{ item.name }}</router-link
        >
        <template v-else>
          {{ item.name }}
        </template>
      </template>

      <template #[`item.actions`]="{ item }">
        <v-tooltip v-if="can($const.RULES.OPERATOR_EDIT)" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              small
              icon
              color="primary"
              @click="goToEdit(item.id)"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("button.edit") }}</span>
        </v-tooltip>

        <v-tooltip v-if="can($const.RULES.OPERATOR_DELETE)" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              small
              icon
              color="red"
              @click="
                onRemove({
                  id: item.id,
                  isBindPerson: item.persons_id ? true : false,
                })
              "
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("button.delete") }}</span>
        </v-tooltip>
      </template>

      <template #footer>
        <DataTableFooter
          ref="tableFooter"
          :pagination="pagination"
          :totalText="$t('operators.total')"
          :perPage="limit"
          @changePagination="onChangePagination"
          @changePerPage="onChangePerPage"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

import OperatorsFilter from "./Filter";

import Alert from "@/components/Alert";
import ImgProtected from "@/components/ImgProtected";
import DataTableFooter from "@/components/DataTableFooter";

import permissions from "@/mixins/permissions";
import filter from "@/mixins/filter";

export default {
  name: "OperatorsList",

  mixins: [permissions, filter],

  components: { Alert, ImgProtected, DataTableFooter, OperatorsFilter },

  data() {
    return {
      page: parseInt(this.$route.query.page) || 1,

      headers: [
        {
          value: "avatar",
          width: 90,
        },
        {
          text: this.$t("operators.name"),
          value: "name",
        },
        {
          text: this.$t("operators.login"),
          value: "username",
        },
        {
          text: "ID",
          value: "id",
        },
        {
          value: "actions",
          align: "end",
          width: 121,
        },
      ],
    };
  },

  methods: {
    ...mapMutations({
      setLimit: "operators/setLimit",
    }),

    ...mapActions({
      getOperatorsAction: "operators/get",
      remove: "operator/remove",
    }),

    onChangeFilter(filter) {
      this.updateFilter({ ...filter });
      this.page = 1;
      this.$refs.tableFooter.onChangePage(this.page);
    },

    onChangePerPage(limit) {
      this.setLimit(limit);
      this.page = 1;
      this.$refs.tableFooter.onChangePage(this.page);
    },

    onChangePagination(page) {
      this.page = page;

      this.getOperators();
    },

    getOperators() {
      const params = { ...this.filterToParams };

      if (this.page) params.page = this.page;
      if (this.limit) params.limit = this.limit;

      this.getOperatorsAction(params);
    },

    goToAdd() {
      this.$router.push({
        name: this.$const.ROUTES.ADD_OPERATOR,
        params: {
          query: this.$route.query,
        },
      });
    },

    goToEdit(id) {
      this.$router.push({
        name: this.$const.ROUTES.EDIT_OPERATOR,
        params: {
          id,
          query: this.$route.query,
        },
      });
    },

    onRemove(data) {
      this.$root.$prompt({
        title: this.$t("message.Delete"),
        message: this.$t("message.confirmDelete"),
        btnAgree: this.$t("button.delete"),
        agree: () => this.delete(data),
      });
    },

    async delete({ id, isBindPerson = false }) {
      const deleteOperator = async (isBindPerson = false) => {
        const params = { id };

        if (isBindPerson) {
          params.is_delete_person = true;
        }

        await this.remove(params);
        this.getOperators();
      };

      if (this.can(this.$const.RULES.PERSON_DELETE) && isBindPerson) {
        this.$root.$prompt({
          title: this.$t("operator.deleteBindingPerson"),
          message: this.$t("operator.deleteBindingPersonMessage"),
          width: 500,
          btnAgree: this.$t("common.yes"),
          btnDisagree: this.$t("common.no"),

          agree: async () => {
            deleteOperator(true);
          },

          disagree: async () => {
            deleteOperator(false);
          },
        });
      } else {
        deleteOperator();
      }
    },
  },

  computed: {
    ...mapState({
      isPending: (state) => state.operators.isPending,
      errorMessage: (state) => state.operators.errorMessage,
      operators: (state) => state.operators.data,
      limit: (state) => state.operators.limit,
      pagination: (state) => state.operators.pagination,
    }),

    filterToParams() {
      const params = {};

      if (this.filter.search) params.search = this.filter.search;

      return params;
    },
  },

  created() {
    this.initFilter({
      search: "",
    });

    this.getOperators();
  },
};
</script>
