<template>
  <div>
    <v-row class="mb-2 mt-5">
      <v-col v-if="errorMessage" cols="12">
        <Alert type="error">
          {{ errorMessage }}
        </Alert>
      </v-col>
      <v-col cols="12">
        <AccessLevelsFilter @change="onChangeFilter" />
      </v-col>
    </v-row>

    <v-skeleton-loader v-if="isPending" class="mx-auto" type="list-item@3" />

    <v-treeview
      v-if="isSuccess"
      :items="accessLevels"
      :search="search"
      dense
      hoverable
      open-on-click
      transition
    >
      <template v-slot:label="{ item }">
        <router-link
          v-if="can($const.RULES.ACCESS_LEVELS_EDIT)"
          :to="{
            name: $const.ROUTES.EDIT_ACCESS_LEVEL,
            params: { id: item.id },
          }"
        >
          {{ item.name }}
        </router-link>
        <template v-else>
          {{ item.name }}
        </template>
      </template>

      <template v-slot:prepend="{ item }">
        <v-tooltip v-if="can($const.RULES.ACCESS_LEVELS_DELETE)" top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              small
              icon
              color="red"
              @click="onRemove(item.id)"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("access-levels.delete") }}</span>
        </v-tooltip>
        <v-tooltip v-if="item.is_guest" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" small>mdi-account-clock</v-icon>
          </template>
          <span>{{ $t("access-levels.isGuest") }}</span>
        </v-tooltip>
      </template>
    </v-treeview>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import Alert from "@/components/Alert";
import AccessLevelsFilter from "./Filter";

import permissions from "@/mixins/permissions";

export default {
  name: "AccessLevelsTree",

  mixins: [permissions],

  components: { Alert, AccessLevelsFilter },

  data() {
    return {
      search: "",
    };
  },

  methods: {
    ...mapActions({
      get: "accessLevels/getTree",
      remove: "accessLevel/remove",
    }),

    goToEdit(id) {
      this.$router.push({
        name: this.$const.ROUTES.EDIT_ACCESS_LEVEL,
        params: { id },
      });
    },

    onRemove(id) {
      if (!id) return;

      this.$root.$prompt({
        title: this.$t("message.Delete"),
        message: this.$t("message.confirmDelete"),
        btnAgree: this.$t("button.delete"),

        agree: async () => {
          await this.remove(id);
          this.get();
        },
      });
    },

    getParentNameById(id) {
      const accessLevel = this.accessLevels.filter(
        (accessLevel) => accessLevel.id === id
      );

      return accessLevel.name;
    },

    onChangeFilter(filter) {
      this.search = filter.search;
    },
  },

  computed: {
    ...mapState({
      isPending: (state) => state.accessLevels.isPending,
      isSuccess: (state) => state.accessLevels.isSuccess,
      errorMessage: (state) => state.accessLevels.errorMessage,
      accessLevels: (state) => state.accessLevels.tree,
    }),
  },

  created() {
    this.get();
  },
};
</script>
