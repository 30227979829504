<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card elevation="2" :loading="isPendingStore">
      <template slot="progress">
        <v-progress-linear color="primary" height="10" indeterminate />
      </template>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="name"
              :label="`${$t('departments.name')} *`"
              :rules="nameRules"
              outlined
            />
          </v-col>

          <v-col cols="12" md="6">
            <v-select
              v-model.number="parentId"
              :items="departments(id)"
              :label="$t('departments.parent')"
              item-value="id"
              item-text="name"
              outlined
              clearable
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn :loading="isPendingStore" @click="cancel">
          <v-icon left>mdi-cancel</v-icon>
          {{ $t("button.cancel") }}
        </v-btn>
        <v-spacer />
        <v-btn color="green" dark :loading="isPendingStore" @click="save">
          <v-icon left>mdi-content-save-outline</v-icon>
          {{ $t("button.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "DepartmentForm",

  props: {
    id: {
      type: Number,
      default: 0,
    },
    data: {
      type: Object,
      default: null,
    },
    isPendingStore: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const name = this.data?.name || "";
    const parentId = this.data?.parent?.id || null;

    return {
      valid: true,

      name,
      parentId,

      nameRules: [(v) => !!v || this.$t("message.NameIsRequired")],
    };
  },

  computed: {
    ...mapGetters({
      departments: "departments/getListExcludeById",
    }),
  },

  methods: {
    ...mapActions({ getDepartments: "departments/getList" }),

    cancel() {
      this.$emit("cancel");
    },

    save() {
      if (!this.$refs.form.validate()) return;

      this.$emit("store", {
        name: this.name,
        parent_id: this.parentId,
      });
    },
  },

  created() {
    const params = {};

    if (this.id) {
      params.exclude_id_nested = this.id;
    }

    this.getDepartments(params);
  },
};
</script>
